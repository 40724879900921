var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-popup',{attrs:{"title":"Настройки анализа показателей оценки качества","icon":_vm.UtilGetIcon('view-AuditIndicators'),"width":_vm.width,"height":_vm.height,"buttonSaveText":"Применить"},on:{"save":_vm.save},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{staticClass:"block__content",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":"Референтное значение 1","rules":[_vm.rules.required],"readonly":_vm.readonly},model:{value:(_vm.dataSource.RefValue1),callback:function ($$v) {_vm.$set(_vm.dataSource, "RefValue1", $$v)},expression:"dataSource.RefValue1"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":"Референтное значение 2","readonly":_vm.readonly,"disabled":!_vm.dataSource.RefValue1 && _vm.dataSource.RefValue1 !== 0},model:{value:(_vm.dataSource.RefValue2),callback:function ($$v) {_vm.$set(_vm.dataSource, "RefValue2", $$v)},expression:"dataSource.RefValue2"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":"Референтное значение 3","readonly":_vm.readonly,"disabled":(!_vm.dataSource.RefValue2 && _vm.dataSource.RefValue2 !== 0) ||
              (!_vm.dataSource.RefValue1 && _vm.dataSource.RefValue1 !== 0)},model:{value:(_vm.dataSource.RefValue3),callback:function ($$v) {_vm.$set(_vm.dataSource, "RefValue3", $$v)},expression:"dataSource.RefValue3"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{directives:[{name:"up-first-symbol",rawName:"v-up-first-symbol"}],attrs:{"label":"Описание результата анализа показателя оценки качества между нулем и первым референтным значением ПОК","rows":"3","required":"","rules":[_vm.rules.required],"readonly":_vm.readonly},model:{value:(_vm.dataSource.AnalisysDescription1),callback:function ($$v) {_vm.$set(_vm.dataSource, "AnalisysDescription1", $$v)},expression:"dataSource.AnalisysDescription1"}}),_c('v-textarea',{directives:[{name:"up-first-symbol",rawName:"v-up-first-symbol"}],attrs:{"label":"Описание результата анализа показателя оценки качества между первым и вторым референтным значением ПОК","rows":"3","required":"","rules":[_vm.rules.required],"readonly":_vm.readonly},model:{value:(_vm.dataSource.AnalisysDescription2),callback:function ($$v) {_vm.$set(_vm.dataSource, "AnalisysDescription2", $$v)},expression:"dataSource.AnalisysDescription2"}}),_c('v-textarea',{directives:[{name:"up-first-symbol",rawName:"v-up-first-symbol"}],attrs:{"label":"Описание результата анализа показателя оценки качества между вторым и третьим референтным значением ПОК","rows":"3","readonly":_vm.readonly,"disabled":!_vm.dataSource.AnalisysDescription1 ||
              !_vm.dataSource.AnalisysDescription2 ||
              (!_vm.dataSource.RefValue2 && _vm.dataSource.RefValue2 !== 0)},model:{value:(_vm.dataSource.AnalisysDescription3),callback:function ($$v) {_vm.$set(_vm.dataSource, "AnalisysDescription3", $$v)},expression:"dataSource.AnalisysDescription3"}}),_c('v-textarea',{directives:[{name:"up-first-symbol",rawName:"v-up-first-symbol"}],attrs:{"label":"Описание результата анализа показателя оценки качества между третьим референтным значением ПОК и 100","rows":"3","readonly":_vm.readonly,"disabled":!_vm.dataSource.AnalisysDescription1 ||
              !_vm.dataSource.AnalisysDescription2 ||
              !_vm.dataSource.AnalisysDescription3 ||
              (!_vm.dataSource.RefValue3 && _vm.dataSource.RefValue3 !== 0)},model:{value:(_vm.dataSource.AnalisysDescription4),callback:function ($$v) {_vm.$set(_vm.dataSource, "AnalisysDescription4", $$v)},expression:"dataSource.AnalisysDescription4"}}),_c('m-autocomplete',{attrs:{"items":_vm.auditIndicators,"item-text":(item) => item.Name,"item-value":(e) => e,"label":"Показатели оценки качества по умолчанию","deletable-chips":"","multiple":"","title":"Показатели оценки качества по умолчанию"},model:{value:(_vm.dataSource.auditIndicators),callback:function ($$v) {_vm.$set(_vm.dataSource, "auditIndicators", $$v)},expression:"dataSource.auditIndicators"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }