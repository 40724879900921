<template>
  <base-detail-view
    :loading="loading"
    title="Организация"
    :subTitle="dataSource.Name"
    :isUnsaved="isUnsaved && valid"
    @onRefresh="init"
    @onSave="save"
  >
    <v-form class="block__content" v-model="valid">
      <v-container>
        <v-row>
          <v-col>
            <m-select
              v-model="dataSource.Type"
              :items="OrganizationTypes"
              label="Тип организации"
              :clearable="false"
              :readonly="readOnly"
              item-text="text"
            ></m-select>
            <v-text-field
              v-model="dataSource.Name"
              label="Краткое наименование"
              maxlength="100"
              required
              :rules="[rules.required]"
              :clearable="!readOnly"
              :readonly="readOnly"
              v-up-first-symbol
            ></v-text-field>
            <v-text-field
              v-model="dataSource.FullName"
              label="Полное наименование"
              maxlength="200"
              required
              :rules="[rules.required]"
              :clearable="!readOnly"
              :readonly="readOnly"
              v-up-first-symbol
            ></v-text-field>
            <v-text-field
              v-if="dataSource.Type === 0"
              v-model="dataSource.LegalAddress"
              label="Юридический адрес"
              maxlength="150"
              :clearable="!readOnly"
              :readonly="readOnly"
              v-up-first-symbol
            ></v-text-field>
            <v-text-field
              v-model="dataSource.FactAddress"
              label="Адрес регистрации"
              maxlength="150"
              :clearable="!readOnly"
              :readonly="readOnly"
              v-up-first-symbol
            ></v-text-field>
            <v-text-field
              v-model="dataSource.MailAddress"
              label="Почтовый адрес"
              maxlength="100"
              :clearable="!readOnly"
              :readonly="readOnly"
              v-up-first-symbol
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="dataSource.OGRN"
              maxlength="15"
              :label="dataSource.Type === 0 ? 'ОГРН' : 'ОГРИП'"
              :rules="[rules.digits]"
              :clearable="!readOnly"
              :readonly="readOnly"
            ></v-text-field>
            <v-text-field
              v-model="dataSource.INN"
              label="ИНН"
              maxlength="12"
              :rules="[rules.digits]"
              :clearable="!readOnly"
              :readonly="readOnly"
            ></v-text-field>
            <v-text-field
              v-if="dataSource.Type === 0"
              v-model="dataSource.KPP"
              label="КПП"
              maxlength="9"
              :rules="[rules.digits]"
              :clearable="!readOnly"
              :readonly="readOnly"
            ></v-text-field>
            <v-text-field
              v-model="dataSource.Phone"
              label="Номер телефона"
              maxlength="30"
              :clearable="!readOnly"
              :readonly="readOnly"
            ></v-text-field>
            <v-text-field
              v-model="dataSource.Email"
              label="Электронная почта"
              maxlength="50"
              :rules="[rules.email]"
              :clearable="!readOnly"
              :readonly="readOnly"
            ></v-text-field>
            <v-text-field
              v-model="dataSource.WebSite"
              label="Сайт"
              maxlength="50"
              :clearable="!readOnly"
              :readonly="readOnly"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </base-detail-view>
</template>

<script>
import OrganizationService from "../../services/OrganizationService";
import { OrganizationTypes } from "../../data/enums";
import Permissions from "../../data/permissions";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";
import UnsavedMixin from "../../mixins/UnsavedMixin.js";
import BaseDetailView from "../../layouts/BaseDetailView.vue";
import MSelect from "../../components/base/inputs/mSelect.vue";

export default {
  name: "view-OrganizationEdit",
  components: {
    BaseDetailView,
    MSelect,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  data: function () {
    return {
      apiService: OrganizationService,
      loading: true,
      valid: false,
      dataSource: {
        Name: "",
      },
      rules: {
        required: (value) => !!value || "Укажите значение.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !value || pattern.test(value) || "Некорректный e-mail.";
        },
        digits: (value) => {
          const pattern = /^\d+$/;
          return (
            !value ||
            pattern.test(value) ||
            "Значение должно содержать только цифры."
          );
        },
      },
      readOnly: !this.hasPermission(Permissions.OrganizationEdit),
    };
  },
  computed: {
    OrganizationTypes: () =>
      OrganizationTypes.map((t) => {
        return { Id: t.id, text: t.value };
      }),
  },
  async mounted() {
    await this.init();
  },
};
</script>

<style></style>
