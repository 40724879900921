import notification from "./notification.vue";
import { io } from "socket.io-client";
import $store from "@/store/";

function Install(Vue, options = {}) {
  const property = options.property || "$notification";
  delete options.property;
  const vuetify = options.vuetify;
  delete options.vuetify;

  const router = options.router;
  delete options.router;

  const store = options.store;
  delete options.store;

  if (!vuetify) {
    console.warn(
      "Module vuetify-notification needs vuetify instance. Use Vue.use(Vuetifynotification, { vuetify })"
    );
  }
  const Ctor = Vue.extend(
    Object.assign({ vuetify, router, store }, notification)
  );
  function createnotificationCmp(options) {
    const container =
      document.querySelector("[data-app=true]") || document.body;
    return new Promise((resolve) => {
      const cmp = new Ctor(
        Object.assign(
          {},
          {
            propsData: Object.assign(
              {},
              Vue.prototype[property].options,
              options
            ),
            destroyed: () => {
              container.removeChild(cmp.$el);
              resolve(cmp.value);
            },
          }
        )
      );
      container.appendChild(cmp.$mount().$el);
    });
  }

  function show({ message, header, notificationItem }, options = {}) {
    options.message = message;
    options.header = header;
    options.notificationItem = notificationItem;
    return createnotificationCmp(options);
  }
  Vue.prototype[property] = show;
  Vue.prototype[property].options = options || {};

  init(store, show);
  $store.dispatch("notification/loadNotificationCounts");
}

function init(store, show) {
  const url = process.env.VUE_APP_SERVER_URL.slice(0, -5);
  const socket = io.connect(url, {
    path: "/socket/",
    auth: {
      token: store.state.AccessToken,
    },
  });

  // client-side
  socket.on("connect", () => {
    console.log(socket.id);
  });

  socket.on("disconnect", () => {
    console.log(socket.id);
  });

  socket.on("message", async function (message) {
    try {
      console.log("message: ", message, JSON.parse(message));
      const obj = JSON.parse(message);
      show({
        message: obj.Text,
        header: obj.Header,
        notificationItem: obj,
      });
      $store.dispatch("notification/incrementCounts", obj.MenuList); // Обновление количество опв
    } catch (error) {
      console.log(message);
    }
  });
}

if (typeof window !== "undefined" && window.Vue) {
  window.Vue.use(Install);
}

export default Install;
