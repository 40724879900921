<template>
  <base-table-expanded :colspan="$attrs['headers'].length">
    <v-row>
      <v-col>
        <table>
          <tr>
            <td>Содержание:</td>
            <td>{{ Task.Content }}</td>
          </tr>
        </table>
      </v-col>
      <v-col>
        <table>
          <tr>
            <td>
              <div>Результат:</div>
            </td>
            <td>
              <div>{{ Task.Result }}</div>
            </td>
          </tr>
        </table>
      </v-col>
    </v-row>
  </base-table-expanded>
</template>

<script>
import BaseTableExpanded from "@/layouts/BaseTableExpanded";

export default {
  components: { BaseTableExpanded },
  props: {
    Task: Object,
  },
};
</script>

<style></style>
