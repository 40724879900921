import { debounce } from "lodash";
export default {
  data() {
    return {
      options: {},
      groupBy: [],
      totalCount: null,
      loader: null,
      virtualPage: 1,
      $isLoadFilters: false, // Первый раз загрузились фильтры; Обновлять не надо.
    };
  },
  provide() {
    const provideOptions = {};
    Object.defineProperty(provideOptions, "value", {
      enumerable: true,
      get: () => this.options,
    });
    return {
      updatePage: this.updatePage,
      resetPagination: this.resetPagination,
      provideOptions: provideOptions,
    };
  },
  methods: {
    resetPagination() {
      this.virtualPage = 1;
      if (this.loader) this.loader.reset();
      this.totalCount = null;
      this.dataSource = [];
    },
    async updatePage(loader) {
      this.loader = loader;
      if (this.totalCount === null) {
        await new Promise((resolve) => {
          setTimeout(resolve, 1000);
        });
        loader.loaded();
        return;
      }

      if (this.virtualPage * 50 < this.totalCount) {
        try {
          this.virtualPage += 1;
          await this.loadingDataSource();
        } catch (error) {
          this.virtualPage--;
          console.log(error);
          loader.error();
          return;
        }
        loader.loaded();
      } else {
        loader.complete();
      }
    },
    getTotalCount() {
      return this.totalCount;
    },
    updateOptions: debounce(async function () {
      // Первый раз загрузились фильтры
      if (this.options.filters && !this.$isLoadFilters) {
        this.$isLoadFilters = true;
        this.loading = false;
        return;
      }

      this.resetPagination();
      this.init();
    }, 1000),
    async groupInit(args) {
      try {
        this.loading = true;

        let params = args?.query ? args.query : {};

        if (this?.showCompleted === "notdone") {
          params.done = false;
        }

        params = { ...params, ...args };
        params = {
          ...params,
          options: {
            ...this.options,
            page: this.virtualPage,
            itemsPerPage: 50,
            listGroupBy:
              this.options.listGroupByValue?.length &&
              this.options.listGroupByValue[0] === null
                ? []
                : this.options.listGroupBy,
          },
        };

        const res = await this.apiService.getListPerPage.apply(
          null,
          Object.keys(params).length > 0 ? [params, args] : []
        );

        this.loading = false;
        this.totalCount = res.data.TotalCount;
        return res.data.Values;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },
  watch: {
    options: {
      deep: true,
      immediate: true,
      handler() {
        this.loading = true;
        this.updateOptions();
      },
    },
  },
};
