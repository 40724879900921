import * as Enums from "@/data/enums";

const getEnumsValues = function (name) {
  return Enums[name];
};

const getEnums = function (value, name) {
  const options = typeof name === "object" ? name : getEnumsValues(name);

  return typeof options[0] === "object"
    ? options.find((el) => {
        return el.id === value;
      })?.value
    : options[value];
};

export default { getEnums, getEnumsValues };
