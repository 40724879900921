var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tfoms-panel',{staticClass:"analysis-tfoms__base-table analysis-tfoms__lost-income",attrs:{"small":"","title":"Выполнение плана","icon":"mdi-currency-rub","icon-color":"var(--v-error-lighten1)","header-hidden":""}},[_c('div',{staticClass:"d-flex justify-space-around analysis-tfoms__lost-income-wrap",attrs:{"title":""}},[_c('div',{staticClass:"analysis-tfoms__lost-income-mata"},[_c('div',{staticClass:"analysis-tfoms__lost-income-date"},[_vm._v(" "+_vm._s(_vm.tfomsParams.value.textSelectPeriod)+" "+_vm._s(_vm.queryParams.params.year)+" г. ")]),_c('div',{staticClass:"analysis-tfoms__lost-income-name"},[_vm._v("Выполнение плана")]),_c('div',{staticClass:"analysis-tfoms__lost-income-value",class:{
          'error--text': _vm.percent < 100,
          'success--text': _vm.percent > 100,
        }},[_vm._v(" "+_vm._s(_vm.percent)+"% ")])]),_c('div',{staticClass:"mr-8"},[_c('base-table',{attrs:{"items":_vm.data,"headers":[
          {
            text: '',
            value: 'name',
            cellClass: 'text-no-wrap',
            sortable: false,
          },
          {
            text: '',
            value: 'value',
            align: 'center',
            cellClass: 'text-no-wrap',
            sortable: false,
          },
        ],"hide-default-footer":"","notShowSelect":"","nested":"","showSettings":false},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_c('div',[_vm._v(_vm._s(item.name.title))])]}},{key:"item.value",fn:function({ item, index }){return [_c('span',{class:{ 'error--text': item.value < 0 }},[(item.value)?_c('span',[_vm._v(_vm._s(_vm._f("numberSplit")(item.value)))]):_c('span',[_vm._v("-")]),(index == 0 && item.value === 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('m-icon',_vm._g(_vm._b({staticStyle:{"position":"relative","left":"7px","cursor":"pointer"},attrs:{"icon":"mdi-information-outline","color":"warning"}},'m-icon',attrs,false),on))]}}],null,true)},[_vm._v(" Для отражения % выполнения плана по объемам необходимо выбрать условие и вид оказания МП ")]):_vm._e()],1)]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }