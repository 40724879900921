var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-list-view',{ref:"table",attrs:{"title":"Статистические показатели","dataSource":_vm.dataSource,"headers":_vm.headers,"loading":_vm.loading,"selectedItems":_vm.selected,"pageEditName":"StatisticIndicatorEdit","id":"statistic-indicator-values","funcHandleClickId":(item) => (item.ParentId ? item.ParentId : item.Id),"fixedWidthActions":"","showCompleted":_vm.showCompleted,"showCompletedText":"Показать архивные показатели","exclude-settings-actions":['group'],"showFilters":true},on:{"onRefresh":_vm.init,"onDelete":_vm.del,"update:selectedItems":function($event){_vm.selected=$event},"update:selected-items":function($event){_vm.selected=$event},"update:showCompleted":function($event){_vm.showCompleted=$event},"update:show-completed":function($event){_vm.showCompleted=$event}},scopedSlots:_vm._u([{key:"actionBefore",fn:function({ isShowSearch }){return [(!isShowSearch)?_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mr-2",staticStyle:{"font-size":"14px"}},[_vm._v("Итоги за период: ")]),_c('base-date-picker-range',{attrs:{"value":_vm.dates,"label":'',"outlined":"","hide-details":"","dense":""},on:{"input":_vm.updateAllPeriods}})],1):_vm._e()]}},{key:"table",fn:function({ attrs, listeners }){return [_c('base-tree-table',_vm._g(_vm._b({attrs:{"items":_vm.dataSource,"options":"ObjectsSummary","tree-table-contents":"","item-class":(e) => e.IsNotified && 'font-weight-bold'},scopedSlots:_vm._u([{key:"item.DiffValue",fn:function(scope){return [(scope.item.DiffValue === undefined)?[_vm._v("-")]:[_c('v-chip',{class:_vm.StatisticIndicatorHelper.getColorDiff(
                scope.item.DiffValue,
                scope.item.RatingType
              )},[_vm._v(" "+_vm._s((scope.item.DiffValue > 0 ? "+" : "") + (scope.item.DiffValue ? Number(scope.item.DiffValue.toFixed(2)) : "-")))]),_c('span',{staticClass:"statistic-indicator-values__period"},[_vm._v(_vm._s("за " + _vm.periods[scope.item.Period]))])]]}},{key:"item.Value",fn:function({ item }){return [_c('v-chip',{staticClass:"statistic-indicator-values__chip-group",class:_vm.StatisticIndicatorHelper.getColorByRefs(
              [item.RefValue1, item.RefValue2, item.RefValue3],
              item.Value,
              item.RatingType
            )},[_vm._v(_vm._s(item.Value === null ? "-" : Number(item.Value.toFixed(2))))])]}},{key:"item.Date",fn:function({ item }){return [_vm._v(" "+_vm._s(item.Date)+" ")]}},{key:"item.PeriodValue",fn:function({ item }){return [(!item.Level)?_c('div',[_c('span',[_vm._v(_vm._s(item.PeriodValue ? parseFloat(parseFloat(item.PeriodValue).toFixed(2)) : "-"))])]):_vm._e()]}},{key:"item.Period",fn:function({ item }){return [(!item.Level)?_c('div',{staticClass:"d-flex align-center",staticStyle:{"width":"220px"}},[_c('base-date-picker-range',{attrs:{"value":[item.PeriodDate.DateIn, item.PeriodDate.DateOut],"label":'',"hide-details":"","dense":"","left":"","rightered":'true',"hideUnderline":'true',"append-icon":'mdi-chevron-down',"small":'true'},on:{"input":function($event){return _vm.updatePeriod($event, item)}}})],1):_c('div',{staticClass:"d-flex align-center",staticStyle:{"width":"220px"}})]}},{key:"item.Owner",fn:function({ item }){return [_vm._v(" "+_vm._s(item.Owner.Id === _vm.$store.getters.GET_ORGANIZATION.OwnerId ? "-" : item.Owner.Name)+" ")]}},{key:"item.data-table-expand",fn:function({ item, expand, isExpanded }){return [(item.Children && item.Children.length)?_c('td',{staticClass:"text-start"},[_c('v-btn',{staticClass:"v-data-table__expand-icon",class:{ 'v-data-table__expand-icon--active': isExpanded },attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return expand(!isExpanded)}}},[_c('m-icon',{attrs:{"icon":"mdi-chevron-down"}})],1)],1):_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},'base-tree-table',attrs,false),listeners))]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }