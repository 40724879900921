import { debounce } from "lodash";
export default {
  created() {
    fixInputRequired();
  },
};

const fixInputRequired = debounce(function () {
  document
    .querySelectorAll("input[required=required], textarea[required=required]")
    .forEach((e) => {
      const vinput = e.closest(".v-input");
      if (!vinput || !vinput.getAttribute("required")) {
        vinput.setAttribute("required", "required");
      }
    });
}, 100);
