<template>
  <base-popup
    title="Место работы"
    :icon="UtilGetIcon('view-Employees')"
    v-model="dialog"
    @save="$emit('save', dataSource)"
    :hideSave="readonly"
    :width="width"
    :height="height"
  >
    <v-form>
      <v-container>
        <v-row>
          <v-col>
            <m-autocomplete
              label="Должность"
              v-model="dataSource.Position"
              item-text="Name"
              :items="positions"
              :item-value="(e) => e"
              :readonly="readonly"
              :clearable="!readonly"
              dict="Position"
            />
            <base-date-picker
              v-model="dataSource.Period.DateIn"
              label="Дата начала"
              :rules="[rules.required]"
              required
              :readonly="readonly"
            ></base-date-picker>
            <base-date-picker
              v-model="dataSource.Period.DateOut"
              label="Дата окончания"
              :readonly="readonly"
              :clearable="!readonly"
            ></base-date-picker>
            <m-tree-select
              v-model="dataSource.Place"
              :items="partitions"
              selection-type="independent"
              allowSelectParents
              label="Подразделение"
              :readonly="readonly"
              :clearable="!readonly"
              :dict="{ name: 'Partition', notItems: true }"
            ></m-tree-select>
            <v-text-field
              v-model="dataSource.Schedule"
              label="Расписание"
              :readonly="readonly"
              v-up-first-symbol
            ></v-text-field>
            <v-textarea
              v-model="dataSource.Comment"
              label="Примечание"
              :readonly="readonly"
              v-up-first-symbol
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </base-popup>
</template>

<script>
import { mapActions } from "vuex";
import { defaultWorkplace } from "../../data/defaultObjects";
import BasePopup from "../../layouts/BasePopup.vue";
import DataHelper from "../../utils/DataHelper";
import BaseDatePicker from "../base/BaseDatePicker.vue";
import mAutocomplete from "../base/inputs/mAutocomplete.vue";
import MTreeSelect from "../base/inputs/mTreeSelect.vue";

export default {
  name: "view-WorkplaceEdit",
  components: {
    BasePopup,
    BaseDatePicker,
    mAutocomplete,
    MTreeSelect,
  },
  props: {
    workplace: Object,
    value: Boolean,
    readonly: Boolean,
    width: String,
    height: String,
  },
  data: function () {
    return {
      dataSource: defaultWorkplace(),
      positions: [],
      partitions: [],
      rules: {
        required: (value) => !!value || "Укажите значение.",
      },
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        if (value)
          // Workaround: nextTick. При первом вызове окна не отображается установленное подразделение, хотя значение передано.
          // Поле data.initialValue компонента VTreeSelect по какой-то причине не устанавливается.
          // По всей видимости, проблема в компоненте VTreeSelect, обновление vuetify-toolkit до версии 0.4.2 не помогло.
          setTimeout(() => {
            this.dataSource = this.DataFromServerNormalize(
              this._.clone(this.workplace)
            );
          }, 1);
      },
    },
  },
  watch: {
    "$store.state.partitions": function (val) {
      this.partitions = val;
    },
  },
  async mounted() {
    try {
      this.positions = await this.LOAD_POSITIONS();
      this.partitions = await this.LOAD_PARTITIONS();
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    ...mapActions(["LOAD_POSITIONS", "LOAD_PARTITIONS"]),
    DataFromServerNormalize(data) {
      // Необходимо для работы v-tree-select;
      if (data.Place) {
        data.Place = DataHelper.findTreeById(this.partitions, data.Place.Id);
      }
      return data;
    },
  },
};
</script>
