const PlannedPeriodState = [
  { id: 1, value: "Просрочена" },
  { id: 2, value: "Сегодня" },
  { id: 3, value: "На неделе" },
  { id: 4, value: "Свыше недели" },
  { id: 5, value: "Без срока" },
  { id: 6, value: "Завершена" },
];

const AuditOperationStatus = [
  "Новая",
  "Выполняется",
  "На подписи",
  "Завершена",
];

const AuditOperationState = [
  { id: 1, value: "Создал" },
  { id: 2, value: "Начал" },
  { id: 3, value: "Прервал" },
  { id: 4, value: "Подписал" },
  { id: 5, value: "Отозвал подпись" },
  { id: 6, value: "Завершил" },
  { id: 7, value: "Открыл" },
];

const AuditOperationKinds = [
  { id: 0, value: "Плановая" },
  { id: 1, value: "Внеплановая" },
  { id: 2, value: "Пользовательская" },
];

const TaskPriority = [
  { id: 1, value: "Низкий" },
  { id: 2, value: "Средний" },
  { id: 3, value: "Высокий" },
];

const TaskPeriod = [
  { id: 0, value: "Нет" },
  { id: 1, value: "Каждый день" },
  { id: 2, value: "Каждую неделю" },
  { id: 3, value: "Каждый месяц" },
  { id: 4, value: "1 раз в квартал" },
  { id: 5, value: "1 раз в полгода" },
  { id: 6, value: "1 раз в год" },
];

const TaskStatus = [
  { id: 1, value: "Новая" },
  { id: 2, value: "Назначена" },
  { id: 3, value: "Принята в работу" },
  { id: 4, value: "На контроле" },
  { id: 5, value: "Завершена" },
];

const CheckListObjectTypes = [
  { id: 0, value: "Организация" },
  { id: 1, value: "Структурное подразделение" },
  { id: 2, value: "Сотрудник" },
  { id: 4, value: "Произвольный объект" },
  { id: 3, value: "Стандарт оснащения" },
];

const CheckOperationStatus = ["Создана", "Начата", "Завершена"];

const OrganizationOwnerType = [
  { id: 0, value: "Организация ни дочерняя ни родительская" },
  { id: 1, value: "Организация дочерняя" },
  { id: 2, value: "Организация родительская" },
  { id: 3, value: "Организация и родительская и обычная" },
];

const OrganizationTypes = [
  { id: 0, value: "Организация" },
  { id: 1, value: "Индивидуальный предприниматель" },
];

const OrganizationDocumentStatus = [
  { id: -1, value: "Образец" },
  { id: 0, value: "Черновик" },
  { id: 1, value: "На согласовании" },
  { id: 2, value: "Согласован" },
  { id: 3, value: "На Утверждении" },
  { id: 4, value: "Утвержден" },
  { id: 5, value: "Действующий" },
  { id: 6, value: "Отменен" },
];

const OrganizationDocumentType = [
  { Id: 0, Name: "Прочие документы" },
  { Id: 1, Name: "Приказ" },
  { Id: 2, Name: "Распоряжение" },
  { Id: 3, Name: "Положение" },
  { Id: 4, Name: "Политика" },
  { Id: 5, Name: "Решение" },
  { Id: 6, Name: "Протокол" },
  { Id: 7, Name: "Инструкция" },
  { Id: 8, Name: "Должностная инструкция" },
  { Id: 9, Name: "Стандарт процесса" },
  { Id: 10, Name: "Стандартная операционная процедура (СОП)" },
  { Id: 11, Name: "Алгоритм" },
  { Id: 12, Name: "Регламент" },
  { Id: 13, Name: "Правила" },
  { Id: 14, Name: "Письмо" },
  { Id: 15, Name: "Акт" },
  { Id: 16, Name: "Договор" },
  { Id: 17, Name: "Устав" },
  { Id: 18, Name: "Маршрутная карта" },
  { Id: 19, Name: "Справка" },
  { Id: 20, Name: "Постановление" },
  { Id: 21, Name: "Заявление" },
  { Id: 22, Name: "График" },
  { Id: 23, Name: "Записка" },
  { Id: 24, Name: "Карточка" },
  { Id: 25, Name: "Ведомость" },
  { Id: 26, Name: "Паспорт" },
];

const AppliedDocumentTypeObject = [
  { id: 1, value: "Структурное подразделение" },
  { id: 2, value: "Сотрудник" },
  { id: 3, value: "Медицинское оснащение" },
];

const AuditIndicatorAlgorithm = [
  { id: 0, value: "Процентный" },
  { id: 1, value: "Строгий (дихотомический)" },
];

const Period = [
  { id: 0, value: "Помесячно" },
  { id: 1, value: "Поквартально" },
  { id: 2, value: "Год" },
];

const UnwishedEventUnjuredKind = [
  { id: 0, value: "Пациент" },
  { id: 1, value: "Сопровождающий" },
  { id: 2, value: "Сотрудник" },
  { id: 3, value: "Иное лицо" },
];

const StatisticIndicatorRatingType = [
  { id: 0, value: "Чем больше – тем хуже" },
  { id: 1, value: "Чем больше – тем лучше" },
];

const StatisticIndicatorTargetType = [
  { id: 0, value: "Без объекта" },
  { id: 1, value: "Подразделение" },
  { id: 2, value: "Сотрудник" },
];

const StatisticIndicatorPeriod = [
  { id: 6, value: "1 раз в день" },
  { id: 1, value: "1 раз в неделю" },
  { id: 2, value: "1 раз в месяц" },
  { id: 3, value: "1 раз в квартал" },
  { id: 4, value: "1 раз в полгода" },
  { id: 5, value: "1 раз в год" },
];

const StatisticIndicatorSummaryTypes = [
  { id: 0, value: "Суммарное значение" },
  { id: 1, value: "Минимальное значение" },
  { id: 2, value: "Максимальное значение" },
  { id: 3, value: "Среднее значение" },
];

const StatisticIndicatorType = [
  { id: 0, value: "Абсолютный" },
  { id: 1, value: "Относительный" },
];

const CourseStatus = [
  { id: 1, value: "Новый" },
  { id: 2, value: "Обучение" },
  { id: 3, value: "Просрочен" },
  { id: 4, value: "Завершен" },
];

const CourseEmployeeStatus = [
  { id: 1, value: "Новый" },
  { id: 2, value: "В процессе" },
  { id: 3, value: "Завершен" },
];

const SummaryReportStatus = ["Новый", "На подписи", "Подписан"];

const importEmployeesStatus = [
  "В процессе",
  "Успешно завершен",
  "Завершен с ошибкой",
  "Отменен",
  "Импорт не найден",
];

const DocumentAttachedDataType = [
  { id: 0, value: "" },
  { id: 1, value: "Раздел деятельности" },
  { id: 2, value: "ПОК" },
  { id: 3, value: "Чек-лист" },
  { id: 4, value: "Вопрос чек-листа" },
];

export {
  PlannedPeriodState,
  DocumentAttachedDataType,
  importEmployeesStatus,
  CourseEmployeeStatus,
  CourseStatus,
  StatisticIndicatorPeriod,
  StatisticIndicatorTargetType,
  StatisticIndicatorRatingType,
  StatisticIndicatorType,
  AuditOperationStatus,
  AuditOperationState,
  AuditOperationKinds,
  CheckOperationStatus,
  TaskPriority,
  TaskPeriod,
  TaskStatus,
  CheckListObjectTypes,
  OrganizationOwnerType,
  OrganizationTypes,
  OrganizationDocumentStatus,
  OrganizationDocumentType,
  AppliedDocumentTypeObject,
  AuditIndicatorAlgorithm,
  Period,
  UnwishedEventUnjuredKind,
  StatisticIndicatorSummaryTypes,
  SummaryReportStatus,
};
