<template>
  <tfoms-panel
    class="analysis-tfoms__base-table analysis-tfoms__finance"
    small
    :title="title"
    :subTitle="subTitle"
    icon="mdi-currency-rub"
    icon-color="#FB0404"
    :show-more="$attrs['show-more']"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <chart-bar-percent
      v-bind="$attrs"
      v-on="$listeners"
      :factColor="factColor"
      planColor="#FF8761"
      label-plan="Штрафы"
      :label-fact="labelFact"
      :hideDatalabel="hideDatalabel"
    >
    </chart-bar-percent>
  </tfoms-panel>
</template>
<script>
import tfomsPanel from "@/components/analysis/panel.vue";

import chartBarPercent from "@/components/base/chart/chartBarPercent.vue";

export default {
  components: {
    tfomsPanel,
    chartBarPercent,
  },
  inject: {
    provideUnits: {
      default: () => ({
        rub: null,
        unit: null,
      }),
    },
  },

  props: {
    subTitle: {
      type: String,
      default: "2024 год",
    },
    factColor: {
      type: String,
      default: "#F95050",
    },
    labelFact: {
      type: String,
      default: "Финансовые санкции",
    },

    itemName: {
      type: String,
      default: "",
    },

    title: {
      type: String,
      default: "Финансовые санкции",
    },
    onlySanction: {
      type: Boolean,
      default: false,
    },
    hideDatalabel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss">
.analysis-tfoms__finance {
  tr {
    height: 62px !important;
  }
}
</style>
