export function findParent(item, func, colTry, index = 0) {
  if (index === colTry) {
    return false;
  }

  if (func(item)) {
    return item;
  } else {
    return findParent(item?.$parent, func, colTry, ++index);
  }
}
