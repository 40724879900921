<template>
  <div
    style="width: 100%; padding-bottom: 20px; padding-right: 15px"
    class="task-ganta"
  >
    <div class="d-flex align-center mb-2">
      <div class="d-flex align-center mr-2">Фильтр:</div>
      <div class="d-flex">
        <filter-input
          v-for="item in filterHeaders"
          :key="item.value"
          :filter="filters[item.value]"
          :dataSource="tasks"
          :header="item"
          class="ml-2"
          :placeholder="item.text"
          small
        >
        </filter-input>

        <m-select
          v-model="selectSort"
          outlined
          placeholder="Сортировать по"
          dense
          hide-details
          class="ml-5"
          :items="[
            { Id: 2, value: 'По возрастанию даты' },
            { Id: 3, value: 'По убыванию даты' },
          ]"
        ></m-select>
        <v-checkbox
          v-model="showChildren"
          hide-details
          label="Отображать дочерние задачи"
          class="mt-0 ml-4 align-center"
        />
      </div>
      <div class="ml-auto">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <m-icon
              icon="mdi-information-outline"
              color="primary"
              style="cursor: pointer"
              v-bind="attrs"
              v-on="on"
            ></m-icon>
          </template>
          <div class="task-ganta__info">
            Цвета:
            <div class="task-ganta__color-wrap mb-4">
              <div class="task-ganta__color-item">
                <span :style="'background-color:' + colors[2]"></span>Завершены
              </div>
              <div class="task-ganta__color-item">
                <span :style="'background-color:' + colors[3]"></span>Просрочены
              </div>
              <div class="task-ganta__color-item">
                <span :style="'background-color:' + colors[1]"></span>В процессе
              </div>
              <div class="task-ganta__color-item">
                <span :style="'background-color:' + colors[0]"></span>Ожидают
              </div>
            </div>

            Информация:
            <div>
              <div>
                1) Вы можете изменить срок задачи - перетаскивая блок, не
                завершенных задач
              </div>
              <div>
                2) Нажмите и тяните иконку ⮕ на блоке для установки периода
              </div>
              <div>
                3) Для изменение масштаба графика, нажмите ctrl + zoom(колесико
                мыши)
              </div>
              <div>4) Для применения изменений нажмите кнопку "Сохранить"</div>
            </div>
          </div>
        </v-tooltip>
      </div>
    </div>
    <VueDjGantt
      locale="ru"
      resizable
      moveable
      ref="ganta"
      :from="from"
      :to="to"
      :list="list"
      :rows="rows"
      :items="items"
      :height="height"
      @moved="moved"
      @resized="resized"
      @row="itemClick"
    >
      <template slot="data-item-prepend" slot-scope="{ item }">
        <v-tooltip v-show="unsavedItems.includes(item.row.row.Id)" bottom>
          <template v-slot:activator="{ on }">
            <span
              v-show="unsavedItems.includes(item.row.row.Id)"
              v-on="on"
              class="task-ganta__unsaved"
            ></span>
          </template>
          Задача изменена
        </v-tooltip>
      </template>
    </VueDjGantt>
  </div>
</template>
<script>
import { sortBy } from "@/utils/Functions";
import MSelect from "@/components/base/inputs/mSelect.vue";
import { debounce, wrap, memoize, property } from "lodash";
import ItemsFilter from "@/mixins/ItemsFilter.js";
import VueDjGantt from "../../modules/VueGantt/VueDjGantt.vue";
import moment from "moment";

import FilterInput from "../filter/filterInput.vue";

import { TaskStatus } from "@/data/enums";
import DataHelper from "../../utils/DataHelper";

export default {
  components: {
    VueDjGantt,
    FilterInput,
    MSelect,
  },
  mixins: [ItemsFilter],
  props: {
    tasks: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      colors: [
        "var(--v-blue-grey-lighten3)",
        "var(--v-primary-base)",
        "var(--v-success-base)",
        "var(--v-error-base)",
      ], // не начата, в процессе, завершина, просрочена
      from: +(+new Date()), // - 30 * 1 * 24 * 60 * 60 * 1000,
      to: moment().startOf("day").add(1, "months"),
      list: [
        {
          id: "Num",
          width: 80,
          header: {
            content: "Номер",
          },
        },
        {
          id: "Name",
          width: 200,
          header: {
            content: "Задача",
          },
        },
      ],
      rows: [],
      items: [],
      filterHeaders: [
        {
          value: "Author",
          text: "Автор",
          dataType: "object",
          displayText: (e) => this.$options.filters.PersonShortName(e),
        },
        {
          value: "Executors",
          text: "Исполнители",
          dataType: "array",
          displayText: (e) => this.$options.filters.PersonShortName(e),
        },
        {
          value: "Status",
          text: "Статус",
          dataType: "enum",
          options: TaskStatus,
        },
        {
          value: "PlannedPeriod",
          text: "Период",
          dataType: "Period",
        },
      ],
      showChildren: true,
      taskFiltered: null,
      height: 500,
      selectSort: null,
      unsavedItems: [],
    };
  },
  computed: {
    sortedTasks() {
      return this.sortTaskAll(this.tasks);
    },

    filteredTasks() {
      return this.itemsfilter(this.sortedTasks);
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.init();
      },
    },
    showChildren() {
      this.init();
    },
    selectSort() {
      this.init();
    },

    tasks: {
      immediate: true,
      handler(val) {
        this.init(val);
        this.unsavedItems = [];
      },
    },
  },
  mounted() {
    this.$refs.ganta.zoom = 9;
    this.saveDebounced = this.debounceByParam(
      this.updateTask,
      property("rowId"),
      300
    );

    this.initFilter(this.filterHeaders, this.tasks);

    const body = document.body;
    const html = document.documentElement;

    const height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    this.height = height - 310;
  },

  methods: {
    sortTaskAll(tasks) {
      for (let i = 0; i < tasks.length; i++) {
        if (tasks[i].Children.length) {
          tasks[i].Children = this.sortTask(tasks[i].Children);
        }
      }
      return this.sortTask(tasks);
    },
    sortTask(tasks) {
      if (this.selectSort === 2) {
        return sortBy(Object.assign([], tasks), (e) => {
          return moment(e.PlannedPeriod.DateIn, "DD.MM.YYYY").unix();
        });
      } else if (this.selectSort === 3) {
        return sortBy(
          Object.assign([], tasks),
          (e) => {
            return moment(e.PlannedPeriod.DateOut, "DD.MM.YYYY").unix();
          },
          true
        );
      }
      return Object.assign([], tasks);
    },
    itemClick(item) {
      this.editTask(item);
    },
    editTask(task) {
      console.log("editTask TaskGanta");
      this.$store.dispatch("frame/RUN_COMPONENT", {
        componentName: "task/TaskEdit",
        params: {
          _objectId: task.Id,
          _type: "edit",
          _object: task,
          simple: true,
        },
        callback: () => {
          const editedTask = this.$store.getters["frame/GET_DATA"]().object;
          const meta = this.$store.getters["frame/GET_DATA"]().meta;

          if (!editedTask.Name) return;

          const index = this.tasks.findIndex((t) => t.Id === editedTask.Id);
          if (index >= 0) this.$set(this.tasks, index, editedTask);
          else {
            DataHelper.treeListTraverse(this.tasks, (item) => {
              const index = item.Children.findIndex(
                (t) => t.Id === editedTask.Id
              );
              if (index >= 0) {
                // Удаление подзадачи, если она стала самостоятельной
                if (meta._needDelete)
                  item.Children = item.Children.filter(
                    (e) => e.Id !== editedTask.Id
                  );
                else this.$set(item.Children, index, editedTask);
              }
            });
          }

          this.$store.dispatch("frame/SHOW_DIALOG", { show: false });

          this.taskFiltered = this.itemsfilter(this.tasks);
          this.init(this.taskFiltered ? this.taskFiltered : this.tasks);
        },
      });
    },
    updateTask(item) {
      const start = moment(item.time.start).format("DD.MM.YYYY hh:mm:ss");
      const end = moment(item.time.end).format("DD.MM.YYYY hh:mm:ss");
      const task = DataHelper.findTreeById(this.tasks, item.rowId);

      if (task) {
        task.PlannedPeriod.DateIn = start;
        task.PlannedPeriod.DateOut = end;

        this.unsavedItems.push(task.Id);

        const i = this.items.findIndex((e) => e.rowId === task.Id);
        this.items[i].style = { background: this.getTaskColor(task) };
      }
      this.$emit("update:tasks", this.tasks);
    },

    debounceByParam: (targetFunc, resolver, ...debounceParams) =>
      wrap(
        memoize(() => debounce(targetFunc, ...debounceParams), resolver),
        (getMemoizedFunc, ...params) => getMemoizedFunc(...params)(...params)
      ),
    resized(e) {
      this.saveDebounced(e.item.item);
    },
    moved(e) {
      this.saveDebounced(e.item.item);
    },
    getTaskPeriod(task) {
      const t = { start: null, end: null };
      const format = "DD.MM.YYYY";
      const start = moment(task.PlannedPeriod.DateIn, format)
        .clone()
        .startOf("day");
      const end = moment(task.PlannedPeriod.DateOut, format)
        .clone()
        .endOf("day");
      const done = moment(task.Done, format).clone().startOf("day");

      if (!task.PlannedPeriod.DateIn && !task.PlannedPeriod.DateOut) {
        if (task.Done) {
          t.start = done;
          t.end = done.clone().add("1", "days");
        } else {
          t.start = moment().startOf("day");
          t.end = moment().startOf("day").clone().add(1, "days");
        }
      } else if (task.PlannedPeriod.DateIn && !task.PlannedPeriod.DateOut) {
        t.start = start;
        t.end = start.clone().endOf("day");
      } else if (!task.PlannedPeriod.DateIn && task.PlannedPeriod.DateOut) {
        t.start = end.clone().subtract(1, "months");
        t.end = end;
      } else {
        t.start = start;
        t.end = end;
      }
      return t;
    },
    initPeriod(tasks) {
      this.from = +(+new Date());

      let s = this.from;
      let e = this.to;
      const format = "DD.MM.YYYY";

      for (let i = 0; i < tasks.length; i++) {
        const item = tasks[i];
        const ts = moment(item.PlannedPeriod.DateIn, format);
        const te = moment(item.PlannedPeriod.DateOut, format);
        if (item.PlannedPeriod.DateIn && ts < s) {
          s = ts.clone().startOf("day");
        }
        if (item.PlannedPeriod.DateOut && te > e) {
          e = te.clone().endOf("day").add(2, "day");
        }

        this.from = s;
        this.to = e;
      }
    },
    getTaskColor(task) {
      const start = moment(task.PlannedPeriod.DateIn, "DD.MM.YYYY");
      const end = moment(task.PlannedPeriod.DateOut, "DD.MM.YYYY");
      const current = moment();

      if (task.Status === 5) {
        return this.colors[2]; // Выполнена
      } else if (task.PlannedPeriod.DateIn && start > current) {
        return null;
      } else if (!task.PlannedPeriod.DateOut) {
        if (!task.PlannedPeriod.DateIn) return null;
        return this.colors[1]; // В процессе без срока конца
      } else if (end.clone().startOf("day") < current.clone().startOf("day")) {
        return this.colors[3]; // Просрочено
      }

      return this.colors[1];
    },
    init() {
      this.initPeriod(this.filteredTasks);

      this.rows = [];
      this.items = [];

      let tmp = [];
      if (this.showChildren) {
        DataHelper.treeListTraverse(this.filteredTasks, (item) => {
          tmp.push(item);
        });
      } else {
        tmp = this.filteredTasks;
      }

      for (let i = 0; i < tmp.length; i++) {
        const item = tmp[i];
        this.rows.push({ id: item.Id, ...item });

        const time = this.getTaskPeriod(item);
        this.items.push({
          rowId: item.Id,
          label: item.Name,
          style: {
            background: this.getTaskColor(item),
            colors: { Num: this.getTaskColor(item) },
          },
          time: time,
          resizable: item.Status !== 5,
          moveable: item.Status !== 5,
          tooltip: (elem) => {
            return (
              "Исполнители: " +
              (item.Executors.map((e) =>
                this.$options.filters.PersonShortName(e)
              ).join(", ") || "Не указаны") +
              " (" +
              moment(elem.item.time.start).format("DD.MM.YYYY") +
              " - " +
              moment(elem.item.time.end).format("DD.MM.YYYY") +
              ")"
            );
          },
        });
      }
    },
  },
};
</script>
<style lang="scss">
.task-ganta {
  &__unsaved {
    width: 10px;
    height: 10px;
    display: inline-flex;
    background: var(--v-warning-base);
    border-radius: 100%;
    align-self: center;
    margin-right: 6px;
    padding-right: 10px;
  }

  &__color {
    &-wrap {
      display: flex;
      flex-wrap: wrap;
      width: 300px;
    }
    &-item {
      display: flex;
      flex: 50%;
      align-items: center;
      span {
        display: inline-flex;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        margin-right: 5px;
      }
    }
  }
  & .v-input__slot {
    font-size: 14px;
  }
}
</style>
