import DataHelper from "../../utils/DataHelper";

// Добавляет "0" перед однозначным числом
function padZero(n) {
  return n < 10 ? "0" + n : n.toString();
}

// Названия дней и месяцев
const daysShort = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
const daysLong = [
  "Воскресенье",
  "Понедельник",
  "Вторник",
  "Среда",
  "Четверг",
  "Пятница",
  "Суббота",
];
const monthsShort = [
  "Янв",
  "Фев",
  "Мар",
  "Апр",
  "Май",
  "Июн",
  "Июл",
  "Авг",
  "Сен",
  "Окт",
  "Ноя",
  "Дек",
];
const monthsLong = [
  "января",
  "февраля",
  "марта",
  "апреля",
  "мая",
  "июня",
  "июля",
  "августа",
  "сентября",
  "октября",
  "ноября",
  "декабря",
];

// Алиасы в формате даты
const dateAliases = [
  { alias: "д", func: (value) => value.getDate().toString() },
  {
    alias: "дд",
    func: (value) => padZero(value.getDate()),
  },
  {
    alias: "ддд",
    func: (value) => daysShort[value.getDay()],
  },
  {
    alias: "дддд",
    func: (value) => daysLong[value.getDay()],
  },
  {
    alias: "М",
    func: (value) => (value.getMonth() + 1).toString(),
  },
  {
    alias: "ММ",
    func: (value) => padZero(value.getMonth() + 1),
  },
  {
    alias: "МММ",
    func: (value) => monthsShort[value.getMonth()],
  },
  {
    alias: "ММММ",
    func: (value) => monthsLong[value.getMonth()],
  },
  {
    alias: "гг",
    func: (value) => value.getFullYear().toString().slice(2, 4),
  },
  {
    alias: "гггг",
    func: (value) => value.getFullYear().toString(),
  },
  {
    alias: "ч",
    func: (value) => value.getHours() - (value.getHours() > 12 ? 12 : 0),
  },
  {
    alias: "чч",
    func: (value) =>
      padZero(value.getHours() - (value.getHours() > 12 ? 12 : 0)),
  },
  {
    alias: "Ч",
    func: (value) => value.getHours().toString(),
  },
  {
    alias: "ЧЧ",
    func: (value) => padZero(value.getHours()),
  },
  {
    alias: "м",
    func: (value) => value.getMinutes().toString(),
  },
  {
    alias: "мм",
    func: (value) => padZero(value.getMinutes()),
  },
  {
    alias: "с",
    func: (value) => value.getSeconds().toString(),
  },
  {
    alias: "сс",
    func: (value) => padZero(value.getSeconds()),
  },
];

// Форматирование даты
function formatDate(value, format) {
  const date = value instanceof Date ? value : DataHelper.toDateTime(value);
  if (!date) return null;
  if (!format)
    return (
      (date.getDate() < 10 ? "0" : "") +
      date.getDate() +
      "." +
      (date.getMonth() < 9 ? "0" : "") +
      (date.getMonth() + 1) +
      "." +
      date.getFullYear()
    );
  return formatPart(format);

  function formatPart(part) {
    if (!part) return part;
    // Поиск алиаса в обратном порядке, чтобы захватить сначала длинные алиасы, а затем короткие.
    for (let i = dateAliases.length - 1; i >= 0; i--) {
      const index = part.indexOf(dateAliases[i].alias);
      if (index >= 0) {
        let result = "";
        if (index > 0) result += formatPart(part.slice(0, index));
        result += dateAliases[i].func(date);
        if (index + dateAliases[i].alias.length < part.length)
          result += formatPart(part.slice(index + dateAliases[i].alias.length));
        return result;
      }
    }
    return part;
  }
}

// Вспомогательные методы для объектных значений
/* function getCurrentWorkplace(employee) {
  const date = new Date();
  return employee.WorkPlaces.find(
    (wp) =>
      DataHelper.toDate(wp.DateIn) <= date &&
      (!wp.DateOut || DataHelper.toDate(wp.DateOut) > date)
  );
} */

// Алиасы в формате объектных значений
const objectAliases = [
  // Алиасы сотрудника
  {
    type: 11,
    defaultFormat: "{ФИО}, {Должность}",
    aliases: [
      {
        alias: "ФИО",
        func: (value) =>
          value.LastName +
          " " +
          (value.FirstName ? value.FirstName.charAt(0) + "." : "") +
          (value.MiddleName ? value.MiddleName.charAt(0) + "." : ""),
      },
      {
        alias: "Фамилия",
        func: (value) => value.LastName,
      },
      {
        alias: "Имя",
        func: (value) => value.FirstName,
      },
      {
        alias: "Отчество",
        func: (value) => value.MiddleName,
      },
      {
        alias: "Ф",
        func: (value) => (value.LastName ? value.LastName.charAt(0) + "." : ""),
      },
      {
        alias: "И",
        func: (value) =>
          value.FirstName ? value.FirstName.charAt(0) + "." : "",
      },
      {
        alias: "О",
        func: (value) =>
          value.MiddleName ? value.MiddleName.charAt(0) + "." : "",
      },
      {
        alias: "ДатаРождения",
        func: (value, format) => formatDate(value.BirthDate, format),
      },
      {
        alias: "Должность",
        func: (value) => value?.Position?.Name,
      },
      {
        alias: "Подразделение",
        func: (value) => value?.Partition?.Name,
      },
    ],
  },
  // Алиасы подразделения
  {
    type: 12,
    defaultFormat: "{Название}",
    aliases: [
      {
        alias: "Название",
        func: (value) => value.Naimenovanie,
      },
      {
        alias: "Адрес",
        func: (value) => value.Address,
      },
    ],
  },
];

// Форматирует объектное значение по указанному формату с заданным набором алиасов
function FormatValueWithAliases(value, format, aliases) {
  if (!format) return null;
  let result = "";
  let start = 0;
  let state = 0;
  for (let i = 0; i < format.length; i++) {
    // Поиск строки в формате алиаса
    if (state === 0 && format[i] === "{") {
      if (i > start) result += format.slice(start, i);
      start = i;
      state = 1;
    } else if (state === 1 && format[i] === "}") {
      // Строка в формате алиаса найдена
      let found = false;
      if (i > start + 1) {
        let a = format.slice(start + 1, i);
        let f = null;
        const delim = a.indexOf(":");
        if (delim >= 0) {
          f = a.slice(delim + 1);
          a = a.slice(0, delim);
        }
        // Поиск и применение алиаса к значению
        for (const j in aliases) {
          if (aliases[j].alias === a) {
            const aliasValue = aliases[j].func(value, f);
            if (aliasValue) result += aliasValue.toString();
            found = true;
            break;
          }
        }
      }
      if (!found) result += format.slice(start, i + 1);
      start = i + 1;
      state = 0;
    }
  }
  if (format.length > start) result += format.slice(start);
  return result;
}

// Форматирует значение заданного типа по указанному формату
function FormatValue(type, value, format) {
  if (!value) return "";

  // Простые типы
  switch (type) {
    // Целое число, десятичное число, строка
    case 1:
    case 2:
    case 4:
      return value.toString();
    // Логическое значение
    case 3:
      return value ? "Да" : "Нет";
    // Дата и время
    case 5:
      return formatDate(value, format);
  }

  // Объекты
  for (const objAliases of objectAliases)
    if (objAliases.type === type) {
      if (!format) format = objAliases.defaultFormat;
      return FormatValueWithAliases(value, format, objAliases.aliases);
    }

  return "";
}

// Вычисляет значение параметра документа
function EvaluateField(field) {
  // Базовые параметры специфического типа
  if (!field.Value) return "";
  switch (field.Name) {
    case "Type":
      return field.Value.Name;
    case "Avtory":
    case "Soglasuyut":
      if (Array.isArray(field.Value))
        return field.Value.map((e) => FormatValue(11, e, field.Format)).join(
          ", "
        );
      return "";
    case "Podpisyvaet_utverzhdaet":
      return FormatValue(11, field.Value, field.Format);
    case "Oznakomit": // AcquaintanceNeed
      if (Array.isArray(field.Value))
        return field.Value.map((e) =>
          FormatValue(11, e.Employee, field.Format)
        ).join(", ");
      return "";
    case "Content":
    case "Files":
      return "";
    case "Utverzhdayushchiy_dokument":
    case "Iskhodnyy_dokument":
      return (
        "Документ №" +
        (field.Value.Num ? field.Value.Num : "") +
        " - " +
        (field.Value?.Name ? field.Value?.Name : "")
      );
    case "Razdel_deyatelnosti":
      return field.Value?.Name ?? "Раздел деятельности";
  }

  // Обычные параметры
  return FormatValue(field.Type, field.Value, field.Format);
}

// Создает источник данных для полей слияния в документе
function CreateDataSource(fields, documentType = "document") {
  const record = {};
  for (const i in fields) {
    if (fields[i].Name) {
      const field = fields[i];
      record[field.Name] =
        documentType === "template" ? field.DisplayName : EvaluateField(field);
    }
  }
  return { store: [record], map: (dataItem) => dataItem };
}

export { FormatValue, CreateDataSource };
