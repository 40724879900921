<template>
  <div
    class="block__content statistic-indicator-setting-calc"
    v-if="value && value.Type === 1"
  >
    <div class="statistic-indicator-setting-calc__label">
      Формула расчета показателя
    </div>
    <div class="statistic-indicator-setting-calc__name">
      {{ value.Name }}
    </div>
    <v-form class="statistic-indicator-setting-calc__body">
      <div class="statistic-indicator-setting-calc__inner">
        <div class="statistic-indicator-setting-calc__left">
          <div>
            <v-textarea
              v-model="value.PhenomenonDescription"
              placeholder="Введите название числителя"
              outlined
              hide-details
              auto-grow
              rows="2"
              :readonly="readonly"
            ></v-textarea>
          </div>
          <hr class="my-1" />
          <div>
            <v-textarea
              v-model="value.EnvironmentDescription"
              placeholder="Введите название знаменателя"
              outlined
              hide-details
              auto-grow
              rows="2"
              :readonly="readonly"
            ></v-textarea>
          </div>
        </div>
        <div class="statistic-indicator-setting-calc__center">
          <span>X</span>
        </div>
        <div class="statistic-indicator-setting-calc__right d-flex">
          <v-text-field
            v-model="value.BaseIndicatorValue"
            placeholder="1000"
            outlined
            hide-details
            style="width: 70px"
            class="mr-2"
            :readonly="readonly"
          ></v-text-field>
          <v-text-field
            v-model="value.RelativeUnits"
            placeholder="пациенто-дней"
            outlined
            hide-details
            style="width: 150px"
            :readonly="readonly"
          ></v-text-field>
        </div>
      </div>
    </v-form>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.statistic-indicator-setting-calc {
  text-align: center;
  padding-top: 41px;
  padding-bottom: 41px;

  fieldset {
    border-color: var(--v-blue-grey-base) !important;
  }

  &__label {
    font-size: 14px;
    line-height: 158%;
    color: var(--v-grey-base);
  }
  &__name {
    margin-top: 50px;
    font-size: 14px;
    line-height: 158%;
    color: var(--v-blue-grey-base);
  }

  &__body {
    margin-top: 38px;
    display: flex;
    justify-content: center;
  }

  &__inner {
    width: 636px;
    display: flex;
  }

  &__left {
    flex: 1;
  }
  &__center {
    line-height: 158%;
    color: var(--v-blue-grey-base);
    margin: 0 12px;
    align-self: center;
  }

  &__right {
    align-self: center;
    .v-input__slot {
      min-height: 40px !important;
    }
  }
}
</style>
