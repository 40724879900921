<template>
  <div>
    <base-tree-list-edit
      :items="valueWithNumber"
      @input="changeValue"
      @clickRow="clickRow"
      item-text="Content"
      caption="вопрос"
      :show-add="!readonly"
      group="test"
      :readOnly="readonly"
      :new-object="newObject"
      :show-edit-popup="showEditPopup"
      @showEdit="showEdit"
      :showActions="['add', 'delete']"
    >
      <template slot="afterLabel" slot-scope="scope">
        <div class="ml-3 pr-5" @click.stop>
          <v-checkbox
            v-if="scope.nodeEditing && scope.nodeEditing.Id === scope.item.Id"
            v-model="scope.nodeEditing.IsRequired"
            hide-details
            @click.stop
            label="Обязательный"
            @change="change2(scope.nodeEditing, $event)"
            style="margin-top: 7px; margin-right: 10px"
          ></v-checkbox>
        </div>
      </template>
      <template v-slot:label="{ item }"
        ><b v-if="isRequired(item)">
          {{ item.Num + " " + item.Content }}
        </b>
        <span v-else> {{ item.Num + " " + item.Content }} </span>
      </template>
      <template #captureArea>
        <v-btn
          v-if="!readonly"
          class="capture-area"
          @click.stop.prevent
          icon
          color="blue-grey"
          title="Переместить"
        >
          <m-icon icon="mdi-menu" small></m-icon>
        </v-btn>
      </template>
    </base-tree-list-edit>
    <question-edit-popup
      v-if="!readonly"
      v-model="dialog"
      :question="question"
      @save="save"
      :width="'80vw'"
      :height="'80vh'"
    ></question-edit-popup>
  </div>
</template>

<script>
import BaseTreeListEdit from "../../../components/base/BaseTreeListEdit.vue";
import DataHelper from "../../../utils/DataHelper";
import QuestionEditPopup from "../../../components/CheckList/Question/QuestionEditPopup.vue";

export default {
  name: "view-questions",
  components: { BaseTreeListEdit, QuestionEditPopup },
  props: {
    value: Array,
    readonly: {
      type: Boolean,
      default: false,
    },
    showEditPopup: Boolean,
    newObject: {
      type: Object,
      default: () => ({ IsRequired: false }),
    },
  },
  data() {
    return {
      dialog: false,
      question: null,
    };
  },
  computed: {
    valueWithNumber() {
      if (this.value) {
        // DataHelper.treeListTextNumeration(this.value, "IntNum", "NumText");
        return this.value;
      } else return [];
    },
  },
  watch: {
    valueWithNumber(value) {
      DataHelper.treeListGenerateNumerationText(value, "Num");
    },
  },
  methods: {
    changeValue(value) {
      this.$emit("input", value);
    },
    showEdit(value) {
      if (this.readonly === true) return;
      this.dialog = true;
      this.question = value;
    },
    clickRow(value) {
      this.showEdit(value);
    },
    save(item) {
      const id = item?.Id;
      const items = this._.cloneDeep(this.valueWithNumber);
      if (item.isNew) {
        item.isNew = false;
        if (!item.ParentId) items.push(item);
        else
          DataHelper.treeListTraverse(items, (element) => {
            if (element.Id === item.ParentId) {
              element.Children.push(item);
            }
          });
      } else {
        DataHelper.treeListTraverse(items, (element) => {
          if (element.Id === id) {
            Object.keys(element).forEach(function (key) {
              element[key] = item[key];
            });
          }
        });
      }
      this.$emit("input", items);
      this.$emit("save");
    },
    isRequired(question) {
      if (!question.ParentId)
        return DataHelper.treeListTraverse(question, (item) => {
          if (item.IsRequired) {
            return true;
          }
        });
      else if (question.IsRequired) return true;
    },
  },
};
</script>

<style></style>
