<template>
  <base-list-view
    :dataSource="dataSource"
    :headers="headers"
    isNested
    headerHidden
    hide-default-footer
    editable
    show-actions
    :hideDelete="readOnly"
    notShowSelect
    pageEditName="EquipmentEdit"
    @customEdit="edit"
    :show-add-in-table="
      !readOnly
        ? [
            { text: 'Добавить существующее оборудование', key: 'addExisting' },
            { text: 'Создать новое оборудование', key: 'createNew' },
          ]
        : false
    "
    @customAdd="customAdd"
  >
    <template #item.ValidationDate="{ item }">
      <div
        :class="
          processDateClass(
            item.IsValidationRequired,
            item.ValidationDate,
            item.ValidationPeriod
          )
        "
      >
        {{
          processDateText(
            item.IsValidationRequired,
            item.ValidationDate,
            item.ValidationPeriod
          )
        }}
      </div>
    </template>
    <template #item.ServiceDate="{ item }">
      <div
        :class="
          processDateClass(
            item.IsServiceRequired,
            item.ServiceDate,
            item.ServicePeriod
          )
        "
      >
        {{
          processDateText(
            item.IsServiceRequired,
            item.ServiceDate,
            item.ServicePeriod
          )
        }}
      </div>
    </template>
    <equipments-select
      v-model="dialogSelect"
      :filter="equipmentsFilter"
      :width="'80vw'"
      :height="'80vh'"
      @save="select"
    ></equipments-select>
  </base-list-view>
</template>

<script>
import DataHelper from "../../utils/DataHelper";
import BaseListView from "../../layouts/BaseListView.vue";
import EquipmentsSelect from "../../components/organization/EquipmentsSelect.vue";

export default {
  name: "view-PartitionEquipments",
  components: {
    BaseListView,
    EquipmentsSelect,
  },
  props: {
    partitionId: Number,
    dataSource: {
      type: Array,
      default: () => [],
    },
    readOnly: Boolean,
  },
  data: () => {
    return {
      // Название, Рег.номер, Инв. номер, Подлежит поверке, Подлежит ТО, Примечание
      headers: [
        { text: "Название", value: "Name" },
        { text: "Рег.номер", value: "RegNum" },
        { text: "Инв.номер", value: "Num" },
        { text: "Подлежит поверке", value: "ValidationDate", align: "center" },
        { text: "Подлежит ТО", value: "ServiceDate", align: "center" },
        { text: "Примечание", value: "Comment" },
      ],
      dialogSelect: false,
      equipmentsFilter: [],
    };
  },
  methods: {
    customAdd(key) {
      switch (key) {
        case "addExisting":
          this.addExisting();
          break;
        case "createNew":
          this.createNew();
          break;
      }
    },
    addExisting() {
      this.equipmentsFilter = this.dataSource.map((item) => item.Id);
      this.dialogSelect = true;
    },
    createNew() {
      this.$router.push({
        name: "EquipmentEdit",
        params: { objectId: 0 },
        query: {
          type: "create",
          partition: this.partitionId,
        },
      });
    },
    select(value) {
      this.dialogSelect = false;
      this.$emit("add", value);
    },
    edit(value) {
      this.$router.push({
        name: "EquipmentEdit",
        params: {
          objectId: value.Id,
        },
      });
    },
    // Дополнительные колонки мед.изделий
    processDateText(required, date, period) {
      if (!required) return "-";
      let d = DataHelper.toDate(date);
      if (!d) return "Дата неизвестна";
      d = DataHelper.AddMonths(d, period ?? 0);
      return d.toLocaleDateString();
    },
    processDateClass(required, date, period) {
      if (!required) return { nodata: true };
      let d = DataHelper.toDate(date);
      if (d) d = DataHelper.AddMonths(d, period ?? 0);
      if (d && d.getTime() < DataHelper.today()) return { over: true };
      return null;
    },
  },
};
</script>

<style lang="sass" scoped>
.over
  color: var(--v-error-lighten1)
</style>
