var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},[_c('panel-filter',{attrs:{"items":_vm.data,"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c('template',{slot:"panelFilterPrepend"},[_vm._t("panelFilterPrepend")],2)],2),_c('div',{staticClass:"ml-5",staticStyle:{"flex":"1"}},[_c('panel',{staticStyle:{"position":"relative"},attrs:{"hide-icon":"","small":""}},[(_vm.loading)?_c('wrapper-loader',{attrs:{"circle":""}}):_vm._e(),_c('v-row',[_c('v-col',[(_vm.dataSource.MonthValues)?_c('tfoms-finance',{staticStyle:{"width":"100%"},attrs:{"data":_vm.dataSource.MonthValues.map((e) => [e]),"title":"Общая оценка состояния качества и безопасности медицинской деятельности","hideIcon":"","factColor":'var(--v-primary-base)',"labelFact":"Показатель оценки качества","hideDatalabel":false,"labels":_vm.labels0,"subTitle":null,"period":_vm.queryParams.params.periodType || 0,"refs":_vm.dataSource.RefValues,"isClickLabel":false,"hideBorder":""},on:{"itemClick":_vm.itemClick}}):_vm._e()],1),_c('v-col',[_c('kibmd-info',{attrs:{"value":_vm.dataSource.Value,"description":_vm.dataSource.Description,"year":_vm.queryParams.params.Year}})],1)],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',[_c('panel',{staticStyle:{"position":"relative"},attrs:{"title":"Оценка по показателям качества","small":"","hide-icon":"","isClickLabel":false,"labels":_vm.labels0}},[(_vm.loading)?_c('wrapper-loader',{attrs:{"circle":""}}):_vm._e(),_c('template',{slot:"titleAppend"},[_c('div',[_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":"показать все","title":!_vm.showAllPOK
                    ? 'Показать ПОК без значений'
                    : 'Скрыть ПОК без значений'},model:{value:(_vm.showAllPOK),callback:function ($$v) {_vm.showAllPOK=$$v},expression:"showAllPOK"}})],1)]),(_vm.dataSource.IndicatorValues)?_c('kibmd-table2',{attrs:{"data":_vm.showAllPOK
                ? _vm.dataSource.IndicatorValues
                : _vm.dataSource.IndicatorValues.filter(
                    (e) => e.Value || e.Value === 0
                  ),"refs":_vm.dataSource.RefValues,"value":_vm.queryParams1.params.indicatorId
                ? _vm.dataSource.IndicatorValues.find(
                    (e) =>
                      e.Indicator.Id === _vm.queryParams1.params.indicatorId.Id
                  )
                : null},on:{"input":_vm.updateSelectIndicator}}):_vm._e()],2)],1),_c('v-col',[_c('panel',{staticStyle:{"position":"relative"},attrs:{"title":"Оценка по медицинским организациям","small":"","hide-icon":"","isClickLabel":false,"labels":_vm.labels3}},[(_vm.loading)?_c('wrapper-loader',{attrs:{"circle":""}}):_vm._e(),_c('template',{slot:"titleAppend"},[_c('div',[_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":"показать все","title":!_vm.showAllMO
                    ? 'Показать МО без значений'
                    : 'Скрыть МО без значений'},model:{value:(_vm.showAllMO),callback:function ($$v) {_vm.showAllMO=$$v},expression:"showAllMO"}})],1)]),(_vm.dataSource.OrganizationValues)?_c('kibmd-table2',{attrs:{"data":_vm.showAllMO
                ? _vm.dataSource.OrganizationValues
                : _vm.dataSource.OrganizationValues.filter(
                    (e) => e.Value || e.Value === 0
                  ),"type":2,"refs":_vm.dataSource.RefValues,"value":_vm.queryParams1.params.organizationId
                ? _vm.dataSource.OrganizationValues.find(
                    (e) =>
                      e.Organization.Id ===
                      _vm.queryParams1.params.organizationId.Id
                  )
                : null},on:{"input":_vm.updateSelectOrganization}}):_vm._e()],2)],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',[_c('KibmdTableCheck',{attrs:{"data":_vm.externalIndicator.Audits,"labels":_vm.labels1,"refs":_vm.dataSource.RefValues,"loading":_vm.loading},model:{value:(_vm.selectCheck),callback:function ($$v) {_vm.selectCheck=$$v},expression:"selectCheck"}})],1),_c('v-col',[_c('KibmdTableCheckList',{attrs:{"data":_vm.externalIndicator.CheckLists,"labels":_vm.labels2,"refs":_vm.dataSource.RefValues,"loading":_vm.loading},on:{"input":function($event){return _vm.$router.push({
              name: $event.isCheckOperation
                ? 'CheckOperationRun'
                : 'CheckListEdit',
              params: { objectId: $event.Id },
            })}}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }