var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{attrs:{"readonly":_vm.readonly}},[_c('tfoms-spacer',{attrs:{"title":"ОБЩИЕ ДАННЫЕ ТФОМС ПО ОРГАНИЗАЦИИ"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('tfoms-panel',{attrs:{"small":""}},[_c('tfoms-table',{attrs:{"data":_vm.dataWithTotal}})],1)],1),_c('v-col',{attrs:{"cols":"5"}},[_c('tfoms-bar',{attrs:{"title":"Принято к оплате","data":_vm.percentFinances,"icon":"mdi-currency-rub","factColor":"#0CA85D","year":_vm.queryParams.params.year}}),_c('tfoms-finance',{staticClass:"mt-5",attrs:{"data":_vm.data.map((e) => [e.SanctionValue, e.FineValue]),"subTitle":_vm.queryParams.params.year + ' год'},on:{"clickMore":function($event){_vm.tabMore = 1}}}),(false)?_c('tfoms-lost-income',{staticClass:"mt-5",attrs:{"value":_vm.sum}}):_vm._e()],1)],1),_c('tfoms-spacer',{attrs:{"title":"ДАННЫЕ В РАЗРЕЗЕ УСЛОВИЙ ОКАЗАНИЯ МЕДИЦИНСКОЙ ПОМОЩИ"}}),(_vm.aggregatedInfo)?_c('div',{staticClass:"d-flex",staticStyle:{"position":"relative"}},[(_vm.loading || _vm.startLoading != _vm.endLoading)?_c('wrapper-loader'):_vm._e(),(_vm.dataSource.HealthCares)?_c('tfoms-conditions',{attrs:{"HealthCares":_vm.dataSource.HealthCares},model:{value:(_vm.healthCare),callback:function ($$v) {_vm.healthCare=$$v},expression:"healthCare"}}):_vm._e(),_c('v-row',{staticClass:"ml-5"},[_c('v-col',[(_vm.aggregatedInfo && _vm.aggregatedInfo.VolumeValues.length)?_c('tfoms-bar',{attrs:{"title":"ОБЪЕМЫ","data":_vm.getValues(_vm.aggregatedInfo.VolumeValues),"icon":"mdi-currency-rub","values":[
            {
              name: 'План',
              value: _vm.aggregatedInfo.VolumeTotalPlanValue,
              label: this.provideUnits.unit.text,
            },
            {
              name: 'Факт',
              value: _vm.aggregatedInfo.VolumeTotalValue,
              label: this.provideUnits.unit.text,
            },
            {
              name: 'Выполнение плана',
              value: _vm.aggregatedInfo.VolumePercent + '%',
              type: 'percent',
            },
          ],"labels":[_vm.queryParams.params.year + ' год', _vm.currentCare.Name]}}):_vm._e(),_c('tfoms-finance',{staticClass:"mt-5",attrs:{"title":"КОЛИЧЕСТВО САНКЦИЙ","data":_vm.aggregatedInfo.SanctionCountValues.map((e) => [
              e.FineVolumeValue,
              e.NonPaymentVolumeValue,
            ]),"values":[
            {
              value: _vm.aggregatedInfo.SanctionVolumeCount,
              label: _vm.provideUnits.unit.text,
            },
          ],"subTitle":_vm.queryParams.params.year + ' год',"labels":[_vm.currentCare.Name]}})],1),_c('v-col',[_c('tfoms-bar',{attrs:{"title":"ФИНАНСОВОЕ ОБЕСПЕЧЕНИЕ","data":_vm.getValues(
              _vm.aggregatedInfo.FinanceValues,
              'FinancialPlanValue',
              'FinancialValue'
            ),"values":[
            {
              name: 'План',
              value: _vm.aggregatedInfo.FinanceTotalPlanValue,
              label: this.provideUnits.rub.text,
            },
            {
              name: 'Факт',
              value: _vm.aggregatedInfo.FinanceTotalValue,
              label: this.provideUnits.rub.text,
            },
            {
              name: 'Выполнение плана',
              value: _vm.aggregatedInfo.FinancePercent + '%',
              type: 'percent',
            },
          ],"icon":"mdi-currency-rub","factColor":"#0CA85D","labels":[_vm.queryParams.params.year + ' год', _vm.currentCare.Name]}}),_c('tfoms-finance',{staticClass:"mt-5",attrs:{"title":"СУММА САНКЦИЙ","values":[
            {
              value: _vm.aggregatedInfo.SanctionTotalValue,
              label: this.provideUnits.rub.text,
            },
          ],"data":_vm.aggregatedInfo.SanctionValues.map((e) => [
              e.SanctionValue,
              e.FineValue,
            ]),"labels":[_vm.currentCare.Name],"subTitle":_vm.queryParams.params.year + ' год'}})],1)],1)],1):_vm._e(),_c('tfoms-spacer',{attrs:{"title":"ДЕТАЛЬНАЯ ИНФОРМАЦИЯ В РАЗРЕЗЕ УСЛОВИЙ И ВИДОВ ОКАЗАНИЯ МП, ПОДРАЗДЕЛЕНИЙ И СОТРУДНИКОВ"}}),(_vm.dataSource.HealthCares)?_c('div',{staticClass:"d-flex mt-5",staticStyle:{"position":"relative"}},[(
        _vm.$refs.tfomsParams &&
        (_vm.$refs.tfomsParams.loading ||
          _vm.$refs.tfomsParams.startLoading != _vm.$refs.tfomsParams.endLoading)
      )?_c('wrapper-loader'):_vm._e(),_c('tfoms-params',{ref:"tfomsParams",attrs:{"currentCare":_vm.currentCare,"healthCares":_vm.dataSource.HealthCares,"groupId":_vm.groupId,"tfomsMore":_vm.$refs.tfomsMore,"year":_vm.queryParams.params.year},on:{"update:groupId":function($event){_vm.groupId=$event},"update:group-id":function($event){_vm.groupId=$event},"updateParams":function($event){return _vm.$emit('updateParams', $event)},"updatedetailParams":function($event){return _vm.$emit('updatedetailParams', $event)}}}),_c('tfoms-more',{ref:"tfomsMore",staticClass:"ml-5",staticStyle:{"flex":"1"},attrs:{"groupId":_vm.groupId,"tfomsParams":_vm.$refs.tfomsParams},on:{"update:groupId":function($event){_vm.groupId=$event},"update:group-id":function($event){_vm.groupId=$event},"updateParams":function($event){return _vm.$emit('updateParams', $event)},"updatedetailParams":function($event){return _vm.$emit('updatedetailParams', $event)}},model:{value:(_vm.tabMore),callback:function ($$v) {_vm.tabMore=$$v},expression:"tabMore"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }