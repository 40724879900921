<template>
  <base-list-view
    :dataSource="value"
    :headers="headers"
    isNested
    :header-hidden="!isSelect"
    :hide-default-footer="!isSelect"
    v-on="$listeners"
    v-bind="$attrs"
    :notShowSelect="!isSelect"
    ref="listView"
    textCustomAdd="Добавить организацию"
    @customAdd="showDialog = true"
    :custom-filter="customFilter"
    :show-actions="!isSelect"
    editable
    hideEdit
  >
    <popup-organization
      v-if="$attrs['show-add-in-table']"
      v-model="showDialog"
      :show-add-in-table="false"
      @save="save"
    ></popup-organization>
  </base-list-view>
</template>
<script>
export default {
  components: {
    BaseListView: () => import("@/layouts/BaseListView.vue"),
    PopupOrganization: () => import("./PopupOrganization.vue"),
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    isSelect: {
      type: Boolean,
      default: false,
    },
    customFilter: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      showDialog: false,
      employees: [],
      headers: [
        {
          text: "Название медицинских организаций",
          value: "Name",
          notGroup: true,
        },
      ],
    };
  },
  async mounted() {},
  methods: {
    save(items) {
      for (let i = 0; i < items.length; i++) {
        if (!this.value.find((e) => e.Id === items[i].Id))
          this.value.push({ ...items[i], isNew: true });
      }
      this.showDialog = false;
    },
  },
};
</script>
