<template>
  <div>
    <base-panel title="Главная" :logo="require('@/assets/logo.svg')">
      <template slot="action">
        <v-btn @click="init" class="mr-4" title="Обновить" icon>
          <m-icon icon="tool-refresh" s16></m-icon>
        </v-btn>
        <v-btn color="primary" dark @click="showSettings = !showSettings"
          >Настроить</v-btn
        >
      </template>
      <div
        class="d-flex flex-wrap"
        style="margin-left: -12px; margin-right: -12px"
      >
        <card
          v-if="isShow(8)"
          title="С чего начать"
          :icon="UtilGetIcon('view-Instructions')"
          :params="data.instruction"
        >
        </card>
        <card-chart
          v-if="isShow(0)"
          title="Состояние качества и безопасности медицинской деятельности"
          :icon="UtilGetIcon('view-AnalysisIndicators')"
          :params="data.chart"
        >
        </card-chart>
        <card
          v-if="isShow(1)"
          title="Задачи"
          :icon="UtilGetIcon('view-Tasks')"
          :params="data.task"
          ><template slot="item.PlannedEnd" slot-scope="{ item }">
            <span v-html="taskStatus(item)"></span>
          </template>
        </card>

        <card
          v-if="isShow(2)"
          title="Проекты и планы мероприятий"
          :icon="UtilGetIcon('view-Projects', 'all')"
          :params="data.project"
        >
          <template slot="cardAction">
            <v-row class="mt-1">
              <v-col
                ><v-btn
                  block
                  depressed
                  @click="
                    $router.push({
                      name: 'Projects',
                      params: { type: 'project' },
                    })
                  "
                  >Все проекты</v-btn
                ></v-col
              >
              <v-col
                ><v-btn
                  block
                  depressed
                  @click="
                    $router.push({
                      name: 'Plans',
                      params: { type: 'plan' },
                    })
                  "
                  >Все планы</v-btn
                ></v-col
              >
            </v-row>
          </template>
          <template slot="item.status" slot-scope="{ item }">
            {{
              item.DoneTaskPercent === 0
                ? "Новый"
                : item.Done
                ? "Завершен"
                : "Действующий"
            }}
          </template>
          <template slot="item.DoneTaskPercent" slot-scope="{ item }">
            {{ parseFloat(parseFloat(item.DoneTaskPercent).toFixed(2)) + "%" }}
            ({{ item.Completed }} из {{ item.Total }})
          </template>
        </card>
        <card
          v-if="isShow(3)"
          title="Документы"
          :icon="UtilGetIcon('view-LocalDocuments')"
          :params="data.document"
        >
        </card>
        <card
          v-if="isShow(4)"
          title="Проверки"
          :icon="UtilGetIcon('view-AuditOperations')"
          :params="data.audit"
        >
          <template slot="item.DateIn" slot-scope="{ item }">
            {{ item.Period.DateIn.substring(0, 10) }} -
            {{ item.Period.DateOut.substring(0, 10) }}
          </template>
          <template slot="item.Subject" slot-scope="{ item }">
            {{ item.Subject ? item.Subject : "Без предмета проверки" }}
          </template>
          <template slot="item.Status" slot-scope="{ item }">
            <span :class="classStatus(item.Status)"
              >{{ item.Status | getEnums("AuditOperationStatus") }}
            </span>
          </template>
        </card>
        <card
          v-if="isShow(6)"
          title="Нежелательные события"
          :icon="UtilGetIcon('view-UnwishedEvents')"
          :params="data.unwishedEvent"
        >
          <template slot="item.Date" slot-scope="{ item }">{{
            item.Date.substring(0, 10)
          }}</template>
        </card>
        <card
          v-if="isShow(5)"
          title="Статистические данные"
          :icon="UtilGetIcon('view-StatisticIndicators')"
          :params="data.stat"
          id="statistic-indicator-values"
        >
          <template slot="item.LastValue" slot-scope="{ item }">
            <v-chip
              v-if="item.LastValue || item.LastValue === 0"
              class="statistic-indicator-values__chip-group"
              :class="
                StatisticIndicatorHelper.getColorByRefs(
                  [item.RefValue1, item.RefValue2, item.RefValue3],
                  item.LastValue,
                  item.RatingType
                )
              "
              >{{ item.LastValue }}</v-chip
            >
          </template>
          <template slot="item.DiffValue" slot-scope="{ item }">
            <v-chip
              v-if="item.DiffValue"
              :class="
                StatisticIndicatorHelper.getColorDiff(
                  item.DiffValue,
                  item.RatingType
                )
              "
              >{{ (item.DiffValue > 0 ? "+" : "") + item.DiffValue }} </v-chip
            ><span
              v-if="item.DiffValue"
              class="statistic-indicator-values__period"
              >{{ "за " + periods[item.Period] }}</span
            >
          </template>
          <template slot="item.DateIn" slot-scope="{ item }">{{
            item.DateIn.substring(0, 10)
          }}</template>
        </card>
        <card
          v-if="isShow(7)"
          title="Мои курсы"
          :icon="UtilGetIcon('knowledgebase-my')"
          :params="data.course"
        >
          <template slot="item.Status" slot-scope="{ item }">
            <div class="knowledge-base__status-point__wrapper">
              <span
                class="knowledge-base__status-point"
                :class="'knowledge-base__status-point--' + item.Status"
              ></span>
              Пройдено <b>{{ item.Completed }}</b> из <b>{{ item.Total }}</b>
            </div>
          </template>
        </card>
        <card
          title="История обновлений системы"
          :icon="UtilGetIcon('SystemUpdateHistory')"
          :params="data.news"
          :customHandleClick="data.news.handleClick"
        >
          <template slot="item.Title" slot-scope="{ item }">
            <b v-if="!item.Read">{{ item.Title }}</b>
            <span v-else>{{ item.Title }} </span>
          </template>

          <template slot="item.Period.DateIn" slot-scope="{ item }">{{
            item.DateIn.substring(0, 10)
          }}</template>
        </card>
        <card
          title="Новости"
          :params="data.news2"
          :icon="UtilGetIcon('SystemUpdateHistory')"
          :customHandleClick="data.news2.handleClick"
        >
          <template slot="item.Title" slot-scope="{ item }">
            <b v-if="!item.Read">{{ item.Title }}</b>
            <span v-else>{{ item.Title }} </span>
          </template>
          <template slot="item.DateIn" slot-scope="{ item }">{{
            item.Period.DateIn.substring(0, 10)
          }}</template>
        </card>
      </div>
    </base-panel>
    <modal-settings
      v-model="showSettings"
      :settings="settings"
      @save="saveSettings"
    ></modal-settings>
    <modal-confirmation-phone
      v-model="showConfirmPhone"
    ></modal-confirmation-phone>
  </div>
</template>

<script>
import Api from "../../services/Api";
import BasePanel from "@/layouts/BasePanel";
import card from "./components/card.vue";
import cardChart from "./components/cardChart.vue";
import minimumDataService from "@/services/MinimumDataService";
import {
  OrganizationDocumentStatus,
  AuditOperationStatus,
} from "../../data/enums";
import modalSettings from "./components/modalSettings.vue";
import modalConfirmationPhone from "./components/modalConfirmationPhone.vue";

import StatisticIndicatorHelper from "../../views/statisticIndicator/StatisticIndicatorHelper";
import DataHelper from "../../utils/DataHelper";
import NewsService from "../../services/NewsService";

export default {
  name: "view-Home",
  components: {
    BasePanel,
    card,
    cardChart,
    modalSettings,
    modalConfirmationPhone,
  },
  data() {
    return {
      periods: ["", "неделю", "месяц", "квартал", "полгода", "год", "день"],
      showSettings: false,
      showConfirmPhone: false,
      settings: [],
      isInit: false,
      data: {
        instruction: {
          load: minimumDataService.instruction,
          headers: [{ text: "Name", value: "Name" }],
          item: "Instructions",
          items: "Instructions",
          handleClick: function () {
            this.$router.push({ name: "Instructions" });
          },
          moreText: "Все инструкции",
        },
        chart: { load: minimumDataService.chart },
        task: {
          load: minimumDataService.task,
          headers: [
            { text: "Name", value: "Name" },
            {
              text: "PlannedEnd",
              value: "PlannedEnd",
              cellClass: "td-minimum",
            },

            /* {
              text: "Executor",
              value: "Executor",
              displayText: (e) => this.$options.filters.PersonShortName(e),
            }, */
          ],
          item: "TaskEdit",
          items: "Tasks",
          moreText: "Мои задачи",
          query: { type: "my" },
        },
        project: {
          load: minimumDataService.project,
          headers: [
            { text: "Name", value: "Name" },
            { text: "status", value: "status", cellClass: "td-minimum" },
            {
              text: "DoneTaskPercent",
              value: "DoneTaskPercent",
              cellClass: "td-minimum",
            },
          ],
          item: "ProjectEdit",
          items: "Projects",
          moreText: "Все проекты",
          query: { type: "project" },
        },
        document: {
          load: minimumDataService.document,
          headers: [
            {
              text: "Type",
              value: "Type",
              dataType: "object",
              displayText: (e) => e?.Name,
            },
            { text: "Name", value: "Name" },
            {
              text: "Status",
              value: "Status",
              dataType: "enum",
              options: OrganizationDocumentStatus,
              cellClass: "td-minimum",
            },
            { text: "Todo", value: "Todo", cellClass: "td-minimum red--text" },
          ],
          item: "LocalDocumentEdit",
          items: "LocalDocuments",
          moreText: "Мои документы",
          query: { type: "my" },
        },
        audit: {
          load: minimumDataService.audit,
          headers: [
            { text: "Subject", value: "Subject" },
            {
              text: "Status",
              value: "Status",
              dataType: "enum",
              options: AuditOperationStatus,
              cellClass: "td-minimum",
            },
            {
              cellClass: "td-minimum",
              text: "DateIn",
              value: "DateIn",
            },
          ],
          item: "AuditOperationEdit",
          items: "AuditOperations",
          moreText: "Мои проверки",
          query: { type: "my" },
        },
        unwishedEvent: {
          load: minimumDataService.unwishedEvent,
          headers: [
            {
              text: "Date",
              value: "Date",
              cellClass: "td-minimum",
            },
            { text: "Content", value: "Content" },
          ],
          item: "UnwishedEventEdit",
          items: "UnwishedEventsJournal",
          moreText: "Все события",
        },
        news: {
          load: minimumDataService.news,
          headers: [
            {
              text: "DateIn",
              value: "DateIn",
              cellClass: "td-minimum",
            },
            { text: "Title", value: "Title" },
            { text: "Version", value: "Version" },
          ],
          item: "NewsEdit",
          items: "News",
          handleClick: function (item) {
            if (!item.Read) this.$set(item, "Read", true);
            this.$store.dispatch("frame/RUN_COMPONENT", {
              componentName: "news/NewsEdit",
              params: {
                _objectId: item.Id,
                persistent: true,
                color: "#FBFBFB",
              },
            });
          },
          moreText: "История обновлений системы",
          query: { type: "system" },
        },
        news2: {
          load: minimumDataService.news2,
          headers: [
            {
              text: "DateIn",
              value: "DateIn",
              cellClass: "td-minimum",
            },
            { text: "Title", value: "Title" },
          ],
          item: "NewsEdit",
          items: "News",
          handleClick: function (item) {
            if (!item.Read) this.$set(item, "Read", true);
            this.$store.dispatch("frame/RUN_COMPONENT", {
              componentName: "news/NewsEdit",
              params: {
                _objectId: item.Id,
                persistent: true,
                color: "#FBFBFB",
              },
            });
          },
          moreText: "Все новости",
          query: { type: "all" },
        },
        stat: {
          load: minimumDataService.stat,
          headers: [
            {
              text: "Name",
              value: "Name",
            },
            {
              text: "LastValueDate",
              value: "LastValueDate",
              cellClass: "td-minimum",
              displayText: (text) => text?.substring(0, 10),
            },
            {
              text: "LastValue",
              value: "LastValue",
              cellClass: "td-minimum",
            },
            {
              text: "DiffValue",
              value: "DiffValue",
              cellClass: "td-minimum",
            },
          ],
          items: "StatisticIndicators",
          item: "StatisticIndicatorEdit",
          moreText: "Все стат.данные",
          query: { type: 1 },
        },
        course: {
          load: minimumDataService.course,
          headers: [
            {
              text: "Name",
              value: "Name",
            },
            {
              text: "Status",
              value: "Status",
              cellClass: "td-minimum",
            },
          ],
          valueKey: "CourseId",
          items: "KnowledgeBaseMy",
          item: "KnowledgeBaseCourseEmployeeEdit",
          moreText: "Мои курсы",
          query: { type: "my" },
        },
      },
    };
  },
  computed: {
    StatisticIndicatorHelper() {
      return StatisticIndicatorHelper;
    },
  },
  async created() {
    this.init();

    // TODO: Переход на вкладку "Начало работы" (инструкции)
    const openHelpView = await Api().get("help/GetHelpStartNoMore");
    if (!openHelpView.data) {
      this.$router.push({
        name: "Instructions",
      });
    }

    // TODO: Переход на новости, если есть новая
    const newNews = (await NewsService.getLastUpdateNews()).data;
    if (newNews && !newNews.Read) {
      this.$store.dispatch("frame/RUN_COMPONENT", {
        componentName: "news/NewsEdit",
        params: {
          _objectId: newNews.Id,
          persistent: true,
          color: "#FBFBFB",
        },
      });
    }

    if (
      !this.$store.state.user.PhoneNum ||
      !this.$store.state.user.IsNumberChangedInformed
    ) {
      const user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;

      this.showConfirmPhone = user.modalNumberPhone !== false;
    }
  },
  methods: {
    isShow(type) {
      const isSelected = this.settings[type]?.IsSelected;
      return isSelected;
    },
    async init() {
      this.isInit = false;
      this.settings = (await minimumDataService.settings.get()).data.Items;
      this.isInit = true;
    },
    taskStatus(item) {
      // Группировка по сроку
      const today = DataHelper.today();
      const tomorrow = DataHelper.today().setDate(today.getDate() + 1);

      // Группировка по сроку завершения
      const date = DataHelper.toDate(item.PlannedEnd);
      const dateStart = DataHelper.toDate(item.PlannedStart);
      let status = 0;

      if (date) {
        // просроченные
        if (date < today) status = 1;
        // сегодня
        else if (date < tomorrow) status = 2;
      } else if (!date && dateStart) {
        // просроченные
        if (dateStart < today) status = 1;
        // сегодня
        else if (dateStart < tomorrow) status = 2;
        // известна только дата начала
        else if (dateStart > today) status = 3;
      }
      // период не задан
      else if (!date && !dateStart) {
        status = 4;
      }

      if (status === 0) return item.PlannedEnd?.substring(0, 10);
      else if (status === 1) return "<b class='red--text'>Просрочена</b>";
      else if (status === 2) return "<b class='text-pending'>Сегодня</b>";
      else if (status === 3) return item.PlannedStart?.substring(0, 10);
      else if (status === 4)
        return "<b class='text-pending'>Период не задан</b>";
    },
    classStatus(status) {
      switch (status) {
        case 1:
        case 2:
          return "text-pending";
        case 3:
          return "text-success";
        default:
          return "";
      }
    },
    saveSettings(items) {
      for (let i = 0; i < items.length; i++) {
        const item = this.settings.find((e) => e.Type === items[i].Type);
        if (!item) return;
        item.IsSelected = items[i].IsSelected;
        item.IsDisabled = items[i].IsDisabled;
      }

      minimumDataService.settings.update(this.settings);
      this.showSettings = false;
    },
  },
};
</script>
<style lang="scss">
.td-minimum {
  width: 1%;
}

.td-fixed-width-actions {
  width: 104.7px !important;
  min-width: 104.7px !important;
}

#statistic-indicator-values {
  .statistic-indicator-values__period {
    margin-left: 6px;
    color: var(--v-text-base);
  }
  .v-row-group__header {
    background-color: unset !important;
  }
  .v-chip {
    height: 24px;
    border-radius: 10px;
    color: var(--v-white-lighten1);
    justify-content: center;
    padding: 0 8px !important;

    &.statistic-indicator-values__chip-group {
      text-align: center;
      width: 104px;
      background: var(--v-grey-base);
    }

    &.beautify {
      background-color: var(--v-beautify-base) !important;
    }
    &.success {
      background-color: var(--v-success-base) !important;
    }
    &.warning {
      background-color: var(--v-warning-base) !important;
      // color: var(--v-text-base);
    }
    &.error {
      background-color: var(--v-error-base) !important;
    }
    &.grey {
      background-color: var(--v-text-lighten1) !important;
    }
  }
}
</style>

<style>
.text-success {
  color: var(--v-success-base) !important;
}
.text-pending {
  color: var(--v-warning-base) !important;
}
</style>
