import { Doughnut, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Doughnut,
  mixins: [reactiveProp],
  props: {
    options: {},
    chartData: {},
    commonPercent: "",
    fontSize: {
      type: Number,
      default: 140,
    },
  },
  mounted() {
    const customPlugin = (chart) => {
      const { width, height, ctx } = chart.chart;
      ctx.restore();
      const fontSize = (height / this.fontSize).toFixed(2);
      ctx.font = `${fontSize}rem Noto Sans, sans-serif`;
      ctx.textBaseline = "middle";

      const text = this.commonPercent + "";
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 1.9;

      ctx.fillText(text, textX, textY);
      ctx.save();
    };

    this.addPlugin({
      id: "my-plugin",
      beforeDraw: customPlugin,
    });
    this.renderChart(this.chartData, this.options);
  },
};
