var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-list-view',{attrs:{"dataSource":_vm.dataSource,"headers":_vm.headers,"isNested":"","headerHidden":"","hide-default-footer":"","editable":"","show-actions":"","hideDelete":_vm.readOnly,"notShowSelect":"","pageEditName":"EquipmentEdit","show-add-in-table":!_vm.readOnly
      ? [
          { text: 'Добавить существующее оборудование', key: 'addExisting' },
          { text: 'Создать новое оборудование', key: 'createNew' },
        ]
      : false},on:{"customEdit":_vm.edit,"customAdd":_vm.customAdd},scopedSlots:_vm._u([{key:"item.ValidationDate",fn:function({ item }){return [_c('div',{class:_vm.processDateClass(
          item.IsValidationRequired,
          item.ValidationDate,
          item.ValidationPeriod
        )},[_vm._v(" "+_vm._s(_vm.processDateText( item.IsValidationRequired, item.ValidationDate, item.ValidationPeriod ))+" ")])]}},{key:"item.ServiceDate",fn:function({ item }){return [_c('div',{class:_vm.processDateClass(
          item.IsServiceRequired,
          item.ServiceDate,
          item.ServicePeriod
        )},[_vm._v(" "+_vm._s(_vm.processDateText( item.IsServiceRequired, item.ServiceDate, item.ServicePeriod ))+" ")])]}}])},[_c('equipments-select',{attrs:{"filter":_vm.equipmentsFilter,"width":'80vw',"height":'80vh'},on:{"save":_vm.select},model:{value:(_vm.dialogSelect),callback:function ($$v) {_vm.dialogSelect=$$v},expression:"dialogSelect"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }