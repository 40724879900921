<template>
  <base-list-view
    :dataSource="dataSource"
    :headers="headers"
    isNested
    headerHidden
    hide-default-footer
    editable
    show-actions
    :show-add-in-table="!readOnly"
    :hideDelete="readOnly"
    notShowSelect
    hideEdit
    textCustomAdd="Добавить стандарты оснащения"
    :sort-by="['Profile', 'Name']"
    @customAdd="add"
    @customHandle="(value) => $emit('handle', value)"
  >
    <equipment-standarts-select
      v-model="dialogSelect"
      :filter="standartsFilter"
      :width="'80vw'"
      :height="'80vh'"
      @save="select"
    ></equipment-standarts-select>
  </base-list-view>
</template>

<script>
import BaseListView from "../../layouts/BaseListView.vue";
import EquipmentStandartsSelect from "../../components/organization/EquipmentStandartsSelect.vue";

export default {
  name: "view-PartitionEquipmentStandarts",
  components: {
    BaseListView,
    EquipmentStandartsSelect,
  },
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
    readOnly: Boolean,
  },
  data: () => {
    return {
      headers: [
        { text: "Название", value: "Name" },
        {
          text: "Профиль",
          value: "Profile",
          dataType: "object",
          displayText: (p) => p?.Name,
        },
        { text: "Нормативный документ", value: "NormativeDocument" },
      ],
      dialogSelect: false,
      standartsFilter: [],
    };
  },
  methods: {
    add() {
      this.standartsFilter = this.dataSource.map((item) => item.Id);
      this.dialogSelect = true;
    },
    select(value) {
      this.dialogSelect = false;
      this.$emit("add", value);
    },
  },
};
</script>
