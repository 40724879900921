<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="420"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-show="itemCount && (itemCount.Count > 0 || fullName === 'total')"
        v-bind="attrs"
        v-on="on"
        fab
        color="primary"
        class="notification__point"
        :class="{ 'notification__point--active': notSmall }"
        dark
        x-small
        style="font-size: 0.8em"
      >
        {{ itemCount ? itemCount.Count : 0 }}
      </v-btn>
    </template>
    <v-card v-if="itemCount" max-width="452" class="mx-auto" :loading="loading">
      <v-toolbar class="mb-2" height="46px">
        <m-icon icon="medexpert-notifications" active class="mr-4"></m-icon>

        <v-toolbar-title
          :style="{
            fontSize: '16px',
            fontWeight: itemCount.Count ? '600' : '500',
          }"
        >
          Уведомления
          <span v-if="itemCount.Count" class="primary--text">
            ({{ itemCount.Count }})
          </span>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="menu = false">
          <m-icon color="primary" icon="mdi-close"></m-icon>
        </v-btn>
      </v-toolbar>
      <div class="body">
        <v-list two-line class="pt-0">
          <v-divider v-if="false"></v-divider>

          <div
            v-for="{ label, items, button } in categorizedItems"
            :key="label"
          >
            <v-subheader
              v-if="items.length"
              class="d-flex justify-space-between align-center"
              style="
                color: var(--v-text-lighten1);
                font-size: 14px;
                font-weight: 600;
              "
            >
              {{ label }}
              <v-btn
                v-if="button"
                text
                @click="itemsIsRead()"
                color="primary"
                :disabled="!items.length || items[0].IsRead"
              >
                Прочитать все
              </v-btn>
            </v-subheader>
            <div v-if="items.length" class="d-flex justify-center">
              <v-divider style="max-width: 415px"></v-divider>
            </div>
            <v-list-item
              v-for="item in items"
              :key="item.Id"
              @click="clickItem(item)"
            >
              <v-list-item-avatar class="mr-0 rounded-0">
                <NotificationIcon
                  :notificationItem="item"
                  :color="!item.IsRead ? 'primary' : 'disabled'"
                ></NotificationIcon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  :class="{
                    'font-weight-bold': !item.IsRead,
                    'truncate-text': true,
                  }"
                >
                  {{ item.Header }}</v-list-item-title
                >
                <v-list-item-subtitle
                  style="color: var(--v-text-primary) !important"
                  :class="{
                    'font-weight-bold': !item.IsRead,
                    'truncate-text': true,
                  }"
                >
                  {{ item.Text }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="notification__date">
                  {{ item.Date | DateNear }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
          <v-list-item v-if="!loading && fullName === 'total'">
            <v-btn
              class="my-1"
              block
              depressed
              @click="more"
              :loading="loadingMore"
            >
              Показать еще
            </v-btn>
          </v-list-item>
        </v-list>
      </div>
    </v-card>
  </v-menu>
</template>
<script>
import { mapGetters } from "vuex";
import NotificationIcon from "./components/notificationIcon.vue";
export default {
  components: {
    NotificationIcon,
  },

  props: {
    view: {
      type: String,
      default: null,
    },
    typeView: {
      type: String,
      default: null,
    },
    notSmall: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      items: [],
      loading: false,
      loadingMore: false,
    };
  },

  computed: {
    ...mapGetters("notification", ["GET_COUNTS", "GET_TYPE"]),
    type() {
      return this.GET_TYPE(this.view);
    },
    fullName() {
      if (!this.view) {
        return "total";
      }
      let t = this.type?.pageListName;
      if (this.typeView) {
        t += ":" + this.typeView;
      }
      return t;
    },
    itemCount() {
      return this.GET_COUNTS?.find((e) => e.MenuItem === this.fullName);
    },
    categorizedItems() {
      return [
        {
          label: "Новые уведомления",
          items: this.items.filter((e) => !e.IsRead),
          button: true,
        },
        {
          label: "История уведомлений",
          items: this.items.filter((e) => e.IsRead),
          button: false,
        },
      ];
    },
  },
  watch: {
    menu(val) {
      if (val) {
        this.init();
      }
    },
  },
  methods: {
    clickItem(item) {
      if (this.items.length === 1) this.menu = false;
      this.$store.dispatch("notification/clickNotif", {
        notificationItem: item,
        router: this.$router,
      });
    },
    async more() {
      this.loadingMore = true;
      const newItems = await this.$store.dispatch(
        "notification/moreNotification",
        {
          fullName: this.fullName,
        }
      );
      this.items = [...this.items, ...newItems];

      this.loadingMore = false;
    },
    async init() {
      this.loading = true;
      this.items = await this.$store.dispatch("notification/loadNotification", {
        fullName: this.fullName,
      });
      this.loading = false;
    },

    async getIcon(item) {
      return await this.$store.dispatch("notification/getIcon", {
        num: item.ObjectType,
        pageType: item.MenuList.length
          ? item.MenuList[0].split(":")?.[1]
          : null,
      });
    },

    itemsIsRead() {
      this.$store.dispatch("notification/itemsIsRead", {
        fullName: this.fullName,
      });
      this.menu = false;
    },
  },
};
</script>
<style scoped lang="scss">
.v-menu__content {
  overflow-y: visible;
  overflow-x: visible;
  box-shadow: none;
  padding: 5px;
  .v-card {
    border-radius: 10px !important;
    box-shadow: none !important;
    .v-toolbar {
      border-radius: 10px !important;
    }
    .body {
      max-height: 73vh;
      overflow-y: auto;
      border-radius: 10px;
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }
  }
}
.truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 320px;
}
.notification__date {
  position: absolute;
  right: 13px;
  height: 31px;
  line-height: normal !important;
  text-align: right;
  max-width: 65px;
  white-space: normal;
  word-break: break-word;
}
</style>
<style lang="scss">
.notification__point:not(.notification__point--active) {
  transform: scale(0.3, 0.3);
  position: absolute;
  top: calc(50% - 16px);

  .v-btn__content {
    opacity: 0;
  }
  &:hover {
    transform: scale(1, 1);
    .v-btn__content {
      opacity: 1;
    }
  }
}
</style>
