<template>
  <div>
    <v-container class="violation-table-images">
      <v-layout row class="violation-table-images___container">
        <div
          v-for="img in images"
          :key="img.Id"
          class="violation-table-images__wrapper"
        >
          <v-hover v-slot="{ hover }">
            <v-card :elevation="hover ? 6 : 0" :class="{ 'on-hover': hover }">
              <img
                v-img:group="1"
                class="violation-table-images__img"
                :src="
                  img.Base64Content
                    ? img.Base64Content
                    : `${server_url}common/GetFile?externalId=${img.ExternalId}`
                "
              />
              <v-card-title
                class="text-h6 white--text violation-table-images__actions"
                :class="{ 'on-hover': hover }"
              >
                <v-row class="fill-height flex-row" justify="space-between">
                  <v-btn
                    :class="{ 'show-btns': hover }"
                    icon
                    title="Скачать"
                    x-small
                    download
                    :href="`${apiUrl}common/GetFile?externalId=${img.ExternalId}`"
                  >
                    <m-icon
                      icon="mdi-download"
                      :class="{ 'show-btns': hover }"
                    ></m-icon>
                  </v-btn>
                  <v-btn
                    v-if="!readOnly"
                    :class="{ 'show-btns': hover }"
                    icon
                    title="Удалить"
                    x-small
                    @click="remove(img.Id)"
                  >
                    <m-icon
                      icon="mdi-close"
                      :class="{ 'show-btns': hover }"
                    ></m-icon>
                  </v-btn>
                </v-row>
              </v-card-title>
            </v-card>
          </v-hover>
        </div>

        <div v-if="withAdd" class="violation-table-images__wrapper">
          <v-btn
            outlined
            color="primary"
            class="violation-table-images__add"
            @click="$refs.inputUpload.click()"
            :disabled="readOnly"
          >
            <m-icon icon="mdi-plus" large @click="add"></m-icon>
          </v-btn>
          <input
            v-show="false"
            @change="uploadImage"
            ref="inputUpload"
            type="file"
            accept="image/*"
          />
        </div>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "view-images2",
  props: {
    images: Array,
    withAdd: {
      type: Boolean,
      default: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      server_url: null,
    };
  },
  computed: {
    apiUrl() {
      // Полный путь к url должен быть именно в компоненте, а не в шаблоне.
      return process.env.VUE_APP_SERVER_URL;
    },
  },
  mounted() {
    this.server_url = process.env.VUE_APP_SERVER_URL;
  },
  methods: {
    remove(id) {
      this.$emit(
        "update:images",
        this.images.filter((e) => e.Id !== id)
      );
    },
    add() {},
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;

        this.$set(this.images, this.images.length, {
          Id: -Math.round(Math.random() * 1000000),
          Base64Content: this.previewImage,
          ExternalId: null,
          Name: image.name,
        });
      };
    },
  },
};
</script>

<style lang="scss">
.violation-table-images {
  margin-left: unset;
  &__container {
    padding: 0 10px;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  &__wrapper {
    margin: 10px 10px;
  }

  &__add {
    width: 80px !important;
    height: 80px !important;
  }
}

.violation-table-images__actions {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.violation-table-images__actions.on-hover {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: opacity 0.2s ease-in-out;
  background: var(--v-text-lighten1);
}
.violation-table-images .v-card {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  transition: opacity 0.2s ease-in-out;
}

.violation-table-images .v-card.on-hover {
  opacity: 0.8;
}

.violation-table-images .v-card.elevation-6.on-hover {
  transition: box-shadow 0.2s ease-in-out;
}

.violation-table-images
  .v-card:not(.on-hover)
  .violation-table-images__actions {
  opacity: 0;
}

.show-btns {
  color: var(--v-white-base) !important;
}
</style>
