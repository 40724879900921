import Vue from "vue";
import store from "../store";
import DataHelper from "../utils/DataHelper";

const today = function () {
  return DataHelper.toServerDateTime(DataHelper.today());
};

const now = function () {
  return DataHelper.toServerDateTime(new Date());
};

const dafaultAuditOperation = () => ({
  Id: 0,
  Kind: 0,
  Num: 0,
  ObjectType: 0,
  Reason: "",
  Subject: "",
  Period: {
    DateIn: today(),
    DateOut: DataHelper.toServerDateTime(
      DataHelper.AddWorkingDays(new Date(), 9)
    ),
  },
  External: false,
  Organization: null,
  Comment: null,
  Status: 0,
  Members: [],
  CheckOperations: [],
  Files: [],
});

const defaultTask = () => {
  const d = new Date();
  d.setSeconds(0);
  d.setMinutes(d.getMinutes() + 10);
  return {
    Id: -Math.round(Math.random() * 100000),
    // Created: today(),
    Project: null,
    Parent: null,
    Priority: 2,
    Author: store.getters.GET_USER.Employee,
    Executors: [],
    // Executor: store.getters.GET_USER.Employee,
    Notify: [store.getters.GET_USER.Employee],
    PlannedPeriod: { DateIn: null, DateOut: null },
    // PlannedStart: null,
    // PlannedEnd: today(),
    // NotifyStartDate: DataHelper.toServerDateTime(d),
    NotifyStartSent: null,
    Periodicity: null,
    PeriodicityHistory: [],
    Children: [],
    Files: [],
    History: [],
    Status: 1,
    Result: "",
  };
};

const defaultProject = (type) => ({
  Id: -Math.round(Math.random() * 100000),
  AuditOperations: [],
  Authors: [store.getters.GET_USER.Employee],
  Type: type ?? 0,
  Period: { DateIn: today() },
  Shared: false,
  OnlyRead: false,
  Tasks: [],
  Files: [],
});

const defaultCheckListCopy = () => ({
  Id: -1,
  Name: "",
  Questions: [],
});

const dafaultCheckList = () => ({
  Id: -1,
  Name: "",
  Documents: [],
  Questions: [],
});

const defaultCheckOperation = function (
  checkList,
  { CustomObject, EmployeeObject, PartitionObject, None }
) {
  return {
    CheckList: checkList,
    CurrentUserIsAuditor: true,
    Children: PartitionObject ? PartitionObject.Children : [],
    Name:
      CustomObject ||
      (EmployeeObject
        ? Vue.filter("PersonShortName")(EmployeeObject)
        : PartitionObject
        ? PartitionObject.Name
        : "Не выбрано"),
    CustomObject,
    EmployeeObject,
    PartitionObject,
    objectCheck:
      CustomObject ||
      (EmployeeObject
        ? Vue.filter("PersonShortName")(EmployeeObject)
        : PartitionObject
        ? PartitionObject.Name
        : None
        ? ""
        : "Не выбрано"),
    Questions: null,
    Status: 0,
    Id: PartitionObject
      ? PartitionObject.Id + -Math.round(Math.random() * 1000000)
      : EmployeeObject
      ? EmployeeObject.Id + -Math.round(Math.random() * 1000000)
      : -Math.round(Math.random() * 1000000),
    Auditors: [],
  };
};

const defaultFile = () => ({
  Name: "",
  Base64Content: "",
  Id: -Math.round(Math.random() * 100000),
  ExternalId: "",
  file: null,
});

const defaultEmployee = () => ({
  Id: -Math.round(Math.random() * 100000),
  LastName: "",
  FirstName: "",
  MiddleName: "",
  BirthDate: null,
  User: defaultUser(),
  WorkPlaces: [],
});

const defaultWorkplace = () => ({
  Id: -Math.round(Math.random() * 100000),
  Period: { DateIn: today(), DateOut: null },
  Place: null,
  Position: null,
  Comment: "",
});

const defaultPosition = () => ({
  Id: -Math.round(Math.random() * 100000),
  Name: "",
});

const defaultUser = () => ({
  Id: -1,
  IsActive: true,
  IsAdmin: false,
  ChangePasswordOnFirstLogon: true,
  Devices: [],
});

const defaultPartition = () => ({
  Id: -Math.round(Math.random() * 100000),
  Name: "",
  Children: [],
});

const defaultEquipment = () => ({
  Id: -Math.round(Math.random() * 100000),
  Documents: [],
  IsServiceRequired: false,
  IsValidationRequired: false,
  IssueDate: null,
  MeasureAccuracy: null,
  MeasureRange: null,
  MeasuringType: null,
  Model: null,
  Name: null,
  Num: null,
  Partitions: [],
  RegDate: null,
  RegEnd: null,
  RegNum: null,
  SerialNum: null,
  ServiceContract: null,
  ServiceDate: null,
  ServicePeriod: 0,
  StandartRecords: [],
  Type: null,
  ValidationBid: null,
  ValidationDate: null,
  ValidationPeriod: 0,
});

const defaultAppliedDocument = (typeId = 0) => ({
  Id: -Math.round(Math.random() * 100000),
  Type: typeId > 0 ? { Id: typeId } : null,
  Num: "",
  Date: today(),
  DateOut: null,
  Partition: null,
  Equipment: null,
  Employee: null,
  Contragent: null,
  Sum: 0,
  Comment: "",
  Files: [],
});

const defaultAppliedDocumentType = () => ({
  Id: -Math.round(Math.random() * 100000),
  Name: "Новый тип",
  Object: 0,
  Options: 0,
});

const defaultLocalDocument = () => ({
  Id: -Math.round(Math.random() * 100000),
  Type: 0,
  Date: today(),
  Naimenovanie: "",
  Avtory: [],
  Soglasuyut: [], // AgreementsBy
  Podpisyvaet_utverzhdaet: null,
  Oznakomit: [],
  Files: [],
});

const defaultLocalDocumentHistory = () => ({
  Id: -Math.round(Math.random() * 100000),
  Date: today(),
  Status: null,
  Employee: null,
  Comment: null,
});

const defaultContragent = () => ({
  Id: -Math.round(Math.random() * 100000),
  Name: "",
  Phone: "",
  Address: "",
});

const defaultAuditIndicator = () => ({
  Id: -Math.round(Math.random() * 100000),
  Name: "",
  Description: "",
  Subject: null,
  Algorithm: 0,
  Category: 0,
  RefValue1: 0,
  RefValue2: 0,
  RefValue3: 0,
  CheckLists: [],
});

const defaultAuditIndicatorCategory = () => ({
  Id: -Math.round(Math.random() * 100000),
  Name: "",
});

const defaultDocumentTemplate = (parameters) => ({
  Id: -Math.round(Math.random() * 100000),
  DocumentType: null,
  ActivitySection: null,
  IsSystem: false,
  Name: "",
  Options: 0,
  Parameters: parameters ?? [],
  IsOftenUsed: true,
});
const defaultDocumentTemplateField = () => ({
  Id: -Math.round(Math.random() * 100000),
  Name: "",
  Type: 1,
  Format: "",
  DisplayName: "",
  IsBase: false,
});

const defaultUnwishedEvent = () => ({
  Id: -Math.round(Math.random() * 100000),
  Created: today(),
  Date: now(),
  Time: null,
  Place: null,
  InjuredKind: 0,
  InjuredPerson: null,
  Type: null,
  ConsequenceType: null,
  Content: null,
  Result: null,
  Comment: null,
  Files: [],
  Partition: [],
  FieldValues: [],
  Tasks: [],
  Children: [],
});

const defaultUnwishedEventResponsible = () => ({
  Id: -Math.round(Math.random() * 100000),
  Employee: null,
  Types: null,
  Partitions: [],
  AllTypes: false,
  AllPartitions: false,
});

const defaultUnwishedEventType = () => ({
  Id: -Math.round(Math.random() * 100000),
  Name: "",
  Responsibles: [],
});

const defaultUnwishedConsequenceType = () => ({
  Id: -Math.round(Math.random() * 100000),
  Category: null,
  Description: "",
});

const defaultStatisticIndicator = () => ({
  Id: -Math.round(Math.random() * 100000),
  RatingType: 0,
  Name: "",
  Period: 2,
  TargetType: 0,
  RefDescription1:
    "Значение статистического показателя требует принятия незамедлительных действий",
  RefDescription2: "Значение статистического показателя вызывает тревогу",
  RefDescription3: "Допустимое значение статистического показателя",
  RefDescription4: "Очень хорошее значение статистического показателя",
  Type: 0,
  AutoCreateValues: true,
  Organizations: [],
  Objects: [],
  PeriodDate: { DateIn: null, DateOut: null },
});

const KnowledgeBase = {
  question: () => ({
    Id: -Math.round(Math.random() * 100000),
    Images: [],
    Num: 0,
    Text: "",
    Type: 0,
    Variants: [
      {
        Id: -Math.round(Math.random() * 100000),
        IsCorrect: false,
        IsSelected: false,
        Num: 1,
        Text: "",
      },
      {
        Id: -Math.round(Math.random() * 100000),
        IsCorrect: false,
        IsSelected: false,
        Num: 2,
        Text: "",
      },
      {
        Id: -Math.round(Math.random() * 100000),
        IsCorrect: false,
        IsSelected: false,
        Num: 3,
        Text: "",
      },
    ],
  }),
  variant: () => ({
    Id: -Math.round(Math.random() * 100000),
    IsCorrect: false,
    IsSelected: false,
    Num: 0,
    Text: "",
  }),
  subject: () => ({
    Id: -Math.round(Math.random() * 100000),
    Name: "",
    Description: "",
  }),
  course: () => ({
    Id: -Math.round(Math.random() * 100000),
    Description: "",
    Name: "",
    Subject: null,
    Lessons: [],
    Records: [],
    Authors: [store.getters.GET_USER.Employee],
    Supervisors: [],
    Employees: [],
    IsPublic: false,
    Period: { DateIn: today() },
  }),
  lesson: () => ({
    Id: -Math.round(Math.random() * 100000),
    Name: "",
    Num: 0,
    Records: [],
  }),
  record: () => ({
    Content: null,
    Document: null,
    Id: -Math.round(Math.random() * 100000),
    IsTest: false,
    Name: "",
    Num: 0,
    Questions: Array[0],
    Time: 0,
    Type: 1,
  }),
  material: () => ({
    Document: null,
    HtmlContent: null,
    Id: -Math.round(Math.random() * 100000),
    IsTest: false,
    Name: "",
    Num: 0,
    Questions: [],
    RtfContent: null,
    VideoUrl: null,
    FileInput: null,
    Time: 0,
    Type: 1,
  }),
  participant: () => ({
    Id: -Math.round(Math.random() * 100000),
    Employee: null,
    role: 1,
  }),
};

const defaultSummaryReport = {
  step1: () => ({
    Id: -Math.round(Math.random() * 100000),
    DateIn: null,
    DateOut: null,
    Authors: [store.getters.GET_USER.Employee],
    Approvement: store.getters.GET_USER.Employee,
    DoneSteps: [],
    IsApprovement: false,
  }),
  step2: () => ({
    Id: -Math.round(Math.random() * 100000),
    Status: 0,
    AuditOperationsContent: {
      PlannedAuditCount: 0,
      UnplannedAuditCount: 0,
      PercentValue: 0,
      ViolationCount: 0,
      Indicators: [],
      Tasks: [],
    },
    Step: 2,
  }),
  step3: () => ({
    Id: -Math.round(Math.random() * 100000),
    EventsContent: { Conclusion: null, Events: [] },
    Step: 3,
  }),
  step4: () => ({
    Id: -Math.round(Math.random() * 100000),
    EventsContent: { Conclusion: null, Events: [] },
    Step: 4,
  }),
  step5: () => ({
    Id: -Math.round(Math.random() * 100000),
    MonitoringContent: { Conclusion: null, Monitorings: [] },
    Step: 5,
  }),
  step6: () => ({
    Id: -Math.round(Math.random() * 100000),
    Conclusion: null,
    Step: 6,
  }),
  step7: () => ({
    Id: -Math.round(Math.random() * 100000),
    SettingsContent: { Settings: [] },
    Step: 7,
  }),
};

const defaultAuditIndicatorCategoriesEdit = () => ({
  Id: -Math.round(Math.random() * 100000),
  Name: "",
  Documents: [],
});

export {
  defaultAuditIndicatorCategoriesEdit,
  defaultStatisticIndicator,
  dafaultAuditOperation,
  defaultCheckOperation,
  defaultTask,
  defaultProject,
  dafaultCheckList,
  defaultCheckListCopy,
  defaultFile,
  defaultEmployee,
  defaultWorkplace,
  defaultPosition,
  defaultUser,
  defaultPartition,
  defaultEquipment,
  defaultAppliedDocument,
  defaultAppliedDocumentType,
  defaultLocalDocument,
  defaultLocalDocumentHistory,
  defaultContragent,
  defaultAuditIndicator,
  defaultAuditIndicatorCategory,
  defaultDocumentTemplateField,
  defaultDocumentTemplate,
  defaultUnwishedEvent,
  defaultUnwishedEventResponsible,
  defaultUnwishedEventType,
  defaultUnwishedConsequenceType,
  KnowledgeBase,
  defaultSummaryReport,
};
