import Api from "./Api";

export default {
  async get(params) {
    return Api().get("statisticindicator/GetStatisticIndicators", {
      params: {
        archive: !(params?.done === false),
        fields: params?.fields || 8,
        year: params?.year,
      },
    });
  },
  async show(id, params) {
    return Api().get("statisticindicator/GetStatisticIndicator", {
      params: { id, ...params },
    });
  },
  async create(statisticindicator) {
    return Api().post(
      "statisticindicator/createstatisticindicator",
      statisticindicator
    );
  },
  async update(statisticindicator) {
    return Api().put(
      "statisticindicator/UpdateStatisticIndicator",
      statisticindicator
    );
  },
  async updateAllPeriods(periods) {
    return Api().put("statisticindicator/UpdateAllUpdateAllPeriods", periods);
  },
  async delete(id) {
    return Api().delete("statisticindicator/deletestatisticindicator", {
      params: { id },
    });
  },

  async getValues(indicatorId, lastCount = 0, analysis = false, year = 2024) {
    return Api().get("statisticindicator/GetStatisticIndicatorValues", {
      params: {
        indicatorId,
        lastCount,
        analysis,
        year,
      },
    });
  },

  async getValueSum(id, dateIn, dateOut) {
    return Api().get("statisticindicator/GetStatisticIndicatorValueSum", {
      params: { id, dateIn, dateOut },
    });
  },

  async createValue(indicator) {
    return Api().post(
      "statisticindicator/createstatisticindicatorvalue",
      indicator
    );
  },
  async updateValue(statisticindicator) {
    return Api().put(
      "statisticindicator/UpdateStatisticIndicatorValue",
      statisticindicator
    );
  },
  async deleteValue(id) {
    return Api().delete("statisticindicator/deletestatisticindicatorvalue", {
      params: { id },
    });
  },
  async deleteGroup(id, PeriodIn) {
    return Api().post("statisticindicator/DeleteStatisticIndicatorGroupValue", {
      Id: id,
      PeriodIn,
    });
  },
  createValues(id) {
    return Api().post("statisticindicator/CreateStatisticIndicatorValues", {
      Id: id,
    });
  },

  archive(id) {
    return Api().put("statisticindicator/UpdateStatisticIndicatorState", {
      id,
    });
  },
};
