<template>
  <m-icon :icon="icon" :color="color"></m-icon>
</template>
<script>
export default {
  props: {
    notificationItem: {
      type: Object,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      icon: "medexpert-notifications",
    };
  },
  watch: {
    notificationItem: {
      immediate: true,
      handler(obj) {
        this.init(obj);
      },
    },
  },
  methods: {
    async init(obj) {
      this.icon = await this.$store.dispatch("notification/getIcon", {
        num: obj.ObjectType,
        pageType: obj?.MenuList?.length
          ? obj.MenuList[0].split(":")?.[1]
          : null,
      });
    },
  },
};
</script>
