<template>
  <div>
    <base-popup
      title="Добавление несоответствия / предложения"
      :icon="UtilGetIcon('view-AuditOperations')"
      @save="save"
      v-model="dialog"
      :disabledSave="!!editingItems.length || hasEmptyDescription"
      :width="width"
      :height="height"
    >
      <v-flex v-if="question">
        <v-container>
          <v-layout>
            <v-card elevation="0" width="100%">
              <v-card-title class="py-2" style="line-height: 1rem">
                <v-treeview
                  :items="[treeNames]"
                  expand-icon="$ToolArrowDown"
                  item-text="Text"
                  item-children="Children"
                  class="block__content"
                  style="width: 100%"
                  open-all
                  full-width
                >
                  <template v-slot:label="{ item }">
                    <span
                      :class="
                        ['', 'success--text', 'error--text', 'warning--text'][
                          question.Answer
                        ]
                      "
                    >
                      {{ item.Text }}
                    </span>
                  </template>
                  <template v-slot:append="{ item }">
                    <template v-if="!item.Children.length">
                      <div
                        class="answer__item"
                        v-for="{
                          name,
                          nameOutline,
                          title,
                          value,
                          color,
                        } in answerOptions"
                        :key="name"
                      >
                        <v-btn
                          icon
                          :title="title"
                          :disabled="readonly"
                          @click.stop="pressButtons(value)"
                        >
                          <m-icon
                            :icon="
                              value === question.Answer ? name : nameOutline
                            "
                            :color="!readonly ? color : 'inherit'"
                          />
                        </v-btn>
                      </div>
                    </template>
                  </template>
                </v-treeview>
                <div>
                  <b
                    v-if="question.IsRequired"
                    class="caption mt-2 warning--text"
                    >(Обязательно для исполнения)</b
                  >

                  <br v-if="question.IsRequired && question.Comment" />

                  <text-grid class="mt-2">
                    <text-grid-row>
                      <text-grid-column class="text-subtitle-2" width="130px">
                        <m-icon
                          small
                          color="primary"
                          icon="mdi-comment-processing-outline"
                          class="mr-1"
                          style="line-height: 1.25rem"
                        ></m-icon>
                        Примечание:
                      </text-grid-column>
                      <text-grid-column
                        class="text-body-2"
                        style="line-height: 1.25rem; flex: 1"
                        v-html="commentText(question.Comment)"
                      >
                      </text-grid-column>
                    </text-grid-row>
                    <text-grid-row>
                      <text-grid-column
                        class="text-subtitle-2"
                        width="130px"
                        style="line-height: 1.25rem"
                      >
                        <m-icon
                          small
                          color="primary"
                          icon="medexpert-docs"
                          :height="13"
                          class="mr-1"
                        ></m-icon>
                        Документы:
                      </text-grid-column>
                      <text-grid-column
                        class="text-body-2"
                        style="line-height: 1.25rem; flex: 1"
                      >
                        <menu-list-document-with-template
                          v-if="question.Documents.length"
                          :documents="question.Documents"
                        >
                          {{ question.Documents[0].Name }}
                          {{
                            question.Documents.length > 1
                              ? "(+" + (question.Documents.length - 1) + ")"
                              : ""
                          }}
                        </menu-list-document-with-template>
                        <template v-else> Нет </template>
                      </text-grid-column>
                    </text-grid-row>
                  </text-grid>
                </div>
              </v-card-title>
              <v-layout>
                <v-flex>
                  <v-tabs v-model="tab" background-color="transparent" grow>
                    <v-tab v-for="(item, index) in items" :key="index">
                      {{ item.text }}
                    </v-tab>
                  </v-tabs>
                </v-flex>
              </v-layout>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <tab-violations
                    ref="tabViolations"
                    :Violations="question.Violations"
                    :readOnly="readonly"
                    :treeNames="treeNames"
                    :auditOperationNum="auditOperationNum"
                    :checkListName="checkListName"
                    :objectCheck="objectCheck"
                  />
                </v-tab-item>
                <v-tab-item>
                  <tab-recommendations
                    ref="tabRecommendations"
                    :Recommendations="question.Recommendations"
                    :readOnly="readonly"
                    :treeNames="treeNames"
                    :auditOperationNum="auditOperationNum"
                    :checkListName="checkListName"
                    :objectCheck="objectCheck"
                  />
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-layout>
        </v-container>
      </v-flex>
    </base-popup>
  </div>
</template>

<script>
import CheckOperationService from "@/services/CheckOperationService";
import TabViolations from "./tabs/TabViolations.vue";
import BasePopup from "../../../layouts/BasePopup.vue";
import TabRecommendations from "./tabs/TabRecommendations.vue";

export default {
  name: "view-CheckOperationQuestionNotes",
  components: {
    textGrid: () => import("../../../components/base/textGrid"),
    textGridRow: () => import("../../../components/base/textGrid/textGridRow"),
    textGridColumn: () =>
      import("../../../components/base/textGrid/textGridColumn.vue"),
    MenuListDocumentWithTemplate: () =>
      import("@/components/document/MenuListDocumentWithTemplate"),
    TabViolations,
    BasePopup,
    TabRecommendations,
  },
  inject: ["external"],
  provide() {
    return {
      editingItems: this.editingItems,
    };
  },
  props: {
    question: {
      type: Object,
      default: () => {},
    },
    answerOptions: {
      type: Array,
    },
    treeNames: Object,
    readonly: {
      type: Boolean,
      default: false,
    },
    width: String,
    height: String,
    auditOperationNum: [String, Number],
    checkListName: String,
    objectCheck: String,
  },
  data() {
    return {
      tab: null,
      items: [
        { text: "Несоответствия", icon: "mdi-clipboard-remove-outline" },
        { text: "Предложения", icon: "mdi-clipboard-edit-outline" },
      ],
      dialog: false,
      editingItems: [],
    };
  },
  computed: {
    hasEmptyDescription() {
      return (
        this.question?.Violations?.some((r) => !r.Description) ||
        this.question?.Violations?.some((r) =>
          r?.Recommendations?.some((d) => !d.Description)
        ) ||
        this.question?.Recommendations?.some((r) => !r.Description)
      );
    },
  },
  watch: {
    question: function (val) {
      if (val && !this.isEmptyObject(val)) {
        this.dialog = true;
      }
      this.violationActive = null;
    },
    dialog: function (val) {
      if (!val) {
        this.editingItems.pop();
        this.$emit("close");
      }
    },
  },
  methods: {
    commentText(comment) {
      return comment?.replaceAll("\n", "<br />") || "Нет";
    },
    async save(index) {
      try {
        if (index !== undefined) this.question.Answer = index;

        try {
          await CheckOperationService.update(
            this.question,
            true,
            this.external
          );
        } catch (error) {
          console.log(error);
        } finally {
          this.$parent.init();
        }

        if (!index) {
          this.$store.dispatch("frame/DESTROY_COMPONENT");
          this.dialog = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    pressButtons(index) {
      this.save(index);
      // if (!this.readonly) this.$emit("action", this.question, index);
    },
  },
};
</script>

<style lang="scss">
.v-treeview-node__append {
  display: flex;
}
</style>
