<template>
  <v-tabs v-bind="$attrs" v-on="$listeners" :value="value" ref="tabs">
    <slot name="default"> </slot>
  </v-tabs>
</template>
<script>
import { debounce } from "lodash";
export default {
  name: "m-tabs",
  inject: ["currentRouter", "nested"],
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    withRoute: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  watch: {
    value(val) {
      if (this.nested || !this.withRoute) return;
      this.changeTab(val);
    },
    "$store.state.pageTabs.activeTab"(val) {
      if (this.currentRouter.path === val && this.withRoute) {
        this.changeTab(this.value);
      }
    },
  },
  mounted() {
    if (this.withRoute)
      setTimeout(() => {
        if (this.nested) return;
        if (this.$route.query?.tab) {
          this.$emit("change", parseInt(this.$route.query?.tab));
          setTimeout(() => {
            this.$refs.tabs.callSlider();
          }, 1000);
        }
      }, 300);
  },
  methods: {
    changeTab: debounce(function (val) {
      if (val === parseInt(this.$route.query.tab)) return;

      if (this.$route.query.tab === undefined && val === 0) return;

      this.$router.push({
        path: this.$route.fullPath,
        query: { ...this.$route.query, tab: val },
      });
    }, 400),
  },
};
</script>
