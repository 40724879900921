<template>
  <base-detail-view
    :loading="loading"
    :title="
      type === 'create'
        ? 'Создание нежелательного события'
        : 'Нежелательное событие'
    "
    :sub-title="dataSource.Done ? 'Завершено' : ''"
    :isUnsaved="isUnsaved && valid"
    @onSave="save"
    @onRefresh="init"
  >
    <template slot="subTitle">
      <span class="caption">{{ dataSource.Done ? "Завершено" : "" }}</span>
      <span
        v-if="$store.state.Organization.OwnerType === 2 && type !== 'create'"
        class="ml-2 caption"
      >
        {{
          dataSource.OwnerName
            ? 'Медицинская организация: "' + dataSource.OwnerName + '"'
            : ""
        }}
      </span>
      <span
        v-else-if="
          $store.state.Organization.OwnerType === 1 &&
          (dataSource.OwnerName
            ? dataSource.OwnerName !== dataSource.Type.Owner.Name
            : false) &&
          type !== 'create'
        "
        class="ml-2 caption"
      >
        {{
          dataSource.Type.Owner.Name
            ? "Данные " +
              (dataSource.Shared
                ? "доступны "
                : "будут доступны после опубликования ") +
              'для: "' +
              dataSource.Type.Owner.Name +
              '"'
            : ""
        }}
      </span>
    </template>
    <template v-if="!readonlyConsequence" slot="action">
      <menu-button
        v-if="!dataSource.Done"
        @click="done($event)"
        caption="Завершение нежелательного события"
        text="Завершить"
        commentLabel="Результат разбора события"
        :value="dataSource.Result"
        :disabled="isUnsaved"
      >
        <template slot="extended">
          <base-date-picker v-model="dateDone" label="Дата завершения" />
        </template>
      </menu-button>
      <v-btn v-else dark color="primary" class="ml-4" @click="open">
        Открыть
      </v-btn>
      <v-btn
        v-if="
          dataSource.Type &&
          dataSource.Type.IsShared &&
          $store.state.Organization.OwnerType === 1
        "
        :loading="loading"
        color="primary"
        dark
        class="ml-4"
        @click="
          dataSource.Shared = !dataSource.Shared;
          save();
        "
      >
        {{ !dataSource.Shared ? "Опубликовать" : "Отменить публикацию" }}
      </v-btn>
    </template>
    <v-form v-model="valid" :readonly="!!dataSource.Done || readonly">
      <div class="block__content">
        <v-container>
          <v-row>
            <v-col>
              <base-date-picker
                v-model="dataSource.Date"
                label="Дата события"
                :readonly="readonlyEvent"
                :clearable="!readonlyEvent && !dataSource.Done"
                required
                :rules="[rules.required]"
              ></base-date-picker>
              <v-text-field
                v-model="dataSource.InjuredPerson"
                label="Ф.И.О. пострадавшего"
                :readonly="readonlyEvent"
                :clearable="!readonlyEvent && !dataSource.Done"
                maxlength="100"
                v-up-first-symbol
              ></v-text-field>
              <base-date-picker
                v-if="dataSource.Done"
                v-model="dataSource.Done"
                label="Дата завершения"
                :readonly="true"
                :clearable="false"
              ></base-date-picker>
            </v-col>
            <v-col>
              <v-text-field
                v-model="dataSource.Time"
                type="time"
                label="Время"
                :readonly="readonlyEvent"
              ></v-text-field>
              <m-select
                v-model="dataSource.InjuredKind"
                :items="UnjuredKind"
                item-text="value"
                item-value="id"
                label="Категория пострадавшего"
                :readonly="readonlyEvent"
                :clearable="false"
              ></m-select>
            </v-col>
            <v-col>
              <div class="d-flex">
                <div style="flex: 1">
                  <m-tree-select
                    v-show="!dataSource.AnotherPlace"
                    v-model="dataSource.Partition"
                    :items="
                      this.IsCurrentOwner ? partitions : dataSource.Partition
                    "
                    @click.prevent.stop
                    selection-type="independent"
                    allowSelectParents
                    label="Подразделение"
                    :dict="{ name: 'Partition', notItems: true }"
                    required
                    :rules="!dataSource.AnotherPlace ? [rules.required] : []"
                  ></m-tree-select>
                  <v-text-field
                    v-show="dataSource.AnotherPlace"
                    v-model="dataSource.Place"
                    label="Место"
                    :readonly="readonlyEvent"
                    :clearable="!readonlyEvent && !dataSource.Done"
                    maxlength="200"
                  ></v-text-field>
                </div>
                <div title="Место" class="ml-3">
                  <v-checkbox
                    v-model="dataSource.AnotherPlace"
                    label="Другое место"
                    class="text-no-wrap"
                  ></v-checkbox>
                </div>
              </div>

              <m-select
                v-model="dataSource.Type"
                :items="eventTypes"
                item-text="Name"
                label="Вид события"
                return-object
                required
                :rules="[rules.required]"
                :readonly="readonlyEvent"
                :clearable="false"
                @change="changeType"
              ></m-select>
            </v-col>
          </v-row>
          <v-textarea
            v-model="dataSource.Content"
            label="Что произошло"
            :readonly="readonlyEvent"
            :clearable="false"
            auto-grow
            rows="1"
            maxlength="1000"
            required
            :rules="[rules.required]"
            v-up-first-symbol
          ></v-textarea>
        </v-container>
      </div>

      <div
        v-if="
          dataSource.FieldValues && dataSource.FieldValues.length && !loading
        "
        class="block__content block__content2"
      >
        <v-container class="px-0">
          <dynamic-inputs
            v-model="valid"
            :readonly="!!dataSource.Done || readonly"
            fixed-width
            :classWrapper="'d-flex flex-wrap'"
            :fields="dataSource.FieldValues"
            :type="1"
            @update="updateField"
          />
        </v-container>
      </div>

      <div class="block__content block__content2">
        <v-container>
          <v-row>
            <v-col>
              <m-select
                v-model="dataSource.ConsequenceType"
                :items="
                  this.IsCurrentOwner
                    ? consequenceTypes
                    : dataSource.ConsequenceType
                "
                item-text="Description"
                label="Последствия"
                return-object
                :readonly="readonlyEvent || readonlyResponsible"
                :clearable="!readonlyEvent && !dataSource.Done"
                @click="responsibleStatus()"
              ></m-select>
              <v-textarea
                v-model="dataSource.Result"
                label="Результаты разбора события"
                :readonly="readonlyEvent || readonlyResponsible"
                :clearable="false"
                auto-grow
                rows="1"
                maxlength="1000"
                v-up-first-symbol
                @click="responsibleStatus()"
              ></v-textarea>
              <v-textarea
                v-model="dataSource.Comment"
                label="Примечание"
                :readonly="readonlyEvent || readonlyResponsible"
                :clearable="false"
                auto-grow
                rows="1"
                maxlength="1000"
                v-up-first-symbol
                @click="responsibleStatus()"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div v-if="IsCurrentOwner">
        <m-tabs v-model="tab">
          <v-tab key="Tasks">Задачи</v-tab>
          <v-tab key="UnshiwedEvents">Связанные НС</v-tab>
          <v-tab key="File">Прикрепленные файлы</v-tab>
        </m-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item key="Tasks">
            <task-list
              v-if="dataSource.Tasks && dataSource.Tasks"
              class="mt-2"
              :Tasks.sync="dataSource.Tasks"
              style="margin-bottom: 0 !important"
              :isFromTask="false"
              :afterAddTask="
                (data) => ({ ...data, Content: dataSource.Result })
              "
              typeParent="unwishedEvent"
              :readonly="readonly"
              :apiService="TaskService.unwishedEvent"
              @afterDelete="originObject.Tasks = [...dataSource.Tasks]"
            ></task-list>
          </v-tab-item>
          <v-tab-item key="UnshiwedEvents">
            <unwished-event-list
              v-if="dataSource.Children"
              :items.sync="dataSource.Children"
              :currentUnwished="dataSource"
              :readonly="readonly"
            ></unwished-event-list>
          </v-tab-item>
          <v-tab-item key="File">
            <fileLibrary
              :files.sync="dataSource.Files"
              :init="init"
              :save="save"
              :showAdd="true"
              :showAddText="false"
              :notShowDelete="false"
              nameButtonAddFile="Прикрепить файл"
              ref="fileLibrary"
              :id="dataSource.Id"
              :supportImg="true"
            >
              <template slot="info">
                <div style="padding: 50px 0; text-align: center">
                  Прикрепленные файлы отсутствуют
                </div>
              </template>
            </fileLibrary>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-form>
    <informer
      v-if="
        false && dataSource.Children && dataSource.Children.find((e) => !e.Link)
      "
      :offset-x="tab === 0 ? -70 : 0"
      :offset-y="12"
    >
      Имееться ({{ dataSource.Children.filter((e) => !e.Link).length }}) похожих
      НС
      <br />
      Для объединения перейдите в Связанные НС
    </informer>
  </base-detail-view>
</template>

<script>
import { debounce } from "lodash";
import UnwishedEventService from "../../services/UnwishedEventService";
import { defaultUnwishedEvent } from "../../data/defaultObjects";
import { UnwishedEventUnjuredKind } from "../../data/enums";
import Permissions from "../../data/permissions";
import UnsavedMixin from "../../mixins/UnsavedMixin.js";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";
import { mapActions } from "vuex";
import BaseDetailView from "../../layouts/BaseDetailView.vue";
import MSelect from "../../components/base/inputs/mSelect.vue";
import MenuButton from "../document/components/MenuButton.vue";
import BaseDatePicker from "../../components/base/BaseDatePicker.vue";
import DataHelper from "../../utils/DataHelper";
import MTreeSelect from "../../components/base/inputs/mTreeSelect.vue";
import TaskList from "@/components/task/TaskList";
import mTabs from "@/components/base/tabs/mTabs";
import UnwishedEventList from "../../components/UnwishedEvent/UnwishedEventList.vue";
import TaskService from "@/services/TaskService";
import fileLibrary from "@/components/FileLibrary.vue";

export default {
  name: "view-UnwishedEventEdit",
  components: {
    BaseDetailView,
    MSelect,
    MenuButton,
    BaseDatePicker,
    MTreeSelect,
    TaskList,
    mTabs,
    fileLibrary,

    informer: () => import("@/modules/VuetifyInformer/informer.vue"),
    DynamicInputs: () => import("@/components/DynamicInputs.vue"),
    UnwishedEventList,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  data: function () {
    return {
      // Api
      apiService: UnwishedEventService,
      getDefaultObject: defaultUnwishedEvent,
      getObjectText: (obj, isNew) =>
        isNew ? "новое" : obj.Date?.substr(0, 10),
      loading: true,

      eventTypes: [],
      consequenceTypes: [],
      // Типы событий, для которых текущий сотрудник может вводить результаты; null - запрет на редактирование результатов.
      responsibleEventTypes: null,
      dataSource: defaultUnwishedEvent(),

      valid: false,
      rules: {
        required: (value) =>
          (!!value && value.length !== 0) || "Укажите значение.",
      },
      showCompleted: false,
      dateDone: DataHelper.toServerDateTime(DataHelper.today()),
      partitions: [],
      responsible: null,
      showPartition: false,
      tab: 0,
    };
  },
  computed: {
    TaskService() {
      return TaskService;
    },
    IsCurrentOwner() {
      return this.dataSource.OwnerId
        ? this.$store.state.Organization.OwnerId === this.dataSource.OwnerId
        : true;
    },
    readonly() {
      return this.dataSource.OnlyRead;
    },
    readonlyEvent() {
      return (
        !this.hasPermission(Permissions.UnwishedEventEdit) || this.readonly
      );
    },
    readonlyConsequence() {
      return !(
        this.responsibleEventTypes &&
        (this.responsibleEventTypes.length === 0 ||
          this.responsibleEventTypes.findIndex(
            (t) => t.Id === this.dataSource?.Type?.Id
          ) >= 0)
      );
    },
    readonlyResponsible() {
      return !(
        (this.responsible?.AllTypes ||
          this.responsible?.Types.some(
            (e) => e?.Id === this.dataSource?.Type?.Id
          )) &&
        (this.responsible?.AllPartitions ||
          this.responsible?.Partitions.some(
            (e) => e?.Id === this.dataSource?.Partition?.Id
          ))
      );
    },
    UnjuredKind() {
      return UnwishedEventUnjuredKind;
    },
  },
  watch: {
    "$store.state.partitions": function (val) {
      this.partitions = val;
    },
  },
  async mounted() {
    try {
      this.eventTypes = await this.LOAD_UNWISHED_EVENT_TYPES();
      this.consequenceTypes = await this.LOAD_UNWISHED_CONSEQUENCE_TYPES();
      this.partitions = await this.LOAD_PARTITIONS();
      const responsibles = await this.LOAD_UNWISHED_EVENT_RESPONSIBLES();
      this.responsible = responsibles.find(
        (r) => r.Employee.Id === this.USER.Employee.Id
      );
      this.responsibleEventTypes = this.responsible && this.responsible.Types;
    } catch (error) {
      console.log(error);
    }
    await this.init();

    // Загрузка всех ораганизаций для пролежней
    if (
      this.dataSource.FieldValues?.find(
        (e) => e.Name === "AttachedOrganizationOrganization"
      )
    )
      this.LOAD_ORGANIZATIONS();
  },
  methods: {
    ...mapActions([
      "LOAD_UNWISHED_EVENT_TYPES",
      "LOAD_UNWISHED_CONSEQUENCE_TYPES",
      "LOAD_UNWISHED_EVENT_RESPONSIBLES",
      "LOAD_PARTITIONS",
    ]),
    ...mapActions("loaderDataSources", ["LOAD_ORGANIZATIONS"]),
    DataFromServerNormalize(data) {
      if (!data.Partition) data.AnotherPlace = true;
      if (this.type === "create") this.showPartition = false;

      // Дочернии
      for (let i = 0; i < data.Children.length; i++) {
        data.Children[i].PlaceDisplay =
          data.Children[i].Partition?.Name || data.Children[i]?.Place;
        data.Children[i].Link = data.Children[i].Link ? 1 : 0;
      }

      return data;
    },
    changeType(e) {
      this.$set(
        this.dataSource,
        "FieldValues",
        e.ExtraFields ? e.ExtraFields : []
      );

      // Пролежни
      if (this.dataSource.FieldValues.length) {
        this.updateCameFromHidden();
        this.updateAttachedOrganizationHidden();
        this.updateTotalHidden();
      }
    },
    updateCameFromHidden() {
      const cameFrom = this.dataSource.FieldValues.find(
        (e) => e.Name === "CameFrom"
      );
      this.dataSource.FieldValues.filter(
        (e) => e.Code === "CameFrom" && e.Name !== "CameFrom"
      ).forEach((element) => {
        element.Hidden = true;
      });
      const arr = [
        null,
        null,
        "CameFromPartition",
        "CameFromOrganization",
        "CameFromPlace",
      ];
      const item = this.dataSource.FieldValues.find(
        (e) => e.Name === arr[cameFrom.Value]
      );
      if (item) item.Hidden = false;

      // Если выбрана организация
      if (arr[cameFrom.Value] === "CameFromOrganization")
        this.dataSource.FieldValues.find(
          (e) => e.Name === "CameFromAnotherPlace"
        ).Hidden = false;

      // Если активен чек бокс
      if (
        this.dataSource.FieldValues.find(
          (e) => e.Name === "CameFromAnotherPlace"
        ).Value &&
        cameFrom.Value === 3
      ) {
        this.dataSource.FieldValues.find(
          (e) => e.Name === "CameFromOrganization"
        ).Hidden = true;
        this.dataSource.FieldValues.find(
          (e) => e.Name === "CameFromPlace"
        ).Hidden = false;
      }
      this.dataSource.FieldValues = [...this.dataSource.FieldValues];
    },
    updateAttachedOrganizationHidden() {
      const attachedOrganizationAnotherPlace = this.dataSource.FieldValues.find(
        (e) => e.Name === "AttachedOrganizationAnotherPlace"
      );
      if (attachedOrganizationAnotherPlace) {
        this.dataSource.FieldValues.find(
          (e) => e.Name === "AttachedOrganization"
        ).Hidden = !!attachedOrganizationAnotherPlace.Value;

        this.dataSource.FieldValues.find(
          (e) => e.Name === "AttachedOrganizationPlace"
        ).Hidden = !attachedOrganizationAnotherPlace.Value;
      }
      this.dataSource.FieldValues = [...this.dataSource.FieldValues];
    },
    updateTotalHidden() {
      const cameFrom = this.dataSource.FieldValues.find(
        (e) => e.Name === "CameFrom"
      );
      const attachedOrganizationAnotherPlace = this.dataSource.FieldValues.find(
        (e) => e.Name === "AttachedOrganizationAnotherPlace"
      );
      // Пококазывать только если поступил из дома
      if (cameFrom) {
        this.dataSource.FieldValues.find(
          (e) => e.Name === "AttachedOrganization"
        ).Hidden = !(
          cameFrom.Value === 1 && !attachedOrganizationAnotherPlace.Value
        );
        this.dataSource.FieldValues.find(
          (e) => e.Name === "AttachedOrganizationPlace"
        ).Hidden = !(
          cameFrom.Value === 1 && !!attachedOrganizationAnotherPlace.Value
        );
        this.dataSource.FieldValues.find(
          (e) => e.Name === "AttachedOrganizationAnotherPlace"
        ).Hidden = cameFrom.Value !== 1;
      }
    },
    updateField: debounce(function (params) {
      const i = this.dataSource.FieldValues.findIndex(
        (e) => e.Name === params.name
      );

      if (i !== -1) {
        this.dataSource.FieldValues[i].Value = params.value;
        this.$set(
          this.dataSource.FieldValues,
          i,
          this.dataSource.FieldValues[i]
        );
      }

      if (
        params.name === "CameFrom" ||
        params.name === "CameFromAnotherPlace"
      ) {
        this.updateCameFromHidden(params);
      }

      if (params.name === "AttachedOrganizationAnotherPlace") {
        this.updateAttachedOrganizationHidden(params);
      }

      this.updateTotalHidden();
    }, 30),
    responsibleStatus() {
      if (this.readonlyResponsible)
        this.$alert(
          "Данный раздел может редактировать только ответсвенное лицо."
        );
    },
    done(ev) {
      const req = {
        Id: this.dataSource.Id,
        IsClosing: true,
        Done: this.dateDone,
        Result: this.dataSource.Result,
      };
      this.updateState(req);
    },
    open() {
      const req = {
        Id: this.dataSource.Id,
        IsClosing: false,
      };
      this.updateState(req);
    },
    async updateState(req) {
      const { data } = await UnwishedEventService.updateStatus(req);

      this.dataSource.Done = data.Done;
      this.dataSource.Result = data.Result;
      this.dataSource.OnlyRead = data.OnlyRead;

      this.originObject.Done = data.Done;
      this.originObject.Result = data.Result;
      this.originObject.OnlyRead = data.OnlyRead;
    },
    // todo: исправить
    DataBeforeSave(data) {
      const d = this._.cloneDeep(data);

      // todo: Решает проблему с m-tree-select Children, но создает проблему с Мутацией
      if (!this.dataSource.AnotherPlace) {
        d.Place = null;
      } else if (this.dataSource.AnotherPlace) {
        d.Partition = null;
        d.Place = d.Place ? d.Place : "";
      }

      return d;
    },

    async save() {
      await this.baseSave();

      if (this.dataSource.FieldValues?.length) {
        this.updateCameFromHidden();
        this.updateAttachedOrganizationHidden();
        this.updateTotalHidden();
        this.originObject = this.getActualDataSources(this.dataSource);
      }
    },
    async init() {
      await this.baseInit();

      if (this.dataSource.FieldValues?.length) {
        this.updateCameFromHidden();
        this.updateAttachedOrganizationHidden();
        this.updateTotalHidden();
        this.originObject = this.getActualDataSources(this.dataSource);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.block__content2
  margin-top: 10px
</style>
