<template>
  <base-list-view
    pageEditName="CheckOperationRun"
    title=""
    :dataSource="CheckOperations"
    :headers="modifiedHeaders"
    :sort-by.sync="sortBy"
    @customHandle="customHandle"
    @afterEdit="chengeObjectCheck"
    notShowSelect
    :notShowActions="['add']"
    isNested
    header-hidden
    hide-default-footer
    :show-add-in-table="!readonly"
    show-actions
    editable
    ref="listView"
    single-select
    :hideDelete="(item) => readonly || item.Status !== 0"
    :hideEdit="
      (item) =>
        readonlyChangeObjects ||
        (item.Status !== 0 &&
          !(
            selectedObjectType === 4 &&
            (item.Status === 1 || item.Status === 2)
          ))
    "
    @customAdd="customAdd"
    not-filter
  >
    <template slot="item.objectCheck" slot-scope="scope">
      <div v-show="isEditingObject(scope)">
        <div
          v-if="isEditingObject(scope) && selectedObjectType === 2"
          @click.stop
        >
          <m-autocomplete
            v-model="scope.rowEditing.EmployeeObject"
            :items="employees"
            label="Выберите сотрудника"
            return-object
            filter="EmployeeActive"
            dict="Employees"
            :textCustomAdd="'Создать нового сотрудника'"
          ></m-autocomplete>
        </div>
        <div v-else-if="selectedObjectType === 1" @click.stop>
          <m-tree-select
            v-if="scope.rowEditing"
            v-model="scope.rowEditing.PartitionObject"
            :items="partitions"
            @click.prevent.stop
            selection-type="independent"
            allowSelectParents
            label="Выберите структурное подразделение"
            :dict="{ name: 'Partition', notItems: true }"
          ></m-tree-select>
        </div>
        <v-text-field
          v-else-if="isEditingObject(scope) && selectedObjectType === 4"
          v-model="scope.rowEditing.CustomObject"
          @click.stop
          label="Название объекта"
          maxlength="200"
        ></v-text-field>
      </div>
      <span v-show="!isEditingObject(scope)">{{ scope.item.objectCheck }}</span>
    </template>

    <template slot="item.Auditors" slot-scope="scope">
      <div v-show="isEditing(scope)">
        <m-select
          v-if="isEditing(scope)"
          v-model="scope.rowEditing.Auditors"
          label="Выберите ответственных"
          deletable-chips
          multiple
          @click.stop
          :items="transformedAuditors(scope)"
          :item-text="(item) => $options.filters.PersonShortName(item.Employee)"
          return-object
          v-focusInput.noFocus.noEnter="scope.closeEdit"
        ></m-select>
      </div>
      <span v-show="!isEditing(scope)">{{
        scope.item.Auditors.map((a) =>
          $options.filters.PersonShortName(a.Employee)
        ).join(", ")
      }}</span>
    </template>
    <template slot="item.AnsweredQuestionCount" slot-scope="{ item }">
      <!-- ! AnsweredYesPercent === 0 ? проверка по CheckStatus, тогда: -->
      <!-- ! CheckStatus === 3 -> все отмечены "не применимо" -->
      <!-- ! CheckStatus === 2 -> все отмечены "нет" -->
      <!-- ! Иначе цвет будет от StatisticIndicatorHelper -->
      {{ item.AnsweredQuestionCount || 0 }}/{{ item.QuestionCount || 0 }}
      <span
        style="margin-left: 1.2rem"
        :class="
          (item.AnsweredYesPercent === 0
            ? item.CheckStatus === 2
              ? 'error'
              : item.CheckStatus === 3
              ? 'warning'
              : ''
            : StatisticIndicatorHelper.getColorByRefs(
                [refValues.RefValue1, refValues.RefValue2, refValues.RefValue3],
                item.AnsweredYesPercent,
                1,
                ['error', 'warning', 'success', 'beautify']
              )) + '--text'
        "
      >
        {{
          item.CheckStatus === 3
            ? "Не применимо 100%"
            : `Соответствует ${item.AnsweredYesPercent || 0} %`
        }}
      </span>
    </template>
    <check-operation-add
      :CheckOperations="CheckOperations"
      :auditors="auditors"
      :width="'60vw'"
      :height="'90vh'"
      v-model="dialogCL"
      @add="$emit('add', $event)"
      :selected-object-type="selectedObjectType"
    />
    <check-operation-ES-add
      :CheckOperations="CheckOperations"
      v-model="dialogES"
      :width="'60vw'"
      :height="'90vh'"
      @add="$emit('add', $event)"
    />
  </base-list-view>
</template>

<script>
import BaseListView from "../../layouts/BaseListView.vue";
import CheckOperationAdd from "./CheckOperationAdd";
import CheckOperationESAdd from "./CheckOperationESAdd";
import { mapActions } from "vuex";
import MSelect from "../base/inputs/mSelect.vue";
import mAutocomplete from "../base/inputs/mAutocomplete.vue";
import { CheckOperationsNormalize } from "../../utils/Normalize";
import MTreeSelect from "@/components/base/inputs/mTreeSelect.vue";
import StatisticIndicatorHelper from "../../views/statisticIndicator/StatisticIndicatorHelper";

export default {
  name: "view-CheckOperation",
  components: {
    CheckOperationAdd,
    CheckOperationESAdd,
    BaseListView,
    MSelect,
    MTreeSelect,
    mAutocomplete,
  },
  props: {
    CheckOperations: {
      type: Array,
      default: () => [],
    },
    auditors: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    readonlyChangeObjects: {
      type: Boolean,
      default: true,
    },
    isUnsaved: {
      type: Boolean,
    },
    selectedObjectType: {
      type: Number,
    },
    refValues: {
      type: Object,
      default: () => ({ RefValue1: 0, RefValue2: 0, RefValue3: 0 }),
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Чек-лист",
          value: "CheckList.Name",
          tooltip: "Наименование чек-листа",
        },
        {
          text: "Объект проверки",
          value: "objectCheck",
          tooltip: "Объект проверки по выбранному чек-листу",
        },
        {
          text: "Аудиторы",
          value: "Auditors",
          tooltip:
            "Аудитор, который будет проводить проверку по данному чек-листу. Другим аудиторам доступен только просмотр проверки",
          dataType: "array",
          displayText: (e) =>
            this.$options.filters.PersonShortName(e?.Employee),
        },
        {
          text: "Статус",
          value: "AnsweredQuestionCount",
          align: "left",
          width: "1%",
          cellClass: "text-no-wrap",
        },
      ],
      sortBy: ["CheckList.Name"],
      dialogCL: false,
      dialogES: false,
      selected: [],
      employees: [],
      partitions: [],
    };
  },
  computed: {
    StatisticIndicatorHelper() {
      return StatisticIndicatorHelper;
    },
    modifiedHeaders() {
      return this.selectedObjectType === 0 ||
        this.selectedObjectType === undefined
        ? this.headers.filter((_, index) => [0, 2, 3].includes(index))
        : [...this.headers];
    },
  },
  watch: {
    "$store.state.partitions": function (val) {
      this.partitions = val;
    },
  },
  async created() {
    this.employees = await this.LOAD_EMPLOYEES();
    this.partitions = await this.LOAD_PARTITIONS();
  },
  methods: {
    ...mapActions(["LOAD_EMPLOYEES", "LOAD_PARTITIONS"]),
    customAdd() {
      const key =
        this.selectedObjectType === 3 ? "equipmentStandart" : "checkList";
      switch (key) {
        case "checkList":
          this.dialogCL = true;
          break;
        case "equipmentStandart":
          this.dialogES = true;
      }
    },
    transformedAuditors(scope) {
      return this.auditors.map((a) => {
        return scope.rowEditing.Auditors.findIndex(
          (e) => e?.Employee?.Id === a?.Id
        ) >= 0
          ? scope.rowEditing.Auditors.find((e) => e.Employee.Id === a.Id)
          : { Employee: { ...a }, Id: a.Id };
      });
    },
    updateStatus(id, val) {
      const index = this.CheckOperations.findIndex((e) => e.Id === id);
      this.$emit("updateStatus", { index, val });
    },
    isEditing(scope) {
      return (
        scope.rowEditing &&
        scope.item.Id === scope.rowEditing.Id &&
        !this.readonlyChangeObjects &&
        scope.item.Status === 0
      );
    },
    isEditingObject(scope) {
      return (
        scope.rowEditing &&
        scope.item.Id === scope.rowEditing.Id &&
        !this.readonlyChangeObjects &&
        (scope.item.Status === 0 ||
          (this.selectedObjectType === 4 &&
            (scope.item.Status === 1 || scope.item.Status === 2)))
      );
    },
    customHandle(value) {
      if (this.$refs.listView.$refs.tableFull.rowEditing) return;
      if (!value?.Id || value.Id <= 0) return;
      if (
        !value.CustomObject &&
        !value.EmployeeObject &&
        !value.PartitionObject &&
        this.selectedObjectType !== 0
      ) {
        this.$alert("Выберите объект проверки");
        this.$refs.listView.$refs.tableFull.edit(0, value);
        return;
      } else if (this.isUnsaved) {
        this.$alert("Проверка не сохранена");
        return;
      }

      this.$store.commit(
        "pageTabs/SET_TRANSACTION_AUDIT_OPERATION",
        this.updateStatus
      );
      this.$router.push({
        name: "CheckOperationRun",
        params: {
          objectId: value.Id,
        },
      });
    },
    chengeObjectCheck() {
      this.CheckOperations = CheckOperationsNormalize(this.CheckOperations);
    },
  },
};
</script>

<style></style>
