<template>
  <base-popup
    title="Выбор проверки"
    @save="save"
    :icon="UtilGetIcon('view-AuditOperations')"
    v-model="dialog"
    :width="width"
    :height="height"
  >
    <v-form>
      <v-container>
        <v-layout column>
          <v-flex>
            <base-list-view
              title="Проверки"
              :dataSource="dataSource"
              :headers="headers"
              :showDelete="false"
              :notShowActions="['add']"
              :search.sync="search"
              :selectedItems="selectedAudits"
              @input="(value) => (this.selectedAudits = value)"
              @onRefresh="loadAudits"
            >
              <template #item.DateIn="{ item }">
                {{ item.DateIn | formatDate }}
              </template>
              <template #item.DateOut="{ item }">
                {{ item.DateOut | formatDate }}
              </template>
            </base-list-view>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </base-popup>
</template>

<script>
import BasePopup from "@/layouts/BasePopup.vue";
import BaseListView from "@/layouts/BaseListView.vue";
import AuditOperationService from "@/services/AuditOperationService";
import TaskService from "@/services/TaskService";
import { AuditOperationKinds, AuditOperationStatus } from "@/data/enums";
import DataHelper from "@/utils/DataHelper";

export default {
  components: { BasePopup, BaseListView },
  filters: {
    formatDate: function (value) {
      return DataHelper.toDate(value)?.toLocaleDateString();
    },
  },
  props: {
    value: Boolean,
    width: {
      type: String,
      default: "80vw",
    },
    height: {
      type: String,
      default: "80vh",
    },
  },
  data: () => {
    return {
      dataSource: [],
      selectedAudits: [],
      search: "",
      headers: [
        {
          text: "Номер",
          value: "Num",
        },
        {
          text: "Предмет проверки",
          value: "Subject",
        },
        {
          text: "Начало проверки",
          value: "DateIn",
          dataType: "Date",
        },
        {
          text: "Окончание проверки",
          value: "DateOut",
          dataType: "Date",
        },
        {
          text: "Вид",
          value: "Kind",
          dataType: "enum",
          options: AuditOperationKinds,
        },
        {
          text: "Статус",
          value: "Status",
          dataType: "enum",
          options: AuditOperationStatus,
        },
      ],
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        if (val !== this.value) this.$emit("input", val);
      },
    },
  },
  watch: {
    dialog(visible) {
      if (visible) {
        this.search = null;
        this.loadAudits();
      }
    },
  },
  methods: {
    async save() {
      this.dialog = false;
      const ids = this.selectedAudits.map((a) => a.Id);
      const tasks = await this.loadTasks(ids);
      if (tasks.length) await this.$emit("add-tasks", tasks);
      this.selectedAudits = [];
    },
    async loadAudits() {
      try {
        const data = (await AuditOperationService.get(null, null, [], 3, false))
          .data;
        if (data.length) this.dataSource = data;
      } catch (error) {
        console.log(error);
      }
    },
    async loadTasks(ids) {
      try {
        return (await TaskService.getTasksByAuditOperations(ids)).data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
