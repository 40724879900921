<template>
  <v-card flat>
    <v-card-text row>
      <div>
        <offer-table
          :items="Recommendations"
          ref="violations__violationsTabele"
          :readOnly="readOnly"
          :treeNames="treeNames"
          :auditOperationNum="auditOperationNum"
          :checkListName="checkListName"
          :objectCheck="objectCheck"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import OfferTable from "../../../CheckList/Question/violation/OfferTable.vue";

export default {
  components: { OfferTable },
  props: {
    Recommendations: Array,
    readOnly: {
      type: Boolean,
    },
    treeNames: Object,
    auditOperationNum: [String, Number],
    checkListName: String,
    objectCheck: String,
  },
};
</script>

<style></style>
