<template>
  <v-file-input
    v-bind="$attrs"
    v-on="$listeners"
    v-model="internalFiles"
    outlined
    dense
    hide-details
    :clearable="!hideClose"
    :multiple="multiple"
    placeholder="Выберите файл(ы)"
    @change="fileChange($event)"
  >
    <template #selection="{ index, text }">
      <v-chip
        small
        label
        color="primary"
        :close="!hideClose"
        @click:close="fileDelete(index)"
        @click.stop="fileUnload(index)"
      >
        {{ text }}
      </v-chip>
    </template>
  </v-file-input>
</template>

<script>
import { defaultFile } from "../../data/defaultObjects";

export default {
  name: "view-InputLoadFile",
  model: {
    prop: "files",
  },
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalFiles: this.getInternalFiles(),
      a: null,
    };
  },
  watch: {
    files: function () {
      this.internalFiles = this.getInternalFiles();
    },
  },
  methods: {
    // Добавление новых файлов или очистка всего массива
    async fileChange(values) {
      this.$emit("change", values);
      if (values && !Array.isArray(values)) values = [values];
      if (!values || !values.length) {
        this.files.splice(0, this.files.length);
      } else {
        if (!this.multiple && values.find((v) => !v.initialized))
          this.files.splice(0, this.files.length);
        for (const value of values) {
          if (value.initialized) continue;

          const aIndex = this.files.findIndex((e) => e.Name === value.name);

          if (aIndex >= 0) {
            if (
              await this.$confirm(
                "Файл " + value.name + " уже существует. Перезаписать ?"
              )
            ) {
              this.files.splice(aIndex, 1);
            } else {
              this.internalFiles = this.getInternalFiles();
              continue;
            }
          }

          const file = defaultFile();
          const reader = new FileReader();
          reader.readAsDataURL(value);
          reader.onload = (e) => {
            this.a = e.target.result;
            file.Base64Content = reader.result;
          };
          file.Name = value.name;

          this.files.push(file);
        }
      }
    },
    // Удаление файла
    fileDelete(index) {
      if (index >= 0 && index < this.files.length) this.files.splice(index, 1);
    },
    // Скачивание файла
    async fileUnload(index) {
      if (
        index >= 0 &&
        index < this.files.length &&
        this.files[index].ExternalId
      ) {
        const url = `${process.env.VUE_APP_SERVER_URL}common/GetFile?externalId=${this.files[index].ExternalId}`;
        location.assign(url);
      }
    },
    // Копирование исходного списка файлов
    getInternalFiles() {
      return this.files
        ? this.files.map((f) => {
            return {
              name: f.Name,
              ExternalId: f.ExternalId,
              initialized: true,
              Base64Content: f.Base64Content,
            };
          })
        : [];
    },
  },
};
</script>
