<template>
  <div>
    <object
      :data="url"
      type="application/pdf"
      style="min-height: 100vh; width: 100%"
      :class="{ 'full-screen': fullScreen }"
    >
      <p>Alternative text - include a link <a :href="url">to the PDF!</a></p>
    </object>
    <BtnFullScreen v-model="fullScreen"> </BtnFullScreen>
  </div>
</template>
<script>
import BtnFullScreen from "../BtnFullScreen.vue";

export default {
  components: { BtnFullScreen },
  props: {
    url: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fullScreen: false,
    };
  },
};
</script>
<style lang="scss">
.full-screen {
  min-height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1001;
}
</style>
