<template>
  <base-detail-view
    class="analysis-tfoms"
    :loading="loading"
    :title="'Анализ состояние качества и безопасности медицинской деятельности '"
    :isUnsaved="isUnsaved && valid"
    @onRefresh="onRefresh(queryParams)"
    :showSave="false"
    fillHeader
  >
    <template slot="title">
      <div class="v-toolbar__title d-flex align-center">
        Анализ состояние качества и безопасности медицинской деятельности
      </div>
    </template>
    <div class="d-flex mb-2">
      <div style="width: 320px; align-self: center"></div>
      <m-tabs v-model="tab" :slider-size="2" :with-route="false">
        <v-tab key="Indicator">Показатели оценки качества</v-tab>
        <v-tab key="Statistic">Статистические показатели</v-tab>
        <v-tab key="UnwishedEvents">Нежелательные события</v-tab>
        <v-tab key="ConsolidatedReports" disabled>Сводные отчеты (Скоро)</v-tab>
      </m-tabs>
    </div>

    <v-window v-model="tab">
      <v-window-item>
        <tab1
          v-model="selected0"
          ref="tab1"
          @updateParams="queryParams = { ...$event }"
        >
          <template slot="panelFilterPrepend">
            <select-year
              class="ml-0"
              :value="year"
              @input="updateYear1"
              hide-details
            ></select-year>
            <m-select
              v-model="selectPeriod"
              :items="optionsPeriod"
              placeholder="Помесячно"
              itemValue="id"
              hide-details
              class="mb-4 pt-0"
            ></m-select> </template
        ></tab1>
      </v-window-item>
      <v-window-item>
        <tab2
          v-model="selected"
          ref="tab2"
          @updateParams="queryParams = { ...$event }"
        >
          <template slot="panelFilterPrepend">
            <select-year
              class="ml-0 mb-2"
              :value="year"
              @input="updateYear2"
            ></select-year
          ></template>
        </tab2>
      </v-window-item>
      <v-window-item>
        <tab3
          v-model="selected2"
          ref="tab3"
          @updateParams="queryParams = { ...$event }"
        >
          <template slot="panelFilterPrepend">
            <select-year
              class="ml-0 mb-2"
              :value="year"
              @input="updateYear3"
            ></select-year
          ></template>
        </tab3>
      </v-window-item>
    </v-window>
  </base-detail-view>
</template>

<script>
import UnsavedMixin from "@/mixins/UnsavedMixin.js";
import CreateAndEditMixin from "@/mixins/CreateAndEditMixin.js";
import Permissions from "@/data/permissions";
// import KibmdService from "@/services/KibmdService";

import BaseDetailView from "@/layouts/BaseDetailView.vue";

import mTabs from "@/components/base/tabs/mTabs";

import tab1 from "./tabs/tab1.vue";
import tab2 from "./tabs/tab2.vue";
import tab3 from "./tabs/tab3.vue";

export default {
  name: "view-AnalysisKibmd",
  components: {
    BaseDetailView,

    mTabs,

    tab1,
    tab2,
    tab3,
    mSelect: () => import("@/components/base/inputs/mSelect"),
    selectYear: () => import("../../../components/analysis/selectYear.vue"),
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  provide() {
    const queryParams = {};
    const queryParams1 = {};
    const filterParams = {};

    Object.defineProperty(queryParams, "params", {
      enumerable: true,
      get: () => this.queryParams,
    });

    Object.defineProperty(queryParams1, "params", {
      enumerable: true,
      get: () => this.queryParams1,
    });

    Object.defineProperty(filterParams, "params", {
      enumerable: true,
      get: () => this.filterParams,
    });

    return {
      queryParams,
      queryParams1,
      updateQueryParams: this.updateQueryParams,
      updateQueryParams1: this.updateQueryParams1,
      filterParams,
    };
  },
  data() {
    return {
      year: new Date().getUTCFullYear(),
      year1: new Date().getUTCFullYear(),
      year2: new Date().getUTCFullYear(),
      year3: new Date().getUTCFullYear(),
      loading: true,
      tab: 0,
      unit: null,
      rub: null,
      filterParams: {
        selected0: [[], [], []],
        selected1: [[], [], []],
        selected2: [[], [], []],
      },
      queryParams: {
        Year: new Date().getUTCFullYear(),
        periodType: 0,
        periodNum: null,
      },
      queryParams1: { organizationId: null, indicatorId: null },
      ownerViewAll: this.hasPermission(Permissions.OwnerViewAll),
      owners: [],
      owner: parseInt(window.localStorage.getItem("tfoms_owner_id", null)),
      selected0: [[], [], []],
      selected: [[], [], []],
      selected2: [[], [], []],
      selectPeriod: 0,
      optionsPeriod: [
        { id: 0, value: "Помесячно" },
        { id: 1, value: "Поквартально" },
        { id: 2, value: "Полугодие" },
      ],
    };
  },
  watch: {
    tab(val) {
      this.year = this["year" + (val + 1)];

      this.queryParams = { ...this.queryParams, Year: this.year };
    },
    selectPeriod(val) {
      this.queryParams = { ...this.queryParams, periodType: val };
      this.update(this.queryParams);
    },
    selected0(val) {
      const t = {
        StatisticIndicatorsIds: val[0],
        OrganizationsIds: val[1],
        AuditIds: val[2],
      };

      this.filterParams.selected0 = t;

      this.update({ ...this.queryParams, ...t });
    },
    selected(val) {
      const t = {
        StatisticIndicatorsIds: val[0],
        OrganizationsIds: val[1],
        PartitionsIds: val[2],
      };

      this.update({ ...this.queryParams, ...t });
    },
    selected2(val) {
      const t = {
        UnwishedEventTypesIds: val[0],
        OrganizationsIds: val[1],
        PartitionsIds: val[2],
      };

      this.update({ ...this.queryParams, ...t });
    },
  },

  async mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);

    // await this.init(this.queryParams);
  },

  methods: {
    updateQueryParams(newParams) {
      this.queryParams = { ...this.queryParams, ...newParams };
      this.init(this.queryParams);
    },
    updateQueryParams1(newParams) {
      this.queryParams1 = { ...this.queryParams1, ...newParams };
    },

    updateYear1(val) {
      this.year1 = val;
      this.queryParams = { ...this.queryParams, Year: val };
      this.update(this.queryParams);
    },
    updateYear2(val) {
      this.year2 = val;
      this.queryParams = { ...this.queryParams, Year: val };
      this.update(this.queryParams);
    },
    updateYear3(val) {
      this.year3 = val;
      this.queryParams = { ...this.queryParams, Year: val };
      this.update(this.queryParams);
    },
    update(e) {
      this.init(e);
    },
    onRefresh(e) {
      let p = e;
      if (this.tab === 0) {
        p = {
          ...p,
          ...{
            StatisticIndicatorsIds: this.selected0[0],
            OrganizationsIds: this.selected0[1],
            AuditIds: this.selected0[2],
          },
        };
      } else if (this.tab === 1) {
        p = {
          ...p,
          ...{
            StatisticIndicatorsIds: this.selected[0],
            OrganizationsIds: this.selected[1],
            PartitionsIds: this.selected[2],
          },
        };
      } else if (this.tab === 2) {
        p = {
          ...p,
          ...{
            UnwishedEventTypesIds: this.selected2[0],
            OrganizationsIds: this.selected2[1],
            PartitionsIds: this.selected2[2],
          },
        };
      }
      this.init(p);
    },
    async init(e) {
      await this.$refs["tab" + (this.tab + 1)].init(e);
    },
  },
};
</script>

<style lang="scss">
.analysis-tfom__icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 37px;
  background-color: var(--v-blue-grey-lighten2);
}

.analysis-tfoms__table {
  tbody {
    td {
      height: 32px !important;
    }
  }
}

.analysis-tfoms__table-total {
  tbody {
    tr:last-child {
      font-weight: bold;
    }
  }
}

.analysis-tfoms__base-table {
  thead {
    background-color: var(--v-blue-grey-lighten2) !important;
    tr {
      height: 36px !important;
      background-color: var(--v-blue-grey-lighten2) !important;
    }
    th {
      vertical-align: bottom;
      background-color: var(--v-blue-grey-lighten2) !important;
    }

    tr:first-child {
      th {
        box-shadow: unset !important;
      }
    }

    tr:first-child {
      height: 36px !important;
      th {
        height: 36px !important;
      }
    }
    tr:last-child {
      height: 36px !important;
      th {
        top: 0 !important;
        height: 36px !important;
      }
    }
  }
}
</style>
