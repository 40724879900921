import Api from "./Api";

export default {
  kibmd1: {
    async getFilterData(year) {
      return Api().get("common/GetAnalysisFilterData", {
        params: {
          requestType: 60,
          year: year,
        },
      });
    },
    async get(params = {}) {
      return Api().post("auditOperation/GetExternalCommonAnalysis", {
        Year: params?.Year || 2024,
        IndicatorsIds: params?.StatisticIndicatorsIds || [],
        OrganizationsIds: params?.OrganizationsIds || [],
        AuditIds: params?.AuditIds || [],
        periodType: params?.periodType || 0,
        periodNum: params?.periodNum,
      });
    },
    byPeriod(params) {
      return Api().post("auditOperation/GetExternalCommonAnalysisByPeriod", {
        Year: params?.Year || 2024,
        OrganizationsIds: params.OrganizationsIds,
        IndicatorsIds: params.IndicatorsIds,
        AuditIds: params.AuditIds,
        indicatorId: params.indicatorId?.Id,
        periodType: params.periodType,
        periodNum: params.periodNum,
      });
    },
    tab1: {
      getExternalIndicatorAnalysisByOperation(params) {
        return Api().post(
          "auditOperation/GetExternalIndicatorAnalysisByOperation",
          {
            Year: params?.Year || 2024,
            OrganizationsIds: params.OrganizationsIds,
            IndicatorsIds: params.IndicatorsIds,
            AuditIds: params.AuditIds,
            organizationId: params.organizationId?.Id,
            indicatorId: params.indicatorId?.Id,
            periodType: params.periodType,
            periodNum: params.periodNum,
          }
        );
      },
      GetExternalAuditCheckOperations(params) {
        return Api().post("auditOperation/GetExternalAuditCheckOperations", {
          Year: params?.Year || 2024,
          periodType: params.periodType,
          periodNum: params.periodNum,
          OrganizationsIds: params.OrganizationsIds,
          IndicatorsIds: params.IndicatorsIds,
          AuditIds: params.AuditIds,
        });
      },
    },
    tab2: {
      get(params) {
        return Api().get("auditOperation/GetExternalCommonAnalysisByPeriod", {
          params: {
            listType: 2,
            Year: params?.Year || 2024,
            organizationId: params.organizationId2?.Id,
            indicatorId: params.indicatorId?.Id,
            periodType: params.periodType,
            periodNum: params.periodNum,
          },
        });
      },
      getExternalIndicatorAnalysisByOperation(params) {
        return Api().get(
          "auditOperation/GetExternalIndicatorAnalysisByOperation",
          {
            params: {
              Year: params?.Year || 2024,
              organizationId: params.organizationId2?.Organization.Id,
              indicatorId: params.indicatorId?.Indicator.Id,
              periodType: params.periodType,
              periodNum: params.periodNum,
            },
          }
        );
      },
      GetExternalAuditCheckOperations(params) {
        return Api().get("auditOperation/GetExternalAuditCheckOperations", {
          params: {
            Year: params?.Year || 2024,
            periodType: params.periodType,
            periodNum: params.periodNum,
            organizationId: params.organizationId2?.Organization.Id,
            indicatorId: params.indicatorId?.Indicator.Id,
            auditOperationId: params.auditOperationId,
          },
        });
      },
    },
  },
  kibmd2: {
    async getFilterData() {
      return Api().get("common/GetAnalysisFilterData", {
        params: {
          requestType: 14,
        },
      });
    },
    async get(params = {}) {
      return Api().post("StatisticIndicator/GetStatisticIndicatorsAnalysis", {
        Year: params?.Year || 2024,
        StatisticIndicatorsIds: params?.StatisticIndicatorsIds || [],
        OrganizationsIds: params?.OrganizationsIds || [],
        PartitionsIds: params?.PartitionsIds || [],
      });
    },
    async getStatisticIndicatorsNames(params = {}) {
      return Api().get("StatisticIndicator/GetStatisticIndicatorsNames");
    },
    async getRelatedOrganizations(params = {}) {
      return Api().get("organization/GetRelatedOrganizations");
    },
  },
  kibmd3: {
    async getFilterData() {
      return Api().get("common/GetAnalysisFilterData", {
        params: {
          requestType: 13,
        },
      });
    },
    async get(params = {}) {
      return Api().post("UnwishedEvent/GetUnwishedEventAnalysis", {
        Year: params?.Year || 2024,
        Month: params?.Month,
        UnwishedEventTypesIds: params?.UnwishedEventTypesIds || [],
        OrganizationsIds: params?.OrganizationsIds || [],
        PartitionsIds: params?.PartitionsIds || [],
      });
    },
    async getUnwishedEventsPlaces(params = {}) {
      return Api().get("UnwishedEvent/GetUnwishedEventsPlaces");
    },
    async getRelatedOrganizations(params = {}) {
      return Api().get("organization/GetRelatedOrganizations");
    },
    async getUnwishedEventByTypeAnalysis(params = {}) {
      return Api().get("UnwishedEvent/GetUnwishedEventByTypeAnalysis", {
        params: params,
      });
    },
  },
};
