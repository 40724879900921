<template>
  <div>
    <menu-tabs />
    <the-navigation />
  </div>
</template>

<script>
import MenuTabs from "./MenuTabs.vue";
import TheNavigation from "./TheNavigation";

export default {
  components: { TheNavigation, MenuTabs },
};
</script>
