<template>
  <v-card style="height: 500px">
    <demo-header
      :icon="'medexpert-play'"
      :title="'Обучающие туры'"
      :progress="progress"
      :pageName="pageName"
    ></demo-header>

    <v-divider></v-divider>

    <v-list style="max-height: 415px" class="overflow-y-auto">
      <v-list-item v-for="(item, i) in items[0].TrainingTours" :key="item.Id">
        <div
          v-if="showItems"
          class="tour-manager__page"
          v-delayAddClass="{
            delay: 150 * (i + 1),
            class: 'tour-manager__page--done',
          }"
          @click="$emit('startDemo', item)"
        >
          <div class="tour-manager__page-wrap">
            <div class="tour-manager__page-icon">
              <m-icon
                v-if="item.Percent === 100"
                icon="check-a"
                size="32"
                large
                color="var(--v-success-lighten1)"
              />
              <div v-else class="tour-manager__page-icon-circle">
                <v-badge
                  v-if="item.Percent > 0"
                  bordered
                  offset-y="-5"
                  offset-x="-10"
                ></v-badge>
                <span class="tour-manager__page-number">{{ i + 1 }}</span>
              </div>
            </div>
            <div class="tour-manager__page-info">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    class="tour-manager__page-title"
                    >{{ item.Name }}</span
                  >
                </template>
                <span>{{ item.Name }}</span>
              </v-tooltip>

              <span
                v-if="items[$route.name]"
                class="tour-manager__page-stage"
                :class="buildStyleStatus(item.Percent)"
                >{{ item.NumPerformed }} из {{ item.TotalNum }}</span
              >
            </div>
          </div>
          <div class="mt-3 d-flex align-center" style="height: 16px">
            <v-progress-linear
              :value="item.Percent"
              :color="
                item.Percent === 100
                  ? 'var(--v-success-lighten1)'
                  : 'var(--v-primary-base)'
              "
              rounded
              height="6"
            ></v-progress-linear>
            <div
              class="tour-manager__page-percent"
              :class="buildStyleStatus(item.Percent)"
            >
              {{ Math.floor(item.Percent) }}%
            </div>
          </div>
          <span class="tour-manager__page-back">
            <m-icon icon="mdi-arrow-right" color="primary"></m-icon>
          </span>
        </div>
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script>
export default {
  components: {
    demoHeader: () => import("./demoHeader"),
  },
  props: {
    showItems: {
      type: Boolean,
      default: false,
    },
    selectPage: {
      type: Number,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    pageName: {
      type: String,
      default: "Страница",
    },
  },

  computed: {
    progress() {
      if (!this.items.length) return 0;

      const item = this.items[0];
      if (!item.TrainingTours?.length) return 0;
      const sum = item.TrainingTours.reduce(
        (sum, item) => sum + item.TotalNum,
        0
      );
      const cur = item.TrainingTours.reduce(
        (sum, item) => sum + item.LastStepNum,
        0
      );

      return Math.floor((cur / sum) * 100) || 0;
    },
  },
  methods: {
    buildStyleStatus(progress) {
      return {
        "tour-manager--in-progress": progress > 0 && progress < 100,
        "tour-manager--finished": progress === 100,
      };
    },
  },
};
</script>
<style lang="scss">
.tour-manger__container .theme--light.v-list-item:hover {
  background-color: unset;
}

.tour-manager__page {
  padding: 10px 15px;
  margin: 0 0 24px 0;
  position: relative;
  width: 100%;

  box-sizing: border-box;
  border: solid 1px var(--v-blue-grey-lighten3);
  border-radius: 8px;
  cursor: pointer;
  transition: all 440ms ease 0s;

  opacity: 0;
  box-shadow: none;
  transform: translateY(24px);

  &:hover {
    transform: translateY(-2px);
    background-color: var(--v-blue-grey-lighten4);

    .tour-manager__page-back {
      opacity: 1;
      right: 12px;
    }
  }

  &-wrap {
    display: flex;
    align-items: center;
  }

  &--done {
    opacity: 1;
    box-shadow: var(--v-text-lighten3) 0px 4px 10px 0px;
    transform: translateY(0px);
  }

  &--notdone {
    box-shadow: var(--v-overlay-base) 0px 4px 10px 0px;
    border: solid 1px var(--v-primary-base);
  }

  &-icon {
    margin-right: 12px;
  }

  &-icon-circle {
    width: 34px;
    height: 34px;
    background: var(--v-blue-grey-lighten3);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    inset: 12px auto auto 12px;

    .v-badge__badge {
      height: 12px;
      min-width: 0;
      padding: 0;
      width: 12px;
    }
  }

  &-number {
    color: var(--v-text-base);
  }

  &-info {
    display: flex;
    flex-direction: column;
  }

  &-title {
    font-size: 14px;
    text-overflow: ellipsis;
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
  }

  &-stage {
    font-size: 12px;
    color: var(--v-text-lighten1);
  }

  &-percent {
    font-size: 12px;
    line-height: 16px;
    padding-left: 14px;
  }

  &-back {
    position: absolute;
    z-index: 10;
    opacity: 0;
    left: auto;
    bottom: auto;
    top: 12px;
    right: 30px;
    transition: all 160ms ease 0s;
    transform: translate(0) scale(1) rotate(0deg) skew(0deg);
  }
}

.tour-manager__cancel-wrap {
  display: flex;
  justify-content: center;
}

.tour-manager__progress-circular {
  font-size: 14px;
}
</style>
