var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.id >= 0 &&
      !_vm.loading &&
      !(_vm.isContentVisible || (_vm.fieldFiles && _vm.fieldFiles.length))
    )?_c('v-container',[_vm._t("info"),(_vm.showAdd)?_c('v-row',[(_vm.showAddText)?_c('v-col',[_c('v-btn',{attrs:{"block":"","large":"","color":"primary"},on:{"click":_vm.addText}},[_c('m-icon',{attrs:{"icon":"tool-edit","fill":"white","left":"","size":"20"}}),_vm._v(" Создать текст документа на основе шаблона ")],1)],1):_vm._e(),_c('v-col',[_c('v-btn',{attrs:{"block":"","large":"","color":"primary"},on:{"click":function($event){return _vm.addFile2()}}},[_c('m-icon',{attrs:{"icon":"mdi-file-document-outline","left":""}}),_vm._v(" "+_vm._s(_vm.nameButtonAddFile)+" ")],1),_c('v-file-input',{ref:"file",staticClass:"d-none",attrs:{"placeholder":"Выберите файл","accept":_vm.supportImg
              ? 'application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, image/png'
              : 'application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document'},on:{"change":function($event){return _vm.fileAdd($event)}}})],1)],1):_vm._e()],2):_vm._e(),(_vm.isContentVisible || (_vm.fieldFiles && _vm.fieldFiles.length))?_c('base-panel',{staticClass:"mt-4 local-document-edit__panel",attrs:{"headerTogether":"","headerHidden":!_vm.showDocument || _vm.headerHidden,"icon":'mdi-file-document-outline',"title":_vm.title}},[(_vm.showDocument && _vm.showDocument.id !== -1)?_c('v-btn',{attrs:{"slot":"action","icon":"","title":"Скачать оригинал","download":"","small":"","href":_vm.urlForDownload},slot:"action"},[_c('m-icon',{attrs:{"icon":"tool-download","s16":""}})],1):_vm._e(),(!_vm.notShowDelete)?_c('v-btn',{attrs:{"slot":"action","icon":"","color":"red lighten-1","title":"Удалить","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.showDocument.id !== -1 ? _vm.documentDel() : _vm.textDel()}},slot:"action"},[_c('m-icon',{attrs:{"icon":"tool-delete","s16":""}})],1):_vm._e(),_c('base-left-tabs',{ref:"base-left-tabs",attrs:{"show-add":!_vm.showAdd ? false : !!_vm.files,"show-add-text":!_vm.showAddText ? false : !_vm.isContentVisible,"hide-tabs":!!_vm.files,"dataSource":_vm.dataSource},on:{"addFile":_vm.addFile},scopedSlots:_vm._u([{key:"itemsAppend",fn:function({ showAdd, showAddText }){return [(showAdd)?_c('div',{staticClass:"left-tab__tabs-item"},[_c('v-file-input',{ref:"file",staticClass:"d-none",attrs:{"placeholder":"Выберите файл","accept":_vm.supportImg
                ? 'application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, image/png'
                : 'application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document'},on:{"change":function($event){return _vm.fileAdd($event)}}}),_c('div',[(showAddText)?_c('v-btn',{staticClass:"left-tab__btn",attrs:{"text":"","color":"primary","title":"Добавить текст","block":""},on:{"click":function($event){return _vm.addText()}}},[_c('m-icon',{attrs:{"icon":"tool-add-item","left":""}}),_vm._v(" Добавить текст ")],1):_vm._e(),_c('v-btn',{staticClass:"left-tab__btn",attrs:{"text":"","color":"primary","title":"Добавит документ","block":""},on:{"click":function($event){return _vm.addFile2()}}},[_c('m-icon',{attrs:{"icon":"tool-add-item","left":"","size":"20"}}),_vm._v(" Добавить файл ")],1)],1)],1):_vm._e()]}}],null,false,3117611983),model:{value:(_vm.showDocument),callback:function ($$v) {_vm.showDocument=$$v},expression:"showDocument"}},[(_vm.isContentVisible)?_c('base-left-tab',{attrs:{"name":"text","title":"Текст","id":-1,"value":-1}},[(!_vm.loading)?_c('rich-viewer',{ref:"rich",staticStyle:{"margin-top":"0 !important"},attrs:{"readOnly":!_vm.enabledContent,"value":_vm.content,"fields":_vm.fields,"document-type":"document","loading":_vm.loading},on:{"documentChanged":function($event){return _vm.$emit('documentChanged', $event)}}}):_vm._e()],1):_vm._e(),_vm._l((_vm.fieldFiles),function(file){return _c('base-left-tab',{key:file.Id,attrs:{"name":file.Name,"title":file.Name,"id":file.Id,"value":file.Id}},[(_vm.isImage(file))?_c('img-viewer',{attrs:{"url":`${_vm.apiUrl}common/GetFile?externalId=` + file.ExternalId}}):_c('pdf-viewer',{attrs:{"url":`${_vm.apiUrl}common/GetFileStream?externalId=` + file.ExternalId}})],1)})],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }