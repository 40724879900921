<template>
  <div class="mt-4">
    <div v-if="!isInit" class="text-center my-1">Загрузка документа...</div>
    <div :id="'richEdit' + id" />
    <BtnFullScreen
      :value="fullScreen"
      @input="toggleFullScreen"
    ></BtnFullScreen>
  </div>
</template>

<script>
import { waitForElm, waitForPropertyInObject } from "../../utils/Functions";
import {
  create,
  createOptions,
  RichEdit,
  ViewType,
  RichEditUnit,
  DocumentFormat,
  PageLayoutTabCommandId,
  ViewTabCommandId,
} from "devexpress-richedit";
import { debounce } from "lodash";
import { diff } from "deep-diff";
import { CreateDataSource } from "./ValueFormatter";
import LocalDocumentService from "../../services/LocalDocumentService";
import BtnFullScreen from "../BtnFullScreen.vue";

export default {
  name: "view-RichViewer",
  components: { BtnFullScreen },
  rich: RichEdit,
  props: {
    value: String,
    readonly: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: [Array, Object],
      default: () => {},
    },
    printAuto: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    documentType: {
      type: String,
      default: "document",
      validator: function (value) {
        return ["document", "template"].indexOf(value) !== -1;
      },
    },
  },
  data: () => ({
    id: Math.round(Math.random() * 100000),
    isInit: false,
    lastNewDataSource: null,
    rich: null,
    syncData: false,
    fullScreen: false,
  }),
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (!val) return;
        if (!this.isInit) this.init();
        else {
          this.rich.openDocument(
            this.value,
            "DocumentName",
            DocumentFormat.OpenXml
          );
        }
      },
    },
    fields: function () {
      setTimeout(() => {
        if (!this.lastNewDataSource) {
          const newDataSource = CreateDataSource(
            this.fields,
            this.documentType
          );

          this.rich.mailMergeOptions.setDataSource(newDataSource);
          this.lastNewDataSource = newDataSource.store;
        } else this.updateFields();
      }, 1000);
    },
    readonly: function () {
      if (this.rich) this.rich.readOnly = this.readonly;
    },
    loading: function () {
      if (this.loading === true) this.syncData = true;
    },
  },
  async mounted() {
    this.init();
  },
  beforeDestroy() {
    if (this.rich) {
      this.rich.dispose();
      this.rich = null;
    }
  },
  methods: {
    saveDocument() {
      this.rich.saveDocument();
    },
    emitChange: debounce(function (val) {
      this.$emit("documentChanged", val);
    }, 500),
    toggleFullScreen() {
      this.rich.executeCommand(ViewTabCommandId.ToggleFullScreen);
      this.fullScreen = !this.fullScreen;
    },
    documentPrint: async function () {
      const { data } = await LocalDocumentService.getOrganizationDocumentPDF(
        this.$route.params?.objectId
      );
      const pdfWindow = window.open(``);
      pdfWindow.document.write(
        "<iframe id='doPrint' width='100%' height='100%' src='data:application/pdf;base64, " +
          encodeURI(data) +
          "'></iframe>"
      );
    },
    updateFields: debounce(async function () {
      const newDataSource = CreateDataSource(this.fields, this.documentType);
      if (this.rich && !!diff(this.lastNewDataSource, newDataSource.store)) {
        this.rich.mailMergeOptions.setDataSource(newDataSource);
        this.lastNewDataSource = newDataSource.store;
      }
    }, 2000),
    init: debounce(async function () {
      if (this.isInit) return;
      this.isInit = true;
      // the createOptions() method creates an object that contains RichEdit options initialized with default values
      const options = createOptions();
      options.ribbon.visible = !this.readonly;
      options.bookmarks.visibility = true;
      options.bookmarks.color = "#FB0404";
      options.confirmOnLosingChanges.enabled = false;
      options.confirmOnLosingChanges.message =
        "Are you sure you want to perform the action? All unsaved document data will be lost.";
      // options.fields.updateFieldsBeforePrint = true;
      // options.fields.updateFieldsOnPaste = true;
      options.mailMerge.viewMergedData = true;
      // events
      options.events.activeSubDocumentChanged = () => {};
      options.events.autoCorrect = () => {};
      options.events.calculateDocumentVariable = () => {};
      options.events.characterPropertiesChanged = () => {};
      options.events.contentInserted = () => {};
      options.events.contentRemoved = () => {};
      options.events.documentChanged = (val) => {
        if (this.syncData) this.syncData = false;
        else this.emitChange(val);
      };
      options.events.documentFormatted = () => {};
      options.events.documentLoaded = () => {
        // this.updateFields();
        // Авто печать
        if (this.printAuto && this.value) {
          this.documentPrint();
        }
      };
      options.events.gotFocus = () => {};
      options.events.hyperlinkClick = () => {};
      options.events.keyDown = () => {};
      options.events.keyUp = () => {};
      options.events.paragraphPropertiesChanged = () => {};
      options.events.lostFocus = () => {};
      options.events.pointerDown = () => {};
      options.events.pointerUp = () => {};
      options.events.saving = function (s, e) {
        e.handled = true;
      };
      options.events.saved = () => {};
      options.events.selectionChanged = () => {};
      /* options.events.customCommandExecuted = (s, e) => {
              switch (e.commandName) {
                case "insertEmailSignature":
                  s.document.insertParagraph(s.document.length);
                  s.document.insertText(s.document.length, "_________");
                  s.document.insertParagraph(s.document.length);
                  s.document.insertText(s.document.length, "Best regards,");
                  s.document.insertParagraph(s.document.length);
                  s.document.insertText(s.document.length, "John Smith");
                  s.document.insertParagraph(s.document.length);
                  s.document.insertText(s.document.length, "john@example.com");
                  s.document.insertParagraph(s.document.length);
                  s.document.insertText(s.document.length, "+1 (818) 844-0000");
                  break;
              }
            }; */
      options.unit = RichEditUnit.Centimeter;
      options.view.viewType = ViewType.PrintLayout;
      options.view.simpleViewSettings.paddings = {
        left: 15,
        top: 15,
        right: 15,
        bottom: 15,
      };
      options.autoCorrect = {
        correctTwoInitialCapitals: true,
        detectUrls: true,
        enableAutomaticNumbering: true,
        replaceTextAsYouType: true,
        caseSensitiveReplacement: false,
        replaceInfoCollection: [
          { replace: "wnwd", with: "well-nourished, well-developed" },
          { replace: "(c)", with: "©" },
        ],
      };
      // options.exportUrl =
      //   "https://medexpert-webservice-hq3.conveyor.cloud/document/getcontent";
      options.readOnly = this.readonly;
      options.height = "800px";
      // spell
      options.spellCheck.enabled = true;
      options.spellCheck.suggestionCount = 4;
      let spellCheckerWorker = this.$store.state.richWorker?.spellCheckerWorker;
      const spellCheckerCallbacks = Object.create(null);
      let spellCheckerWorkerCommandId = 0;
      const self = this;
      options.spellCheck.checkWordSpelling = function (word, callback) {
        if (!spellCheckerWorker) {
          console.log("create worker");
          const myDictionary =
            JSON.parse(localStorage.getItem("myDictionary")) || [];
          spellCheckerWorker = new Worker("/spell-checker-worker.js");
          self.$store.commit("richWorker/SET_WORKER", spellCheckerWorker);
          myDictionary.forEach(function (word) {
            spellCheckerWorker.postMessage({
              command: "addWord",
              word: word,
            });
          });
        }
        spellCheckerWorker.onmessage = function (e) {
          const savedCallback = spellCheckerCallbacks[e.data.id];
          if (!savedCallback) return;
          delete spellCheckerCallbacks[e.data.id];
          savedCallback(e.data.isCorrect, e.data.suggestions);
        };
        const currId = spellCheckerWorkerCommandId++;
        spellCheckerCallbacks[currId] = callback;
        spellCheckerWorker.postMessage({
          command: "checkWord",
          word: word,
          id: currId,
        });
      };
      options.spellCheck.addWordToDictionary = function (word) {
        const myDictionary =
          JSON.parse(localStorage.getItem("myDictionary")) || [];
        myDictionary.push(word);
        localStorage.setItem("myDictionary", JSON.stringify(myDictionary));
        spellCheckerWorker.postMessage({
          command: "addWord",
          word: word,
        });
      };
      // spell end
      await waitForElm("#richEdit" + this.id);
      const richElement = document.getElementById("richEdit" + this.id);
      this.rich = create(richElement, options);
      // если новый документ то установить ему размер a4
      if (!this.value) {
        this.rich.executeCommand(PageLayoutTabCommandId.SetSectionA4PaperSize);
      }
      if (this.value) {
        this.rich.openDocument(
          this.value,
          "DocumentName",
          DocumentFormat.OpenXml
        );
      }
    }, 500),
    async getDocumentContent() {
      await waitForPropertyInObject(this, "rich");
      return new Promise((resolve) => {
        this.rich.exportToBase64((res) => {
          resolve(res);
        });
      });
    },
  },
};
</script>
