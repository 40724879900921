<template>
  <div>
    <v-btn
      slot="action"
      v-if="typeof report === 'string'"
      :title="localizeReportNames[report]"
      @click.stop="showReport(report)"
      icon
    >
      <m-icon icon="tool-print" s16></m-icon>
    </v-btn>
    <v-menu v-else offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon title="Печать отчетов">
          <m-icon icon="tool-print" s16></m-icon>
        </v-btn>
      </template>
      <v-list class="report-activator__list">
        <v-list-item
          v-for="(item, index) in report"
          :key="index"
          @click="showReport(item)"
        >
          <v-list-item-title>{{ localizeReportNames[item] }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { localizeReportNames } from "../../data/localization";

export default {
  props: {
    report: [String, Array],
    params: Object,
  },
  data() {
    return {
      localizeReportNames,
    };
  },
  methods: {
    showReport(report) {
      this.$router.push({
        name: "ReportPage",
        params: {
          report,
          objectId:
            this.params?.objectId.value ?? this.params?.objectId.value ?? 0,
        },
        query: {
          objectName: this.params?.objectId.name,
        },
      });
    },
  },
};
</script>

<style></style>
