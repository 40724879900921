import Api from "../../../services/Api";

export default {
  async get(pageName, pageType, typeView, id) {
    return Api().get("trainingtour/GetTrainingTours", {
      params: { pageName, pageType, typeView, id },
    });
  },
  async stepComplete(stepId) {
    return Api().put("trainingtour/TrainingTourStepComplete", {
      stepId,
    });
  },

  help: {
    video: {
      get(pageName, pageType, typeView, id) {
        return Api().get("help/GetHelpVideos", {
          params: { pageName, pageType, typeView, id },
        });
      },
      async complete(id) {
        return Api().put("help/HelpVideoComplete", {
          id,
        });
      },
    },
    doc: {
      get(pageName, pageType, typeView, id) {
        return Api().get("help/GetHelpInstructions", {
          params: { pageName, pageType, typeView, id },
        });
      },
      async complete(id) {
        return Api().put("help/HelpInstructionComplete", {
          id,
        });
      },
    },
  },
};
