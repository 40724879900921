<template>
  <div class="demo-hint__menu">
    <v-tooltip bottom v-for="(item, i) in tabs" :key="item.id">
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
          :key="item.icon"
          :class="{ 'demo-hint__menu-item--active': i === value }"
          class="demo-hint__menu-item"
          v-ripple
          @click="$emit('input', i)"
        >
          <m-icon :icon="item.icon" large></m-icon>
        </div>
      </template>
      <span>{{ item.title }}</span>
    </v-tooltip>

    <div class="demo-hint__menu-item-close">
      <v-btn
        class="demo-hint__menu-close"
        icon
        color="primary"
        @click="$emit('close')"
      >
        <m-icon icon="mdi-close"></m-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  props: { value: { type: Number, default: 0 } },
  data() {
    return {
      tab: 0,
      tabs: [
        { id: 0, icon: "medexpert-play", title: "Обучающие туры" },
        { id: 1, icon: "medexpert-video", title: "Видео-инструкции" },
        { id: 2, icon: "medexpert-doc", title: "Инструкции" },
      ],
    };
  },
};
</script>
<style lang="scss">
.demo-hint__menu {
  width: 0;
  height: 67px;
  padding: 10px 14.5px;
  background: var(--v-white-base);
  display: flex;
  position: absolute;
  top: -79px;
  border-radius: 30px;
  overflow: hidden;
  animation: anim 0.5s linear forwards;
  box-shadow: 0px 3px 1px -2px var(--v-text-lighten3),
    0px 2px 2px 0px var(--v-text-lighten3),
    0px 1px 5px 0px var(--v-text-lighten3);

  &-close {
    transition: transform 0.25s, opacity 0.25s;
    opacity: 1;

    &:hover {
      transform: rotate(90deg);
    }
  }

  &-item-close {
    width: 47px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: end;
    flex: 1;
  }

  &-item {
    width: 47px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    cursor: pointer;
    border-radius: 100%;

    svg {
      width: 30px !important;
      height: 30px !important;
    }

    &:hover,
    &--active {
      border: solid 1px var(--v-blue-grey-base);
    }
  }
  @keyframes anim {
    0% {
      opacity: 0;
      width: 0;
    }
    100% {
      opacity: 1;
      width: 100%;
    }
  }
}
</style>
