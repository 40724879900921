<template>
  <div
    :class="`v-treeview-node v-treeview-node--click ${
      hasChildren ? '' : 'v-treeview-node--leaf'
    }`"
  >
    <div class="v-treeview-node__root" @click="edit">
      <!--<div
        v-for="index in appendLevel"
        :key="index"
        class="v-treeview-node__level"
      />-->
      <v-btn
        v-if="hasChildren"
        title="Свернуть/Развернуть"
        icon
        @click.stop.prevent="open = !open"
      >
        <m-icon
          :icon="open ? 'tool-arrow-down' : 'tool-arrow-right'"
          size="24"
        ></m-icon>
      </v-btn>

      <slot name="prepend" v-bind="{ item: value, open }" />
      <div class="v-treeview-node__label" :class="{ 'ml-7': !hasChildren }">
        <slot name="label" v-bind="{ item: value, open }">
          {{ value.name }}
        </slot>
      </div>
      <slot name="append" v-bind="{ item: value }" />
      <slot name="captureArea" v-bind="{ item: value, open }" />
    </div>
    <div class="v-treeview-node__Children v-treeview-node__Children__draggable">
      <draggable
        :group="group"
        :value="value.Children"
        ghost-class="ghost"
        @input="updateValue"
        :class="{ test: !value.Children.length }"
        handle=".capture-area"
      >
        <template v-if="open">
          <treeview-node
            v-for="child in value.Children"
            :key="child.Id"
            :group="group"
            :value="child"
            :level="level + 1"
            :expand-icon="expandIcon"
            @input="updateChildValue"
            :isOpen="opens.has(child.Id)"
            :opens="opens"
            :updateItems="updateItems"
            :pageEditName="pageEditName"
            v-on="
              $listeners['clickRow'] ? { clickRow: $listeners['clickRow'] } : {}
            "
          >
            <template v-slot:prepend="{ item, open }">
              <slot name="prepend" v-bind="{ item, open }" />
            </template>
            <template v-slot:label="{ item, open }">
              <slot name="label" v-bind="{ item, open }"></slot>
            </template>
            <template v-slot:append="{ item }">
              <slot name="append" v-bind="{ item }" />
            </template>
            <template v-slot:captureArea="{ item, open }">
              <slot name="captureArea" v-bind="{ item, open }" />
            </template>
          </treeview-node>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Draggable from "vuedraggable";

export default Vue.extend({
  name: "TreeviewNode",
  components: {
    Draggable,
  },
  props: {
    level: {
      type: Number,
      default: 0,
    },
    value: {
      type: Object,
      default: () => ({
        Id: 0,
        name: "",
        Children: [],
      }),
    },
    root: {
      type: Boolean,
      default: () => false,
    },
    group: {
      type: String,
      default: null,
    },
    expandIcon: {
      type: String,
      default: "mdi-menu-down",
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    opens: {
      type: Set,
      default: () => new Set(),
    },
    updateItems: {
      type: Function,
    },
    pageEditName: {
      type: String,
    },
  },
  data: function () {
    return {
      open: this.isOpen,

      localValue: Object.assign({}, this.value),
    };
  },
  computed: {
    hasChildren() {
      return this.value.Children !== null && this.value.Children.length > 0;
    },
    isDark() {
      return this.$vuetify.theme.isDark;
    },
    appendLevel() {
      return this.level + (this.hasChildren ? 0 : 1);
    },
  },
  watch: {
    value(value) {
      this.localValue = Object.assign({}, value);
    },
    // TODO: Костыль, решает проблему не обновления localValue
    "value.Content": function () {
      this.localValue = Object.assign({}, this.value);
    },
    isOpen(val) {
      this.open = val;
    },
  },
  methods: {
    updateValue(value) {
      this.localValue.Children = [...value];
      this.$emit("input", this.localValue);
    },
    updateChildValue(value) {
      const index = this.localValue.Children.findIndex(
        (c) => c.Id === value.Id
      );
      this.$set(this.localValue.Children, index, value);
      this.$emit("input", this.localValue);
    },
    edit() {
      if (this.$listeners.clickRow) {
        this.$listeners.clickRow(this.localValue);
        return;
      }

      if (this.localValue.Id <= 0 || !this.pageEditName) return;

      // todo: Говно код, исправить
      this.$store.commit("pageTabs/SET_TRANSACTION_LIST_VIEW_DETAIL", {
        listViewUpdateItems: this.updateItems,
        listViewInit: this.$parent?.init,
      });
      this.$router.push({
        name: this.pageEditName,
        params: {
          objectId: this.localValue.Id,
        },
      });
    },
  },
});
</script>
<style scoped lang="sass">
.v-treeview-node__Children__draggable
  margin: 0 0 0 2rem

.v-treeview-node__root:hover
  background-color: var(--v-blue-grey-lighten4)

.test
  min-height: 3px
</style>
<style lang="scss">
.theme--light.v-treeview
  .v-treeview-node--click
  > .v-treeview-node__root:hover::before {
  opacity: 0;
}
</style>
