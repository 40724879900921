<template>
  <div justify-end ref="search_container">
    <v-menu
      v-model="isShow"
      bottom
      origin="center center"
      transition="slide-x-transition"
      :close-on-content-click="false"
      content-class="search--menu-main"
      :close-on-click="!value"
      :nudge-left="210"
      :nudge-width="200"
      :nudge-bottom="-5"
      :attach="this.$refs.search_container"
    >
      <template v-slot:activator="{ on }">
        <v-btn text medium v-on="on" title="Поиск">
          {{ labelTextSearch }}
          <m-icon icon="tool-search" right size="18"></m-icon>
        </v-btn>
      </template>

      <v-text-field
        clearable
        hide-details
        solo
        dense
        autofocus
        placeholder="Поиск..."
        append-icon="$ToolSearch"
        v-bind:value="value"
        v-on:input="$emit('input', $event)"
        style="font-weight: 500"
      >
      </v-text-field>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    default: () => "",
    labelTextSearch: String,
  },
  data() {
    return {
      isShow: !!this.value,
    };
  },
  watch: {
    isShow: function (val) {
      this.$emit("changeOpen", val);
    },
  },
  activated() {
    if (!this.isShow && this.value) {
      this.isShow = true;
    }
  },
};
</script>
<style scoped>
.search--menu-main {
  box-shadow: none;
  max-width: 282px;
}
</style>

<style></style>
