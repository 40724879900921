<template>
  <base-popup
    title="Настройка главного экрана"
    @save="save"
    v-model="dialog"
    :width="width"
    :height="height"
    v-bind="$attrs"
  >
    <template v-slot:activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>
    <v-container>
      <base-table
        :items="items"
        :headers="headers"
        item-key="Type"
        hide-default-header
        hide-default-footer
        notShowSelect
      >
        <template slot="item.IsSelected" slot-scope="{ item }">
          <v-simple-checkbox
            v-model="item.IsSelected"
            :ripple="false"
            :disabled="item.IsDisabled"
          ></v-simple-checkbox>
        </template>
      </base-table>
    </v-container>
  </base-popup>
</template>
<script>
import BasePopup from "@/layouts/BasePopup.vue";

import BaseTable from "@/components/base/BaseTable.vue";

export default {
  components: { BasePopup, BaseTable },
  props: {
    value: { type: Boolean, default: false },
    width: String,
    height: String,
    settings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      items: [
        {
          Type: 0,
          Name: "Анализ состояния качества и безопасности медицинской деятельности",
          IsSelected: false,
          IsDisabled: false,
        },
        {
          Type: 1,
          Name: "Задачи",
          IsSelected: false,
          IsDisabled: false,
        },
        {
          Type: 2,
          Name: "Проекты",
          IsSelected: false,
          IsDisabled: false,
        },
        {
          Type: 3,
          Name: "Документы",
          IsSelected: false,
          IsDisabled: false,
        },
        {
          Type: 4,
          Name: "Проверки",
          IsSelected: false,
          IsDisabled: false,
        },
        {
          Type: 5,
          Name: "Статистические данные",
          IsSelected: false,
          IsDisabled: false,
        },
        {
          Type: 6,
          Name: "Учет нежелательных событий",
          IsSelected: false,
          IsDisabled: false,
        },
        {
          Type: 7,
          Name: "Учебные курсы",
          IsSelected: false,
          IsDisabled: false,
        },
        {
          Type: 8,
          Name: "Начало работы",
          IsSelected: false,
          IsDisabled: false,
        },
      ],
      headers: [
        {
          value: "IsSelected",
        },
        {
          value: "Name",
          text: "Name",
        },
      ],
    };
  },
  watch: {
    settings(val) {
      for (let i = 0; i < val.length; i++) {
        const item = this.items.find((e) => e.Type === val[i].Type);
        if (!item) return;
        item.IsSelected = val[i].IsSelected;
        item.IsDisabled = val[i].IsDisabled;
      }
    },
    dialog(val) {
      this.$emit("input", val);
    },
    value(val) {
      if (val !== this.dialog) this.dialog = val;
    },
  },
  methods: {
    save() {
      this.$emit("save", this.items);
    },
  },
};
</script>
