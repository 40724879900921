<template>
  <v-layout>
    <base-detail-view
      title="Анализ состояния качества и безопасности медицинской деятельности"
      :showSave="false"
      @onRefresh="init"
    >
      <v-btn
        slot="action"
        @click="dialogSelect = true"
        dark
        color="primary"
        class="ml-4"
        title="Настройка показателей"
      >
        <m-icon icon="mdi-filter-outline" left></m-icon>
        Показатели
      </v-btn>

      <v-card height="100%" class="mt-4 pa-4 pt-6">
        <v-row>
          <v-col lg="6" sm="12">
            <v-skeleton-loader
              class="mt-10"
              v-if="loadingCurrentAnalysis"
              type="list-item-avatar-three-line"
            ></v-skeleton-loader>

            <div v-else>
              <p class="ma-0">
                Общая оценка состояния качества и безопасности медицинской
                деятельности
              </p>
              <p class="font-weight-bold">
                {{ "на " + currentAnalysis.DisplayDate }}
              </p>
              <div
                class="d-flex flex-no-wrap align-center justify-space-between"
                v-if="loading === false"
              >
                <div class="doughnut_chart">
                  <doughnut-chart
                    :chart-data="doughnutData"
                    :options="doughnutOptions"
                    :common-percent="
                      (currentAnalysis.Value ? currentAnalysis.Value : 0) + '%'
                    "
                    :title="`Оценено ${auditIndicatorsNotNull} из ${auditIndicators.length} показателей`"
                  ></doughnut-chart>
                </div>
                <div class="align-self-center">
                  <p>
                    {{ currentAnalysis.Description }}
                  </p>
                  <p style="font-size: 14px; text-align: center">
                    {{
                      `Состояние оценено путем проведения плановых и внеплановых
                    проверок ${auditIndicatorsNotNull} из
                                        ${auditIndicators.length} показателей`
                    }}
                  </p>
                </div>
              </div>
              <div
                v-if="loading === true"
                class="d-flex flex-no-wrap justify-center align-items-center"
              >
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
            </div>
          </v-col>
          <v-col lg="6" sm="12">
            <div
              v-if="loadingCurrentAnalysis === false && dataSource.length"
              class="d-flex flex-column align-center"
            >
              <m-select
                :value="period"
                :itemValue="'id'"
                @change="changePeriod"
                :items="Period"
                :item-text="(item) => item.value"
                :clearable="false"
                return-object
                single-line
                dense
                class="pa-0 ma-0"
                style="width: 160px"
              ></m-select>
              <bar-chart
                :chart-data="barData"
                :options="barOptions"
                class="align-self-stretch"
                style="height: 20vh"
              >
              </bar-chart>
              <div
                v-if="period === 0 || period === 1"
                class="d-flex flex-no-wrap justify-center"
              >
                <v-container style="width: 120px">
                  <m-select
                    v-model="year"
                    :items="years"
                    :clearable="false"
                  ></m-select>
                </v-container>
              </div>
            </div>

            <div v-if="loadingCurrentAnalysis">
              <v-skeleton-loader type="text@1, image"></v-skeleton-loader>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="analysis_indicators__help-panel"
            align-self="center"
          >
            <v-btn
              slot="action"
              @click="isShowPanel = !isShowPanel"
              dark
              color="primary"
              class="ml-4"
              title="Развернуть/Свернуть"
            >
              {{ isShowPanel ? "Свернуть" : "Развернуть" }}
              <m-icon
                :icon="isShowPanel ? 'tool-arrow-up' : 'tool-arrow-down'"
                class="ml-4"
                size="24"
              ></m-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>

      <div v-show="isShowPanel" class="mt-4">
        <v-card v-if="auditIndicators.length">
          <v-card-actions>
            <h4>Показатели оценки качества</h4>
          </v-card-actions>
          <v-card-text>
            <div v-if="loadingCurrentAnalysis === false">
              <v-row
                v-for="(auditIndicator, index) in auditIndicators"
                justify="start"
                align="center"
                :key="index"
                no-gutters
                class="mb-2 analysis_indicators__row"
                @click="selectIndicator(index)"
              >
                <v-col lg="5" sm="12" class="pr-4">
                  <div
                    class="pa-1 pl-4 pr-4"
                    style="
                      width: 100%;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      border-radius: 10px;
                    "
                    :key="index"
                    :style="{
                      background:
                        index !== selectedIndicatorIndex
                          ? null
                          : 'var(--v-primary-base)',
                      color:
                        index !== selectedIndicatorIndex
                          ? 'var(--v-text-base)'
                          : 'var(--v-white-base)',
                    }"
                    :title="auditIndicator.Indicator.Name"
                  >
                    {{ auditIndicator.Indicator.Name }}
                  </div>
                </v-col>
                <v-col lg="5" sm="10">
                  <span v-if="auditIndicator.Value === null">
                    Проверка не проводилась
                  </span>

                  <progress-bar
                    v-else
                    :value="auditIndicator.Value"
                    :color="
                      index !== selectedIndicatorIndex
                        ? 'var(--v-blue-grey-lighten1)'
                        : 'var(--v-primary-base)'
                    "
                    :textColor="
                      index !== selectedIndicatorIndex ? 'var(--v-text-base)' : 'var(--v-white-base)',
                    "
                  ></progress-bar>
                </v-col>
                <v-col lg="2" sm="2" style="text-align: right">
                  <span :class="getDateClass(auditIndicator.Date)">{{
                    $options.filters.DateShort_MYYYY(auditIndicator.Date)
                  }}</span>
                </v-col>
              </v-row>
            </div>
            <div v-if="loadingCurrentAnalysis === true">
              <v-skeleton-loader type="text@5"></v-skeleton-loader>
            </div>
          </v-card-text>
        </v-card>

        <v-row class="mt-0" v-if="auditIndicator && indicatorData.length">
          <v-col>
            <v-card height="100%">
              <v-card-actions>
                <h4>
                  Динамика изменения показателя ({{
                    auditIndicator.Indicator.Name
                  }})
                </h4>
                <v-spacer></v-spacer>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <m-icon icon="mdi-dots-vertical"></m-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="item in Period"
                      :key="item.id"
                      @click="changeIndicatorPeriod(item.id)"
                    >
                      <v-list-item-title>{{ item.value }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-actions>
              <v-card-text>
                <div v-if="loadingIndicator === false">
                  <bar-chart
                    :chart-data="indicatorBarData"
                    :options="indicatorBarOptions"
                    style="height: 20vh"
                  >
                  </bar-chart>
                  <div class="d-flex flex-no-wrap justify-center">
                    <v-container style="width: 120px">
                      <m-select
                        v-model="indicatorYear"
                        :items="years"
                        label="Год"
                        :clearable="false"
                      ></m-select>
                    </v-container>
                  </div>
                </div>
                <div
                  v-if="loadingIndicator === true"
                  class="d-flex flex-no-wrap justify-center align-items-center"
                >
                  <v-progress-circular
                    :size="70"
                    :width="7"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card height="100%">
              <v-card-actions>
                <h4>{{ checkListTitle }}</h4>
              </v-card-actions>
              <v-card-text>
                <v-expansion-panels multiple flat>
                  <v-expansion-panel
                    v-for="(checkList, i) in checkLists"
                    :key="i"
                  >
                    <v-expansion-panel-header class="pa-1">
                      <v-row align="center" justify="space-between" no-gutters>
                        <v-col>
                          <span> {{ checkList.Name }}</span>
                        </v-col>
                        <v-col>
                          <span v-if="checkList.Value === null">
                            Проверка не проводилась
                          </span>
                          <progress-bar
                            v-else
                            :value="checkList.Value"
                            :color="'var(--v-blue-grey-lighten1)'"
                          ></progress-bar>
                        </v-col>
                        <v-col style="text-align: right; padding-right: 15px">
                          <span :class="getDateClass(checkList.DateOut)">{{
                            $options.filters.DateShort_MYYYY(checkList.DateOut)
                          }}</span>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row
                        v-for="(obj, inx) in checkList.Objects"
                        :key="inx"
                        align="center"
                        justify="space-between"
                        @click="
                          $router.push({
                            name: 'CheckOperationRun',
                            params: {
                              objectId: obj.Id,
                            },
                          })
                        "
                      >
                        <v-col>
                          <span>
                            -
                            {{
                              obj.ObjectDescription
                                ? obj.ObjectDescription
                                : "без объекта"
                            }}</span
                          >
                        </v-col>

                        <v-col>
                          <span v-if="obj.Value === null">
                            Проверка не проводилась
                          </span>
                          <progress-bar
                            v-else
                            :value="obj.Value"
                            :color="'var(--v-blue-grey-lighten1)'"
                          ></progress-bar>
                        </v-col>
                        <v-col style="text-align: right; padding-right: 35px">
                          <span :class="getDateClass(obj.DateOut)">{{
                            $options.filters.DateShort_MYYYY(obj.DateOut)
                          }}</span>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-card class="mt-4 mb-4">
          <v-card-actions>
            <h4>Сравнительный анализ</h4>
          </v-card-actions>
          <v-card-text>
            <v-row>
              <v-col sm="6">
                <base-date-picker
                  v-model="date1"
                  label="Выберите первую дату"
                  :title="'Выберите первую дату для сравнения'"
                />
                <base-date-picker
                  v-model="date2"
                  label="Выберите вторую дату"
                  :title="'Выберите вторую дату для сравнения'"
                  :readonly="!date1"
                />
                <v-list v-if="date2">
                  <v-list-item
                    v-for="(item, index) in computedComparedValues"
                    :key="item.Id"
                  >
                    <v-list-item-title>{{
                      ++index + ". " + item.Name
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="!showAll">
                    <v-btn class="my-1" block depressed @click="showAll = true"
                      >Показать все объекты ({{
                        comparedValues.length - 5
                      }})</v-btn
                    >
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col sm="6">
                <div v-if="date2">
                  <radar-chart
                    :chart-data="radarData"
                    :options="radarOptions"
                  ></radar-chart>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>

      <statistic-indicators-chart
        class="mt-4"
        :loading="loadingStatisticIndicators"
        :items="statisticIndicators"
        :showYear.sync="showYear"
      ></statistic-indicators-chart>

      <unwished-events-chart
        :loading="loadingUnwishedEvents"
        :items="unwishedEvents"
        :year="yearUnwishedEvents"
        :period="periodUnwishedEvents"
        @changeYear="changeUnwishedEventsYear"
        @changePeriod="changeUnwishedEventsPeriod"
        class="mt-2"
      ></unwished-events-chart>

      <audit-indicator-select
        v-model="dialogSelect"
        @save="select"
        :width="'50vw'"
        :height="'60vh'"
        :selectedAuditIndicators="selectedAuditIndicators"
      />
    </base-detail-view>
  </v-layout>
</template>

<script>
import BaseDetailView from "@/layouts/BaseDetailView";
import DoughnutChart from "@/views/analysis/DoughnutChart.js";
import BarChart from "@/views/analysis/BarChart.js";
import RadarChart from "@/views/analysis/RadarChart.js";
import AnalysisIndicatorService from "@/services/AnalysisIndicatorService";
import UnwishedEventService from "../../services/UnwishedEventService";
import StatisticIndicatorService from "../../services/StatisticIndicatorService";
import DataHelper from "../../utils/DataHelper";
import mSelect from "@/components/base/inputs/mSelect";
import BaseDatePicker from "@/components/base/BaseDatePicker.vue";
import { Period } from "@/data/enums";
import AuditIndicatorSelect from "@/components/analysis/AuditIndicatorSelect.vue";
import ProgressBar from "../../components/analysis/ProgressBar.vue";
import StatisticIndicatorsChart from "../../views/statisticIndicator/statisticIndicatorsChart.vue";
import UnwishedEventsChart from "../../views/unwishedEvents/UnwishedEventsChart.vue";
import moment from "moment";
import StatisticIndicatorHelper from "../statisticIndicator/StatisticIndicatorHelper";

export default {
  name: "view-AnalysisIndicators",
  components: {
    BaseDetailView,
    DoughnutChart,
    BarChart,
    RadarChart,
    mSelect,
    BaseDatePicker,
    AuditIndicatorSelect,
    ProgressBar,
    StatisticIndicatorsChart,
    UnwishedEventsChart,
  },
  data() {
    return {
      loading: false,
      loadingCurrentAnalysis: false,
      loadingIndicator: false,
      dataSource: [],
      indicatorData: [],
      checkLists: [],
      algorithm: 0,
      auditIndicators: [],
      auditIndicator: null,
      selectedIndicatorIndex: null,
      initialYear: 2020,
      years: [],
      currentAnalysis: {},
      dialogSelect: false,
      auditIndicatorIds: [],
      selectedAuditIndicators: [],
      showYear: new Date().getFullYear(),

      comparedValues: [],

      checkListTitle: "",

      auditIndicatorAnalysisValues: [],
      auditIndicatorAnalysisValues2: [],

      currentDate: null,

      period: 0,
      year: new Date().getFullYear(),

      indicatorPeriod: 0,
      indicatorYear: new Date().getFullYear(),

      date1: null,
      date2: null,

      doughnutData: {
        datasets: [
          {
            data: [40, 60],
            backgroundColor: ["#BBC6F0", "#FFC805"],
            hoverOffset: 4,
          },
        ],
      },
      doughnutOptions: {
        tooltips: {
          enabled: false,
        },
      },
      barData: {},
      barOptions: {},
      indicatorBarData: {},
      indicatorBarOptions: {},

      radarData: null,
      radarOptions: {
        tooltips: {
          enabled: true,
          callbacks: {
            title: (item) => {
              const i = item[0].index;
              return i >= 0 && this.comparedValues?.length >= i + 1
                ? this.comparedValues[i]?.Name
                : "";
            },
          },
        },
        maintainAspectRatio: false,
        scale: {
          ticks: {
            display: false,
            min: 0,
            max: 100,
          },
        },
        elements: {
          line: {
            borderWidth: 1,
          },
        },
      },
      isShowPanel: false,

      loadingUnwishedEvents: false,
      unwishedEvents: [],
      yearUnwishedEvents: new Date().getFullYear(),
      periodUnwishedEvents: 0,

      loadingStatisticIndicators: false,
      statisticIndicators: [],
      isSettings: true,
      showAll: false,
    };
  },

  computed: {
    computedComparedValues() {
      return this.comparedValues.slice(
        0,
        this.showAll ? this.comparedValues.length : 5
      );
    },
    Period: () => {
      return Period;
    },
    auditIndicatorsNotNull() {
      return this.auditIndicators.filter((a) => a.Value !== null).length;
    },
  },

  watch: {
    showYear() {
      this.loadStatisticIndicators();
    },
    async year(value) {
      if (value) {
        this.selectedIndicatorIndex = null;
        await this.loadAnalysis();
        this.indicatorYear = value;
      }
    },

    indicatorYear(value) {
      if (value) {
        this.loadIndicatorAnalysis();
      }
    },
    date1(value) {
      if (value) {
        this.loadAnalysis2();
      }
    },
    date2(value) {
      if (value) {
        this.loadAnalysis2();
      }
    },
  },

  async mounted() {
    await this.init();
  },
  methods: {
    DataFromServerNormalize(data) {
      data = data.map((obj) =>
        Object.assign({}, obj, {
          Value: obj.Value ? Math.round(Number(obj.Value)) : obj.Value,
          DisplayDate: obj.Date ? this.calculateDate(obj.Date) : "",
        })
      );
      return data;
    },

    async init() {
      this.selectedIndicatorIndex = null;
      this.indicatorData = [];
      this.auditIndicator = null;
      this.comparedValues = [];
      this.years = [];
      for (let year = 2020; year <= new Date().getFullYear(); year++)
        this.years.push(year);
      this.loadAnalysis();
      this.loadUnwishedEvents();
      this.loadStatisticIndicators();
    },

    // устанавливает данные для анализа показателей в выбранном периоде для формирования пончиковой диаграммы
    async setCurrentAnalysis(analysis) {
      this.currentAnalysis = analysis;
      const { Value } = analysis;

      this.doughnutData = {
        datasets: [
          {
            data: [Value, 100 - Value],
            backgroundColor: [
              StatisticIndicatorHelper.getColorHexByRefs(
                analysis.RefValues,
                Value,
                true
              ),
              "#BBC6F0",
            ],
            hoverOffset: 4,
          },
        ],
      };
    },

    // загружает данные анализа показателей для выбранного периода (месяц, квартал, год) для формирования столбчатой диаграммы (Bar chart)
    async loadAnalysis() {
      this.loadingCurrentAnalysis = true;
      const dates = this.getBoundaryDates(this.period, this.year);
      const { data } = await AnalysisIndicatorService.show(
        this.algorithm,
        this.isSettings,
        dates,
        this.auditIndicatorIds,
        true,
        true
      );
      this.dataSource = this.DataFromServerNormalize(data);

      if (this.dataSource.length) {
        this.barData = {
          labels: this.getChartLabels(this.dataSource, this.period),
          datasets: [
            {
              data: this.dataSource.map((e) => e.Value),
              backgroundColor: this.dataSource.map((item, i, { length }) => {
                const hasAudit = item.AuditIndicatorValues.some(
                  (a) => a.Value !== null
                );

                if (this.isEqualCurrentDate(item.Date)) return "blue";
                else if (hasAudit) return "#BBC6F0";
                else return "#E8ECFF";
              }),
              minBarLength: 5,
            },
          ],
        };

        this.barOptions = {
          legend: {
            display: false,
          },
          tooltips: {
            mode: "label",
            callbacks: {
              title: (tooltipItem, data) => {
                return `Оценено ${this.auditIndicatorsNotNull} из ${this.auditIndicators?.length} показателей`;
              },
              label: function () {
                return "";
              },
            },
          },
          plugins: {
            datalabels: {
              align: "end",
              anchor: "end",
              color: "#2757FF",
              formatter: (val) => Math.round(val),
            },
          },
          maintainAspectRatio: false,
          onClick: this.handle,
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
                barPercentage: 0.9,
                offset: true,
              },
            ],
            yAxes: [
              {
                gridLines: {
                  display: false,
                },
                ticks: {
                  display: false,
                  min: 0,
                },
              },
            ],
          },
          layout: {
            padding: {
              top: 25,
            },
          },
        };

        const lastIndex = this.dataSource.findIndex((item, i, { length }) => {
          if (this.isEqualCurrentDate(item.Date) || length - 1 === i)
            return true;
          return false;
        });

        this.auditIndicators = this.DataFromServerNormalize(
          this.dataSource[lastIndex].AuditIndicatorValues
        );
        this.date1 = moment().endOf("month").format("DD.MM.YYYY");
        this.loadAnalysis1(this.auditIndicators);

        this.comparedValues = this.auditIndicators.map((a) => ({
          Id: a.Indicator.Id,
          Name: a.Indicator.Name,
        }));
        this.auditIndicator = null;
        this.setCurrentAnalysis(this.dataSource[lastIndex]);
      }
      this.loadingCurrentAnalysis = false;
    },

    async loadUnwishedEvents() {
      this.unwishedEvents = (
        await UnwishedEventService.getByPeriods(
          this.yearUnwishedEvents,
          this.periodUnwishedEvents
        )
      ).data;
    },

    async loadStatisticIndicators() {
      this.loadingStatisticIndicators = true;

      this.statisticIndicators = (
        await StatisticIndicatorService.get({
          done: false,
          year: this.showYear,
          fields: 16,
        })
      ).data;
      this.loadingStatisticIndicators = false;
    },

    // загружает данные для анализа конкретного показателя для выбранного периода (месяц, квартал, год) для формирования столбчатой диаграммы (Bar chart)
    async loadIndicatorAnalysis() {
      this.loadingIndicator = true;
      const dates = this.getBoundaryDates(
        this.indicatorPeriod,
        this.indicatorYear
      );
      const { data } = await AnalysisIndicatorService.show(
        this.algorithm,
        this.isSettings,
        dates,
        this.auditIndicatorIds,
        true,
        true
      );
      if (data.length) {
        this.indicatorData = [];
        this.checkLists = [];

        for (const res of data) {
          const indicatorValue = res.AuditIndicatorValues.find(
            (d) => d?.Indicator?.Id === this.auditIndicator?.Indicator?.Id
          );
          this.indicatorData.push({
            Date: res.Date,
            Value: indicatorValue?.Value ?? 0,
            Values: indicatorValue?.Values ?? [],
          });
        }

        this.checkLists = this.DataFromServerNormalize(
          this.indicatorData[this.indicatorData.length - 1].Values
        );
        this.checkListTitle = `Чек-листы (показатель: ${
          this.auditIndicator.Indicator.Name
        } на ${this.$options.filters.DateShort(
          this.indicatorData[this.indicatorData.length - 1].Date
        )})`;

        this.loadAnalysis1(
          this.indicatorData[this.indicatorData.length - 1].Values
        );

        this.indicatorBarData = {
          labels: this.getChartLabels(this.indicatorData, this.indicatorPeriod),
          datasets: [
            {
              data: this.indicatorData.map((e) => e.Value.toFixed(2)),
              backgroundColor: this.indicatorData.map(() => "#E8ECFF"),
              minBarLength: 5,
            },
          ],
        };

        this.indicatorBarOptions = {
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                return Math.round(Number(tooltipItem.yLabel));
              },
            },
          },
          legend: {
            display: false,
          },
          plugins: {
            datalabels: {
              align: "end",
              anchor: "end",
              color: "#2757FF",
              formatter: Math.round,
            },
          },
          onClick: this.handleIndicator,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
                barPercentage: 0.9,
                categoryPercentage: 1.0,
                offset: true,
              },
            ],
            yAxes: [
              {
                gridLines: {
                  display: false,
                },
                ticks: {
                  display: false,
                  min: 0,
                },
              },
            ],
          },
          layout: {
            padding: {
              top: 25,
            },
          },
        };
      }
      this.loadingIndicator = false;
    },

    // загружает данные для первого графика-радара
    async loadAnalysis1(analysisArray) {
      console.log("loadAnalysis1");
      this.auditIndicatorAnalysisValues2 = [];

      if (analysisArray.length) {
        this.auditIndicatorAnalysisValues =
          this.DataFromServerNormalize(analysisArray);
      }
    },

    // загружает данные для второго графика-радара
    async loadAnalysis2() {
      console.log("loadAnalysis2");
      const { data } = await AnalysisIndicatorService.show(
        this.algorithm,
        this.isSettings,
        [
          DataHelper.toDateTime(this.date1),
          DataHelper.toDateTime(this.date2),
        ].filter(Boolean),
        this.auditIndicatorIds,
        true,
        true,
        true
      );
      const res = data[0].AuditIndicatorValues;

      if (data.length) {
        this.auditIndicatorAnalysisValues2 =
          this.auditIndicatorAnalysisValues.map((item) => {
            let temp = null;
            if (this.auditIndicator) {
              temp = res
                .find(
                  (i) => i.Indicator.Id === this.auditIndicator.Indicator.Id
                )
                ?.Values?.find((d) => d.CheckListId === item.CheckListId);
            } else {
              temp = res.find((i) => i.Indicator.Id === item.Indicator.Id);
            }

            if (temp)
              return {
                ...temp,
                Value: temp?.Value?.toFixed(2),
              };
            else
              return {
                ...item,
                Value: 0,
              };
          });

        // this.radarData = {
        //   labels: this.auditIndicatorAnalysisValues.map((g, index) => ++index),
        //   datasets: [
        //     {
        //       label: this.$options.filters.DateShort(this.date1),
        //       data: this.auditIndicatorAnalysisValues.map((v) => v.Value),
        //       fill: true,
        //       backgroundColor: "rgba(232, 236, 255, 0.8)",
        //     },
        //   ],
        // };

        this.comparedValues = res.map((a) => ({
          Id: a.Indicator.Id,
          Name: a.Indicator.Name,
        }));

        this.radarData = Object.assign({}, this.radarData, {
          labels: data[0].AuditIndicatorValues.map((g, index) => ++index),
          datasets: [
            {
              label: this.$options.filters.DateShort(data[0].Date),
              data: data[0].AuditIndicatorValues.map((v) => v.Value),
              fill: true,
              backgroundColor: "rgba(232, 236, 255, 0.8)",
            },

            {
              label: this.$options.filters.DateShort(data[1].Date),
              data: data[1].AuditIndicatorValues.map((v) => v.Value),
              fill: true,
              backgroundColor: "rgba(255, 200, 5, 0.5)",
            },
          ],
        });
      }
    },

    // обрабатывает клик при нажатии на столбчатой диаграмме показателей
    async handle(event, array) {
      if (array.length > 0) {
        const selectedIndex = array[0]._index;
        this.selectedIndicatorIndex = null;
        this.auditIndicator = null;
        this.indicatorData = [];

        this.barData = Object.assign({}, this.barData, {
          datasets: [
            {
              ...this.barData.datasets[0],
              backgroundColor: this.barData.datasets[0].data.map(
                () => "#BBC6F0"
              ),
            },
          ],
        });

        const backgroundColorModified = [
          ...this.barData.datasets[0].backgroundColor,
        ];
        backgroundColorModified[selectedIndex] = "primary";

        this.barData = Object.assign({}, this.barData, {
          datasets: [
            {
              ...this.barData.datasets[0],
              backgroundColor: backgroundColorModified,
            },
          ],
        });

        this.auditIndicators = this.DataFromServerNormalize(
          this.dataSource[selectedIndex].AuditIndicatorValues
        );
        // this.date1 = moment().endOf("month").format("DD.MM.YYYY");

        this.loadAnalysis1(this.auditIndicators);
        this.setCurrentAnalysis(this.dataSource[selectedIndex]);
      }
    },

    // обрабатывает клик при нажатии на столбчатой диаграмме конкретного показателя
    handleIndicator(event, array) {
      if (array.length > 0) {
        const selectedIndex = array[0]._index;

        this.indicatorBarData = Object.assign({}, this.indicatorBarData, {
          datasets: [
            {
              ...this.indicatorBarData.datasets[0],
              backgroundColor: this.indicatorBarData.datasets[0].data.map(
                () => "#E8ECFF"
              ),
            },
          ],
        });

        const backgroundColorModified = [
          ...this.indicatorBarData.datasets[0].backgroundColor,
        ];
        backgroundColorModified[selectedIndex] = "primary";

        this.indicatorBarData = Object.assign({}, this.indicatorBarData, {
          datasets: [
            {
              ...this.indicatorBarData.datasets[0],
              backgroundColor: backgroundColorModified,
            },
          ],
        });
        this.checkLists = this.DataFromServerNormalize(
          this.indicatorData[selectedIndex].Values
        );
        this.checkListTitle = `Чек-листы (показатель: ${
          this.auditIndicator.Indicator.Name
        } на ${this.$options.filters.DateShort(
          this.indicatorData[selectedIndex].Date
        )})`;

        // this.date1 = moment().endOf("month").format("DD.MM.YYYY");
        this.loadAnalysis1(this.indicatorData[selectedIndex].Values);
      }
    },

    async selectIndicator(index) {
      if (this.selectedIndicatorIndex === index) {
        this.selectedIndicatorIndex = null;
        this.auditIndicator = null;
        this.indicatorData = [];

        this.comparedValues = this.auditIndicators.map((a) => ({
          Id: a.Indicator.Id,
          Name: a.Indicator.Name,
        }));
      } else {
        this.selectedIndicatorIndex = index;
        this.auditIndicator = this.auditIndicators[index];
        await this.loadIndicatorAnalysis();
      }
    },

    changePeriod(obj) {
      this.period = obj.id;
      this.init();
    },

    changeIndicatorPeriod(id) {
      this.indicatorPeriod = id;
      this.loadIndicatorAnalysis();
    },

    changeUnwishedEventsYear(year) {
      this.yearUnwishedEvents = year;
      this.loadUnwishedEvents();
    },
    changeUnwishedEventsPeriod(id) {
      this.periodUnwishedEvents = id;
      this.loadUnwishedEvents();
    },

    calculateDate(date) {
      const transformedDate = DataHelper.toDateTime(date);
      const year = transformedDate.getFullYear();

      switch (this.period) {
        case 0:
          return `${transformedDate.toLocaleDateString("default", {
            month: "long",
          })} ${year} год`;
        case 1: {
          const month = transformedDate.getMonth();
          const quarter = Math.floor((month + 3) / 3);
          return `${quarter} квартал ${year} года`;
        }
        case 2:
          return `${year} год`;
      }
    },

    getBoundaryDates(period, year) {
      const dates = [];
      switch (period) {
        case 0:
          for (let i = 0; i <= 11; i++) {
            const lastDayOfMonth = new Date(year, i + 1, 0).getDate();
            dates.push(new Date(year, i, lastDayOfMonth));
          }

          break;
        case 1: {
          for (let i = 1; i <= 4; i++) {
            dates.push(this.getLatestDateQuarter(i, year));
          }
          break;
        }

        case 2:
          for (let i = this.initialYear; i <= new Date().getFullYear(); i++)
            if (i === new Date().getFullYear())
              dates.push(new Date(i, new Date().getMonth(), 0));
            else dates.push(new Date(i, 11, 31));
          break;
      }
      return dates;
    },

    getChartLabels(array, period) {
      let labels = [];
      if (period === 0) {
        labels = array.map((e) =>
          DataHelper.toDateTime(e.Date).toLocaleDateString("default", {
            month: "short",
          })
        );
      } else if (period === 1) {
        labels = array.map(
          (e) =>
            Math.floor((DataHelper.toDateTime(e.Date).getMonth() + 3) / 3) +
            " квартал"
        );
      } else {
        labels = array.map((e) =>
          DataHelper.toDateTime(e.Date).toLocaleDateString("default", {
            year: "numeric",
          })
        );
      }
      return labels;
    },

    getLatestDateQuarter(quarter, year) {
      switch (quarter) {
        case 1:
          return new Date(year, 2, 31);
        case 2:
          return new Date(year, 5, 30);
        case 3:
          return new Date(year, 8, 30);
        case 4:
          return new Date(year, 11, 31);
        default:
          return new Date();
      }
    },

    select(value) {
      this.auditIndicatorIds = value.length ? value.map((v) => v.Id) : [];
      this.selectedAuditIndicators = value.length ? value : [];
      this.isSettings = false;
      this.init();
    },

    getDateClass(str) {
      const date = DataHelper.toDate(str);
      if (!date) return null;

      const month = date.getMonth();
      const quarter = Math.floor((month + 3) / 3);
      const year = date.getFullYear();

      const currentMonth = new Date().getMonth();
      const currentQuarter = Math.floor((currentMonth + 3) / 3);
      const currentYear = new Date().getFullYear();

      if (month === currentMonth && year === currentYear)
        return { month: true };
      if (quarter === currentQuarter && year === currentYear)
        return { quarter: true };
      return null;
    },

    isEqualCurrentDate(date) {
      if (this.year !== DataHelper.today().getFullYear()) return false;
      switch (this.period) {
        case 0:
          return (
            DataHelper.toDate(date).getMonth() === DataHelper.today().getMonth()
          );

        case 1:
          return (
            moment(DataHelper.toDate(date)).quarter() ===
            moment(DataHelper.today()).quarter()
          );
      }
      return false;
    },
  },
};
</script>

<style lang="sass">
.doughnut_chart
  max-width: 200px
  margin: 10px

.month
  color: var(--v-success-base)

.quarter
  color: var(--v-primary-base)

.analysis_indicators__help-panel
  text-align: center
.analysis_indicators__row
  cursor: pointer
</style>
