<template>
  <v-snackbar left :value="value" dark multi-line timeout="60000">
    <div class="d-flex" style="cursor: pointer" @click="onClick">
      <div class="mr-4" style="align-self: center">
        <NotificationIcon
          :notificationItem="notificationItem"
        ></NotificationIcon>
      </div>
      <div>
        <div class="mb-2 font-weight-bold" style="font-size: 16px">
          {{ header }}
        </div>
        <div>{{ message }}</div>
      </div>
    </div>

    <template v-slot:action="{}">
      <v-btn icon @click.native="value = false">
        <m-icon :icon="$vuetify.icons.values.close"></m-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import NotificationIcon from "./components/notificationIcon.vue";

export default {
  name: "base-alert",
  components: {
    NotificationIcon,
  },

  props: {
    message: {
      type: String,
      required: true,
    },
    header: {
      type: String,
      required: true,
    },
    notificationItem: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      value: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.$destroy();
    }, 60000);
  },
  destroyed() {},
  methods: {
    onClick() {
      this.$destroy();
      this.$store.dispatch("notification/clickNotif", {
        notificationItem: { CanOpen: true, ...this.notificationItem },
        router: this.$router,
      });
    },
  },
};
</script>
