<template>
  <base-detail-view
    :loading="loading"
    title="Статистический показатель"
    :isUnsaved="isUnsaved && valid"
    @onRefresh="init"
    @onSave="save"
  >
    <template slot="subTitle">
      <span class="caption ml-5">
        {{
          type === "create"
            ? "новый показатель"
            : dataSource.Name + (dataSource.Archived ? " (В архиве)" : " ")
        }}
      </span>
      <span class="caption ml-5 dark">
        {{
          typeOrganization === 1 && dataSource.OnlyRead
            ? "Данные доступны для: " + dataSource.OwnerName
            : null
        }}
      </span>
    </template>
    <v-btn
      v-if="
        dataSource.Id > 0 &&
        hasStatisticIndicatorEdit &&
        dataSource.OwnerId === $store.state.Organization.OwnerId
      "
      slot="action"
      @click="archive"
      :disabled="!dataSource.Archived ? isUnsaved : null"
      dark
      color="primary"
      class="ml-4"
      :title="dataSource.Archived ? 'Извлечь из архива' : 'Перенести в архив'"
      :outlined="!!dataSource.Archived"
      >{{
        dataSource.Archived ? "Извлечь из архива" : "Перенести в архив"
      }}</v-btn
    >
    <v-form v-model="valid" :readonly="readonlyBase" class="block__content">
      <v-container>
        <v-row class="mr-4">
          <v-col>
            <v-text-field
              v-model="dataSource.Name"
              label="Название"
              maxlength="100"
              required
              :rules="[rules.required]"
              v-up-first-symbol
            ></v-text-field>
            <v-row>
              <v-col>
                <m-select
                  v-model="dataSource.Type"
                  :items="StatisticIndicatorType"
                  label="Тип"
                  :clearable="false"
                  item-text="value"
                  item-value="id"
                ></m-select
              ></v-col>
              <v-col>
                <v-text-field
                  v-model="dataSource.Units"
                  label="Единица измерения"
                  maxlength="50"
                ></v-text-field
              ></v-col>
            </v-row>
            <m-select
              v-model="dataSource.RatingType"
              :items="StatisticIndicatorRatingType"
              label="Динамика значения показателя"
              :clearable="false"
              item-text="value"
              item-value="id"
            ></m-select>
            <base-date-picker-range
              v-model="dates"
              label="Период сбора данных"
              :readonly="readonlyBase"
              required
              :rules="[rules.required]"
            />
          </v-col>
          <v-col>
            <m-select
              v-model="dataSource.TargetType"
              :items="StatisticIndicatorTargetType"
              label="Объект оценки"
              :clearable="false"
              item-text="value"
              item-value="id"
            ></m-select>
            <m-select
              v-model="dataSource.Period"
              :items="StatisticIndicatorPeriod"
              label="Периодичность сбора данных"
              :clearable="false"
              item-text="value"
              item-value="id"
              :rules="[rules.required]"
              required
            ></m-select>

            <m-autocomplete
              multiple
              v-model="dataSource.Responsibles"
              :items="employees"
              label="Контроль"
              return-object
              filter="EmployeeActive"
              dict="Employees"
              :textCustomAdd="'Создать нового сотрудника'"
              :readonly="readonlyBase"
              :clearable="!readonlyBase"
            ></m-autocomplete>
            <v-checkbox
              v-if="typeOrganization === 2"
              :disabled="
                readonlyBase ||
                (dataSource.Organizations &&
                  dataSource.Organizations.length > 0)
              "
              v-model="otherMedicalOrganization"
              hide-details
              class="shrink mr-2 mt-0"
            >
              <template v-slot:label> Другие МО </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-form>
      <base-panel class="mt-2" headerHidden>
        <m-tabs v-model="tab">
          <v-tab key="Values">Значения показателя</v-tab>
          <v-tab key="Responsibles">{{
            dataSource.TargetType === 0 ? "Ответственные" : "Объекты"
          }}</v-tab>
          <v-tab
            v-if="!readonlyBase && otherMedicalOrganization"
            key="Organization"
          >
            Другие МО
          </v-tab>
          <v-tab key="Settings">Настройки</v-tab>
        </m-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item key="Values" class="mt-3">
            <v-row>
              <v-col cols="6">
                <statistic-indicator-values
                  v-model="showObject"
                  :value2="dataSource.Groups"
                  :type="dataSource.TargetType"
                  :type2="dataSource.Type"
                  :refs="[
                    dataSource.RefValue1,
                    dataSource.RefValue2,
                    dataSource.RefValue3,
                  ]"
                  :period="dataSource.Period"
                  :RatingType="dataSource.RatingType"
                  :maxGroups="dataSource.MaxGroups"
                  :dataSource="dataSource"
                  :showYear.sync="showYear"
                  :readonlyItemValue="readonlyItemValue"
                  @createValues="createValues"
                  @refresh="refreshData"
              /></v-col>
              <v-col cols="6">
                <statistic-indicator-value-detail
                  v-if="showObject && !showObject.Values"
                  :item="showObject"
                  :type="dataSource.TargetType"
                  class="mb-2"
                  @close="showObject = null"
                  :readonly="(item) => readonlyItemValue(item)"
                ></statistic-indicator-value-detail>
                <statistic-indicator-values-chart
                  :groups="dataSource.Groups"
                  :name="dataSource.Name"
                  :responsibles="dataSource.Objects"
                  :type="dataSource.TargetType"
                  :period="dataSource.Period"
                  :currentYear="currentYear"
                  :years="years"
                  :rules="backgroundRules"
                  @changeYear="changeYear"
                  :showObject="showObject"
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item key="Responsibles" class="mt-3">
            <v-col class="px-0 py-0" v-if="dataSource.TargetType !== 0">
              <statistic-indicator-setting-responsibles
                :value="dataSource.Objects"
                :type="dataSource.TargetType"
                :readonly="readonlyResponsibles"
              />
            </v-col>
            <v-col class="px-0 py-0" v-else>
              <div style="padding: 12px" class="block__content mb-3">
                <m-autocomplete
                  multiple
                  v-model="dataSource.ValueResponsibles"
                  :items="employees"
                  label="Ответственные"
                  return-object
                  filter="EmployeeActive"
                  dict="Employees"
                  :textCustomAdd="'Создать нового сотрудника'"
                  :readonly="readonlyResponsibles"
                ></m-autocomplete>
              </div>
            </v-col>
          </v-tab-item>
          <v-tab-item key="Settings" class="mt-3">
            <div style="padding: 12px" class="block__content mb-3">
              <v-row>
                <v-col cols="6">
                  <m-select
                    v-model="dataSource.SummaryType"
                    :items="StatisticIndicatorSummaryTypes"
                    label="Тип подсчета данных"
                    :clearable="false"
                    :readonly="readonlyBase"
                    item-text="value"
                    item-value="id"
                    required
                  ></m-select>
                </v-col>
                <v-col>
                  <v-switch
                    v-model="dataSource.AutoCreateValues"
                    label="Автоматическое создание периодов"
                    color="primary"
                    hide-details
                    :readonly="readonlyBase"
                  ></v-switch>
                </v-col>
              </v-row>
            </div>
            <v-row>
              <v-col cols="6">
                <statistic-indicator-setting-refs
                  :RefValue1.sync="dataSource.RefValue1"
                  :RefValue2.sync="dataSource.RefValue2"
                  :RefValue3.sync="dataSource.RefValue3"
                  :RefDescription1.sync="dataSource.RefDescription1"
                  :RefDescription2.sync="dataSource.RefDescription2"
                  :RefDescription3.sync="dataSource.RefDescription3"
                  :RefDescription4.sync="dataSource.RefDescription4"
                  :RatingType="dataSource.RatingType"
                  :readonly="readonlyBase"
                  ref="statisticIndicatorSettingRefs"
                />
              </v-col>
              <v-col cols="6">
                <statistic-indicator-setting-calc
                  :value="dataSource"
                  :readonly="readonlyBase"
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item key="Organization" class="mt-3">
            <list-organization
              :value="dataSource.Organizations"
              :show-add-in-table="true"
            ></list-organization>
          </v-tab-item>
        </v-tabs-items>
      </base-panel>
    </v-form>
  </base-detail-view>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import StatisticIndicatorService from "../../services/StatisticIndicatorService";
import { defaultStatisticIndicator } from "../../data/defaultObjects";
import Permissions from "../../data/permissions";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";
import UnsavedMixin from "../../mixins/UnsavedMixin.js";
import BaseDetailView from "../../layouts/BaseDetailView.vue";

import MSelect from "../../components/base/inputs/mSelect.vue";
import mAutocomplete from "../../components/base/inputs/mAutocomplete.vue";
import {
  StatisticIndicatorRatingType,
  StatisticIndicatorTargetType,
  StatisticIndicatorPeriod,
  StatisticIndicatorSummaryTypes,
  StatisticIndicatorType,
} from "../../data/enums";
import BasePanel from "../../layouts/BasePanel.vue";
import StatisticIndicatorValues from "../../components/statisticIndicator/statisticIndicatorValues.vue";
import StatisticIndicatorValuesChart from "../../components/statisticIndicator/statisticIndicatorValuesChart.vue";
import statisticIndicatorSettingResponsibles from "../../components/statisticIndicator/statisticIndicatorSettingResponsibles.vue";
import statisticIndicatorSettingRefs from "../../components/statisticIndicator/statisticIndicatorSettingRefs.vue";
import statisticIndicatorSettingCalc from "../../components/statisticIndicator/statisticIndicatorSettingCalc.vue";
import statisticIndicatorValueDetail from "../../components/statisticIndicator/statisticIndicatorValueDetail.vue";
import ListOrganization from "../../components/organization/ListOrganization.vue";
import DataHelper from "../../utils/DataHelper";
import BaseDatePickerRange from "@/components/base/BaseDatePickerRange.vue";
import mTabs from "@/components/base/tabs/mTabs";

export default {
  name: "view-StatisticIndicatorEdit",
  components: {
    BaseDetailView,
    MSelect,
    mAutocomplete,
    BasePanel,
    StatisticIndicatorValues,
    StatisticIndicatorValuesChart,
    statisticIndicatorSettingRefs,
    statisticIndicatorSettingResponsibles,
    statisticIndicatorSettingCalc,
    statisticIndicatorValueDetail,
    BaseDatePickerRange,
    mTabs,
    ListOrganization,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  provide() {
    return { AuditIndicatorEdit: this.init };
  },
  data: function () {
    return {
      apiService: StatisticIndicatorService,
      getDefaultObject: defaultStatisticIndicator,
      getObjectText: (obj, isNew) => (isNew ? "новый" : obj.Name),
      loading: true,
      valid: false,
      showObject: null,
      dataSource: {
        Name: "",
        PeriodDate: { DateIn: null, DateOut: null },
      },
      headers: [],
      rules: {
        required: (value) => !!value || "Укажите значение.",
        refRange: (value) =>
          (value >= 0 && value <= 100) || "Значение должно быть от 0 до 100",
        ref2: (value, val1) =>
          value === 0 ||
          value > parseFloat(val1) ||
          "Каждое значение должно быть последовательно больше предыдущего",
        ref3: (value, val2) =>
          value === 0 ||
          (val2 > 0 && value > parseFloat(val2)) ||
          "Каждое значение должно быть последовательно больше предыдущего",
      },
      employees: [],

      StatisticIndicatorRatingType,
      StatisticIndicatorTargetType,
      StatisticIndicatorPeriod,
      StatisticIndicatorSummaryTypes,
      StatisticIndicatorType,
      tab: null,
      groups: {},
      groupsOriginal: {},
      currentYear: null,
      colors1: ["rgba(249, 80, 80, 0.5)", "rgba(12, 168, 93, 0.5)"],
      colors2: [
        "rgba(249, 80, 80, 0.5)",
        "rgba(255, 200, 5, 0.5)",
        "rgba(12, 168, 93, 0.5)",
      ],
      colors3: [
        "rgba(249, 80, 80, 0.5)",
        "rgba(255, 200, 5, 0.5)",
        "rgba(12, 168, 93, 0.5)",
        "rgba(0, 160, 160, 50%)",
      ],
      showYear: moment().year(),
      otherMedicalOrganization: false,
    };
  },

  computed: {
    hasStatisticIndicatorEdit() {
      return this.hasPermission(Permissions.StatisticIndicatorEdit);
    },
    readonly() {
      return !!this.dataSource?.Archived;
    },
    readonlyBase() {
      return !!(
        !this.hasPermission(Permissions.StatisticIndicatorEdit) ||
        this.dataSource?.Archived ||
        this.dataSource?.OnlyRead
      );
    },

    readonlyItemValue() {
      return (item) => {
        return !!(this.dataSource?.Archived || item.OnlyRead
          ? item.OnlyRead
          : false);
      };
    },
    readonlyResponsibles() {
      return !!(
        !this.hasPermission(Permissions.StatisticIndicatorEdit) ||
        this.dataSource?.Archived
      );
    },
    typeOrganization() {
      return this.$store.getters.GET_ORGANIZATION.OwnerType;
    },
    dates: {
      get() {
        let result = [];
        if (this.dataSource) {
          const start = this.dataSource.PeriodDate.DateIn;
          const end = this.dataSource.PeriodDate.DateOut;
          result = [start, end];
        }
        return result.filter((e) => e);
      },
      set(value) {
        if (!value || value.length === 0) {
          this.dataSource.PeriodDate.DateIn = null;
          this.dataSource.PeriodDate.DateOut = null;
        } else if (value?.length === 1) {
          this.dataSource.PeriodDate.DateIn = value[0];
          this.dataSource.PeriodDate.DateOut = null;
        } else if (value?.length === 2) {
          this.dataSource.PeriodDate.DateIn = value[0];
          this.dataSource.PeriodDate.DateOut = value[1];
        }
      },
    },
    years() {
      const years = [];
      for (const key of Object.keys(this.groupsOriginal)) {
        for (const item of this.groupsOriginal[key].list) {
          const year = DataHelper.toDate(item.PeriodDate.DateIn).getFullYear();
          years.push(year);
        }
      }
      return Array.from(new Set(years));
    },
    backgroundRules() {
      const val = [
        this.dataSource.RefValue1,
        this.dataSource.RefValue2,
        this.dataSource.RefValue3,
      ];
      const values = val.filter((ref) => ref || ref === 0).length;
      const rangeArr = [];
      switch (values) {
        case 1: {
          const colors1_ = this.dataSource.RatingType
            ? [...this.colors1]
            : [...this.colors1].reverse();
          rangeArr.push(
            {
              yMin: -Infinity,
              yMax: val[0],
              backgroundColor: colors1_[0],
            },
            {
              yMin: val[0],
              yMax: Infinity,
              backgroundColor: colors1_[1],
            }
          );
          break;
        }
        case 2: {
          const colors2_ = this.dataSource.RatingType
            ? [...this.colors2]
            : [...this.colors2].reverse();
          rangeArr.push(
            {
              yMin: -Infinity,
              yMax: val[0],
              backgroundColor: colors2_[0],
            },
            {
              yMin: val[0],
              yMax: val[1],
              backgroundColor: colors2_[1],
            },
            {
              yMin: val[1],
              yMax: Infinity,
              backgroundColor: colors2_[2],
            }
          );
          break;
        }

        case 3: {
          const colors3_ = this.dataSource.RatingType
            ? [...this.colors3]
            : [...this.colors3].reverse();
          rangeArr.push(
            {
              yMin: -Infinity,
              yMax: val[0],
              backgroundColor: colors3_[0],
            },
            {
              yMin: val[0],
              yMax: val[1],
              backgroundColor: colors3_[1],
            },
            {
              yMin: val[1],
              yMax: val[2],
              backgroundColor: colors3_[2],
            },
            {
              yMin: val[2],
              yMax: Infinity,
              backgroundColor: colors3_[3],
            }
          );
          break;
        }

        default:
          break;
      }
      return rangeArr;
    },
  },
  watch: {
    showYear() {
      this.init();
    },
  },
  async created() {
    this.employees = await this.LOAD_EMPLOYEES();
  },
  async mounted() {
    await this.init();
    if (this.dataSource?.Organizations?.length > 0) {
      this.otherMedicalOrganization = true;
    }
  },
  methods: {
    ...mapActions(["LOAD_EMPLOYEES"]),
    async refreshData() {
      await this.init();
      this.listViewDetail.listViewInit(); // todo: Переделать на опуцию для обновления через init(); Либо обновить 1 элемент, либо обновить все списочное представление
    },
    DataFromServerNormalize(data) {
      if (data.Groups) {
        data.MaxGroups = Math.max(...data.Groups.map((o) => o.Value));
        data.Groups = data.Groups.reverse();
      }
      return data;
    },
    async archive() {
      const { data } = await StatisticIndicatorService.archive(
        this.dataSource.Id
      );
      this.dataSource.Archived = data.Archived;
      this.originObject.Archived = data.Archived;
      this.applyListViewItemUpdateFunc();
    },
    async save() {
      if (!(await this.baseSave())) return;

      await this.init();
      if (this.$refs.statisticIndicatorSettingRefs)
        this.$refs.statisticIndicatorSettingRefs.resetEdit();
    },
    async createValues() {
      const lastPeriod = this.dataSource.Groups[0];
      if (lastPeriod && lastPeriod.Value == null) {
        this.$alert(
          "Нельзя создавать новый период без данных в предыдущем периоде."
        );
      } else {
        await StatisticIndicatorService.createValues(this.dataSource.Id);
        this.init();
      }
    },
    async init() {
      await this.baseInit({ year: this.showYear });
      const periodStartYear = moment(
        this.dataSource.PeriodDate.DateIn,
        "DD.MM.YYYY"
      ).year();
      const periodEndYear = moment(
        this.dataSource.PeriodDate.DateOut,
        "DD.MM.YYYY"
      ).year();
      if (
        this.dataSource.PeriodDate.DateIn !== null &&
        this.showYear < periodStartYear
      ) {
        this.showYear = periodStartYear;
      }
      if (
        this.dataSource.PeriodDate.DateOut !== null &&
        this.showYear > periodEndYear
      ) {
        this.showYear = periodEndYear;
      }
    },
    changeYear(value) {
      if (!value) {
        this.init();
        return;
      }
      const groupsClone = this._.cloneDeep(this.groupsOriginal);
      for (const key of Object.keys(groupsClone)) {
        const res = groupsClone[key].list.filter(
          (l) => DataHelper.toDate(l.PeriodDate.DateIn).getFullYear() === value
        );

        if (res.length) {
          groupsClone[key].list = res;
        } else {
          delete groupsClone[key];
        }
        this.groups = { ...groupsClone };
        this.currentYear = value;
      }
    },
  },
};
</script>
