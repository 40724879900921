var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analysis-tfoms__table analysis-tfoms__base-table analysis-tfoms__table-total"},[_c('base-table',{attrs:{"items":_vm.data,"headers":[
      { text: 'Период', value: 'Name', sortable: false },

      { text: 'План', value: 'FinancialPlanValue', align: 'right' },
      {
        text: 'Факт',
        value: 'FinancialValue',
        align: 'right',
        cellClass: 'text-no-wrap',
      },
      { text: 'Выполнение', value: 'FinancialValuePercent', align: 'right' },
      {
        text: 'Неоплата',
        value: 'NonPaymentValue',
        align: 'right',
        cellClass: 'error--text',
      },
      {
        text: 'Штрафы',
        value: 'FineValue',
        align: 'right',
        cellClass: 'error--text',
      },
      {
        text: 'Санкции',
        value: 'SanctionValue',
        align: 'right',
        cellClass: 'error--text',
      },
    ],"hide-default-footer":"","notShowSelect":"","not-filter":"","exclude-settings-actions":['table', 'group'],"nested":"","item-class":(item) => {
        return _vm.value && _vm.value.Id === item.Id ? 'font-weight-bold' : '';
      }},on:{"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"}),_c('th',{staticClass:"text-center success--text",attrs:{"colspan":"3"}},[_vm._v(" Принято к оплате ")]),_c('th',{staticClass:"text-center error--text",attrs:{"colspan":"3"}},[_vm._v(" Финансовые санкции ")]),_c('th')])])]},proxy:true},{key:"item.FinancialValuePercent",fn:function({ item }){return [_vm._v(" "+_vm._s(item.FinancialValuePercent)+"% ")]}},{key:"item.FineValue",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("numberSplit")(item.FineValue))+" ")]}},{key:"item.SanctionValue",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("numberSplit")(item.SanctionValue))+" ")]}},{key:"item.FinancialPlanValue",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("numberSplit")(item.FinancialPlanValue))+" ")]}},{key:"item.NonPaymentValue",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("numberSplit")(item.NonPaymentValue))+" ")]}},{key:"item.FinancialValue",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("numberSplit")(item.FinancialValue))),(item.FinancialValue > item.FinancialPlanValue)?_c('m-icon',{attrs:{"color":"success","icon":'mdi-arrow-up-thin'}}):_vm._e(),(item.FinancialValue < item.FinancialPlanValue)?_c('m-icon',{attrs:{"color":"error","icon":'mdi-arrow-down-thin'}}):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }