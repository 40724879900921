<template>
  <td class="table-expanded" v-bind:colspan="colspan">
    <slot></slot>
  </td>
</template>

<script>
export default {
  props: {
    colspan: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="sass">
.table-expanded
  border-bottom: solid 1px red
  padding: 15px 50px 30px 50px !important
  td:first-child
    padding-right: 20px
    white-space: nowrap
    width: 1%
    vertical-align: top
  tr td
    padding-top: 15px
  tr:first-child
    width: 1%
  table
    width: 100%
</style>
