<template>
  <v-layout>
    <base-detail-view
      :loading="loading"
      :title="
        this.type === 'create'
          ? this.currentRouter.query.external
            ? 'Создание внешней проверки'
            : 'Создание проверки'
          : this.dataSource.External
          ? 'Внешняя проверка №' + (dataSource.Num || '')
          : 'Проверка №' + (dataSource.Num || '')
      "
      :isUnsaved="isUnsaved && valid"
      :tooltipRefresh="'Обновить экран. Внимание, несохраненные изменения будут потеряны'"
      @onSave="save"
      @onRefresh="init"
    >
      <template slot="subTitle">
        <span
          :class="{
            'caption ml-5': true,
            'status-audit-operation--new': dataSource.Status === 0,
            'status-audit-operation--performs': dataSource.Status === 1,
            'status-audit-operation--onsign': dataSource.Status === 2,
            'status-audit-operation--completed': dataSource.Status === 3,
          }"
          >{{ dataSource.Status | getEnums("AuditOperationStatus") }}
        </span>
      </template>
      <v-btn
        v-if="dataSource.Status !== 0"
        slot="action"
        title="Показать результаты проверки"
        icon
        @click="openAnalysis()"
      >
        <m-icon icon="tool-analysis" s16></m-icon>
      </v-btn>
      <report-activator
        slot="action"
        :report="
          dataSource.External
            ? [
                'ExternalAuditOperationReport',
                'ExternalAuditOperationObjectsReport',
                'ExternalAuditOperationReportByIndicators',
              ]
            : [
                'AuditOperationReport',
                'AuditOperationObjectsReport',
                'AuditOperationReportByIndicators',
                'CorrectiveActionListReport',
                'CorrectiveActionPlanReport',
                'CorrectiveActionPlanExecutionReport',
                'AuditOperationSummaryReport',
              ]
        "
        :params="{
          objectId: { name: 'pAuditOperation', value: $route.params.objectId },
        }"
      />
      <template v-if="isAuditor">
        <v-btn
          v-if="
            isAuditor &&
            !isChairman &&
            isAuditor.HasSign &&
            dataSource.Status !== 3
          "
          slot="action"
          @click="revokeSign()"
          dark
          color="primary"
          class="ml-4"
          title="Отозвать подпись"
          >Отозвать подпись</v-btn
        >
        <members-with-sign
          v-if="
            (dataSource.Status === 2 ||
              (dataSource.Status === 1 &&
                dataSource.Members.some((m) => m.HasSign))) &&
            isChairman
          "
          :disabled="
            isAuditor &&
            !isChairman &&
            !dataSource.Members.some((m) => m.HasSign)
          "
          slot="action"
          :members="membersSign"
          :Id="dataSource.Id"
          @update-audit="init"
        >
        </members-with-sign>
        <v-btn
          v-if="dataSource.Status === 1 && isChairman"
          slot="action"
          @click="abortOperation()"
          dark
          color="primary"
          class="ml-4"
          title="Прервать проведение проверки. Все результаты проверки будут удалены"
          >Прервать</v-btn
        >
        <v-btn
          v-if="
            (dataSource.Status === 1 || dataSource.Status === 2) && isAuditor
              ? !isAuditor.HasSign
              : false
          "
          :disabled="!isOpenSign"
          slot="action"
          @click="sign()"
          dark
          color="primary"
          class="ml-4"
          title="Подписать проверку"
        >
          <m-icon icon="tool-edit" left size="18"></m-icon>
          Подписать
        </v-btn>

        <v-btn
          v-if="dataSource.Status === 0"
          :disabled="isUnsaved"
          slot="action"
          @click="setStatus(2)"
          dark
          color="primary"
          class="ml-4"
          title="Начать проведение проверки"
          >Начать</v-btn
        >
      </template>
      <v-btn
        v-if="
          dataSource.Status === 3 && isAllProjectsEdit && !readonlyExternalOwner
        "
        slot="action"
        dark
        color="primary"
        class="ml-4"
        title="Создать план"
        @click="createPlan"
        >Создать план</v-btn
      >
      <template v-if="!readonlyExternalChild">
        <v-btn
          v-if="dataSource.Status === 2 && isChairman"
          slot="action"
          @click="setStatus(5)"
          dark
          color="primary"
          class="ml-4"
          title="Завершить проверку"
          >Завершить</v-btn
        >
        <v-btn
          v-if="dataSource.Status === 3 && isChairman"
          slot="action"
          @click="setStatus(6)"
          dark
          color="primary"
          class="ml-4"
          title="Открыть проверку"
          >Открыть</v-btn
        >
      </template>
      <v-form
        class="block__content"
        v-model="valid"
        :readonly="readonly"
        title=""
      >
        <v-container>
          <v-row>
            <v-col>
              <v-row>
                <v-col>
                  <base-date-picker-range
                    v-model="dates"
                    label="Период выполнения проверки"
                    :title="'Установить период проведения проверки. Период не должен превышать 10-ти рабочих дней'"
                    :readonly="readonly || readonlyExternalChild"
                    required
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col v-if="!readonly">
                  <v-text-field
                    v-model="dataSource.Num"
                    type="number"
                    placeholder="Номер"
                    label="Номер проверки"
                    :rules="[rules.maxNumber]"
                    counter="9"
                    :readonly="readonlyExternalChild"
                  ></v-text-field>
                </v-col>
              </v-row>
              <div style="height: 14px"></div>
              <m-autocomplete
                :value="auditors"
                multiple
                label="Аудиторы"
                deletable-chips
                return-object
                :items="readonlyExternalChild ? auditors : employees"
                :clearable="!(readonly || readonlyExternalChild)"
                :fetch-clear="fetchClearAuditor"
                @change="changeAuditor"
                required
                :rules="[rules.required]"
                :chipColor="signColor"
                title="Выбрать аудиторов (членов комиссии)"
                :filter="['EmployeeActive', employeesInAuditorsFilter]"
                :dict="{ name: 'Employees', notItems: true }"
                :textCustomAdd="'Создать нового сотрудника'"
                :readonly="readonly || readonlyExternalChild"
              />
              <m-select
                v-model="chairman"
                :items="auditors"
                :chipColor="signColor"
                no-data-text="Аудиторы не выбраны!"
                label="Председатель"
                required
                :rules="[rules.required]"
                :isAddItem="false"
                chips
                title="Выбрать председателя комиссии"
                :clearable="!(readonly || readonlyExternalChild)"
                :dict="{ name: 'Employees', notItems: true }"
                :readonly="readonly || readonlyExternalChild"
              ></m-select>
              <v-row
                v-if="
                  currentRouter.query.external ||
                  (dataSource.External &&
                    dataSource.Owner.Id === $store.state.Organization.OwnerId)
                "
                ><v-col>
                  <m-autocomplete
                    v-model="dataSource.Organization"
                    :items="listOrganizations"
                    item-text="Name"
                    label="Медицинская организация"
                    required
                    :rules="[rules.required]"
                    :isAddItem="false"
                    title="Выбрать медицинскую организацию"
                    :clearable="!(readonly || readonlyExternalChild)"
                    return-object
                    :readonly="readonly"
                  ></m-autocomplete>
                </v-col>
                <v-col>
                  <v-checkbox
                    class="mt-4"
                    v-model="dataSource.ExternalShared"
                    hide-details
                    :label="'Информировать МО'"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <m-select
                v-if="
                  dataSource.External &&
                  dataSource.Owner &&
                  dataSource.Owner.Id !== $store.state.Organization.OwnerId
                "
                v-model="dataSource.Owner"
                :items="owner"
                item-text="Name"
                label="Головная организация"
                :isAddItem="false"
                title="Проверяющая медицинскую организацию"
                :clearable="false"
                :readonly="readonly || readonlyExternalChild"
              ></m-select>
              <m-autocomplete
                v-model="observers"
                label="Присутствующие"
                deletable-chips
                multiple
                :items="readonlyExternalOwner ? observers : employees"
                :clearable="!(readonly || readonlyExternalOwner)"
                title="Выбрать сотрудников, которые должны присутствовать при проведении проверки"
                :filter="['EmployeeActive', employeesInObserversFilter]"
                :dict="{ name: 'Employees', notItems: true }"
                :textCustomAdd="'Создать нового сотрудника'"
                return-object
                :readonly="readonly || readonlyExternalOwner"
              ></m-autocomplete>
            </v-col>
            <v-col>
              <v-textarea
                auto-grow
                rows="1"
                v-model="dataSource.Subject"
                label="Предмет проверки"
                title="Ввести название предмета проверки"
                required
                :rules="[rules.required]"
                v-up-first-symbol
                maxlength="500"
                counter="500"
                :readonly="readonly || readonlyExternalChild"
              ></v-textarea>
              <m-select
                class="mt-2"
                v-model="dataSource.Kind"
                :items="EnumsHelper.getEnumsValues('AuditOperationKinds')"
                item-text="value"
                item-value="id"
                label="Вид проверки"
                title="Выбрать вид проверки (плановая, внеплановая, пользовательская)"
                required
                :clearable="false"
                :readonly="readonly || readonlyExternalChild"
              ></m-select>
              <m-select
                class="mt-2"
                :value="dataSource.ObjectType"
                @input="selectedType"
                :items="
                  EnumsHelper.getEnumsValues('CheckListObjectTypes').filter(
                    (item) =>
                      !dataSource.External || item.id === 0 || item.id === 4
                  )
                "
                item-text="value"
                item-value="id"
                label="Объект проверки"
                title="Выбрать объект проверки (организация, произвольный объект, сотрудник, структурное подразделение)"
                required
                :clearable="false"
                :readonly="readonly || readonlyExternalChild"
              ></m-select>
              <v-textarea
                class="mt-3"
                rows="1"
                auto-grow
                height="auto"
                v-model="dataSource.Reason"
                label="Основание проверки"
                counter="500"
                maxlength="500"
                title="Ввести основание для проведения проверки"
                v-up-first-symbol
                :readonly="readonly || readonlyExternalChild"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <m-tabs v-model="tab">
        <v-tab key="CheckLists">Чек-листы</v-tab>
        <v-tab key="Equipments" class="audit-opertion-edit__tab-end"
          >Заключение по проверке</v-tab
        >
        <v-tab key="Files">Файлы</v-tab>
        <v-tab v-if="!readonlyExternalChild" key="History"
          >История изменений</v-tab
        >
      </m-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item key="CheckLists" class="audit-operation-edit__operation">
          <check-operation
            v-if="!loading"
            class="mt-2"
            :CheckOperations="dataSource.CheckOperations"
            :auditors="auditors"
            :readonly="readonly || readonlyExternalChild"
            :readonly-change-objects="
              readonlyChangeObjects || readonlyExternalChild
            "
            :isUnsaved="isUnsaved"
            @updateStatus="updateStatusCheckOperation"
            @add="addCheckOperation"
            :refValues="refValues"
            :selected-object-type="dataSource.ObjectType"
          />
        </v-tab-item>
        <v-tab-item key="Equipments" class="mt-2">
          <v-form class="block__content" :readonly="!isAuditor">
            <v-container>
              <v-row>
                <v-col>
                  <v-textarea
                    v-model="dataSource.Conclusion"
                    placeholder="Заключение по проверке"
                    maxlength="2000"
                    counter
                    auto-grow
                    rows="3"
                    v-up-first-symbol
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-tab-item>
        <v-tab-item key="Files">
          <fileLibrary
            :files.sync="dataSource.Files"
            :showAddText="false"
            :id="dataSource.Id"
            :init="init"
            :save="save"
            :title="titleFile"
            nameButtonAddFile="Добавить файл"
            @showDocument="showDocument = $event"
            ref="fileLibrary"
            :showAdd="!readonlyExternalChild"
            :not-show-delete="readonlyExternalChild"
            :disabledActions="
              dataSource.Status === 2 || dataSource.Status === 3
                ? 'Операция должна находится в состоянии \'Новый\' или \'Выполняется\''
                : false
            "
          >
            <template
              v-if="readonlyExternalChild && dataSource.Files.length === 0"
              slot="info"
            >
              <div style="padding: 50px 0; text-align: center">Нет файлов</div>
            </template>
            <template v-else slot="info">
              <div style="padding: 50px 0; text-align: center">
                Добавьте файл готового документа в формате word или pdf
              </div>
            </template>
          </fileLibrary>
        </v-tab-item>
        <v-tab-item key="History" class="audit-operation-edit__operation">
          <history-of-changes
            v-if="!loading"
            class="mt-2"
            :History="dataSource.History"
            :isUnsaved="isUnsaved"
          />
        </v-tab-item>
      </v-tabs-items>
    </base-detail-view>
    <replace-auditor
      v-model="replaceAuditor.dialog"
      :checkLists="replaceAuditor.checkLists"
      :member="replaceAuditor.member"
      :selected-members="dataSource.Members"
      @updateAuditor="updateAuditor"
    />
    <informer v-if="isAuditor && dataSource.Status === 3">
      Проверка завершена.<br />
      Изменение результатов завершенной проверки недопустимо!
    </informer>
    <informer
      type="warning"
      v-else-if="isAuditor && isAllSign && !(dataSource.Status === 3)"
    >
      Проверка уже подписана. Внесение изменений запрещено.
    </informer>

    <informer
      type="warning"
      v-else-if="dataSource.CurrentMember && dataSource.CurrentMember.HasSign"
    >
      Проверка подписана. Внесение изменений невозможно.<br />
      Для внесения изменений отзовите подпись.
    </informer>
  </v-layout>
</template>

<script>
import _ from "lodash";
import { sortBy } from "@/utils/Functions";

import AuditOperationService from "@/services/AuditOperationService";
import OrganizationService from "@/services/OrganizationService";
import CheckOperation from "@/components/AuditOperation/CheckOperation";
import HistoryOfChanges from "@/components/AuditOperation/HistoryOfChanges";
import { mapActions } from "vuex";
import BaseDetailView from "../../layouts/BaseDetailView.vue";
import UnsavedMixin from "../../mixins/UnsavedMixin.js";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";
import BaseDatePickerRange from "../../components/base/BaseDatePickerRange.vue";
import {
  dafaultAuditOperation,
  defaultCheckOperation,
} from "../../data/defaultObjects";
import ReportActivator from "../../components/report/ReportActivator.vue";
import mSelect from "../../components/base/inputs/mSelect";
import mAutocomplete from "../../components/base/inputs/mAutocomplete";
import { CheckOperationsNormalize } from "../../utils/Normalize";
import Informer from "../../modules/VuetifyInformer/informer.vue";

import DataHelper from "../../utils/DataHelper";
import Permissions from "../../data/permissions";
import ReplaceAuditor from "../../components/AuditOperation/ReplaceAuditor.vue";
import MembersWithSign from "../../components/AuditOperation/MembersWithSign.vue";
import AnalysisSettingsService from "../../services/AnalysisSettingsService";
import mTabs from "@/components/base/tabs/mTabs";
import fileLibrary from "@/components/FileLibrary.vue";

export default {
  name: "view-AuditOperationEdit",
  components: {
    CheckOperation,
    HistoryOfChanges,
    BaseDetailView,
    BaseDatePickerRange,
    ReportActivator,
    mSelect,
    mAutocomplete,
    ReplaceAuditor,
    MembersWithSign,
    mTabs,
    Informer,
    fileLibrary,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  data() {
    return {
      rules: {
        required: (value) => !!value || "Укажите значение.",
        maxNumber: (value) =>
          String(value).length < 10 || "Недопустимое значение",
      },
      valid: true,
      // Api
      apiService: AuditOperationService,
      getDefaultObject: dafaultAuditOperation,
      getObjectText: (obj, isNew) => {
        return this.type === "copy" ? "копия" : isNew ? "новая" : "№" + obj.Num;
      },

      loading: true,
      // Слой визуальных данных
      auditors: [],
      observers: [],
      chairman: null,
      employees: [],
      partitions: [],
      owner: [],
      dataSource: {
        CheckOperations: [],
        Members: [],
        Period: { DateIn: null, DateOut: null },
        Subject: "",
        Organization: null,
        Kind: null,
        Reason: null,
        Comment: null,
        ObjectType: null,
      },
      initDate: false,
      replaceAuditor: {
        dialog: false,
        checkLists: [],
        member: null,
      },
      refValues: {},
      tab: null,
      showDocument: {},
      listOrganizations: [],
    };
  },

  computed: {
    membersSign() {
      return this.dataSource.Members.filter((a) => a.HasSign).sort((x, y) =>
        x.Employee?.LastName.localeCompare(y.Employee?.LastName)
      );
    },
    isOpenSign() {
      let open = false;
      const userCheckList = this.dataSource.CheckOperations.filter(
        (e) =>
          e.Auditors.findIndex(
            (e2) => e2?.Employee?.Id === this.USER?.Employee?.Id
          ) >= 0
      );
      if (userCheckList.length > 0) {
        if (userCheckList.every((e) => e.Status === 2)) open = true;
      } else {
        open = true;
      }
      return open;
    },
    isCompleted() {
      return !this.dataSource.CheckOperations.find((e) => e.Status !== 2);
    },
    isAuditor() {
      return this.dataSource.Members.find(
        (e) =>
          e.Employee.Id === this.$store.getters.GET_USER.Employee.Id &&
          (e.Role === 0 || e.Role === 1)
      );
    },
    isChairman() {
      return (
        this.dataSource.Members.find(
          (e) =>
            e.Employee.Id === this.$store.getters.GET_USER.Employee.Id &&
            e.Role === 1
        ) || this.hasPermission(Permissions.AuditOperationsEditHowAuditor)
      );
    },
    isAllProjectsEdit() {
      return this.hasPermission(Permissions.AllProjectsEdit);
    },
    readonly() {
      if (
        (this.dataSource.CurrentMember &&
          this.dataSource.CurrentMember.HasSign) ||
        this.dataSource.Status === 3
      ) {
        return true;
      }

      const chairman = this.originObject?.Members.find((i) => i.Role === 1)
        ?.Employee.Id;

      switch (this.dataSource.Status) {
        case 0:
          return !this.hasPermission(Permissions.AuditOperationsEdit);
        case 1:
          return (
            chairman !== this.$store.getters.GET_USER.Employee.Id &&
            !this.isChairman
          );
      }
      return true;
    },
    readonlyExternalOwner() {
      return (
        this.dataSource.External &&
        this.$store.state.Organization.OwnerType === 2
      );
    },
    readonlyExternalChild() {
      return (
        this.dataSource.External &&
        this.$store.state.Organization.OwnerType === 1
      );
    },
    readonlyChangeObjects() {
      switch (this.dataSource.Status) {
        case 0:
          return !this.hasPermission(Permissions.AuditOperationsEdit);
        case 1:
          return !this.isAuditor;
      }
      return true;
    },
    isAllSign() {
      let result = false;
      if (this.dataSource.Members && this.dataSource.Members.length > 0) {
        result = this.dataSource.Members.every((auditor) => auditor.HasSign);
      }
      return result;
    },
    dates: {
      get() {
        let result = [];
        if (this.dataSource) {
          const start = this.dataSource.Period.DateIn;
          const end = this.dataSource.Period.DateOut;
          if (start && end) result = [start, end];
        }
        return result;
      },
      set(value) {
        if (!value || value.length === 0) {
          this.dataSource.Period.DateIn = null;
          this.dataSource.Period.DateOut = null;
        } else if (value?.length === 1) {
          this.dataSource.Period.DateIn = value[0];
          this.dataSource.Period.DateOut = DataHelper.toServerDateTime(
            DataHelper.AddWorkingDays(DataHelper.toDate(value[0]), 9)
          );
        } else if (value?.length === 2) {
          this.dataSource.Period.DateIn = value[0];
          this.dataSource.Period.DateOut = value[1];

          const dateIn = DataHelper.toDate(value[0]);
          const dateOut = DataHelper.toDate(value[1]);
          const dateInPlus10 = DataHelper.AddWorkingDays(dateIn, 10);

          if (dateOut >= dateInPlus10) {
            this.$alert(
              "Установлено более 10-ти рабочих дней на выполнения проверки"
            );
          }
        }
      },
    },
    titleFile() {
      let result = "";
      const fileData = this.dataSource.Files?.find(
        (file) => file.Id === this.showDocument.id
      );
      if (fileData) {
        result = [
          fileData.Employee?.LastName,
          fileData.Employee?.FirstName,
          fileData.Employee?.MiddleName,
        ]
          .filter(Boolean)
          .join(" ");
      }

      return result;
    },
  },
  watch: {
    "$store.state.employees": function (val) {
      this.employees = val;
    },
    "$store.state.partitions": function (val) {
      this.partitions = val;
    },
    auditors: function (val) {
      if (val.length === 0) this.chairman = null;
    },
  },
  async mounted() {
    try {
      this.employees = await this.LOAD_EMPLOYEES();
      this.partitions = await this.LOAD_PARTITIONS();
    } catch (error) {
      console.log(error);
    }
    await this.init();

    // Установка номера по умолчанию
    if (!this.dataSource.Num) {
      try {
        const { data } = await AuditOperationService.getAuditOperationNum(
          DataHelper.toDate(this.dataSource.Period.DateIn).getFullYear()
        );
        this.dataSource.Num = data;
      } catch (error) {
        console.log(error);
      }
    }
    // Установка внешней проверки в состояние 'true'
    if (this.currentRouter?.query?.external === true) {
      this.dataSource.External = true;
    }
    // Заполнение массива listOrganizations
    if (this.currentRouter?.query?.external || this.dataSource.External) {
      this.listOrganizations = (
        await OrganizationService.relatedOrganizations.get()
      ).data;
    }
    this.owner = await [this.dataSource.Owner];
  },
  methods: {
    ...mapActions(["LOAD_EMPLOYEES", "LOAD_PARTITIONS"]),
    createPlan() {
      this.$router.push({
        name: "PlanEdit",
        params: {
          objectId: 0,
        },
        query: {
          type: "create",
          AuditOperationId: this.dataSource?.Id,
        },
      });
    },
    employeesInAuditorsFilter(items) {
      return items.filter((elem) => {
        return this.observers.map((e) => e.Id).indexOf(elem.Id) === -1;
      });
    },
    employeesInObserversFilter(items) {
      return items.filter((elem) => {
        return this.auditors.map((e) => e.Id).indexOf(elem.Id) === -1;
      });
    },

    signColor(item) {
      const employee = this.auditors.find((e) => e.Id === item.Id);
      const member = this.dataSource.Members.find(
        (e) => e?.Employee.Id === employee.Id
      );

      const signed = member?.HasSign ?? false;
      return signed === undefined || signed === null
        ? "var(--v-blue-grey-lighten2)"
        : signed === false
        ? "var(--v-blue-grey-lighten2)"
        : "var(--v-success-lighten2)";
    },
    updateAuditor(params) {
      this.auditors = this.auditors.filter((e) => e.Id !== params.old);
      if (params.new)
        this.auditors.push(this.employees.find((e) => e.Id === params.new));
      this.init();
    },
    async changeAuditor(val) {
      const tmp = _.xor(this.auditors, val);
      const chengeItem = tmp.length ? tmp[0] : null;

      if (chengeItem && !val.find((e) => e.Id === chengeItem.Id)) {
        if (!(await this.fetchClearAuditor(chengeItem))) {
          val.push(chengeItem);
          return false;
        }
      }

      this.auditors = val;
    },

    async fetchClearAuditor(item) {
      const member = this.dataSource.Members.find(
        (e) => e?.Employee.Id === item.Id
      );

      if (!member) return true; // Можно удалять
      if (member.Employee.Id === this.chairman) {
        this.$alert("Председатель удалить нельзя");
        return false;
      }

      try {
        let { data } = await AuditOperationService.getAuditMemberOperations(
          member.Id
        );
        // Есть отвеченные вопросы
        if (data.length) {
          data = CheckOperationsNormalize(data);
          this.replaceAuditor.checkLists = data;
          this.replaceAuditor.dialog = true;
          this.replaceAuditor.member = member;
          return 0;
        } else return true; // Можно удалять
      } catch (error) {
        console.log(error);
        return false; // Нельзя
      }
    },

    updateStatusCheckOperation(params) {
      for (const n of [
        "AnsweredQuestionCount",
        "QuestionCount",
        "AnsweredYesPercent",
        "Status",
        "CheckStatus",
      ]) {
        this.dataSource.CheckOperations[params.index][n] = params.val[n];
        this.originObject.CheckOperations[params.index][n] = params.val[n];
      }
    },
    async setStatus(action) {
      if (
        action === 1 &&
        !(await this.$confirm(
          `Вы действительно хотите прервать проверку? <br/>  <br/> <b class='error--text'>Внимание!</b> <br/> <b class='error--text'>Все результаты проверки будут удалены!</b>`,
          { color: "error" }
        ))
      )
        return;
      const { data } = await AuditOperationService.updateStatus(
        this.dataSource.Id,
        action
      );
      if (data?.Status >= 0) {
        this.dataSource.Status = data.Status;
        this.originObject.Status = data.Status;
      }

      this.applyListViewItemUpdateFunc();
    },

    DataFromServerNormalize(data) {
      data.CheckOperations = CheckOperationsNormalize(data.CheckOperations);

      data.CheckOperations = data.CheckOperations.map((o) => ({
        ...o,
        Progress: null,
        Auditors: o.Auditors.sort((x, y) =>
          x.Employee?.LastName.localeCompare(y.Employee?.LastName)
        ),
      }));

      return data;
    },
    CopyDataNormalize(data) {
      const def = dafaultAuditOperation();
      data.Status = 0;
      data.Period = def.Period;
      data.Num = null;

      // Если дочка клонирует до задачу
      if (data.External && this.$store.state.Organization.OwnerType === 1) {
        data.Owner = null;
        data.Organization = null;
        data.Members = [];
        data.External = false;
      }

      if (data.CurrentMember) data.CurrentMember.HasSign = false;

      for (const checkOperation of data.CheckOperations) {
        checkOperation.Status = 0;
        checkOperation.TemplateId = checkOperation.Id;
        checkOperation.Id = -Math.round(Math.random() * 1000000);
      }

      for (const member of data.Members) {
        member.HasSign = false;
      }
      data.Id = -Math.round(Math.random() * 1000000);
      return data;
    },
    getActualDataSources(dataSource) {
      let newObj = {};
      newObj = this._.cloneDeep(dataSource);

      const auditors = [];
      const observers = [];

      for (const i in this.auditors) {
        auditors.push({
          Employee: this.auditors[i],
          Role: this.chairman !== this.auditors[i].Id ? 0 : 1,
          HasSign: newObj.Members.find(
            (a) => a.Employee?.Id === this.auditors[i].Id
          )?.HasSign,
        });
      }

      for (const i in this.observers) {
        observers.push({
          Employee: this.observers[i],
          Role: 2,
          HasSign: newObj.Members.find(
            (a) => a.Employee?.Id === this.observers[i].Id
          )?.HasSign,
        });
      }

      newObj.Members = [];
      newObj.Members.push(...auditors, ...observers);

      return newObj;
    },
    async init() {
      this.refValues = (await AnalysisSettingsService.show()).data;
      await this.baseInit();
      this.initDate = false;
    },
    updateDataView() {
      // dataSource.Members
      this.auditors = [];
      this.observers = [];
      for (const m in this.dataSource.Members) {
        const member = this.dataSource.Members[m];
        switch (member.Role) {
          case 0: // Аудитор
            this.auditors.push(member.Employee);
            break;
          case 1: // Председатель
            this.auditors.push(member.Employee);
            this.chairman = member.Employee.Id;
            break;
          case 2: // Наблюдатель
            this.observers.push(member.Employee);
            break;
        }
      }

      this.auditors = sortBy(this.auditors, (e) => {
        return this.$options.filters.PersonShortName(e);
      });
    },

    async save() {
      return await this.baseSave();
    },

    async sign() {
      await this.setStatus(3);
      await this.init();
    },

    async revokeSign() {
      await this.setStatus(4);
      await this.init();
    },

    async abortOperation() {
      await this.setStatus(1);
      await this.init();
    },

    openAnalysis() {
      this.$router.push({
        name: "AnalysisAudit",
        params: {
          objectId: this.dataSource.Id,
        },
      });
    },
    checkOperationCompare(ch1, ch2) {
      if (
        (ch1?.EmployeeObject &&
          ch1?.EmployeeObject?.Id === ch2?.EmployeeObject?.Id) ||
        (ch1?.PartitionObject &&
          ch1?.PartitionObject?.Id === ch2?.PartitionObject?.Id)
      ) {
        return true;
      } else if (
        typeof ch1?.CustomObject === "string" &&
        ch1?.CustomObject === ch2?.CustomObject
      ) {
        return true;
      } else if (this.dataSource.ObjectType === 0) return true;
      else {
        return false;
      }
    },
    addCheckOperation(value) {
      this.dialog = false;
      const res = [];

      for (const i in value) {
        for (let j = 0; j < value[i].count; j++) {
          const checkList = Object.assign({}, value[i]);
          delete checkList.count;
          delete checkList.objects;

          let checkOperation = null;

          if (j < value[i].objects.length) {
            const object = value[i].objects[j]; // Объект проверки
            if (
              this.dataSource.ObjectType === 1 ||
              this.dataSource.ObjectType === 3
            ) {
              // Ст. Подразделения
              checkOperation = defaultCheckOperation(checkList, {
                PartitionObject: object,
              });
            } else if (this.dataSource.ObjectType === 2) {
              // Сотрудники
              checkOperation = defaultCheckOperation(checkList, {
                EmployeeObject: object,
              });
            } else if (this.dataSource.ObjectType === 4) {
              // Произвольный объект
              checkOperation = defaultCheckOperation(checkList, {
                CustomObject: object,
              });
            }
          }
          if (!checkOperation) {
            checkOperation = defaultCheckOperation(checkList, {
              None: this.dataSource.ObjectType === 0,
            });
          }

          // Установка старого чек-листа
          const oldCheckList = this.dataSource.CheckOperations.find(
            (e) =>
              e.CheckList?.Id === checkOperation.CheckList?.Id &&
              this.checkOperationCompare(e, checkOperation)
          );
          if (oldCheckList) {
            res.push(oldCheckList);
          } else {
            res.push(checkOperation);
          }
        }
      }

      this.dataSource.CheckOperations = [];

      this.dataSource.CheckOperations =
        this.dataSource.CheckOperations.concat(res);
    },
    selectedType(index) {
      if (this.dataSource.ObjectType !== index) {
        const old = this.dataSource.ObjectType;
        this.dataSource.ObjectType = index;
        if (
          this.dataSource.CheckOperations.find(
            (e) => e?.CheckList?.ObjectType !== index
          )
        ) {
          this.$alert(
            "Для изменения объекта проверки, необходимо предварительно удалить выбранные чек-листы!"
          );
          this.$nextTick(() => {
            this.dataSource.ObjectType = old;
          });
        }
      }
    },
  },
};
</script>

<style>
.status-audit-operation--new.caption {
  color: var(--v-text-primary);
}

.status-audit-operation--performs.caption {
  color: var(--v-warning-base);
}

.status-audit-operation--onsign.caption {
  color: var(--v-warning-base);
}

.status-audit-operation--completed.caption {
  color: var(--v-success-base);
}

.audit-operation-edit__files .v-toolbar__title {
  font-weight: 400 !important;
  font-size: 14px !important;
  letter-spacing: normal !important;
  color: var(--v-primary-base) !important;
}
</style>
