import DataHelper from "../../utils/DataHelper";
import * as moment from "moment";

const addDiff = function (groups) {
  for (const key in groups) {
    for (let i = 0; i < groups[key].length; i++) {
      groups[key][i].diff = getOldItemValue(key, groups[key][i], groups);
    }

    const _sum = groups[key].reduce((sum, value) => sum + value.Value, 0);
    groups[key] = {
      list: groups[key],
      sum: _sum,
      diff: getOldGroupValue(key, _sum, groups),
    };
  }
};

const getOldGroupValue = function (group, value, groups) {
  const tmp = Object.keys(groups);
  const oldIndex = tmp.indexOf(group + "") - 1;

  if (oldIndex >= 0) {
    return parseFloat((value - groups[tmp[oldIndex]].PeriodValue).toFixed(2));
  }
  return 0;
};

const getOldItemValue = function (group, value, groups) {
  const tmp = Object.keys(groups);
  const oldIndex = tmp.indexOf(group + "") - 1;

  if (oldIndex >= 0) {
    const item = groups[tmp[oldIndex]].list.find(
      (e) =>
        (e.PartitionObject &&
          e.PartitionObject?.Id === value.PartitionObject?.Id) ||
        (e.EmployeeObject && e.EmployeeObject?.Id === value.EmployeeObject?.Id)
    );
    if (!item) return 0;
    return value.Value - item.Value;
  }
  return 0;
};

const getPeriodValue = function (date, period) {
  const f = "DD.MM.YYYY";
  const d = moment(date, f);
  const half = d.get("quarter") < 3 ? 1 : 2;

  switch (period) {
    case 1:
      return d.startOf("week").unix();
    case 2:
      return d.startOf("month").unix();
    case 3:
      return d.startOf("quarters").unix();
    case 4:
      return d
        .startOf("year")
        .add(half === 1 ? 0 : 6, "month")
        .unix();

    case 5:
      return d.startOf("year").unix();
    case 6:
      return d.startOf("day").unix();
  }
  return period;
};

const getPeriodText = function (date, period) {
  const f = "DD.MM.YYYY";
  const d = moment(date, f);
  const half = d.get("quarter") < 3 ? 1 : 2;

  switch (period) {
    case 1:
      return (
        '<span title="' +
        d.startOf("week").format(f) +
        " - " +
        d.endOf("week").format(f) +
        '">' +
        d.weeks() +
        " неделя" +
        "</span>"
      );
    case 2:
      return d.startOf("month").format("MMMM");
    case 3:
      return `${d.get("quarter")} квартал`;
    case 4:
      return (
        d
          .startOf("year")
          .add(half === 1 ? 0 : 6, "month")
          .format(f) +
        " - " +
        d
          .startOf("year")
          .add(half === 1 ? 6 : 12, "months")
          .add(-1, "day")
          .format(f)
      );
    case 5:
      return d.startOf("year").format("YYYY");
    case 6:
      return d.startOf("day").format("DD.MM.YYYY");
  }
  return period;
};

const normalizeIndicatorValues = function (data, period) {
  if (!data.length) return;

  for (let i = 0; i < data.length; i++) {
    // data[i].Date = data[i].Date.split(" ")[0];
    data[i].dateNow = data[i].Date
      ? DataHelper.toDate(data[i].Date).getTime()
      : 0;
  }
  data.sort(function (a, b) {
    if (a.dateNow < b.dateNow) return 1;
    if (a.dateNow > b.dateNow) return -1;
    return 0;
  });
  for (let i = 0; i < data.length; i++) {
    data[i].group = getPeriodValue(data[i].Date, period) + data[0].Id;
  }
  return data;
};

const getColorByRefs = function (
  refs,
  value,
  RatingType,
  colors = [" lighten-1 error", "warning", "success", "beautify"]
) {
  if (!refs) return "";
  const refsCount = refs.filter((r) => r || r === 0).length;
  if (!refsCount) return "";

  if (refsCount === 2) colors.pop();
  else if (refsCount === 1) colors = ["lighten-1 error", "success"];
  colors = RatingType ? colors : colors.reverse();

  if (value <= refs[0]) return colors[0];

  switch (refsCount) {
    case 1:
      return colors[1];
    case 2:
      if (value > refs[0] && value <= refs[1]) return colors[1];
      else if (value > refs[1]) return colors[2];
      break;
    case 3:
      if (value > refs[0] && value <= refs[1]) return colors[1];
      if (value > refs[1] && value <= refs[2]) return colors[2];
      if (value > refs[2]) return colors[3];
  }
};

const getColorHexByRefs = function (refs, value, RatingType) {
  let colors = ["#FB0404", "#FBB500", "#0CA85D", "#86EAE3"];
  const refsCount = refs.filter((r) => r || r === 0).length;
  if (!refsCount) return "#BBC6F0";

  if (refsCount === 2) colors.pop();
  else if (refsCount === 1) colors = ["#FB0404", "#0CA85D"];
  colors = RatingType ? colors : colors.reverse();

  if (value <= refs[0]) return colors[0];

  switch (refsCount) {
    case 1:
      return colors[1];
    case 2:
      if (value > refs[0] && value <= refs[1]) return colors[1];
      else if (value > refs[1]) return colors[2];
      break;
    case 3:
      if (value > refs[0] && value <= refs[1]) return colors[1];
      if (value > refs[1] && value <= refs[2]) return colors[2];
      if (value > refs[2]) return colors[3];
  }
};

const getColorDiff = function (value, RatingType) {
  let res = value < 0;
  res = RatingType === 0 ? res : !res;

  return value === 0 || value === null ? "grey" : res ? "success" : "error";
};

const getPeriod = function (val, period, plus, format = "DD.MM.YYYY") {
  const d = moment(val, format);

  if (period === 1) {
    return d.startOf("week").add("week", plus);
  } else if (period === 2) {
    return d.startOf("month").add("month", plus);
  } else if (period === 3) {
    return d.startOf("quarter").add("quarter", plus);
  } else if (period === 4) {
    const half = d.get("quarter") < 3 ? 1 : 2;

    return d
      .startOf("year")
      .add(6 * plus, "month")
      .add(half === 1 ? 0 : 6, "month");
  } else if (period === 5) {
    return d.startOf("year").add("year", plus);
  } else if (period === 6) {
    return d.startOf("day").add("day", plus);
  }
};

const allowedDates = function (val, resp, type, lastValues, period) {
  const valId = type === 2 ? "EmployeeObject" : "PartitionObject";
  const resId = type === 2 ? "Employee" : "Partition";

  const lastDate =
    type === 0
      ? lastValues[0]
      : lastValues.find((e) => e[valId].Id === resp[resId].Id);
  if (!lastDate) return true;

  const d = moment(lastDate.Date, "DD.MM.YYYY");
  const valDate = moment(val);

  if (period === 1) {
    return (
      d.startOf("week").add("week", 1) <= valDate &&
      valDate < d.startOf("week").add("week", 1)
    );
  } else if (period === 2) {
    return (
      d.startOf("month").add("month", 1) <= valDate &&
      valDate < d.startOf("month").add("month", 1)
    );
  } else if (period === 3) {
    return (
      d.startOf("quarter").add("quarter", 1) <= valDate &&
      valDate < d.startOf("quarter").add("quarter", 1)
    );
  } else if (period === 4) {
    const half = d.get("quarter") < 3 ? 1 : 2;

    return (
      d
        .startOf("year")
        .add(6, "month")
        .add(half === 1 ? 0 : 6, "month") <= valDate &&
      valDate <
        d
          .startOf("year")
          .add(6, "month")
          .add(half === 2 ? 0 : 6, "month")
    );
  } else if (period === 5) {
    return (
      d.startOf("year").add("year", 1) <= valDate &&
      valDate < d.startOf("year").add("year", 1)
    );
  } else if (period === 6) {
    return d.startOf("day").add("day", 1).unix() === valDate.unix();
  }
};

export default {
  getPeriod,
  allowedDates,
  getColorDiff,
  addDiff,
  normalizeIndicatorValues,
  getColorByRefs,
  getPeriodText,
  getPeriodValue,
  getColorHexByRefs,
};
