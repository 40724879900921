<template>
  <base-list-view
    title=""
    :dataSource="HistoryNormalize"
    :headers="headers"
    notShowSelect
    isNested
    header-hidden
    hide-default-footer
  >
  </base-list-view>
</template>

<script>
import BaseListView from "../../layouts/BaseListView.vue";
import { AuditOperationState } from "@/data/enums";
import { uniqBy } from "lodash";
import Vue from "vue";

export default {
  name: "view-HistoryOfChanges",
  components: {
    BaseListView,
  },
  props: {
    History: {
      type: Array,
      default: () => [],
    },
    isUnsaved: {
      type: Boolean,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Дата изменения",
          value: "Date",
          dataType: "Date",
          width: "1%",
          displayText: Vue.filter("DateTime"),
        },
        {
          text: "Инициатор изменения",
          value: "Employee",
          dataType: "object",
          width: "1%",
          align: "center",
          displayText: Vue.filter("PersonShortName"),
        },
        {
          text: "Состояние",
          value: "Status",
          dataType: "enum",
          width: "1%",
          align: "center",
          options: AuditOperationState,
        },
        {
          text: "Список файлов",
          value: "Files",
          dataType: "array",
          width: "1%",
          align: "center",
          displayText: (e) =>
            (e.Action === 1 ? "Добавлен файл: " : "Удален файл: ") + e.FileName,
        },
      ],
    };
  },
  computed: {
    HistoryNormalize() {
      return this.History.map((e) => {
        return { ...e, Members: uniqBy(e.Members, "Employee.Id") };
      }).reverse();
    },
  },
  watch: {},
  async created() {},
  methods: {},
};
</script>

<style></style>
