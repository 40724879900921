<template>
  <base-popup
    title="Настройки анализа показателей оценки качества"
    :icon="UtilGetIcon('view-AuditIndicators')"
    @save="save"
    v-model="dialog"
    :width="width"
    :height="height"
    buttonSaveText="Применить"
  >
    <v-form v-model="valid" class="block__content">
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
              v-model="dataSource.RefValue1"
              type="number"
              label="Референтное значение 1"
              :rules="[rules.required]"
              :readonly="readonly"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="dataSource.RefValue2"
              type="number"
              label="Референтное значение 2"
              :readonly="readonly"
              :disabled="!dataSource.RefValue1 && dataSource.RefValue1 !== 0"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="dataSource.RefValue3"
              type="number"
              label="Референтное значение 3"
              :readonly="readonly"
              :disabled="
                (!dataSource.RefValue2 && dataSource.RefValue2 !== 0) ||
                (!dataSource.RefValue1 && dataSource.RefValue1 !== 0)
              "
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="dataSource.AnalisysDescription1"
              label="Описание результата анализа показателя оценки качества между нулем и первым референтным значением ПОК"
              rows="3"
              required
              :rules="[rules.required]"
              :readonly="readonly"
              v-up-first-symbol
            ></v-textarea>
            <v-textarea
              v-model="dataSource.AnalisysDescription2"
              label="Описание результата анализа показателя оценки качества между первым и вторым референтным значением ПОК"
              rows="3"
              required
              :rules="[rules.required]"
              :readonly="readonly"
              v-up-first-symbol
            ></v-textarea>
            <v-textarea
              v-model="dataSource.AnalisysDescription3"
              label="Описание результата анализа показателя оценки качества между вторым и третьим референтным значением ПОК"
              rows="3"
              :readonly="readonly"
              v-up-first-symbol
              :disabled="
                !dataSource.AnalisysDescription1 ||
                !dataSource.AnalisysDescription2 ||
                (!dataSource.RefValue2 && dataSource.RefValue2 !== 0)
              "
            ></v-textarea>
            <v-textarea
              v-model="dataSource.AnalisysDescription4"
              label="Описание результата анализа показателя оценки качества между третьим референтным значением ПОК и 100"
              rows="3"
              :readonly="readonly"
              v-up-first-symbol
              :disabled="
                !dataSource.AnalisysDescription1 ||
                !dataSource.AnalisysDescription2 ||
                !dataSource.AnalisysDescription3 ||
                (!dataSource.RefValue3 && dataSource.RefValue3 !== 0)
              "
            ></v-textarea>
            <m-autocomplete
              v-model="dataSource.auditIndicators"
              :items="auditIndicators"
              :item-text="(item) => item.Name"
              :item-value="(e) => e"
              label="Показатели оценки качества по умолчанию"
              deletable-chips
              multiple
              title="Показатели оценки качества по умолчанию"
            ></m-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </base-popup>
</template>

<script>
import BasePopup from "@/layouts/BasePopup.vue";
import AnalysisSettingsService from "../../services/AnalysisSettingsService";
import AuditIndicatorService from "../../services/AuditIndicatorService";
import Permissions from "../../data/permissions";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";

import mAutocomplete from "../../components/base/inputs/mAutocomplete.vue";

export default {
  name: "view-AnalysisSettings",
  components: { mAutocomplete, BasePopup },
  mixins: [CreateAndEditMixin],
  props: {
    value: Boolean,
    width: {
      type: String,
      default: "80vw",
    },
    height: {
      type: String,
      default: "80vh",
    },
  },
  data: function () {
    return {
      loading: true,
      apiService: AnalysisSettingsService,
      dataSource: {
        RefValue1: null,
        RefValue2: null,
        RefValue3: null,
        AnalisysDescription1: null,
        AnalisysDescription2: null,
        AnalisysDescription3: null,
        AnalisysDescription4: null,
        auditIndicators: [],
      },
      valid: false,
      rules: {
        required: (value) => !!value || "Укажите значение.",
      },
      auditIndicators: [],
      selectedAuditIndicators: [],
    };
  },
  computed: {
    readonly() {
      return !this.hasPermission(Permissions.SettingsEdit);
    },
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    async dialog(visible) {
      if (visible) {
        this.selectedAuditIndicators = (
          await this.apiService.getAuditIndicators()
        ).data;
        await this.init();

        this.auditIndicators = (
          await AuditIndicatorService.get(true, true)
        ).data;
      }
    },
  },

  methods: {
    DataFromServerNormalize(data) {
      // Преобразование чисел для строковых редакторов (чтобы не фиксировались изменения при вводе)
      // data.RefValue1 = data.RefValue1?.toString();
      // data.RefValue2 = data.RefValue2?.toString();
      // data.RefValue3 = data.RefValue3?.toString();

      data.auditIndicators = this.selectedAuditIndicators;

      return data;
    },
    async save() {
      if (
        !this.dataSource.RefValue1 ||
        !this.dataSource.AnalisysDescription1 ||
        !this.dataSource.AnalisysDescription2
      )
        return;

      const data = {
        auditIndicatorIds: this.dataSource.auditIndicators.map((s) => s.Id),
      };

      this.selectedAuditIndicators = (
        await this.apiService.updateAuditIndicators(data)
      ).data;
      await this.baseSave();
      this.dialog = false;
    },
  },
};
</script>
