import _ from "lodash";
import moment from "moment";

// Добавляет рабочие дни
const AddWorkingDays = function (
  datStartDate,
  lngNumberOfWorkingDays,
  blnIncSat,
  blnIncSun
) {
  let intWorkingDays = 5;
  let intNonWorkingDays = 2;
  const intStartDay = datStartDate.getDay(); // 0=Sunday ... 6=Saturday
  let intOffset;
  let intModifier = 0;

  if (blnIncSat) {
    intWorkingDays++;
    intNonWorkingDays--;
  }
  if (blnIncSun) {
    intWorkingDays++;
    intNonWorkingDays--;
  }
  const newDate = new Date(datStartDate);
  if (lngNumberOfWorkingDays >= 0) {
    // Moving Forward
    if (!blnIncSat && blnIncSun) {
      intOffset = intStartDay;
    } else {
      intOffset = intStartDay - 1;
    }
    // Special start Saturday rule for 5 day week
    if (intStartDay === 6 && !blnIncSat && !blnIncSun) {
      intOffset -= 6;
      intModifier = 1;
    }
  } else {
    // Moving Backward
    if (blnIncSat && !blnIncSun) {
      intOffset = intStartDay - 6;
    } else {
      intOffset = intStartDay - 5;
    }
    // Special start Sunday rule for 5 day week
    if (intStartDay === 0 && !blnIncSat && !blnIncSun) {
      intOffset++;
      intModifier = 1;
    }
  }
  // ~~ is used to achieve integer division for both positive and negative numbers
  newDate.setTime(
    datStartDate.getTime() +
      Number(
        ~~((lngNumberOfWorkingDays + intOffset) / intWorkingDays) *
          intNonWorkingDays +
          lngNumberOfWorkingDays +
          intModifier
      ) *
        86400000
  );
  return newDate;
};

// Добавляет месяцы с учетом последних дней (должно совпадать с серверной реализацией)
const AddMonths = function (date, months) {
  if (date.getDate() < 29) {
    date.setMonth(date.getMonth() + months);
    return date;
  }
  const lastDay = IsLast(date);
  const result = new Date(date);
  result.setMonth(result.getMonth() + months);
  if (
    (result.getYear() - date.getYear()) * 12 +
      (result.getMonth() - date.getMonth()) >
    months
  )
    result.setDate(0);
  if (lastDay && !IsLast(result)) {
    result.setDate(1);
    result.setMonth(result.getMonth() + 1);
    result.setDate(0);
  }
  return result;
  function IsLast(d) {
    return (
      new Date(d.getYear(), d.getMonth(), d.getDate() + 1).getMonth() !==
      d.getMonth()
    );
  }
};

const treeListGenerateNumerationText = function (tree, property) {
  treeListTraverse(tree, (item, index, parent) => {
    item[property] = (parent ? parent[property] : "") + (index + 1) + ".";
  });
};

const treeListGenerateNumeration = function (tree, property) {
  treeListTraverse(tree, (item, index) => {
    item[property] = index + 1;
  });
};

const treeListTextNumeration = function (tree, property, propertyText) {
  treeListTraverse(tree, (item, _, parent) => {
    if (item)
      item[propertyText] =
        (parent ? parent[propertyText] : "") + item[property] + ".";
  });
};

// Merge 2 tree list
// params
// tree1 = [], tree2 = {}
const mergeTwoTreeList = function (tree1, tree2) {
  const parent = findTreeById(tree1, tree2.Id);
  if (parent) {
    const r = (item, item2) => {
      for (const child of item2.Children) {
        const fnode = item.Children.find((e) => e.Id === child.Id);
        if (!fnode) {
          item.Children.push(child);
        } else {
          r(fnode, child);
        }
      }
    };
    r(parent, tree2);
  } else {
    tree1.push(tree2);
  }

  return parent;
};

const treeGetAllParents = function (tree, item, key) {
  let listAllNodes = [];
  let treeResult = null;

  var listNode = (_tree) => {
    for (const child of _tree.Children) {
      if (item[key] === child[key]) {
        listAllNodes.push(child);
        return true;
      } else {
        if (listNode(child, item)) {
          listAllNodes.push(child);
          return true;
        }
      }
    }
  };

  for (const child of tree) {
    if (listNode(child)) {
      listAllNodes.push(child);
    }
  }

  listAllNodes = _.cloneDeep(listAllNodes);
  for (const child of listAllNodes) {
    child.Children = [];
  }

  listAllNodes.reverse();

  if (!listAllNodes.length) {
    listAllNodes.push(item);
  }

  // List tree in tree hierarchy
  let point = null;
  for (const child of listAllNodes) {
    if (!point) {
      point = child;
      treeResult = point;
    } else {
      point.Children.push(child);
      point = point.Children[0];
    }
  }

  return treeResult;
};

// Tree list all children add parentId
const treeAddParentId = (items, parentId) => {
  for (const j in items) {
    items[j].ParentId = parentId;
    if (items[j].Children.length !== 0)
      treeAddParentId(items[j].Children, items[j].Id);
  }
};

// Проход по всем свойствам объекта включая любых вложенных
const traverse = function (obj, func) {
  _.forOwn(obj, (val, key) => {
    if (_.isArray(val)) {
      val.forEach((el) => {
        traverse(el, func);
      });
    } else {
      func(key, val, obj);
    }
  });
};

// Проходить по всему дереву
const treeListTraverse = function (
  tree,
  func,
  parent = null,
  childName = "Children"
) {
  tree = Array.isArray(tree) ? tree : [tree];

  let index = 0;
  for (const child of tree) {
    const res = func(child, index, parent);

    if (typeof res !== "undefined") {
      return res;
    }

    if (child[childName] && child[childName].length) {
      const res2 = treeListTraverse(child[childName], func, child);
      if (typeof res2 !== "undefined") {
        return res2;
      }
    }
    index++;
  }
};

// Поиск по дереву
const findTreeById = function (tree, Id, valueName = "Id") {
  const r = function (elem) {
    if (elem[valueName] === Id) return elem;

    for (const child of elem.Children) {
      const res = r(child);
      if (res) return res;
    }
  };
  for (const elem of tree) {
    const res = r(elem);
    if (res) return res;
  }

  return null;
};

// Фильтр дерева
const treeFilter = function (array, text) {
  return array.filter(function iter2(o) {
    if (o.Name.toLocaleLowerCase().match(text.toLocaleLowerCase())) {
      return true;
    }
    if (!Array.isArray(o.Children)) {
      return false;
    }
    const temp = o.Children.filter(iter2);
    if (temp.length) {
      o.Children = temp;
      return true;
    }
    return false;
  });
};

// Преобразует строку в дату без времени
const toDate = function (s) {
  if (typeof s === "string" && s.length >= 10) {
    const dateParts = s.substr(0, 10).split(".");
    return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  }
  return null;
};

// Преобразует строку в дату и время
const toDateTime = function (s) {
  if (typeof s === "string" && s.length >= 10) {
    const dateParts = s.substr(0, 10).split(".");
    if (s.length >= 19) {
      const timeParts = s.substr(11, 8).split(":");
      return new Date(
        +dateParts[2],
        dateParts[1] - 1,
        +dateParts[0],
        +timeParts[0],
        +timeParts[1],
        +timeParts[2]
      );
    }
    return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  }
  return null;
};

// Преобразует дату в строку в формате сервера
const toServerDateTime = function (d) {
  if (d instanceof Date) {
    const day = d.getDate().toString();
    const mon = (d.getMonth() + 1).toString();
    return (
      (day.length === 1 ? "0" + day : day) +
      "." +
      (mon.length === 1 ? "0" + mon : mon) +
      "." +
      d.getFullYear() +
      " " +
      NN(d.getHours()) +
      ":" +
      NN(d.getMinutes()) +
      ":" +
      NN(d.getSeconds())
    );
  }
  return null;
  function NN(n) {
    return n < 10 ? "0" + n.toString() : n.toString();
  }
};

// Возвращает текущую дату без времени
const today = function () {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
};

// Переводит строку на русском в верхний регистр на латинице
const translite = function (s, english = true, special = true) {
  if (!s) return s;
  const lat = [
    "A",
    "B",
    "V",
    "G",
    "D",
    "E",
    "YO",
    "ZH",
    "Z",
    "I",
    "Y",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "R",
    "S",
    "T",
    "U",
    "F",
    "KH",
    "TS",
    "CH",
    "SH",
    "SHCH",
    "",
    "Y",
    "",
    "E",
    "YU",
    "Ya",
  ];
  const rus = [
    "А",
    "Б",
    "В",
    "Г",
    "Д",
    "Е",
    "Ё",
    "Ж",
    "З",
    "И",
    "Й",
    "К",
    "Л",
    "М",
    "Н",
    "О",
    "П",
    "Р",
    "С",
    "Т",
    "У",
    "Ф",
    "Х",
    "Ц",
    "Ч",
    "Ш",
    "Щ",
    "Ъ",
    "Ы",
    "Ь",
    "Э",
    "Ю",
    "Я",
  ];
  const English = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  return s
    .toUpperCase()
    .split("")
    .map((ch) => {
      const index = rus.indexOf(ch);
      if (index !== -1) return lat[index];
      if (English.indexOf(ch) !== -1) return english ? ch : "";
      return special ? ch : "";
    })
    .join("");
};

// Группирует массив объектов по ключу
const groupBy = function (items, key) {
  return items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );
};

const dateNormalize = function (date) {
  if (typeof date === "string") {
    const t = date.substring(0, 10);
    return moment(t, "DD.MM.YYYY");
  } else if (date instanceof Date) {
    return moment(date);
  } else if (typeof date === "number") {
    const t = parseInt((date + "").substring(0, 10));
    return moment.unix(t);
  }
};

const dateTimeNormalize = function (date) {
  if (typeof date === "string") {
    return moment(date, "DD.MM.YYYY HH:mm:ss");
  } else if (date instanceof Date) {
    return moment(date);
  } else if (typeof date === "number") {
    const t = parseInt((date + "").substring(0, 10));
    return moment.unix(t);
  }
};

const sortStrings = function (s1, s2) {
  if (!s1 && !s2) return 0;
  if (s1 && !s2) return 1;
  if (!s1 && s2) return -1;
  if (s1 > s2) return 1;
  if (s1 < s2) return -1;
  return 0;
};

// Сортировка с учетом числа в начале и конце строки
const sortNumWithString = function (t1, t2) {
  let n1 = t1?.match(/^\d+/gi);
  let n2 = t2?.match(/^\d+/gi);
  let nr1 = t1?.match(/\d+$/gi);
  let nr2 = t2?.match(/\d+$/gi);

  n1 = n1?.length && n1[0];
  n2 = n2?.length && n2[0];
  nr1 = nr1?.length && nr1[0];
  nr2 = nr2?.length && nr2[0];

  const p1 = n1 ? ("0000000000000000" + n1).slice((n1 + "").length) : "";
  const p2 = n2 ? ("0000000000000000" + n2).slice((n2 + "").length) : "";

  const pr1 = nr1 ? ("0000000000000000" + nr1).slice((nr1 + "").length) : "";
  const pr2 = nr2 ? ("0000000000000000" + nr2).slice((nr2 + "").length) : "";

  let r1 = t1;
  r1 = n1 ? p1 + r1.substr((n1 + "").length) : r1;
  r1 = nr1 ? r1.substr(0, r1.length - (nr1 + "").length) + pr1 : r1;

  let r2 = t2;
  r2 = n2 ? p2 + r2.substr((n1 + "").length) : r2;
  r2 = nr2 ? r2.substr(0, r2.length - (nr2 + "").length) + pr2 : r2;

  return sortStrings(r1, r2);
};

const sortNumWithPrecent = function (t1, t2) {
  const n1 = t1?.match(/(\d+(\.\d+)?)%/);
  const n2 = t2?.match(/(\d+(\.\d+)?)%/);

  if (n1 && n2) {
    const num1 = parseFloat(n1[1]);
    const num2 = parseFloat(n2[1]);

    return num2 - num1;
  } else {
    return 0;
  }
};

export default {
  sortStrings,
  sortNumWithString,
  sortNumWithPrecent,
  dateNormalize,
  dateTimeNormalize,
  AddWorkingDays,
  AddMonths,
  traverse,
  toDate,
  toDateTime,
  toServerDateTime,
  today,
  treeFilter,
  treeAddParentId,
  treeGetAllParents,
  treeListTraverse,
  findTreeById,
  mergeTwoTreeList,
  treeListGenerateNumeration,
  treeListGenerateNumerationText,
  treeListTextNumeration,
  translite,
  groupBy,
};
