import informer from "./informer.vue";

function Install(Vue, options = {}) {
  const property = options.property || "$informer";
  delete options.property;
  const vuetify = options.vuetify;
  delete options.vuetify;
  if (!vuetify) {
    console.warn(
      "Module vuetify-informer needs vuetify instance. Use Vue.use(VuetifyInformer, { vuetify })"
    );
  }
  const Ctor = Vue.extend(Object.assign({ vuetify }, informer));
  function createInformerCmp(options, cont) {
    const container =
      cont || document.querySelector("[data-app=true]") || document.body;
    return new Promise((resolve) => {
      const cmp = new Ctor(
        Object.assign(
          {},
          {
            propsData: Object.assign(
              {},
              Vue.prototype[property].options,
              options
            ),
            destroyed: () => {
              container.removeChild(cmp.$el);
            },
          }
        )
      );
      container.appendChild(cmp.$mount().$el);
      resolve(cmp);
    });
  }

  function show(params, options = {}) {
    options.message = params.message;
    options.route = params.route;
    options.type = params.type;
    return createInformerCmp(options, params.container);
  }
  Vue.prototype[property] = show;
  Vue.prototype[property].options = options || {};
}

if (typeof window !== "undefined" && window.Vue) {
  window.Vue.use(Install);
}

export default Install;
