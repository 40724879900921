<template>
  <div class="row px-2">
    <v-row no-gutters>
      <v-col>
        <base-panel :loading="loading" headerTogether>
          <template slot="title">
            <v-checkbox
              v-if="checkList"
              style="margin-left: 22px"
              @change="selectAll"
              hide-details
            ></v-checkbox>
            <span v-if="!checkList" class="subtitle"
              >Вопросы выбранного чек-листа</span
            >
            <span class="subtitle primary--text">{{
              checkList ? checkList.Name : ""
            }}</span>
          </template>
          <v-treeview
            :items="checkList ? checkList.Questions : []"
            @input="selectCheckList = $event"
            :value="selectCheckList"
            item-key="Id"
            item-text="Content"
            item-children="Children"
            class="block__content"
            dense
            selectable
            open-all
            return-object
            expand-icon="$ToolArrowDown"
          >
            <template v-slot:label="{ item }">
              <b v-if="isRequired(item)">{{ item.Content }}</b>
              <span v-else>{{ item.Content }}</span>
            </template>
          </v-treeview>
        </base-panel>
      </v-col>
      <div style="display: inline-flex">
        <div style="z-index: 2; height: 10px; width: 40px">
          <v-btn
            v-if="selectCheckList.length"
            @click="moveQuestions"
            style="position: fixed"
            color="success"
            title="Использовать в новых вопросах"
            dark
            small
            fab
          >
            <m-icon icon="mdi-arrow-right-circle-outline" x-large></m-icon>
          </v-btn>
        </div>
      </div>
      <v-col>
        <base-panel headerTogether title="Вопросы нового чек-листа">
          <span slot="title" class="subtitle">Вопросы нового чек-листа</span>
          <questions
            :value="newCheckList.Questions"
            show-edit-popup
            @input="$emit('input', $event)"
          />
        </base-panel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BasePanel from "../../../layouts/BasePanel.vue";
import CheckListService from "../../../services/CheckListService";
import DataHelper from "../../../utils/DataHelper";
import Questions from "../Question/Questions.vue";
export default {
  components: { BasePanel, Questions },
  props: {
    checkList: Object,
    newCheckList: Object,
  },
  data: () => ({
    loading: false,
    selectCheckList: [],
  }),
  watch: {
    checkList: function (val) {
      if (val && !val.Questions.length) {
        this.loadQuestions();
      }
    },
  },
  methods: {
    selectAll(e) {
      if (e) {
        DataHelper.treeListTraverse(this.checkList.Questions, (item) => {
          this.selectCheckList.push(item);
        });
      }
    },
    async loadQuestions() {
      this.loading = true;
      const questions = (await CheckListService.show(this.checkList.Id)).data
        .Questions;

      for (const i in questions) {
        if (questions[i].Children?.length !== 0) {
          DataHelper.treeAddParentId(questions[i].Children, questions[i].Id);
        }
      }

      // Добавить всем вопросам ид чек-листа
      DataHelper.treeListTraverse(questions, (item) => {
        item.CheckList = this.checkList.Id;
      });

      this.checkList.Questions = questions;
      this.loading = false;
    },
    moveQuestions() {
      if (this.selectCheckList.length < 1) return;

      for (const child of this.selectCheckList) {
        DataHelper.mergeTwoTreeList(
          this.newCheckList.Questions,
          DataHelper.treeGetAllParents(this.checkList.Questions, child, "Id")
        );
      }
    },
    isRequired(question) {
      if (!question.ParentId)
        return DataHelper.treeListTraverse(question, (item) => {
          if (item.IsRequired) {
            return true;
          }
        });
      else if (question.IsRequired) return true;
    },
  },
};
</script>

<style></style>
