<template>
  <base-list-view
    :dataSource="dataSource"
    :headers="headers"
    :loading="loading"
    @onRefresh="init"
    :customInplaceEdit="customInplaceEdit"
    @customDelete="customDelete"
    :editIndex.sync="editIndex"
    :defaultObject="defaultObject"
    show-actions
    :editable="!readOnly"
    :showDelete="false"
    :disabledAdd="readOnly"
    title="Виды последствий нежелательных событий"
    label-text-btn-create="Добавить вид"
    label-text-search="Поиск вида"
    add-in-table
    notShowSelect
    :showFilters="true"
  >
    <template #item.Category="scope">
      <v-text-field
        v-if="scope.rowEditing && scope.item.Id === scope.rowEditing.Id"
        :value="scope.rowEditing.Category"
        @change="scope.rowEditing.Category = $event"
        :autofocus="scope.item.Id < 0"
        maxlength="10"
        v-focusInput.noFocus="scope.closeEdit"
        v-up-first-symbol
      ></v-text-field>
      <span v-else>{{ scope.item.Category }}</span>
    </template>
    <template #item.Description="scope">
      <v-textarea
        v-if="scope.rowEditing && scope.item.Id === scope.rowEditing.Id"
        :value="scope.item.Description"
        @change="scope.rowEditing.Description = $event"
        rows="3"
        maxlength="1000"
        :autofocus="scope.item.Id > 0"
        v-up-first-symbol
      ></v-textarea>
      <span v-else>{{ scope.item.Description }}</span>
    </template>
  </base-list-view>
</template>

<script>
import UnwishedConsequenceTypeService from "../../services/UnwishedConsequenceTypeService";
import { defaultUnwishedConsequenceType } from "../../data/defaultObjects";
import Permissions from "../../data/permissions";
import BaseListView from "../../layouts/BaseListView.vue";
import ListEditMixin from "../../mixins/ListEditMixin";

export default {
  name: "view-UnwishedConsequenceTypes",
  components: { BaseListView },
  mixins: [ListEditMixin],
  data: function () {
    return {
      loading: true,
      apiService: UnwishedConsequenceTypeService,
      defaultObject: defaultUnwishedConsequenceType,
      headers: [
        { text: "Категория", value: "Category", align: "center" },
        {
          text: "Описание",
          value: "Description",
          width: "80%",
          notGroup: true,
        },
      ],
      dataSource: [],
      editIndex: null,
    };
  },
  computed: {
    readOnly() {
      return !this.hasPermission(Permissions.UnwishedConsequenceTypesEdit);
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async customInplaceEdit(value) {
      if (!value) return;
      const res = (
        await UnwishedConsequenceTypeService[
          value.Id <= 0 ? "create" : "update"
        ](value)
      ).data;
      return res;
    },
    async customDelete(value) {
      if (!value) return;
      if (value.Id > 0) await UnwishedConsequenceTypeService.delete(value.Id);
      const index = this.dataSource.findIndex((item) => item.Id === value.Id);
      if (index >= 0) this.dataSource.splice(index, 1);
      this.editIndex = -1;
    },
  },
};
</script>
