<template>
  <v-list-item @click="$refs['point'].menu = true">
    <v-list-item-icon class="my-auto">
      <m-icon icon="medexpert-notifications" active></m-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>Уведомления</v-list-item-title>
    </v-list-item-content>
    <v-list-item-icon>
      <NotificationPoint ref="point" not-small></NotificationPoint>
    </v-list-item-icon>
  </v-list-item>
</template>

<script>
import NotificationPoint from "../../modules/Notification/notificationPoint.vue";

export default {
  components: {
    NotificationPoint,
  },
};
</script>

<style></style>
