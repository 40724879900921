import Api from "./Api";

export default {
  async show() {
    return Api().get("report/GetReportSettings");
  },
  async getDefault() {
    return Api().get("report/GetReportSettings", {
      params: { defaultValues: true },
    });
  },
  async update(data) {
    return Api().put("report/UpdateReportSettings", data);
  },
};
