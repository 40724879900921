<template>
  <v-flex>
    <base-panel v-bind="$attrs">
      <slot name="actionBefore" slot="action" :isShowSearch="isShowSearch" />
      <base-search
        v-if="notShowActions.indexOf('search') === -1"
        slot="action"
        v-model="searchtext"
        v-bind="$attrs"
        @changeOpen="(val) => (isShowSearch = val)"
      />
      <v-btn
        slot="action"
        v-if="!isNested && notShowActions.indexOf('refresh') === -1"
        @click="$emit('onRefresh')"
        icon
        title="Обновить"
      >
        <m-icon icon="tool-refresh" s16></m-icon>
      </v-btn>
      <v-btn
        slot="action"
        v-if="showDelete && notShowActions.indexOf('delete') === -1"
        @click="del"
        :disabled="!isSelected"
        icon
        title="Удалить"
        color="error"
      >
        <m-icon icon="tool-delete" s16></m-icon>
      </v-btn>
      <v-btn
        v-if="showCopy"
        slot="action"
        @click="copy"
        :disabled="!isSelected || selected.length > 1"
        icon
        title="Копировать"
      >
        <m-icon icon="tool-copy" s16></m-icon>
      </v-btn>

      <slot
        v-for="slot in Object.keys($slots).filter(
          (e) => e !== 'table' && e !== 'actionBefore'
        )"
        :name="slot"
        :slot="slot"
      />

      <prefab-button-save
        v-if="notShowActions.indexOf('add') === -1"
        v-bind="$attrs"
        :disabled="disabledAdd"
        class="ml-4"
        @add="add"
        @pressDropdown="$emit('pressDropdown')"
        slot="action"
      />
      <slot
        name="table"
        v-bind:attrs="{ ...$attrs, searchText: searchtext }"
        v-bind:listeners="{ ...$listeners, 'click:row': handleClick }"
      >
        <base-tree-table
          :items="dataSource"
          :searchText="searchtext"
          :isShowSearch="isShowSearch"
          v-bind="$attrs"
          v-model="selected"
          @click:row="handleClick"
          v-on="$listeners"
          ref="tableFull"
          :hideEdit="$attrs['hideEdit']"
          :hideDelete="$attrs['hideDelete']"
          :fixedWidthActions="$attrs['fixedWidthActions']"
        >
          <template
            v-for="slot in Object.keys($scopedSlots).filter(
              (slot) => slot !== 'action'
            )"
            :slot="slot"
            slot-scope="scope"
            ><slot :name="slot" v-bind="scope" />
          </template>
        </base-tree-table>
      </slot>
    </base-panel>
  </v-flex>
</template>

<script>
import BasePanel from "./BasePanel.vue";
import BaseSearch from "../components/base/BaseSearch";
import PrefabButtonSave from "../components/prefabs/PrefabButtonSave.vue";
import BaseTreeTable from "../components/base/BaseTreeTable.vue";

// @click:row="$listeners['click:row']"

export default {
  components: {
    BasePanel,
    BaseSearch,
    PrefabButtonSave,
    BaseTreeTable,
  },
  props: {
    isNested: {
      type: Boolean,
      default: false,
    },
    notShowActions: {
      type: Array,
      default: () => [],
    },
    showCopy: Boolean,
    showDelete: {
      type: Boolean,
      default: () => true,
    },
    disabledAdd: Boolean,
    dataSource: Array,
    addInTable: Boolean,
    addParams: Object,
    pageEditName: String,
    search: String,
    selectedItems: [],
    funcHandleClickId: {
      type: Function,
      default: null,
    },
  },
  data: () => {
    return {
      selected: [],
      searchtext: "",
      isShowSearch: false,
    };
  },
  computed: {
    isSelected() {
      return this.selected.length > 0;
    },
  },
  watch: {
    selected: function (val) {
      this.$emit("update:selectedItems", val);
    },
    search: {
      immediate: true,
      handler(val) {
        this.searchtext = val;
      },
    },
    searchtext: {
      handler(val) {
        this.$emit("update:search", val);
      },
    },
    selectedItems: {
      handler(val) {
        this.selected = val;
      },
    },
  },

  methods: {
    updateItems(items) {
      items = this._.cloneDeep(items);
      if (items.length > 1) {
        throw new Error("Можно изменить только один элемент");
      }

      let item = items[0];
      if (item) {
        item = this?.$parent?.DataFromServerNormalize([item])[0];
        const itemIndex = this.dataSource.findIndex((e) => e.Id === item.Id);
        if (itemIndex !== -1) this.$set(this.dataSource, itemIndex, item);
        else this.dataSource.push(item);
      }
      this.$emit("update:dataSource", this.dataSource);
    },
    handleClick(value) {
      // Прерывать нажатие на строку если включено редактирование строки
      if (this.$store.getters["itemEdited/isEdit"]) {
        return;
      }

      if (this.$listeners.customHandle) {
        this.$listeners.customHandle(value);
        return;
      }

      // Получение id строки
      const itemId = this.funcHandleClickId
        ? this.funcHandleClickId(value)
        : value.Id;

      if (itemId <= 0 || !this.pageEditName) return;

      this.$store.commit("pageTabs/SET_TRANSACTION_LIST_VIEW_DETAIL", {
        listViewUpdateItems: this.updateItems,
        listViewInit: this.$parent?.init,
      });
      this.$router.push({
        name: this.pageEditName,
        params: {
          objectId: itemId,
        },
      });
    },
    add(item) {
      if (!item || item.value === "new") this.defaultAdd();
      else this.$emit("add", item);
    },
    defaultAdd() {
      if (!this.addInTable) {
        this.$store.commit("pageTabs/SET_TRANSACTION_LIST_VIEW_DETAIL", {
          listViewUpdateItems: this.updateItems,
          listViewInit: this.$parent?.init,
        });
        this.$router.push({
          name: this.pageEditName,
          params: {
            objectId: 0,
            ...this.addParams,
          },
          query: {
            type: "create",
          },
        });
      } else {
        this.$refs.tableFull.addInTable();
      }
    },
    del() {
      if (this.selected.length === 0) return;
      if (this.$listeners.onDelete) {
        const ids = this.selected.map((el) => el.Id);
        this.$emit("onDelete", ids);
        return;
      }
      for (const i in this.selected) {
        const index = this.dataSource.indexOf(this.selected[i]);
        this.dataSource.splice(index, 1);
      }
      this.selected = [];
    },
    copy() {
      this.$router.push({
        name: this.pageEditName,
        params: {
          objectId: 0,
          copyId: this.selected[0].Id,
        },
        query: {
          type: "copy",
        },
      });
    },
  },
};
</script>

<style></style>
