<template>
  <base-detail-view
    :loading="loading"
    title="Подразделение"
    :subTitle="this.type === 'create' ? 'Новое подразделение' : dataSource.Name"
    :isUnsaved="isUnsaved && valid"
    @onRefresh="init"
    @onSave="save"
  >
    <report-activator
      slot="action"
      :report="['EquipmentStandartExecutionReport']"
      :params="{
        objectId: { name: 'pPartitionId', value: $route.params.objectId },
      }"
    />
    <div
      v-if="tab == 3 || (!dataSource.Children.length ? tab == 2 : tab == 4)"
      row
      slot="action"
      class="align-center d-inline-flex"
    >
      <label
        class="tasks__action-show-all v-label blue-grey--text mr-1"
        style="font-size: 14px"
      >
        {{
          "Показывать " +
          ((!dataSource.Children.length ? tab == 2 : tab == 3)
            ? "неработающих"
            : "недействующие")
        }}
      </label>
      <v-checkbox v-model="hideFlag" hide-details></v-checkbox>
    </div>

    <v-form v-model="valid" class="block__content">
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
              v-model="dataSource.Name"
              label="Название"
              :rules="[rules.required]"
              required
              maxlength="250"
              :readonly="readOnly"
              v-up-first-symbol
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="dataSource.Address"
              label="Адрес"
              maxlength="150"
              :readonly="readOnly"
              v-up-first-symbol
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <base-panel v-if="!loading" class="mt-2" headerHidden>
      <m-tabs v-model="tab">
        <v-tab key="Children" v-if="dataSource.Children.length">
          Дочерние подразделения
        </v-tab>
        <v-tab key="Equipments">Изделия и оборудование</v-tab>
        <v-tab key="Standarts">Стандарты оснащения</v-tab>
        <v-tab key="Employees">Сотрудники</v-tab>
        <v-tab key="Documents">Документы</v-tab>
        <v-tab key="Courses">Курсы</v-tab>
      </m-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          key="Children"
          v-if="dataSource.Children.length"
          class="mt-2"
        >
          <base-tree-list-edit
            :items="dataSource.Children"
            item-text="Name"
            :showActions="[]"
            pageEditName="PartitionEdit"
          >
          </base-tree-list-edit>
        </v-tab-item>
        <v-tab-item key="Equipments" class="mt-2">
          <partition-equipments
            :partitionId="dataSource.Id"
            :dataSource="dataSource.Equipments"
            :readOnly="readOnly"
            @add="addEquipments"
          ></partition-equipments>
        </v-tab-item>
        <v-tab-item key="Standarts" class="mt-2">
          <partition-equipment-standarts
            :dataSource="dataSource.EquipmentStandarts"
            :readOnly="readOnly"
            @add="addStandarts"
            @handle="showLinksEdit"
          ></partition-equipment-standarts>
        </v-tab-item>
        <v-tab-item key="Employees" class="mt-2">
          <list-emloyees
            :value="dataSource.Employees"
            :custom-filter="(b, a, e) => filterEmployees(e)"
            :show-partition="!!dataSource.Children.length"
          >
          </list-emloyees>
        </v-tab-item>
        <v-tab-item key="Documents" class="mt-2">
          <list-document
            :value="dataSource.OrganizationDocuments"
            :custom-filter="(b, a, e) => filterDocuments(e)"
            :show-add-in-table="true"
            :search="search"
          >
          </list-document>
        </v-tab-item>
        <v-window-item key="Courses" class="mt-2">
          <list-course
            class="mt-2"
            :value="dataSource.Courses"
            :show-add-in-table="true"
            isTemplate
          >
          </list-course>
        </v-window-item>
      </v-tabs-items>
    </base-panel>
    <partition-standart-links-edit
      v-model="showLinks"
      :standart="standartLinks"
      :readOnly="readOnly"
      :width="'80vw'"
      :height="'80vh'"
      @save="saveLinks"
    ></partition-standart-links-edit>
  </base-detail-view>
</template>

<script>
import PartitionService from "../../services/PartitionService";
import EquipmentStandartsService from "../../services/EquipmentStandartsService";
import { defaultPartition } from "../../data/defaultObjects";
import Permissions from "../../data/permissions";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin";
import UnsavedMixin from "../../mixins/UnsavedMixin.js";
import BaseDetailView from "../../layouts/BaseDetailView.vue";
import BaseTreeListEdit from "../../components/base/BaseTreeListEdit.vue";
import BasePanel from "../../layouts/BasePanel.vue";
import PartitionEquipments from "../../components/organization/PartitionEquipments.vue";
import PartitionEquipmentStandarts from "../../components/organization/PartitionEquipmentStandarts.vue";
import PartitionStandartLinksEdit from "../../components/organization/PartitionStandartLinksEdit.vue";
import ReportActivator from "../../components/report/ReportActivator.vue";
import mTabs from "@/components/base/tabs/mTabs";
import { uniqBy } from "lodash";
import moment from "moment";

export default {
  name: "view-PartitionEdit",
  components: {
    BaseDetailView,
    BaseTreeListEdit,
    BasePanel,
    PartitionEquipments,
    PartitionEquipmentStandarts,
    PartitionStandartLinksEdit,
    ReportActivator,
    mTabs,
    listEmloyees: () =>
      import("@/components/organization/employees/ListEmloyees.vue"),
    listDocument: () => import("@/components/document/ListDocument.vue"),
    listCourse: () => import("@/components/knowledgeBase/ListCourse.vue"),
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  data: function () {
    const readOnly = !this.hasPermission(Permissions.PartitionsEdit);
    return {
      loading: true,
      valid: false,
      apiService: PartitionService,
      getDefaultObject: defaultPartition,
      getObjectText: (obj, isNew) => (isNew ? "новое" : obj.Name),
      dataSource: { Children: [] },
      tab: null,
      rules: {
        required: (value) => !!value || "Укажите значение.",
      },
      showLinks: false,
      standartLinks: { Records: [] },
      readOnly,
      hideFlag: false,
      search: "_",
    };
  },
  async mounted() {
    await this.init();
  },
  methods: {
    DataFromServerNormalize(data) {
      if (!data.Employees) data.Employees = [];
      if (!data.OrganizationDocuments) data.OrganizationDocuments = [];
      if (!data.Courses) data.Courses = [];

      for (let i = 0; i < data.Employees.length; i++) {
        const item = data.Employees[i];

        item.Status = item.User?.IsActive ? 1 : 0;

        if (!item.WorkPlaces) continue;
        const wp = item.WorkPlaces.filter((e) => e !== null);
        if (!wp) continue;

        item.AllPositionName = uniqBy(
          wp.filter((e) => e.Position),
          "Position.Id"
        )
          .map((e) => e?.Position?.Name)
          .sort()
          .join(", ");

        item.AllPlaceName = uniqBy(
          wp.filter((e) => e.Place),
          "Place.Id"
        )
          .map((e) => e?.Place?.Name)
          .sort()
          .join(", ");
      }

      return data;
    },
    filterEmployees(e) {
      const currentDate = moment().startOf("day").unix();

      return this.hideFlag
        ? e
        : (e.User.IsActive === true &&
            moment(e.WorkPlacePeriod.DateIn, "DD.MM.YYYY").unix() <=
              currentDate &&
            (moment(e.WorkPlacePeriod.DateOut, "DD.MM.YYYY").unix() > 0
              ? moment(e.WorkPlacePeriod.DateOut, "DD.MM.YYYY").unix() >
                currentDate
              : true)) ||
            e.isNew === true;
    },
    filterDocuments(e) {
      return this.hideFlag ? e : e.Status === 5 || e.isNew === true;
    },
    // TODO: Для сортировки, придумать решение лучше
    sortAlf(items) {
      return items.sort((x, y) => x.Name.localeCompare(y.Name));
    },
    async init() {
      await this.baseInit();
    },
    addEquipments(value) {
      for (const eq of value) {
        if (this.dataSource.Equipments.find((e) => e.Id === eq.Id)) continue;
        const equipment = this._.cloneDeep(eq);
        this.dataSource.Equipments.push(equipment);
      }
    },
    addStandarts(value) {
      for (const st of value) {
        if (this.dataSource.EquipmentStandarts.find((s) => s.Id === st.Id))
          continue;
        this.dataSource.EquipmentStandarts.push(st);
      }
    },
    // Вызов редактирования соответствия изделий и оборудования стандарту оснащения
    async showLinksEdit(value) {
      const standart = (await EquipmentStandartsService.show(value.Id)).data;

      // Непривязанные изделия и оборудование
      const unlinkedEquipments = [];
      unlinkedEquipments.push(...this.dataSource.Equipments);

      // Добавляем в список привязанные к стандартам оснащения изделия и оборудование
      for (const record of standart.Records) {
        record.Equipments = [];
        record.OldEquipments = [];
        record.SourceEquipments = [];
        for (const equipment of this.dataSource.Equipments) {
          if (equipment.StandartRecords.find((sr) => sr.Id === record.Id)) {
            record.Equipments.push(equipment);
            const index = unlinkedEquipments.indexOf(equipment);
            if (index !== -1) unlinkedEquipments.splice(index, 1);
          }
        }
      }

      // Заполняем вспомогательные коллекции
      for (const record of standart.Records) {
        record.OldEquipments.push(...record.Equipments);
        record.SourceEquipments.push(...record.Equipments);
        record.SourceEquipments.push(...unlinkedEquipments);
        record.SourceEquipments = this.sortAlf(record.SourceEquipments);
      }
      this.standartLinks = standart;
      this.showLinks = true;
    },
    // Сохранение отредактированного соответствия изделий и оборудования стандарту оснащения
    saveLinks() {
      this.showLinks = false;
      for (const equipment of this.dataSource.Equipments) {
        for (const record of this.standartLinks.Records) {
          const hasStandart = record.Equipments.indexOf(equipment) !== -1;
          const index = equipment.StandartRecords.findIndex(
            (sr) => sr.Id === record.Id
          );
          if (!hasStandart && index !== -1)
            equipment.StandartRecords.splice(index, 1);
          else if (hasStandart && index === -1)
            equipment.StandartRecords.push({ Id: record.Id });
        }
      }
    },
  },
};
</script>
