<template>
  <v-row class="mt-0 ml-1 left-tab">
    <v-item-group v-model="window" class="shrink mr-6" mandatory>
      <v-item
        v-for="item in tabs"
        :key="item.id"
        :value="item.id"
        v-slot="{ active, toggle }"
        class="left-tab__tabs-item"
      >
        <div>
          <v-btn
            :input-value="active"
            color="primary"
            :outlined="!active"
            @click="toggle"
            block
            class="left-tab__btn"
            :title="item.title"
          >
            {{ item.title }}
          </v-btn>
        </div>
      </v-item>
      <slot
        name="itemsAppend"
        v-bind:showAdd="showAdd"
        v-bind:showAddText="showAddText"
      ></slot>
    </v-item-group>

    <v-col class="pt-0 pl-0">
      <v-window :value="window" class="" vertical>
        <slot></slot>
      </v-window>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    showAdd: {
      type: Boolean,
      default: false,
    },
    showAddText: {
      type: Boolean,
      default: false,
    },

    dataSource: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      length: 3,
      window: 0,
      activeTabName: null,
      tabs: [],
      timer: null,
    };
  },
  computed: {},
  watch: {
    window(val) {
      const tab = this.tabs.find((e) => e.id === val);
      this.selectTab(tab);
      this.$emit("input", tab);
    },
  },
  mounted() {
    setTimeout(() => {
      this.selectTab("text");
      this.$emit("input", this.tabs[0]);
    }, 1000);

    this.updateTabs();
    let col = -1;
    this.timer = setInterval(() => {
      const vnodes = this.$slots.default?.filter(
        (vnode) => vnode.componentOptions
      );
      if (!vnodes) return;
      if (col !== vnodes.length) {
        this.updateTabs();
        col = vnodes.length;
      }
    }, 1000);
  },
  destroyed() {
    clearInterval(this.timer);
  },

  methods: {
    updateTabs() {
      if (this.$slots.default) {
        this.tabs = this.$slots.default
          .filter((vnode) => vnode.componentOptions)
          .map((vnode) => {
            const { title, name, active, to, disabled, id, author } =
              vnode.componentOptions.propsData;
            return {
              title,
              name,
              active,
              to,
              disabled,
              id,
              author,
            };
          });
      } else this.tabs = [];
    },
    getTabs() {
      if (this.$slots.default) {
        return this.$slots.default
          .filter((vnode) => vnode.componentInstance)
          .map((vnode) => vnode.componentInstance);
      }
      return [];
    },
    removeActive() {
      const tabs = this.getTabs();
      tabs.filter((e) => e.setActive).forEach((tab) => tab.setActive(false));
    },

    selectTab(tabName, item) {
      this.removeActive();

      if (this.activeTabName !== tabName) {
        this.setActiveTab(this.getTabs(), tabName);

        this.activeTabName = tabName;
      } else {
        this.activeTabName = null;
      }

      if (item?.to) {
        this.$router.push(item.to);
      }
    },

    setActiveTab(tabComponents, tabName) {
      tabComponents
        .filter((tab) => tab.name === tabName)
        .forEach((component) => {
          component.setActive(true);
        });
    },
  },
};
</script>
<style lang="scss">
.left-tab {
  &__btn {
    justify-content: flex-start;
    span {
      max-width: 200px;
      display: inline;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.left-tab__tabs-item {
  &:not(:last-child) {
    margin-bottom: 5px;
  }
}
</style>
