<template>
  <div>
    <v-btn
      v-if="!multipleSave"
      @click="select"
      color="primary"
      dark
      title="Создать"
      :disabled="disabled"
    >
      <m-icon icon="tool-add-item" left size="20"></m-icon>
      {{ labelTextBtnCreate }}
    </v-btn>
    <v-menu v-else nudge-top="-42">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="primary"
          dark
          title="Создать"
          :disabled="disabled"
        >
          <m-icon icon="tool-add-item" left size="20"></m-icon>
          {{ multipleSave.length ? multipleSave[0].text : labelTextBtnCreate }}
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="item in multipleSave.filter((e) => e.value !== 'more')"
          :key="item.value"
          @click="change(item)"
        >
          <v-list-item-title v-text="item.text"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  props: {
    labelTextBtnCreate: {
      type: String,
      default: "Создать",
    },
    multipleSave: Array,
    disabled: Boolean,
  },
  data: () => ({
    option: null,
  }),
  created() {
    if (this.multipleSave) this.option = this.multipleSave[0];
  },
  methods: {
    add() {
      if (this.multipleSave) this.$emit("add", this.option);
      else this.$emit("add");
    },
    change(val) {
      this.option = val;
      this.add();
    },

    select() {
      this.add();
    },
  },
};
</script>
