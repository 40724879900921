var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.filter)?_c('div',[(_vm.filter.type === 'Date')?_c('base-date-picker-range',{attrs:{"outlined":"","dense":"","hide-label":"","clearable":"","placeholder":_vm.placeholder},model:{value:(_vm.filter.value),callback:function ($$v) {_vm.$set(_vm.filter, "value", $$v)},expression:"filter.value"}}):(_vm.filter.type === 'Period')?_c('base-date-picker-range',{attrs:{"outlined":"","dense":"","hide-label":"","clearable":"","placeholder":_vm.placeholder},model:{value:(_vm.filter.value),callback:function ($$v) {_vm.$set(_vm.filter, "value", $$v)},expression:"filter.value"}}):(_vm.filter.type === 'enum')?_c('m-select',{attrs:{"items":_vm.header.options.map((elem, i) => {
        return typeof elem === 'object'
          ? { Id: elem.id, text: elem.value }
          : { Id: i, text: elem };
      }),"item-text":"text","item-value":"Id","clearable":"","outlined":"","dense":"","hide-details":"","placeholder":_vm.placeholder},model:{value:(_vm.filter.value),callback:function ($$v) {_vm.$set(_vm.filter, "value", $$v)},expression:"filter.value"}}):(_vm.filter.type === 'boolean')?_c('m-select',{attrs:{"items":_vm.getBooleanItems(_vm.header),"item-text":"text","item-value":"Id","clearable":"","outlined":"","dense":"","hide-details":"","placeholder":_vm.placeholder},model:{value:(_vm.filter.value),callback:function ($$v) {_vm.$set(_vm.filter, "value", $$v)},expression:"filter.value"}}):(_vm.filter.type === 'number')?_c('v-text-field',{attrs:{"type":"number","clearable":"","outlined":"","dense":"","hide-details":"","hide-spin-buttons":"","placeholder":"Найти..."},model:{value:(_vm.filter.value),callback:function ($$v) {_vm.$set(_vm.filter, "value", $$v)},expression:"filter.value"}}):(_vm.filter.type === 'string')?_c('v-text-field',{attrs:{"clearable":"","outlined":"","dense":"","hide-details":"","placeholder":"Найти..."},model:{value:(_vm.filter.value),callback:function ($$v) {_vm.$set(_vm.filter, "value", $$v)},expression:"filter.value"}}):(_vm.filter.type === 'object' || _vm.filter.type === 'array')?_c('m-autocomplete',{attrs:{"flat":"","hide-details":"","small":"","multiple":"","clearable":"","outlined":"","dense":"","dict":_vm.header.dict ? _vm.header.dict : null,"items":_vm.sortAlf(_vm.columnValueList(_vm.header.value), _vm.header),"item-text":_vm.header.dict && _vm.header.dict.serverName
        ? 'Name'
        : (item) => _vm.columnObjectText(_vm.header, item),"placeholder":_vm.placeholder},model:{value:(_vm.filter.value),callback:function ($$v) {_vm.$set(_vm.filter, "value", $$v)},expression:"filter.value"}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }