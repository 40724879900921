import Vue from "vue";
import VueRouter from "vue-router";

import ParentLayer from "../views/ParentLayer";
import home from "@/views/home";
import Profile from "../views/system/Profile";
import CheckOperationRun from "../views/audit/CheckOperationRun";
import AuditOperations from "../views/audit/AuditOperations2";
import AuditOperationEdit from "../views/audit/AuditOperationEdit";
import instructions from "../views/instructions";
import MoreReports from "../views/MoreReports";

import Tasks from "../views/task/Tasks";
import TaskEdit from "../views/task/TaskEdit";
import CheckLists from "../views/CheckList/CheckLists";
import CheckListEdit from "../views/CheckList/CheckListEdit";
import CheckListClone from "../views/CheckList/CheckListClone";
import OrganizationEdit from "../views/organization/OrganizationEdit";
import Organizations from "../views/organization/Оrganizations";
import Employees from "../views/organization/Employees";
import EmployeeEdit from "../views/organization/EmployeeEdit";
import Positions from "../views/organization/Positions";
import PositionEdit from "../views/organization/PositionEdit";
import Partitions from "../views/organization/Partitions";
import PartitionEdit from "../views/organization/PartitionEdit";
import Projects from "@/views/task/Projects";
import ProjectEdit from "@/views/task/ProjectEdit";
import Equipments from "../views/organization/Equipments";
import EquipmentEdit from "../views/organization/EquipmentEdit";

import AppliedDocumentTypes from "../views/common/AppliedDocumentTypes";
import EquipmentStandarts from "../views/common/EquipmentStandarts";
import EquipmentStandartView from "../views/common/EquipmentStandartView";
import Contragents from "../views/organization/Contragents";
import ContragentEdit from "../views/organization/ContragentEdit";
import ReportSettingsEdit from "../views/system/ReportSettingsEdit";
import AuditIndicators from "../views/common/AuditIndicators";
import AuditIndicatorEdit from "../views/common/AuditIndicatorEdit";
import AuditIndicatorCategories from "../views/common/AuditIndicatorCategories";
import AuditIndicatorCategoriesEdit from "../views/common/AuditIndicatorCategoriesEdit";
import AnalysisAudit from "../views/analysis/AnalysisAudit";
import AnalysisIndicators from "../views/analysis/AnalysisIndicators";
import UnwishedEvents from "../views/unwishedEvents/UnwishedEvents";
import UnwishedEventEdit from "../views/unwishedEvents/UnwishedEventEdit";
import UnwishedEventResponsibles from "../views/unwishedEvents/UnwishedEventResponsibles";
import UnwishedEventResponsiblesEdit from "../views/unwishedEvents/UnwishedEventResponsiblesEdit";
import UnwishedEventTypes from "../views/unwishedEvents/UnwishedEventTypes";
import UnwishedEventTypesEdit from "../views/unwishedEvents/UnwishedEventTypesEdit";
import UnwishedConsequenceTypes from "../views/unwishedEvents/UnwishedConsequenceTypes";

import statisticIndicators from "../views/statisticIndicator/statisticIndicators";
import statisticIndicatorEdit from "../views/statisticIndicator/statisticIndicatorEdit";
import { localizeReportNames } from "../data/localization";

import AnalysisTform from "../views/analysis/tfoms/AnalysisTform";
import AnalysisKibmd from "../views/analysis/kibmd/index.vue";

import NewsEdit from "../views/news/NewsEdit.vue";

const dynamicPage = (importer) => () => {
  return importer().then((mod) => mod.default || mod);
};

const ConsolidatedReportEdit = dynamicPage(() =>
  import("../views/consolidatedReport/consolidatedReportEdit")
);

const ConsolidatedReports = dynamicPage(() =>
  import("../views/consolidatedReport/consolidatedReports")
);

const KnowledgeBaseSubjects = dynamicPage(() =>
  import("../views/knowledgeBase/subjects")
);
const KnowledgeBaseMy = dynamicPage(() => import("../views/knowledgeBase/my"));
const KnowledgeBaseBase = dynamicPage(() =>
  import("../views/knowledgeBase/base")
);
const KnowledgeBaseAll = dynamicPage(() =>
  import("../views/knowledgeBase/all")
);
const KnowledgeBaseCourseEdit = dynamicPage(() =>
  import("../views/knowledgeBase/CourseEdit")
);
const KnowledgeBaseMatarialEdit = dynamicPage(() =>
  import("../views/knowledgeBase/MaterialEdit")
);
const KnowledgeBaseTestEdit = dynamicPage(() =>
  import("../views/knowledgeBase/testing/TestingEdit")
);

const ReportPage = dynamicPage(() => import("../views/ReportPage"));

const LocalDocuments = dynamicPage(() =>
  import("../views/document/LocalDocuments")
);
const DocumentTemplates = dynamicPage(() =>
  import("../views/document/DocumentTemplates")
);
const DocumentSample = dynamicPage(() =>
  import("../views/document/DocumentSample")
);
const LocalDocumentEdit = dynamicPage(() =>
  import("../views/document/LocalDocumentEdit")
);
const DocumentTemplateEdit = dynamicPage(() =>
  import("../views/document/DocumentTemplateEdit")
);

const News = dynamicPage(() => import("../views/news/News.vue"));

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: home,
  },
  {
    path: "/news",
    name: "NewsGroup",
    component: ParentLayer,
    children: [
      {
        path: ":type",
        name: "News",
        component: News,
      },
      {
        path: "edit/:objectId",
        name: "NewsEdit",
        component: NewsEdit,
      },
    ],
  },
  {
    path: "/instructions",
    name: "Instructions",
    component: instructions,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/documents",
    name: "DocumentsGroup",
    component: ParentLayer,
    children: [
      {
        path: "local/:type",
        name: "LocalDocuments",
        component: LocalDocuments,
      },
      {
        path: "local/edit/:objectId",
        name: "LocalDocumentEdit",
        component: LocalDocumentEdit,
      },
      {
        path: "templates",
        name: "DocTemplateGroup",
        component: ParentLayer,
        children: [
          {
            path: "view/template",
            name: "DocumentTemplates",
            component: DocumentTemplates,
          },
          {
            path: "view/sample",
            name: "DocumentSample",
            component: DocumentSample,
          },
          {
            path: ":type/edit/:objectId",
            name: "DocumentTemplateEdit",
            component: DocumentTemplateEdit,
          },
        ],
      },
    ],
  },
  {
    path: "/education",
    name: "EducationGroup",
    component: ParentLayer,
    children: [
      {
        path: "courses",
        name: "CoursesGroup",
        component: ParentLayer,
        children: [
          {
            path: "my",
            name: "KnowledgeBaseMy",
            component: KnowledgeBaseMy,
          },
          {
            path: "all",
            name: "KnowledgeBaseAll",
            component: KnowledgeBaseAll,
          },
          {
            path: "topics",
            name: "KnowledgeBaseSubjects",
            component: KnowledgeBaseSubjects,
          },
          {
            path: "base",
            name: "CoursesBaseGroup",
            component: ParentLayer,
            children: [
              {
                path: "view",
                name: "KnowledgeBaseView",
                component: KnowledgeBaseBase,
              },
              {
                path: "edit/:objectId",
                name: "KnowledgeBaseBaseCourseEdit",
                component: KnowledgeBaseCourseEdit,
                props: { courseType: "base" },
              },
              {
                path: "extension/:objectId",
                name: "KnowledgeBaseCourseEdit",
                component: KnowledgeBaseCourseEdit,
              },
              {
                path: "extension-material/:objectId",
                name: "KnowledgeBaseMatarialEdit",
                component: KnowledgeBaseMatarialEdit,
              },
              {
                path: "extension-test/:objectId",
                name: "KnowledgeBaseTestEdit",
                component: KnowledgeBaseTestEdit,
              },
            ],
          },
          {
            path: "execution/:objectId",
            name: "KnowledgeBaseCourseEmployeeEdit",
            component: KnowledgeBaseCourseEdit,
            props: { isEmployee: true },
          },
          {
            path: "execution-material/:objectId",
            name: "KnowledgeBaseMatarialEmployeeEdit",
            component: KnowledgeBaseMatarialEdit,
            props: { isEmployee: true },
          },
          {
            path: "execution-test/:objectId",
            name: "KnowledgeBaseTestEmployeeEdit",
            component: KnowledgeBaseTestEdit,
            props: { isEmployee: true },
          },
        ],
      },
    ],
  },
  {
    path: "/control",
    name: "ControlGroup",
    component: ParentLayer,
    children: [
      {
        path: "checks",
        name: "ChecksGroup",
        component: ParentLayer,
        children: [
          {
            path: "view/:type",
            name: "AuditOperations",
            component: AuditOperations,
            customTabText: function (to) {
              const type = to.params.type;
              return type === "all"
                ? "Все проверки"
                : type === "my"
                ? "Мои проверки"
                : type === "external"
                ? "Внешние проверки"
                : "unknown type";
            },
          },
          {
            path: "edit/:objectId",
            name: "AuditOperationEdit",
            component: AuditOperationEdit,
          },
        ],
      },
      {
        path: "check-lists",
        name: "CheckListsGroup",
        component: ParentLayer,
        children: [
          {
            path: "view",
            name: "CheckLists",
            component: CheckLists,
          },
          {
            path: "edit/:objectId",
            name: "CheckListEdit",
            component: CheckListEdit,
          },
          {
            path: "clone",
            name: "CheckListClone",
            component: CheckListClone,
          },
        ],
      },
      {
        path: "indicators",
        name: "IndicatorsGroup",
        component: ParentLayer,
        children: [
          {
            path: "view",
            name: "AuditIndicators",
            component: AuditIndicators,
          },
          {
            path: "edit/:objectId",
            name: "AuditIndicatorEdit",
            component: AuditIndicatorEdit,
          },
          // Не используется "Шаблоны показателей оценки качества"
          // {
          //   path: "/AuditIndicatorTemplates",
          //   name: "AuditIndicatorTemplates",
          //   component: AuditIndicatorTemplates,
          // },
          // {
          //   path: "/AuditIndicatorTemplateView/:objectId",
          //   name: "AuditIndicatorTemplateView",
          //   component: AuditIndicatorTemplateView,
          // },
        ],
      },
    ],
  },
  {
    path: "/unwished-events",
    name: "UnwishedEventsGroup",
    component: ParentLayer,
    children: [
      {
        path: "journal",
        name: "UnwishedEventsJournal",
        component: UnwishedEvents,
      },
      {
        path: "edit/:objectId",
        name: "UnwishedEventEdit",
        component: UnwishedEventEdit,
      },
      {
        path: "responsibles",
        name: "ResponsiblesGroup",
        component: ParentLayer,
        children: [
          {
            path: "view",
            name: "UnwishedEventResponsibles",
            component: UnwishedEventResponsibles,
          },
          {
            path: "edit/:objectId",
            name: "UnwishedEventResponsiblesEdit",
            component: UnwishedEventResponsiblesEdit,
          },
        ],
      },
      {
        path: "types",
        name: "TypesGroup",
        component: ParentLayer,
        children: [
          {
            path: "view",
            name: "UnwishedEventTypes",
            component: UnwishedEventTypes,
          },
          {
            path: "edit/:objectId",
            name: "UnwishedEventTypesEdit",
            component: UnwishedEventTypesEdit,
          },
        ],
      },
      {
        path: "consequence",
        name: "UnwishedConsequenceTypes",
        component: UnwishedConsequenceTypes,
      },
    ],
  },
  {
    path: "/statistics",
    name: "StatisticsGroup",
    component: ParentLayer,
    children: [
      {
        path: "view",
        name: "StatisticIndicators",
        component: statisticIndicators,
      },
      {
        path: "edit/:objectId",
        name: "StatisticIndicatorEdit",
        component: statisticIndicatorEdit,
      },
    ],
  },
  {
    path: "/equipments",
    name: "EquipmentsGroup",
    component: ParentLayer,
    children: [
      {
        path: "view",
        name: "Equipments",
        component: Equipments,
      },
      {
        path: "edit/:objectId",
        name: "EquipmentEdit",
        component: EquipmentEdit,
      },
    ],
  },
  {
    path: "/analysis",
    name: "AnalysisGroup",
    component: ParentLayer,
    children: [
      {
        path: "view",
        name: "AnalysisIndicators",
        component: AnalysisIndicators,
      },
      {
        path: "check-operation-run/:objectId",
        name: "CheckOperationRun",
        component: CheckOperationRun,
      },
      {
        path: "audit/:objectId",
        name: "AnalysisAudit",
        component: AnalysisAudit,
      },

      {
        path: "tfoms",
        name: "AnalysisTform",
        component: AnalysisTform,
      },

      {
        path: "kibmd",
        name: "AnalysisKibmd",
        component: AnalysisKibmd,
      },
    ],
  },
  {
    path: "/organization",
    name: "OrganizationGroup",
    component: ParentLayer,
    children: [
      {
        path: "requisites",
        name: "RequisitesGroup",
        component: ParentLayer,
        children: [
          {
            path: "edit",
            name: "OrganizationEdit",
            component: OrganizationEdit,
          },
        ],
      },
      {
        path: "organizations",
        name: "Organizations",
        component: Organizations,
      },
      {
        path: "divisions",
        name: "DivisionsGroup",
        component: ParentLayer,
        children: [
          {
            path: "view",
            name: "Partitions",
            component: Partitions,
          },
          {
            path: "edit/:objectId",
            name: "PartitionEdit",
            component: PartitionEdit,
          },
        ],
      },
      {
        path: "Position",
        name: "Position",
        component: ParentLayer,
        children: [
          {
            path: "view",
            name: "Positions",
            component: Positions,
          },
          {
            path: "edit/:objectId",
            name: "PositionEdit",
            component: PositionEdit,
          },
        ],
      },
      {
        path: "employees",
        name: "EmployeesGroup",
        component: ParentLayer,
        children: [
          {
            path: "view",
            name: "Employees",
            component: Employees,
          },
          {
            path: "edit/:objectId",
            name: "EmployeeEdit",
            component: EmployeeEdit,
          },
        ],
      },
      {
        path: "equipment-standarts",
        name: "EquipmentStandartsGroup",
        component: ParentLayer,
        children: [
          {
            path: "view",
            name: "EquipmentStandarts",
            component: EquipmentStandarts,
          },
          {
            path: "edit/:objectId",
            name: "EquipmentStandartView",
            component: EquipmentStandartView,
          },
        ],
      },
      {
        path: "activity-sections",
        name: "ActivitySectionsGroup",
        component: ParentLayer,
        children: [
          {
            path: "view",
            name: "AuditIndicatorCategories",
            component: AuditIndicatorCategories,
          },
          {
            path: "edit/:objectId",
            name: "AuditIndicatorCategoriesEdit",
            component: AuditIndicatorCategoriesEdit,
          },
        ],
      },
      {
        path: "contragents",
        name: "ContragentsGroup",
        component: ParentLayer,
        children: [
          {
            path: "view",
            name: "Contragents",
            component: Contragents,
          },
          {
            path: "edit/:objectId",
            name: "ContragentEdit",
            component: ContragentEdit,
          },
        ],
      },
      {
        path: "applied-document-types",
        name: "AppliedDocumentTypes",
        component: AppliedDocumentTypes,
      },
    ],
  },
  {
    path: "/reports",
    name: "ReportsGroup",
    component: ParentLayer,
    children: [
      {
        path: "consolidated",
        name: "consolidatedReport",
        component: ParentLayer,
        children: [
          {
            path: "view",
            name: "ConsolidatedReports",
            component: ConsolidatedReports,
          },
          {
            path: "edit/:objectId",
            name: "ConsolidatedReportEdit",
            component: ConsolidatedReportEdit,
          },
        ],
      },
      {
        path: "view",
        name: "MoreReports",
        component: MoreReports,
      },
      {
        path: "/ReportSettings",
        name: "ReportSettingsEdit",
        component: ReportSettingsEdit,
      },
      {
        path: "show/:report/:objectId",
        name: "ReportPage",
        customTabText: function (route) {
          const params = route.params;
          const paramsName = localizeReportNames[params.report];
          if (paramsName) return paramsName;
        },
        component: ReportPage,
      },
    ],
  },
  {
    path: "/tasks",
    name: "TasksGroup",
    component: ParentLayer,
    children: [
      {
        path: "view/:type",
        name: "Tasks",
        component: Tasks,
        customTabText: function (to) {
          const type = to.params.type;
          return type === "my"
            ? "Мои задачи"
            : type === "all"
            ? "Все задачи"
            : type === "control"
            ? "Задачи на контроле"
            : "Задачи";
        },
      },
      {
        path: "edit/:objectId",
        name: "TaskEdit",
        component: TaskEdit,
      },
    ],
  },
  {
    path: "/activity",
    name: "ActivityGroup",
    component: ParentLayer,
    children: [
      {
        path: "plans",
        name: "PlansGroup",
        component: ParentLayer,
        children: [
          {
            path: "view",
            name: "Plans",
            component: Projects,
          },
          {
            path: "edit/:objectId",
            name: "PlanEdit",
            component: ProjectEdit,
          },
        ],
      },
      {
        path: "projects",
        name: "ProjectsGroup",
        component: ParentLayer,
        children: [
          {
            path: "view",
            name: "Projects",
            component: Projects,
            customTabText: function (to) {
              return "Проекты";
            },
          },
          {
            path: "edit/:objectId",
            name: "ProjectEdit",
            component: ProjectEdit,
          },
        ],
      },
    ],
  },

  // Шаблоны листов
  // {
  //   path: "/CheckListTemplates",
  //   name: "CheckListTemplates",
  //   component: CheckListTemplates,
  // },
  // {
  //   path: "/CheckListTemplateView/:objectId",
  //   name: "CheckListTemplateView",
  //   component: CheckListTemplateView,
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
