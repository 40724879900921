import { findParent } from "./DomVueHelper";
import { compact } from "lodash";

const buildInfoPage = (item) => {
  const pageComponent = findParent(
    item,
    (e) => {
      return e.$data.dataSource && e.$data.dataSource !== undefined;
    },
    3
  );
  let infoPage = false;
  if (pageComponent) {
    infoPage = {
      name: pageComponent.$options.name,
      type: pageComponent.pageType,
    };
  }
  return infoPage;
};

// Функция убирает из квери не нужные параметры
// tab - установка таба в <m-tabs>
const normalizeFullPath = function (to) {
  const queryParamToString = function (params) {
    const p = Object.keys(params)
      .filter((e) => e !== "tab")
      .map((key) => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
      })
      .join("&");

    return p;
  };
  const params = queryParamToString(to.query);
  return compact([to.path, params]).join("?");
};

export { buildInfoPage, normalizeFullPath };
