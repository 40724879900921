<template>
  <tfoms-panel
    class="analysis-tfoms__base-table"
    small
    :title="title"
    :icon="icon"
    :icon-color="factColor"
    :show-more="$attrs['show-more']"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template slot="subTitle">
      {{ year }} год <span class="primary--text ml-3">{{ itemName }}</span>
    </template>
    <v-row style="flex-wrap: nowrap; text-align: right">
      <v-col
        v-if="meta"
        style="
          max-width: 120px;
          padding-right: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        "
      >
        <div class="analysis-tfoms__bar-date" v-if="meta.date">
          {{ meta.date }}
        </div>
        <div>
          <div class="analysis-tfoms__bar-value" v-if="meta.value">
            {{ meta.value }}
          </div>
          <div class="analysis-tfoms__bar-label" v-if="meta.label">
            {{ meta.label }}
          </div>
        </div>
      </v-col>
      <v-col>
        <chart-bar-percent
          v-bind="$attrs"
          :factColor="factColor"
          :planColor="planColor"
        >
        </chart-bar-percent>
      </v-col>
    </v-row>
    <div class="text-center" v-if="false">
      <v-btn color="primary mt-4">Подробнее</v-btn>
    </div>
  </tfoms-panel>
</template>
<script>
import chartBarPercent from "@/components/base/chart/chartBarPercent.vue";

export default {
  components: {
    chartBarPercent,
    tfomsPanel: () => import("@/components/analysis/panel.vue"),
  },

  props: {
    title: {
      type: String,
      default: "",
    },
    itemName: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "medexpert-users",
    },
    factColor: {
      type: String,
      default: "#5379FB",
    },
    planColor: {
      type: String,
      default: "#BBC6F0",
    },
    meta: {
      type: Object,
      default: null,
    },
    year: {
      type: Number,
      default: 2024,
    },
  },
  data() {
    return {};
  },

  methods: {},
};
</script>
<style lang="scss">
.analysis-tfoms__bar {
  &-date {
    margin-bottom: 30px;
    color: var(--div, --v-blue-grey-base);
    font-size: 20px;
    line-height: 158%;
    font-weight: 600;
  }

  &-value {
    color: var(--div, --v-blue-grey-base);

    font-size: 16px;
    line-height: 120%;
    font-weight: 400;
  }

  &-label {
    color: var(--div, --v-grey-base);

    font-size: 14px;
    line-height: 158%;
    font-weight: 400;
  }
}
</style>
