<template>
  <v-layout>
    <base-detail-view :title="title" :showSave="false" @onRefresh="init">
      <div class="block__content">
        <v-container
          ><v-skeleton-loader
            v-if="loading"
            type="list-item-avatar-three-line"
          ></v-skeleton-loader>
        </v-container>

        <v-container v-if="!loading">
          <v-row>
            <div class="pl-4 pt-3">
              <h4>Общая оценка результатов проверки</h4>
            </div>
          </v-row>
          <v-row align="center">
            <v-col>
              <div class="doughnut_chart">
                <doughnut-chart
                  :chart-data="doughnutData"
                  :options="doughnutOptions"
                  :common-percent="checkAnalysisValue(dataSource.Value) + '%'"
                ></doughnut-chart>
              </div>
            </v-col>
            <v-col>
              <span>
                {{ dataSource.Description }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div class="block__content" :style="{ marginTop: 10 + 'px' }">
        <v-container
          ><v-skeleton-loader v-if="loading" type="text@6"></v-skeleton-loader>
        </v-container>

        <v-container v-if="!loading">
          <v-row>
            <div class="pl-4 pt-3">
              <h4>Оценка результатов по чек-листам</h4>
            </div>
          </v-row>
          <v-row
            v-for="(checkListAnalysisValue, index) in dataSourceValuesSorted"
            :key="checkListAnalysisValue.CheckListId"
          >
            <v-col>
              {{ ++index + ". " + checkListAnalysisValue.Name }}
            </v-col>
            <v-col>
              <div>
                <v-progress-linear
                  v-if="checkListAnalysisValue.TotalQuestionCount !== 0"
                  color="var(--v-blue-grey-lighten1)"
                  height="30"
                  rounded
                  :value="checkListAnalysisValue.Value"
                >
                  {{ checkAnalysisValue(checkListAnalysisValue.Value) }}
                </v-progress-linear>
                <div v-else color="blue-grey">
                  {{
                    checkListAnalysisValue.NotApplicableCount > 0
                      ? "Не применимо 100%"
                      : "Проверка не проводилась"
                  }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div
        class="block__content"
        :style="{ marginTop: 10 + 'px' }"
        v-if="false"
      >
        <v-container>
          <v-skeleton-loader v-if="loading" type="paragraph, image">
          </v-skeleton-loader>
        </v-container>

        <v-container v-if="!loading">
          <v-row>
            <div class="pl-4 pt-3">
              <h4>Сравнительный анализ</h4>
            </div>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="title2"
                label="Текущая проверка"
                readonly
              ></v-text-field>
              <m-combobox
                v-model="auditOperation2"
                label="Проверка для сравнения"
                :items="audits"
                item-text="Title"
              ></m-combobox>
            </v-col>
            <v-col>
              <div class="radar_chart">
                <radar-chart
                  :chart-data="radarData"
                  :options="radarOptions"
                ></radar-chart>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </base-detail-view>
  </v-layout>
</template>

<script>
import BaseDetailView from "@/layouts/BaseDetailView";
import DoughnutChart from "@/views/analysis/DoughnutChart.js";
import RadarChart from "@/views/analysis/RadarChart.js";
import CreateAndEditMixin from "@/mixins/CreateAndEditMixin";
import AnalysisService from "@/services/AnalysisService";
import AuditOperationService from "@/services/AuditOperationService";
import mCombobox from "@/components/base/inputs/mCombobox";
import { sortBy } from "@/utils/Functions";
import StatisticIndicatorHelper from "../statisticIndicator/StatisticIndicatorHelper";

export default {
  name: "view-AnalysisAudit",
  components: {
    BaseDetailView,
    DoughnutChart,
    RadarChart,
    mCombobox,
  },
  mixins: [CreateAndEditMixin],
  data() {
    return {
      // Api
      apiService: AnalysisService,
      getObjectText: (obj) => "№" + obj.Num,

      loading: true,
      dataSource: {},
      audits: [],
      auditOperation1: null,
      auditOperation2: null,
      algorithm: 0,

      doughnutData: null,
      doughnutOptions: {
        tooltips: {
          enabled: false,
        },
      },

      radarData: null,
      radarOptions: {
        scale: {
          ticks: {
            min: 0,
            max: 100,
          },
        },
        elements: {
          line: {
            borderWidth: 1,
          },
        },
      },
    };
  },
  computed: {
    title() {
      return (
        "Анализ результатов " +
        this.AuditOperationKind +
        " проверки № " +
        (this.dataSource.Audit?.Num ? this.dataSource.Audit?.Num : "") +
        (this.dataSource.Audit?.DateIn
          ? " с " +
            this.$options.filters.DateShort(this.dataSource.Audit?.DateIn)
          : "") +
        (this.dataSource.Audit?.DateOut
          ? " по " +
            this.$options.filters.DateShort(this.dataSource.Audit?.DateOut)
          : "")
      );
    },
    title2() {
      return (
        (this.dataSource.Audit?.Num ? "№ " + this.dataSource.Audit?.Num : "") +
        (this.dataSource.Audit?.DateIn
          ? " с " +
            this.$options.filters.DateShort(this.dataSource.Audit?.DateIn)
          : "") +
        (this.dataSource.Audit?.DateOut
          ? " по " +
            this.$options.filters.DateShort(this.dataSource.Audit?.DateOut)
          : "")
      );
    },
    AuditOperationKind() {
      return this.dataSource.Audit?.Kind === 0 ? "плановой" : "внеплановой";
    },

    dataSourceValuesSorted() {
      return sortBy(this.dataSource.Values, "Value", false);
    },

    checkAnalysisValue() {
      return (value) => {
        return Math.round(+value);
      };
    },
  },
  watch: {
    auditOperation2(val) {
      if (val) this.loadAnalysisAudit2(val);
      else this.loadAnalysisAudit1();
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    DataFromServerNormalize(data) {
      data.Value = Math.round(data.Value);
      data.Values = data.Values.sort(
        (ch1, ch2) => ch1.CheckListId - ch2.CheckListId
      );
      return data;
    },
    async init() {
      try {
        this.loading = true;
        await this.loadAnalysisAudit1();
        this.auditOperation2 = null;

        const checkListIds = this.auditOperation1.CheckOperations.map(
          (v) => v.CheckList.Id
        ).filter((v, i, a) => a.indexOf(v) === i);

        if (checkListIds && checkListIds.length >= 3) {
          const { data } = await AuditOperationService.get(
            null,
            null,
            checkListIds,
            3,
            false
          );

          this.audits = data
            .map((obj) =>
              Object.assign({}, obj, {
                Title:
                  "№ " +
                  obj.Num +
                  " с " +
                  this.$options.filters.DateShort(obj.DateIn) +
                  " по " +
                  this.$options.filters.DateShort(obj.DateOut),
              })
            )
            .filter((a) => a.Id !== this.dataSource.Audit.Id);
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
      this.updateObjectText(this.dataSource.Audit, false);
    },

    // загрузка данных анализа проверки для текущего аудита
    async loadAnalysisAudit1() {
      const { data } = await AnalysisService.show(
        this.objectId,
        this.algorithm
      );

      this.dataSource = this.DataFromServerNormalize(data);
      this.doughnutData = {
        datasets: [
          {
            data: [this.dataSource.Value, 100 - this.dataSource.Value],
            backgroundColor: [
              StatisticIndicatorHelper.getColorHexByRefs(
                this.dataSource.RefValues,
                this.dataSource.Value,
                true
              ),
              "#BBC6F0",
            ],
            hoverOffset: 4,
          },
        ],
      };
      this.radarData = {
        labels: this.dataSource.Values.map((g, i) => ++i),
        datasets: [
          {
            label: `Проверка №${this.dataSource.Audit.Num}`,
            data: this.dataSource.Values.map((v) => Math.round(v.Value)),
            fill: true,
            backgroundColor: "rgba(187, 198, 240, 0.5)",
          },
        ],
      };

      const audit = await AuditOperationService.show(this.objectId);

      this.auditOperation1 = audit.data;
    },

    // загрузка данных анализа проверки для выбранного аудита
    async loadAnalysisAudit2({ Id }) {
      if (!Id) return;

      const { data } = await AnalysisService.show(Id, this.algorithm);

      // для добавления нового объекта в свойство datasets объекта radarData используется Object.assign(),
      // чтобы добавленное свойство стало реактивным
      // https://ru.vuejs.org/v2/guide/reactivity.html#%D0%94%D0%BB%D1%8F-%D0%BE%D0%B1%D1%8A%D0%B5%D0%BA%D1%82%D0%BE%D0%B2
      this.radarData = Object.assign({}, this.radarData, {
        datasets: [
          this.radarData.datasets[0],
          {
            label: `Проверка №${data.Audit.Num}`,
            data: data.Values.sort(
              (ch1, ch2) => ch1.CheckListId - ch2.CheckListId
            ).map((v) => Math.round(v.Value)),
            fill: true,
            backgroundColor: "rgba(232,236,255, 0.5)",
          },
        ],
      });
    },
  },
};
</script>

<style lang="sass">
.doughnut_chart
  max-width: 200px
  margin: 10px

.radar_chart
  max-width: 600px
  margin: 10px
</style>
