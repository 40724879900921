<template>
  <div v-if="filter">
    <base-date-picker-range
      v-if="filter.type === 'Date'"
      v-model="filter.value"
      outlined
      dense
      hide-label
      clearable
      :placeholder="placeholder"
    />
    <base-date-picker-range
      v-else-if="filter.type === 'Period'"
      v-model="filter.value"
      outlined
      dense
      hide-label
      clearable
      :placeholder="placeholder"
    />
    <m-select
      v-else-if="filter.type === 'enum'"
      v-model="filter.value"
      :items="
        header.options.map((elem, i) => {
          return typeof elem === 'object'
            ? { Id: elem.id, text: elem.value }
            : { Id: i, text: elem };
        })
      "
      item-text="text"
      item-value="Id"
      clearable
      outlined
      dense
      hide-details
      :placeholder="placeholder"
    ></m-select>

    <m-select
      v-else-if="filter.type === 'boolean'"
      v-model="filter.value"
      :items="getBooleanItems(header)"
      item-text="text"
      item-value="Id"
      clearable
      outlined
      dense
      hide-details
      :placeholder="placeholder"
    ></m-select>

    <v-text-field
      v-else-if="filter.type === 'number'"
      v-model="filter.value"
      type="number"
      clearable
      outlined
      dense
      hide-details
      hide-spin-buttons
      placeholder="Найти..."
    ></v-text-field>
    <v-text-field
      v-else-if="filter.type === 'string'"
      v-model="filter.value"
      clearable
      outlined
      dense
      hide-details
      placeholder="Найти..."
    ></v-text-field>

    <m-autocomplete
      v-else-if="filter.type === 'object' || filter.type === 'array'"
      flat
      hide-details
      small
      multiple
      clearable
      outlined
      dense
      :dict="header.dict ? header.dict : null"
      :items="sortAlf(columnValueList(header.value), header)"
      v-model="filter.value"
      :item-text="
        header.dict && header.dict.serverName
          ? 'Name'
          : (item) => columnObjectText(header, item)
      "
      :placeholder="placeholder"
    >
    </m-autocomplete>
  </div>
</template>
<script>
import { sortBy } from "@/utils/Functions";
import DataHelper from "../../utils/DataHelper";
export default {
  components: {
    mAutocomplete: () => import("@/components/base/inputs/mAutocomplete.vue"),
    mSelect: () => import("@/components/base/inputs/mSelect.vue"),
    BaseDatePickerRange: () =>
      import("@/components/base/BaseDatePickerRange.vue"),
  },
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
    header: {
      type: Object,
      default: () => {},
    },
    filter: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },

  methods: {
    getBooleanItems(header) {
      return [
        { Id: false, text: header?.options?.false || "Нет" },
        { Id: true, text: header?.options?.true || "Да" },
      ];
    },
    sortAlf(items, header) {
      items = sortBy(items, (e) => {
        return this.columnObjectText(header, e, e);
      });
      return items;
    },

    columnObjectText(header, object, item) {
      // Если значение составное. Примерно value = Employee.Place
      if (object === undefined) {
        object = this._.get(item, header.value);
      }
      if (header?.displayText) {
        return header?.displayText(object, item);
      } else {
        return object.Id;
      }
    },

    // Возвращает список уникальных объектов для фильтра по объектам или массивам объектов
    columnValueList(val) {
      const result = [];
      const ids = [];

      DataHelper.treeListTraverse(this.dataSource, (item) => {
        const elem = this._.get(item, val);
        if (Array.isArray(elem)) {
          for (const item of elem) AddItem(item);
        } else AddItem(elem);
      });

      // this.chechItems(result, val);
      return result;
      function AddItem(item) {
        if (item && item.Id && !ids[item.Id]) {
          ids[item.Id] = true;
          result.push(item);
        }
      }
    },

    chechItems: function (items, val) {
      const filter = this.filter.value;

      if (filter?.length) {
        const deleteItems = filter.filter((e) => {
          return !items.find((e2) => e2 === e || e2?.Id === e);
        });
        deleteItems.forEach((element) => {
          filter.splice(filter.indexOf(element), 1);
        });
      }
    },
  },
};
</script>
