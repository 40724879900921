import Api from "./Api";

export default {
  async show(
    algorithm,
    useSettings,
    dates,
    auditIndicatorIds,
    loadIndicators = false,
    onlyWithCheckLists = false,
    orderByIndicators = false
  ) {
    return Api().post("auditOperation/GetCommonAnalysis", {
      algorithm,
      useSettings,
      dates,
      auditIndicatorIds,
      loadIndicators,
      onlyWithCheckLists,
      orderByIndicators,
    });
  },
};
