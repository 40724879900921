import Api from "./Api";

export default {
  async get(
    typeId = 0,
    independent = false,
    full = false,
    partitionId = 0,
    equipmentId = 0,
    employeeId = 0,
    contragentId = 0
  ) {
    return Api().get("document/getAppliedDocuments", {
      params: {
        typeId,
        independent,
        full,
        partitionId,
        equipmentId,
        employeeId,
        contragentId,
      },
    });
  },
  async show(id) {
    return Api().get("document/getAppliedDocument", { params: { id } });
  },
  async create(document) {
    return Api().post("document/createAppliedDocument", document);
  },
  async update(document) {
    return Api().put("document/updateAppliedDocument", document);
  },
  async delete(id) {
    return Api().delete("document/deleteAppliedDocument", { params: { id } });
  },
};
