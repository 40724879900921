<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      :width="width"
      scrollable
      :persistent="persistent"
    >
      <template
        v-for="name in Object.keys($scopedSlots).filter(
          (slot) => slot !== 'default'
        )"
        :slot="name"
        slot-scope="slotData"
        ><slot :name="name" v-bind="slotData"
      /></template>
      <v-card :height="height" style="contain: layout" :color="color">
        <v-card-text class="mt-5">
          <component v-bind:is="cmp" v-bind="properties"></component>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: String,
    value: Boolean,
    comp: {
      type: Object,
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    id: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: "#FFFFFF",
    },
  },
  data: () => {
    return {
      dialog: false,
      cmp: null,
      properties: {},
      persistent: false,
    };
  },

  computed: {
    item: {
      get() {
        return this.$store.getters["frame/GET_DATA"](this.id);
      },
    },
    params: {
      get() {
        return this.$store.getters["frame/GET_DATA"](this.id).params;
      },
    },
    paramObjectId: {
      get() {
        return this.$store.getters["frame/GET_DATA"](this.id).params._objectId;
      },
    },
    paramType: {
      get() {
        return this.$store.getters["frame/GET_DATA"](this.id).params._type;
      },
    },
  },
  watch: {
    dialog: function (val) {
      this.$emit("input", val);
      this.$store.dispatch("frame/SHOW_DIALOG", { show: val, id: this.id });
    },
    value: function (val) {
      this.dialog = val;
    },
    "item.componentName": {
      immediate: true,
      handler() {
        if (!this.item.componentName) return;
        import("../views/" + this.item.componentName).then((c) => {
          this.properties = {
            nested: this.params?.nested ?? true,
            ...this.item.params,
          };
          this.cmp = c.default;
        });
        this.dialog = true;
      },
    },
    params(val) {
      if (!val._objectId) return;
      this.applyParameters();
    },
    "item.dialog": function (val) {
      this.dialog = val;
    },
    "item.reset": function (val) {
      if (val) {
        this.$store.commit("frame/SET_RESET", { val: false, id: this.id });
        this.reset();
      }
    },
    "item.persistent": function (val) {
      this.persistent = val;
    },
  },
  methods: {
    reset() {
      this.dialog = false;
      this.cmp = null;
      this.properties = {};
    },
    // передаёт все props открываемому компоненту
    applyParameters() {
      this.properties = {
        nested: true, // по умолчанию все компоненты считаются дочерними
        ...this.item.params,
      };
      this.dialog = true;
    },
  },
};
</script>

<style></style>
