<template>
  <tfoms-panel
    class="analysis-tfoms__base-table analysis-tfoms__lost-income"
    small
    title="Выполнение плана"
    icon="mdi-currency-rub"
    icon-color="var(--v-error-lighten1)"
    header-hidden
  >
    <div
      class="d-flex justify-space-around analysis-tfoms__lost-income-wrap"
      title=""
    >
      <div class="analysis-tfoms__lost-income-mata">
        <div class="analysis-tfoms__lost-income-date">
          {{ tfomsParams.value.textSelectPeriod }}
          {{ queryParams.params.year }} г.
        </div>
        <div class="analysis-tfoms__lost-income-name">Выполнение плана</div>
        <div
          class="analysis-tfoms__lost-income-value"
          :class="{
            'error--text': percent < 100,
            'success--text': percent > 100,
          }"
        >
          {{ percent }}%
        </div>
      </div>
      <div class="mr-8">
        <base-table
          :items="data"
          :headers="[
            {
              text: '',
              value: 'name',
              cellClass: 'text-no-wrap',
              sortable: false,
            },
            {
              text: '',
              value: 'value',
              align: 'center',
              cellClass: 'text-no-wrap',
              sortable: false,
            },
          ]"
          hide-default-footer
          notShowSelect
          nested
          :showSettings="false"
        >
          <template slot="item.name" slot-scope="{ item }">
            <div>{{ item.name.title }}</div>
          </template>
          <template slot="item.value" slot-scope="{ item, index }">
            <span :class="{ 'error--text': item.value < 0 }"
              ><span v-if="item.value">{{ item.value | numberSplit }}</span>
              <span v-else>-</span>

              <v-tooltip v-if="index == 0 && item.value === 0" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <m-icon
                    icon="mdi-information-outline"
                    color="warning"
                    style="position: relative; left: 7px; cursor: pointer"
                    v-bind="attrs"
                    v-on="on"
                  ></m-icon>
                </template>
                Для отражения % выполнения плана по объемам необходимо выбрать
                условие и вид оказания МП
              </v-tooltip>
            </span>
          </template>
        </base-table>
      </div>
    </div>
  </tfoms-panel>
</template>
<script>
import tfomsPanel from "@/components/analysis/panel.vue";
import BaseTable from "@/components/base/BaseTable";

export default {
  components: {
    tfomsPanel,
    BaseTable,
  },
  inject: {
    provideUnits: {
      default: () => ({
        rub: null,
        unit: null,
      }),
    },
    queryParams: {
      default: () => ({
        params: {},
      }),
    },
    tfomsParams: {
      default: () => ({}),
    },
  },
  props: {
    itemName: {
      type: String,
      default: "",
    },
    value: {
      type: Object,
      default: () => ({
        month: "Итог",
        plan: 0,
        fact: 0,
        plan2: 0,
        fact2: 0,
        sh: 0,
        s: 0,
      }),
    },
    plan: {
      type: Number,
      default: 0,
    },
    fact: {
      type: Number,
      default: 0,
    },
    percent: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      data: [
        {
          icon: { name: "mdi-currency-rub", color: "var(--v-error-lighten3)" },
          name: {
            title: "План",
            subTitle: this.provideUnits.rub.text,
          },
          value: this.plan,
        },
        {
          icon: { name: "mdi-currency-rub", color: "var(--v-error-base)" },
          name: {
            title: "Факт",
            subTitle: this.provideUnits.rub.text,
          },
          value: this.fact,
        },
        {
          icon: { name: "mdi-currency-rub", color: "var(--v-blue-grey-base)" },
          name: {
            title: "Отклонение",
            subTitle: this.provideUnits.rub.text,
          },
          value: this.percent,
        },
      ],
    };
  },
  computed: {},
  watch: {
    plan: {
      immediate: true,
      handler: function (val) {
        this.init();
      },
    },
    fact: {
      immediate: true,
      handler: function (val) {
        this.init();
      },
    },
  },
  methods: {
    init() {
      this.data[0].value = this.plan;
      this.data[1].value = this.fact;
      this.data[2].value = parseFloat((this.fact - this.plan).toFixed(2));
    },
  },
};
</script>
<style lang="scss">
.analysis-tfoms__lost-income {
  &-wrap {
    padding: 60px 40px;
  }
  &-mata {
    text-align: center;
  }
  &-name {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &-date {
    color: var(--v-primary-base);
  }

  &-value {
    font-size: 36px;
  }

  .v-data-table-header {
    display: none;
  }
  .v-data-table {
    width: 200px;
  }
  tr,
  td {
    height: 30px !important;
    padding: 0 !important;
  }
}
</style>
