<template>
  <base-list-view
    :dataSource="dataSource"
    :headers="headers"
    :loading="loading"
    @onRefresh="init"
    @customDelete="customDelete"
    show-actions
    :editable="!readonly"
    :showDelete="false"
    :disabledAdd="readonly"
    hideEdit
    title="Ответственные за результаты нежелательных событий"
    label-text-btn-create="Добавить ответственного"
    label-text-search="Поиск ответственного"
    notShowSelect
    pageEditName="UnwishedEventResponsiblesEdit"
    :disabledDelete="(item) => item.OnlyRead"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :showFilters="true"
  >
    <template #item.Employee.User.Picture="scope">
      <v-avatar style="background-color: var(--v-blue-grey-lighten3)">
        <v-img
          v-if="scope.item.Employee.User.Picture"
          :src="getMediaByExternalId(scope.item.Employee.User.Picture)"
        ></v-img>
        <span v-else>
          {{ scope.item.Employee | PersonInitials }}
        </span>
      </v-avatar>
    </template>
    <template #item.Employee="scope">
      <span>{{ $options.filters.PersonShortName(scope.item.Employee) }}</span>
    </template>
    <template #item.Types="scope">
      <span>{{
        scope.item.Types.length
          ? scope.item.Types.map((t) => t.Name).join(", ")
          : "Все"
      }}</span>
    </template>

    <template #item.Partitions="scope">
      <span>{{
        scope.item.Partitions.length
          ? scope.item.Partitions.map((t) => t.Name).join(", ")
          : "Все"
      }}</span>
    </template>
  </base-list-view>
</template>

<script>
import UnwishedEventResponsibleService from "../../services/UnwishedEventResponsibleService";
import Permissions from "../../data/permissions";
import { mapActions } from "vuex";
import BaseListView from "../../layouts/BaseListView.vue";
import ListEditMixin from "../../mixins/ListEditMixin";
import DataHelper from "../../utils/DataHelper";

export default {
  name: "view-UnwishedEventResponsibles",
  components: { BaseListView },
  mixins: [ListEditMixin],

  data: function () {
    return {
      sortBy: ["Employee"],
      sortDesc: [false],
      loading: true,
      apiService: UnwishedEventResponsibleService,
      headers: [
        {
          text: "Фото",
          value: "Employee.User.Picture",
          align: "center",
          sortable: false,
          notGroup: true,
          notFilter: true,
        },
        {
          text: "Сотрудник",
          value: "Employee",
          dataType: "object", // перестает работать редактирование
          displayText: (e) => this.$options.filters.PersonShortName(e),
          width: "210px",
          cellClass: "text-no-wrap",
        },
        {
          text: "Виды событий",
          value: "Types",
          dataType: "array",
          displayText: (t) => t.Name,
        },
        {
          text: "Структурное подразделение",
          value: "Partitions",
          dataType: "array",
          displayText: (t) => t?.Name,
          customSort: "NumWithString",
        },
      ],
      dataSource: [],
      employees: [],
      eventTypes: [],
      editIndex: null,
      partitions: [],
    };
  },
  computed: {
    readonly() {
      return !this.hasPermission(Permissions.UnwishedEventResponsibleEdit);
    },
  },
  watch: {
    "$store.state.partitions": function (val) {
      this.partitions = val;
    },
  },
  async mounted() {
    try {
      this.employees = await this.LOAD_EMPLOYEES();
      this.eventTypes = await this.LOAD_UNWISHED_EVENT_TYPES();
      this.partitions = await this.LOAD_PARTITIONS();
    } catch (error) {
      console.log(error);
    }
    await this.init();
  },
  methods: {
    ...mapActions([
      "LOAD_EMPLOYEES",
      "LOAD_UNWISHED_EVENT_TYPES",
      "LOAD_PARTITIONS",
    ]),
    DataFromServerNormalize(data) {
      for (let i = 0; i < data.length; i++) {
        data[i].TypesText = data[i].Types.map((e) => e.Name).join(", ");
      }
      return data;
    },
    // TODO: Решает проблему дублирование выбраных элементов в v-tree-select
    getItemsTreeSelect(Partitions) {
      const arr = [];
      for (let i = 0; i < Partitions.length; i++) {
        const item = DataHelper.findTreeById(this.partitions, Partitions[i].Id);

        if (item) arr.push(item);
      }
      return arr;
    },
    inputPartition(rowEditing, obj) {
      if (!rowEditing) return;
      rowEditing.Partitions = obj;
    },
    async customInplaceEdit(value) {
      if (!value) return;
      const res = (
        await UnwishedEventResponsibleService[
          value.Id <= 0 ? "create" : "update"
        ](value)
      ).data;
      return res;
    },
    async customDelete(value) {
      if (!value) return;
      if (value.Id > 0) await UnwishedEventResponsibleService.delete(value.Id);
      const index = this.dataSource.findIndex((item) => item.Id === value.Id);
      if (index >= 0) this.dataSource.splice(index, 1);
      this.editIndex = -1;
    },
  },
};
</script>
