export default {
  inject: {
    provideUnits: {
      default: () => ({
        rub: null,
        unit: null,
      }),
    },
    queryParams: {
      default: () => ({
        params: {},
      }),
    },
    detailParams: {
      default: () => ({
        params: {},
      }),
    },
    tfomsParams: {
      default: () => ({}),
    },
  },
  props: {
    unit: {
      type: Object,
      default: () => ({
        id: 1,
        value: "Тыс. случаев",
        text: "Тыс. случаев",
        alpha: 1000,
      }),
    },
    rub: {
      type: Object,
      default: () => ({
        id: 1,
        value: "Тыс. руб.",
        text: "Тыс. руб.",
        alpha: 1000,
      }),
    },
  },
  computed: {
    subTitlePeriod() {
      return [
        this.tfomsParams.value.textSelectPeriod,
        this.queryParams.params.year + " год",
      ]
        .filter(Boolean)
        .join(" ");
    },
  },
  mounted() {
    this.$watch(
      () => this.provideUnits.rub,
      () => {
        this.init(this.queryParams.params);
      }
    );
  },
  watch: {
    "provideUnits.rub"() {
      // console.log("provideUnits.rub", this);
      // this.init(this.queryParams.params);
    },
    "provideUnits.unit"() {
      this.init(this.queryParams.params);
    },
  },
  methods: {
    getMonthValues(data, defaultValue = [null, null]) {
      const res = Array(12).fill(defaultValue);
      data.forEach((element) => {
        res[element.id - 1] = element.value;
      });
      return res;
    },
    getValues(data, palanName = "VolumePlanValue", factName = "VolumeValue") {
      const res = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      for (let i = 0; i < data.length; i++) {
        let p = Math.round((data[i][factName] / data[i][palanName]) * 100);
        if (!data[i][factName]) {
          p = 0;
        } else if (!data[i][palanName]) {
          p = 100;
        }

        res[data[i].Id - 1] = {
          percent: p !== Infinity ? p : 100,
          fact: data[i][factName] || 0,
          plan: data[i][palanName] || 0,
        };
      }

      return res;
    },
    getRub(num) {
      if (this.provideUnits.rub.id === 0) {
        return num || 0;
      } else if (this.provideUnits.rub.id === 1) {
        const t = num / 1000;
        return parseFloat(t.toFixed(2));
      }
    },
    getUnit(num) {
      if (this.provideUnits.unit.id === 0) {
        return num || 0;
      } else if (this.provideUnits.unit.id === 1) {
        return Math.round(num / 1000);
      }
    },
  },
};
