var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-detail-view',{attrs:{"loading":_vm.loading,"title":"Показатель оценки качества","subTitle":this.type === 'create' ? 'новый показатель' : _vm.dataSource.Name,"isUnsaved":_vm.isUnsaved && _vm.valid},on:{"onRefresh":_vm.init,"onSave":_vm.save}},[_c('v-form',{staticClass:"block__content",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{directives:[{name:"up-first-symbol",rawName:"v-up-first-symbol"}],attrs:{"label":"Название","rules":[_vm.rules.required],"required":"","maxlength":"200","readonly":_vm.readOnly,"clearable":!_vm.readOnly},model:{value:(_vm.dataSource.Name),callback:function ($$v) {_vm.$set(_vm.dataSource, "Name", $$v)},expression:"dataSource.Name"}})],1)],1),_c('v-row',[_c('v-col',[_c('m-select',{attrs:{"items":_vm.categories,"item-text":"Name","label":"Раздел деятельности","return-object":"","readonly":_vm.readOnly,"clearable":!_vm.readOnly},model:{value:(_vm.dataSource.ActivitySection),callback:function ($$v) {_vm.$set(_vm.dataSource, "ActivitySection", $$v)},expression:"dataSource.ActivitySection"}})],1),_c('v-col',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"type":"Number","label":"Реф. значение 1","rules":[_vm.rules.refRange],"readonly":_vm.readOnly},model:{value:(_vm.dataSource.RefValue1),callback:function ($$v) {_vm.$set(_vm.dataSource, "RefValue1", $$v)},expression:"dataSource.RefValue1"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"Number","label":"Реф. значение 2","rules":[
                  _vm.rules.refRange,
                  (value) => _vm.rules.ref2(value, _vm.dataSource.RefValue1),
                ],"readonly":_vm.readOnly},model:{value:(_vm.dataSource.RefValue2),callback:function ($$v) {_vm.$set(_vm.dataSource, "RefValue2", $$v)},expression:"dataSource.RefValue2"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"Number","label":"Реф. значение 3","rules":[
                  _vm.rules.refRange,
                  (value) => _vm.rules.ref3(value, _vm.dataSource.RefValue2),
                ],"readonly":_vm.readOnly},model:{value:(_vm.dataSource.RefValue3),callback:function ($$v) {_vm.$set(_vm.dataSource, "RefValue3", $$v)},expression:"dataSource.RefValue3"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{directives:[{name:"up-first-symbol",rawName:"v-up-first-symbol"}],attrs:{"label":"Описание","rows":"2","maxlength":"500","readonly":_vm.readOnly},model:{value:(_vm.dataSource.Description),callback:function ($$v) {_vm.$set(_vm.dataSource, "Description", $$v)},expression:"dataSource.Description"}})],1)],1)],1)],1),_c('m-tabs',{staticClass:"mt-3",attrs:{"slider-size":2,"with-route":false},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"CheckList"},[_vm._v("Чек-листы")]),_c('v-tab',{key:"Document"},[_vm._v("Документы")])],1),_c('v-window',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-window-item',[_c('base-list-view',{staticClass:"mt-2",attrs:{"title":"Чек-листы","pageEditName":"CheckListEdit","funcHandleClickId":(val) => {
            return val.CheckList.Id;
          },"dataSource":_vm.dataSource.CheckLists,"headers":_vm.headers,"header-hidden":"","isNested":"","hide-default-footer":"","notShowSelect":"","editable":"","show-actions":"","add-in-table":"","show-add-in-table":!_vm.readOnly,"hideDelete":_vm.readOnly,"hideEdit":"","defaultObject":_vm.getDefaultCheckList,"text-custom-add":"Добавить чек-лист"},on:{"customAdd":function($event){_vm.dialog = true}},scopedSlots:_vm._u([{key:"item.CheckList.Name",fn:function(scope){return [(scope.rowEditing && scope.item.Id === scope.rowEditing.Id)?_c('m-autocomplete',{attrs:{"items":_vm.filteredCheckLists(scope.rowEditing),"item-text":"Name","return-object":"","dict":{
              name: 'CheckList',
              notItems: true,
              update: _vm.updateCheckLists,
            }},model:{value:(scope.rowEditing.CheckList),callback:function ($$v) {_vm.$set(scope.rowEditing, "CheckList", $$v)},expression:"scope.rowEditing.CheckList"}}):_c('span',[_vm._v(_vm._s(_vm.getCheckListName(scope.item.CheckList)))])]}},{key:"item.ObjectCount",fn:function(scope){return [(scope.rowEditing && scope.item.Id === scope.rowEditing.Id)?_c('v-text-field',{attrs:{"value":scope.item.ObjectCount,"type":"Number"},on:{"change":function($event){scope.rowEditing.ObjectCount = parseInt($event)}}}):_c('span',[_vm._v(_vm._s(scope.item.ObjectCount))])]}}])})],1),_c('v-window-item',[_c('list-document-with-template',{staticClass:"mt-2",attrs:{"show-add-in-table":true},model:{value:(_vm.dataSource.Documents),callback:function ($$v) {_vm.$set(_vm.dataSource, "Documents", $$v)},expression:"dataSource.Documents"}})],1)],1),_c('check-operation-add',{attrs:{"CheckOperations":_vm.dataSource.CheckLists,"auditors":[],"width":'60vw',"height":'90vh',"needAddObject":false},on:{"add":_vm.addCheckLists},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }