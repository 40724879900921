<template>
  <v-layout>
    <base-list-view
      @onRefresh="init"
      @customHandle="customHandle"
      :dataSource="dataSource"
      :loading="loading"
      :headers="headers"
      :showDelete="false"
      :disabledAdd="true"
      title="Отчеты"
      notShowSelect
      :showFilters="true"
    >
    </base-list-view>
  </v-layout>
</template>

<script>
import BaseListView from "@/layouts/BaseListView.vue";
import ListEditMixin from "@/mixins/ListEditMixin";
import ReportService from "@/services/ReportService";
export default {
  name: "MoreReports",
  components: { BaseListView },
  mixins: [ListEditMixin],
  data() {
    return {
      apiService: ReportService,
      loading: true,
      dataSource: [],
      headers: [{ text: "Название", value: "Name" }],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    customHandle(obj) {
      this.$router.push({
        name: "ReportPage",
        params: {
          report: obj?.ReportId,
          objectId: 0,
        },
      });
    },
    DataFromServerNormalize(data) {
      return data.filter((d) => d.ContextType !== "AuditOperation");
    },
  },
};
</script>

<style></style>
