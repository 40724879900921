<template>
  <base-popup
    title="Прикладной документ"
    :icon="UtilGetIcon('view-AppliedDocumentTypes')"
    v-model="dialog"
    @save="
      () => {
        if (valid) $emit('save', dataSource);
      }
    "
    :disabled-save="!valid"
    :hideSave="readonly"
    :width="width"
    :height="height"
  >
    <v-form v-model="valid">
      <m-select
        v-model="dataSource.Type"
        :items="types"
        item-text="Name"
        label="Тип"
        return-object
        :rules="[rules.required]"
        required
        :readonly="readonly"
        :clearable="!readonly"
      ></m-select>
      <base-date-picker
        v-model="dataSource.Date"
        label="Дата"
        :rules="[rules.required]"
        required
        :readonly="readonly"
      ></base-date-picker>
      <base-date-picker
        v-model="dataSource.DateOut"
        label="Дата завершения"
        :readonly="readonly"
        :clearable="!readonly"
      ></base-date-picker>
      <v-text-field
        v-model="dataSource.Num"
        label="Номер"
        maxlength="50"
      ></v-text-field>
      <m-select
        v-if="objectType !== 3"
        v-model="dataSource.Employee"
        :items="employees"
        :item-text="(item) => $options.filters.PersonShortName(item)"
        label="Сотрудник"
        return-object
        :readonly="readonly"
        :clearable="!readonly"
        filter="EmployeeActive"
      ></m-select>
      <m-tree-select
        v-if="objectType !== 3"
        v-model="dataSource.Partition"
        :items="partitions"
        label="Подразделение"
        :readonly="readonly"
        :clearable="!readonly"
      ></m-tree-select>
      <m-select
        v-model="dataSource.Contragent"
        :items="contragents"
        item-text="Name"
        label="Контрагент"
        return-object
        :readonly="readonly"
        :clearable="!readonly"
      ></m-select>
      <v-textarea
        v-model="dataSource.Comment"
        label="Примечание"
        :readonly="readonly"
        v-up-first-symbol
        maxlength="400"
      ></v-textarea>
    </v-form>
    <table-load-file
      class="mt-2"
      :files="dataSource.Files"
      @addFile="addFile"
    />
  </base-popup>
</template>

<script>
import { mapActions } from "vuex";
import BasePopup from "../../layouts/BasePopup.vue";
import BaseDatePicker from "../base/BaseDatePicker.vue";
import MTreeSelect from "../base/inputs/mTreeSelect.vue";
import MSelect from "../base/inputs/mSelect.vue";
import TableLoadFile from "../loadFiles/TableLoadFile.vue";
import DataHelper from "../../utils/DataHelper";

export default {
  name: "view-AppliedDocumentEdit",
  components: {
    BasePopup,
    BaseDatePicker,
    MTreeSelect,
    MSelect,
    TableLoadFile,
  },
  props: {
    document: Object,
    value: Boolean,
    // 0 - отсутствует, 1 - partition, 2 - employee, 3 - equipment
    objectType: { type: Number, default: 0 },
    readonly: Boolean,
    width: String,
    height: String,
  },
  data: function () {
    return {
      valid: null,
      dataSource: {},
      types: [],
      partitions: [],
      equipments: [],
      employees: [],
      contragents: [],
      rules: {
        required: (value) => !!value || "Укажите значение.",
      },
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    document: {
      immediate: true,
      handler(value) {
        this.dataSource = this._.clone(value);
      },
    },
  },
  methods: {
    ...mapActions([
      "LOAD_APPLIED_DOCUMENT_TYPES",
      "LOAD_CONTRAGENTS",
      "LOAD_EMPLOYEES",
      "LOAD_PARTITIONS",
    ]),
    DataFromServerNormalize(data) {
      // Необходимо для работы v-tree-select;
      if (data.Partition) {
        data.Partition = DataHelper.findTreeById(
          this.partitions,
          data.Partition.Id
        );
      }
      return data;
    },
    addFile(newFile) {
      this.dataSource.Files.push(newFile);
    },
  },
  async mounted() {
    try {
      this.types = (await this.LOAD_APPLIED_DOCUMENT_TYPES()).filter(
        (t) =>
          (t.Object === 0 || t.Object === this.objectType) &&
          // Исключаем специальные типы документов, используемые в отдельных вкладках мед. изделия.
          t.Id !== 3 &&
          t.Id !== 4
      );
      this.contragents = await this.LOAD_CONTRAGENTS();
      if (this.objectType !== 3) this.employees = await this.LOAD_EMPLOYEES();
      if (this.objectType !== 3) this.partitions = await this.LOAD_PARTITIONS();
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
