import { render, staticRenderFns } from "./BaseTreeListEdit.vue?vue&type=template&id=3ca5dc60"
import script from "./BaseTreeListEdit.vue?vue&type=script&lang=js"
export * from "./BaseTreeListEdit.vue?vue&type=script&lang=js"
import style0 from "./BaseTreeListEdit.vue?vue&type=style&index=0&id=3ca5dc60&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports