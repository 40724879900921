import { Line, mixins } from "vue-chartjs";
import ChartjsPluginAnnotation from "chartjs-plugin-annotation";
import * as moment from "moment";
import "moment/locale/ru";
const { reactiveProp } = mixins;

moment.locale("ru");

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ["chartData", "options", "rules"],
  mounted() {
    this.addPlugin(ChartjsPluginAnnotation);
    this.renderChart(this.chartData, this.options);
  },
  watch: {
    options() {
      this.renderChart(this.chartData, this.options);
    },
  },
};
