<template>
  <v-dialog
    eager
    @input="change"
    value="true"
    :max-width="width"
    :persistent="persistent"
    @keydown.esc="choose(false)"
  >
    <v-card tile>
      <v-card-text
        :class="[
          !extionSticky
            ? 'text-body-1 confirm__body'
            : 'text-body-1 confirm__body confirm__sticky',
        ]"
      >
        <m-icon
          icon="tool-warning"
          :color="color"
          size="75"
          class="mb-4"
        ></m-icon>
        <v-toolbar-title
          v-if="Boolean(title)"
          style="color: var(--v-text-primary)"
          v-text="title"
        />
        <p
          v-html="message"
          class="mt-4 confirm__message"
          :class="{ 'text-center': center }"
        ></p>
      </v-card-text>
      <v-card-actions v-if="type === 0" class="confirm__actions">
        <v-btn
          v-if="Boolean(buttomCustomValue)"
          :color="buttonCustomColor"
          :text="buttonFalseFlat"
          @click="choose(buttomCustomValue)"
          ref="buttonCustom"
        >
          {{ buttonCustomText }}
        </v-btn>
        <v-btn
          v-if="Boolean(buttonTrueText)"
          :color="buttonTrueColor"
          :text="buttonTrueFlat"
          @click="choose(true)"
        >
          {{ buttonTrueText }}
        </v-btn>
        <v-btn
          v-if="Boolean(buttonFalseText)"
          :color="buttonFalseColor"
          :text="buttonFalseFlat"
          @click="choose(false)"
          :outlined="buttonFalseOutlined"
          :class="{
            'mt-3': Boolean(buttomCustomValue),
          }"
        >
          {{ buttonFalseText }}
        </v-btn>
      </v-card-actions>
      <v-card-actions v-if="type === 1" class="confirm__actions">
        <v-btn
          v-for="item in options"
          :key="item.OptionCode"
          color="primary"
          @click="choose(item.OptionCode)"
        >
          {{ item.Text }}
        </v-btn>

        <v-btn
          color="primary"
          @click="choose(false)"
          outlined
          :class="{
            'mt-3': options.length > 1,
          }"
          >Отмена</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  VCard,
  VCardActions,
  VCardText,
  VDialog,
  VToolbarTitle,
  VBtn,
} from "vuetify/lib";

export default {
  name: "base-confirm",
  components: {
    VCard,
    VCardActions,
    VCardText,
    VDialog,
    VToolbarTitle,
    VBtn,
  },
  props: {
    buttonCustomFlat: {
      type: Boolean,
      default: true,
    },
    buttomCustomValue: {
      type: String,
      default: null,
    },
    buttonCustomText: {
      type: String,
      default: "Custom",
    },
    buttonTrueText: {
      type: String,
      default: "Yes",
    },
    buttonFalseText: {
      type: String,
      default: "No",
    },
    buttonTrueColor: {
      type: String,
      default: "primary",
    },
    buttonCustomColor: {
      type: String,
      default: "primary",
    },
    buttonFalseColor: {
      type: String,
      default: "blue-grey",
    },
    buttonFalseFlat: {
      type: Boolean,
      default: true,
    },
    buttonTrueFlat: {
      type: Boolean,
      default: true,
    },
    buttonFalseOutlined: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
    icon: {
      type: String,
      default() {
        return this.$vuetify.icons.values.warning;
      },
    },
    message: {
      type: String,
      required: true,
    },
    persistent: Boolean,
    title: {
      type: String,
    },
    width: {
      type: Number,
      default: 500,
    },
    revertAnswer: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: 0,
    },
    options: {
      type: Array,
      default: () => [],
    },
    center: { type: Boolean, default: true },
    extionSticky: { type: Boolean, default: false },
  },
  data() {
    return {
      value: false,
    };
  },
  mounted() {
    document.addEventListener("keyup", this.onEnterPressed);
    // Нужно для установки фокуса на кнопку
    setTimeout(() => {
      if (this.$refs?.buttonCustom?.$el) this.$refs.buttonCustom.$el.focus();
    }, 1);
  },
  destroyed() {
    document.removeEventListener("keyup", this.onEnterPressed);
  },
  methods: {
    onEnterPressed(e) {
      if (e.keyCode === 13) {
        e.stopPropagation();
        this.choose(true);
      }
    },
    choose(value) {
      if (this.revertAnswer && typeof value === "boolean") {
        value = !value;
      }
      this.$emit("result", value);
      this.value = value;
      this.$destroy();
    },
    change() {
      this.$destroy();
    },
  },
};
</script>
<style lang="scss">
.confirm__message {
  white-space: pre-line;
  color: var(--v-text-primary);
}

.confirm__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 50px 0 50px !important;
}

.confirm__sticky {
  position: sticky;
  top: 0;
  max-height: 80vh;
  overflow-y: auto;
}

.confirm__actions {
  flex-direction: column;
  justify-content: center;
  padding: 20px 45px 20px 45px !important;

  .v-btn {
    width: 100%;
    margin-left: 0 !important;
    &:not(:last-child) {
      margin-bottom: 10px !important;
    }
  }
}
</style>
