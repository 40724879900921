<template>
  <base-list-view
    :dataSource="dataSource"
    :headers="headers"
    :loading="loading"
    @onRefresh="init"
    @onDelete="del"
    :disabledAdd="readOnly"
    :showDelete="!readOnly"
    pageEditName="ContragentEdit"
    title="Контрагенты"
    label-text-btn-create="Добавить контрагента"
    label-text-search="Поиск контрагента"
    :showFilters="true"
  >
  </base-list-view>
</template>

<script>
import ContragentService from "../../services/ContragentService";
import Permissions from "../../data/permissions";
import BaseListView from "../../layouts/BaseListView.vue";
import ListEditMixin from "../../mixins/ListEditMixin";

export default {
  name: "view-Contragents",
  components: {
    BaseListView,
  },
  mixins: [ListEditMixin],
  data: function () {
    return {
      loading: true,
      apiService: ContragentService,
      headers: [
        { text: "Название", value: "Name", width: "50%" },
        { text: "Адрес", value: "Address" },
        {
          text: "Телефон",
          value: "Phone",
          cellClass: "td-minimum td5",
        },
      ],
      dataSource: [],
      readOnly: !this.hasPermission(Permissions.ContragentsEdit),
    };
  },
  async mounted() {
    await this.init();
  },
};
</script>
