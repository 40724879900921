<template>
  <div>
    <v-fab-transition>
      <v-btn
        @click="show = !show"
        color="transparent"
        fab
        dark
        large
        fixed
        bottom
        right
        class="informer-btn"
        :class="show ? 'informer-btn__inner' : ''"
        :style="
          'right:' + (16 - offsetX) + 'px;' + 'bottom:' + (16 - offsetY) + 'px;'
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="54"
          height="54"
          viewBox="0 0 54 54"
          fill="none"
        >
          <path
            d="M53.33 26.665C53.33 41.3917 41.3917 53.33 26.665 53.33C11.9383 53.33 0 41.3917 0 26.665C0 11.9383 11.9383 0 26.665 0C41.3917 0 53.33 11.9383 53.33 26.665Z"
            fill="var(--v-background-base)"
          />
          <path
            d="M23.9985 13.3325H29.3315V18.6655H23.9985V13.3325ZM23.9985 23.9985H29.3315V39.9975H23.9985V23.9985ZM26.665 0C11.9459 0 0 11.9459 0 26.665C0 41.3841 11.9459 53.33 26.665 53.33C41.3841 53.33 53.33 41.3841 53.33 26.665C53.33 11.9459 41.3841 0 26.665 0ZM26.665 47.997C14.9057 47.997 5.333 38.4243 5.333 26.665C5.333 14.9057 14.9057 5.333 26.665 5.333C38.4243 5.333 47.997 14.9057 47.997 26.665C47.997 38.4243 38.4243 47.997 26.665 47.997Z"
            :fill="
              type === 'warning'
                ? 'var(--v-warning-base)'
                : 'var(--v-blue-grey-lighten1)'
            "
          />
        </svg>
      </v-btn>
    </v-fab-transition>
    <transition name="slide-fade22">
      <v-alert
        v-if="show"
        outlined
        :type="type"
        text
        @click.prevent.stop
        @click="show = !show"
        :class="classesAlert"
        style="border: unset !important"
        :icon="false"
        :style="
          'right:' + (70 - offsetX) + 'px;' + 'bottom:' + (4 - offsetY) + 'px;'
        "
      >
        <v-row>
          <v-col style="flex-grow: unset; align-self: center">
            <m-icon
              icon="mdi-alert-circle-outline"
              large
              class="mr-1"
              :color="type === 'warning' ? 'white' : ''"
            ></m-icon>
          </v-col>
          <v-col class="informer__text-center">
            <slot></slot>
            {{ message }}
          </v-col>
        </v-row>
      </v-alert>
    </transition>
  </div>
</template>
<script>
export default {
  name: "base-informer",
  props: {
    route: {
      type: Object,
      default: () => {},
    },
    message: {
      type: String,
      default: "",
    },
    type: {
      type: String,
    },
    offsetX: {
      type: Number,
      default: 0,
    },
    offsetY: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      show: false,
      closeTimeout: null,
    };
  },
  computed: {
    classesAlert() {
      return {
        informer: true,
        [this.type]:
          this.type === "success" &&
          this.type === "info" &&
          this.type === "warning" &&
          this.type === "error",
        "blue-grey--text text--lighten-2":
          this.type === undefined || this.type === null,
      };
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        if (this.closeTimeout) clearTimeout(this.closeTimeout);
        this.closeTimeout = setTimeout(() => {
          this.show = false;
        }, 15000);
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.show = true;
    }, 2000);
  },
};
</script>
<style lang="scss">
.informer-btn {
  border: unset !important;
  box-shadow: unset !important;
  margin-bottom: 10px;
}
.informer-btn.informer-btn__inner::after {
  content: "";
  width: 64px;
  height: 64px;
  position: absolute;
  left: -7px;
  box-shadow: unset !important;
  background-color: var(--v-background-base);
  border-radius: 50%;
}

.informer-btn .v-btn__content {
  z-index: 1;
}

.informer {
  position: fixed !important;
  right: 70px;
  bottom: 4px;
  margin-left: 200px;
  max-width: 50%;
}
.informer::before {
  opacity: 1 !important;
  z-index: 1;
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}
.informer .v-alert__content {
  z-index: 2;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--v-text-base);
}
.round {
  position: relative;
  overflow: hidden;
  width: 64px;
  height: 64px;
  right: 0;
}

.round::before {
  content: "";
  position: absolute;
  bottom: 0%;
  right: 0%;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  box-shadow: 0 0px 0px 64px var(--v-warning-base);
}

.informer__text-center {
  display: flex;
  align-items: center;
  min-height: 68px;
  padding-right: 40px;
}

/* Анимации появления и исчезновения могут иметь */
/* различные продолжительности и динамику.       */
.slide-fade22-enter-active {
  transition: all 0.3s ease;
}
.slide-fade22-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade22-enter, .slide-fade22-leave-to
/* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(100px);
  opacity: 0;
}
</style>
