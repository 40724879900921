<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="dates"
    transition="scale-transition"
    offset-y
    :disabled="$attrs['readonly']"
    :left="$attrs['left']"
    content-class="BaseDatePicker"
    :nudge-left="nudgeLeft"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :class="{
          'right-input': $attrs['rightered'],
          'underline-input': $attrs['hideUnderline'],
          small: $attrs['small'],
        }"
        v-model="text"
        :label="$attrs.hasOwnProperty('hide-label') ? null : label"
        :clearable="$attrs['clearable']"
        :rules="$attrs['rules']"
        :title="title"
        :outlined="$attrs['outlined']"
        :filled="$attrs['filled']"
        :hide-details="$attrs['hide-details']"
        :dense="$attrs['dense']"
        :placeholder="placeholder"
        v-mask="'##.##.#### - ##.##.####'"
        @change="inputFromText"
        :readonly="$attrs['readonly']"
        :required="required"
        :style="`font-size: 14px; ${text ? 'min-width: 230px' : ''} `"
      >
        <template v-slot:append>
          <m-icon
            :icon="$attrs['append-icon'] || 'mdi-calendar'"
            v-on="on"
            v-bind="attrs"
            dense
          />
        </template>
      </v-text-field>
    </template>
    <v-row class="mt-0">
      <v-date-picker
        :value="dates.map((e) => e.substr(0, 7))"
        scrollable
        locale="ru"
        type="month"
        no-title
        range
        @input="formatedDate($event)"
        min="1900"
      >
      </v-date-picker>
      <v-date-picker
        ref="datePicker"
        no-title
        v-model="dates"
        scrollable
        range
        locale="ru"
        first-day-of-week="1"
        :weekday-format="getCustomDay"
        class="datePickerRange"
      >
      </v-date-picker>
    </v-row>
    <v-row class="mr-0 mt-0 ml-0 mb-0">
      <v-col>
        <v-text-field
          v-model="text"
          label="Дата"
          :clearable="true"
          dense="dense"
          v-mask="'##.##.#### - ##.##.####'"
          @change="inputFromText"
        >
        </v-text-field>
      </v-col>
      <v-col class="d-flex justify-center">
        <v-btn text color="primary" @click="menu = false"> Отмена </v-btn>
        <v-btn
          text
          color="primary"
          @click="
            () => {
              $refs.menu.save(dates);
              input(dates);
            }
          "
        >
          OK
        </v-btn>
      </v-col>
    </v-row>
  </v-menu>
</template>

<script>
import moment from "moment";

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "Дата начала и конца",
    },
    title: {
      type: String,
      default: "Дата начала и конца",
    },
    nudgeLeft: {
      type: [Number, String],
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    required: { type: Boolean, default: false },
  },
  data: () => {
    return {
      text: null,
      dates: [],
      menu: false,
    };
  },
  computed: {
    dateRangeText: {
      get: function () {
        const rDate = [];
        if (this.dates.length >= 1)
          rDate.push(this.dates[0].split("-").reverse().join("."));
        if (this.dates.length === 2)
          rDate.push(this.dates[1].split("-").reverse().join("."));
        return rDate.join(" - ");
      },
      set: function () {
        this.dates = [];
        this.input(this.dates);
      },
    },
  },
  watch: {
    dateRangeText(val) {
      setTimeout(() => {
        this.text = val;
      }, 1);
    },
    value: {
      handler() {
        const valueCopy = this._.cloneDeep(this.value);

        // Убирает время
        for (const i in valueCopy) {
          valueCopy[i] = valueCopy[i].substr(0, 10);
        }

        // Формат
        for (const i in valueCopy) {
          if (valueCopy[i].indexOf(".") !== -1) {
            valueCopy[i] = valueCopy[i].split(".").reverse().join("-");
          }
        }

        this.dates = valueCopy;
      },
      immediate: true,
    },
  },
  methods: {
    inputFromText(text) {
      if (!text) {
        this.input([]);
        this.dates = [];
        return;
      }

      const arr = text.split("-");
      const tmp = [];
      if (arr.length >= 1 && moment(arr[0], "DD.MM.YYYY").isValid()) {
        tmp.push(moment(arr[0], "DD.MM.YYYY").format("YYYY-MM-DD"));
      }
      if (arr.length >= 2 && moment(arr[1], "DD.MM.YYYY").isValid()) {
        tmp.push(moment(arr[1], "DD.MM.YYYY").format("YYYY-MM-DD"));
      }

      // Синхронизация
      if (tmp.length < this.dates.length) {
        this.dates = this.dates.splice(0, tmp.length);
      }
      for (let i = 0; i < tmp.length; i++) {
        if (!this.dates[i]) {
          this.dates.push(tmp[i]);
        } else {
          this.$set(this.dates, i, tmp[i]);
        }
      }

      for (let i = 0; i < this.dates.length; i++) {
        const t = moment(this.dates[i], "YYYY-MM-DD");
        if (t.year() < 1900) {
          t.set("year", 1900);
        }
        this.dates[i] = t.format("YYYY-MM-DD");
      }

      if (this.dates.length > 0) this.formatedDate(this.dates);
      this.input(this.dates);
    },
    getCustomDay(date) {
      const daysOfWeek = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
      const i = new Date(date).getDay(date);
      return daysOfWeek[i];
    },
    formatedDate(dates) {
      if (this.$refs.datePicker)
        this.$refs.datePicker.tableDate =
          dates.length === 2 ? dates[1] : dates[0];
    },
    input(dates) {
      if (dates && dates.length === 2) {
        if (Date.parse(dates[0]) > Date.parse(dates[1])) {
          dates = dates.reverse();
        }
      }

      const result = [];
      for (const i in dates)
        result.push(dates[i].split("-").reverse().join(".") + " 00:00:00");
      this.$emit("input", result);
    },
  },
};
</script>

<style lang="scss">
.datePickerRange {
  .v-date-picker-table--date {
    thead {
      th:nth-last-child(-n + 2) {
        color: var(--v-error-lighten1);
      }
    }
    tbody {
      tr {
        td:nth-last-child(-n + 2) .v-btn__content {
          color: var(--v-error-lighten1);
        }
      }
    }
  }
}

.BaseDatePicker {
  background: var(--v-white-base);
}
</style>
<style scoped>
.right-input >>> input {
  text-align: right;
}
.underline-input >>> .v-input__slot::before {
  border-style: none !important;
}
.small >>> input {
  color: var(--v-text-base);
  font-size: 14px;
}
</style>
