var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-list-view',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"dataSource":_vm.dataSource,"headers":_vm.headers,"loading":_vm.loading,"show-actions":"","header-hidden":"","notShowSelect":!_vm.readonly,"hide-default-footer":"","notShowSettings":"","show-add-in-table":!_vm.readonly
      ? [
          {
            text: 'Прикрепить похожие НС',
            key: 'addUnwishedLink',
          },
          {
            text: 'Прикрепить НС',
            key: 'addUnwished',
          },
        ]
      : false},on:{"customAdd":_vm.customAdd},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [(!_vm.readonly)?_c('v-btn',{attrs:{"title":"Открепить","icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.unPin(item.Id)}}},[_c('m-icon',{attrs:{"icon":"mdi-close","small":""}})],1):_vm._e()]}},{key:"group.header",fn:function({ group, toggle, isOpen, items }){return [_c('v-btn',{attrs:{"icon":""},on:{"click":toggle}},[_c('m-icon',{attrs:{"icon":isOpen ? 'mdi-minus' : 'mdi-plus'}})],1),_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(group)+" ("+_vm._s(items.length)+")")])]}},{key:"item.Done",fn:function({ item }){return [_vm._v(" "+_vm._s(item.Done ? item.Done.substr(0, 10) : "")+" ")]}}])},'base-list-view',_vm.$attrs,false),_vm.$listeners),[(!_vm.readonly)?_c('unwished-event-list-popup',{attrs:{"dialog":_vm.dialog,"currentUnwished":_vm.currentUnwished,"popupType":_vm.popupType,"sourceItems":_vm.items},on:{"update:dialog":function($event){_vm.dialog=$event},"save":_vm.save}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }