<template>
  <base-detail-view
    :loading="loading"
    title="Должность"
    :subTitle="dataSource.Name"
    :isUnsaved="isUnsaved && valid"
    @onRefresh="init"
    @onSave="save"
  >
    <div
      v-if="tab == 0 || tab == 1"
      row
      slot="action"
      class="align-center d-inline-flex"
    >
      <label
        class="tasks__action-show-all v-label blue-grey--text mr-1"
        style="font-size: 14px"
        >{{
          "Показывать " + (tab == 0 ? "неработающих" : "недействующие")
        }}</label
      >
      <v-checkbox v-model="hideFlag" hide-details></v-checkbox>
    </div>

    <v-form class="block__content" v-model="valid">
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
              v-model="dataSource.Name"
              label="Название"
              :clearable="false"
              :readonly="dataSource.IsSystem"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-checkbox
              v-model="dataSource.IsSystem"
              label="Системная"
              readonly
              style="pointer-events: none"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <m-tabs v-model="tab">
      <v-tab key="Employees">Сотрудники</v-tab>
      <v-tab key="Documents">Документы</v-tab>
      <v-tab key="Course">Курсы</v-tab>
    </m-tabs>
    <v-window :value="tab">
      <v-window-item>
        <list-emloyees
          class="mt-2"
          :value="dataSource.Employees"
          :custom-filter="(b, a, e) => filterEmployees(e)"
          :show-position="false"
        >
        </list-emloyees>
      </v-window-item>
      <v-window-item>
        <list-document
          class="mt-2"
          :value="dataSource.OrganizationDocuments"
          :custom-filter="(b, a, e) => filterDocuments(e)"
          :show-add-in-table="true"
          :search="search"
        >
        </list-document>
      </v-window-item>
      <v-window-item>
        <list-course
          class="mt-2"
          :value="dataSource.Courses"
          :show-add-in-table="true"
          isTemplate
        >
        </list-course>
      </v-window-item>
    </v-window>
  </base-detail-view>
</template>

<script>
import PositionService from "../../services/PositionService";
import Permissions from "../../data/permissions";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";
import UnsavedMixin from "../../mixins/UnsavedMixin.js";
import BaseDetailView from "../../layouts/BaseDetailView.vue";
import mTabs from "@/components/base/tabs/mTabs";
import { uniqBy } from "lodash";
import moment from "moment";

export default {
  name: "view-PositionEdit",
  components: {
    BaseDetailView,
    mTabs,
    listEmloyees: () =>
      import("@/components/organization/employees/ListEmloyees.vue"),
    listDocument: () => import("@/components/document/ListDocument.vue"),
    listCourse: () => import("@/components/knowledgeBase/ListCourse.vue"),
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  data: function () {
    return {
      apiService: PositionService,
      getObjectText: (obj) => {
        return obj?.Name;
      },
      loading: true,
      valid: false,
      tab: 0,
      dataSource: {
        Name: "",
      },
      rules: {
        required: (value) => !!value || "Укажите значение.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !value || pattern.test(value) || "Некорректный e-mail.";
        },
        digits: (value) => {
          const pattern = /^\d+$/;
          return (
            !value ||
            pattern.test(value) ||
            "Значение должно содержать только цифры."
          );
        },
      },
      readOnly: !this.hasPermission(Permissions.PositionsEdit),
      hideFlag: false,
      search: "_",
    };
  },
  computed: {},
  async mounted() {
    await this.init();
  },
  methods: {
    DataFromServerNormalize(data) {
      if (!data.Employees) data.Employees = [];
      if (!data.OrganizationDocuments) data.OrganizationDocuments = [];
      if (!data.Courses) data.Courses = [];

      for (let i = 0; i < data.Employees.length; i++) {
        const item = data.Employees[i];

        if (!item.WorkPlaces) continue;
        const wp = item.WorkPlaces.filter((e) => e !== null);
        if (!wp) continue;
        item.AllPositionName = uniqBy(
          wp.filter((e) => e.Position),
          "Position.Id"
        )
          .map((e) => e?.Position?.Name)
          .sort()
          .join(", ");

        item.AllPlaceName = uniqBy(
          wp.filter((e) => e.Place),
          "Place.Id"
        )
          .map((e) => e?.Place?.Name)
          .sort()
          .join(", ");

        item.Status = item.User?.IsActive ? 1 : 0;
      }
      return data;
    },
    filterEmployees(e) {
      const currentDate = moment().startOf("day").unix();

      return this.hideFlag
        ? e
        : (e.User.IsActive === true &&
            moment(e.WorkPlacePeriod.DateIn, "DD.MM.YYYY").unix() <=
              currentDate &&
            (moment(e.WorkPlacePeriod.DateOut, "DD.MM.YYYY").unix() > 0
              ? moment(e.WorkPlacePeriod.DateOut, "DD.MM.YYYY").unix() >
                currentDate
              : true)) ||
            e.isNew === true;
    },
    filterDocuments(e) {
      return this.hideFlag ? e : e.Status === 5 || e.isNew === true;
    },
  },
};
</script>

<style></style>
