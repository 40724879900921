import Api from "./Api";

export default {
  async get() {
    return Api().get("common/GetEquipmentStandarts");
  },
  async show(id) {
    return Api().get("common/GetEquipmentStandart", { params: { id } });
  },
};
