// import { buildInfoPage } from "../utils/PageHelper";
// export default {
//   props: {
//     icon: {
//       type: String,
//       default: null,
//       validator: function (value) {
//         return typeof value === "string" && value.length > 1;
//       },
//     },
//   },
//   computed: {
//     getViewIcon() {
//       if (typeof this.icon === "string") return this.icon;

//       let res = null;
//       const page = buildInfoPage(this);
//       if (page && page.name !== undefined)
//         res = this.UtilGetIcon(page.name, page.type);
//       return res;
//     },
//   },
// };
import { buildInfoPage } from "../utils/PageHelper";
import { getIcon } from "../utils/IconHelper";
export default {
  props: {
    icon: {
      type: String,
      default: null,
      validator: function (value) {
        return typeof value === "string" && value.length > 1;
      },
    },
  },
  computed: {
    getViewIcon() {
      if (typeof this.icon === "string") return this.icon;

      let res = null;
      const page = buildInfoPage(this);
      if (page && page.name !== undefined) res = getIcon(page.name, page.type);
      return res;
    },
  },
};
