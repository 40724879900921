<template>
  <base-panel title="Подразделения" :icon="UtilGetIcon('view-Partitions')">
    <base-search slot="action" v-model="searchText"></base-search>
    <v-btn slot="action" @click="init" icon title="Обновить">
      <m-icon icon="tool-refresh" s16></m-icon>
    </v-btn>
    <v-btn
      slot="action"
      @click="addTop"
      :disabled="readOnly"
      dark
      color="primary"
      class="ml-4"
    >
      <m-icon icon="tool-add-item" left></m-icon>
      Создать подразделение
    </v-btn>
    <base-tree-list-edit
      :items="filteredItems"
      item-text="Name"
      :search="searchText"
      :showActions="actions"
      :customInplaceEdit="customInplaceEdit"
      @customDelete="customDelete"
      @afterEdit="this.copyTree(this.dataSource)"
      group="test"
      @input="change"
      pageEditName="PartitionEdit"
      caption="название"
      ref="treeListEdit"
    >
      <template
        slot="input-edit"
        slot-scope="{ nodeEditing, caption, closeEdit, itemText, item }"
      >
        <v-text-field
          v-if="nodeEditing && nodeEditing.Id === item.Id"
          v-model="nodeEditing[itemText]"
          @click.stop
          :placeholder="'Введите' + ' ' + caption"
          v-focusInput:tree="closeEdit"
          dense
          background-color="transparent"
          class="base-tree-list-edit__field"
          hide-details
          solo
          v-up-first-symbol
        ></v-text-field>
      </template>
      <template #label="{ item }">
        {{ item.Name }} {{ item.Address ? " (" + item.Address + ")" : "" }}
      </template>
      <template #captureArea>
        <v-btn
          class="capture-area"
          @click.stop.prevent
          icon
          color="blue-grey"
          title="Переместить"
        >
          <m-icon icon="mdi-menu" small></m-icon>
        </v-btn>
      </template>
    </base-tree-list-edit>
  </base-panel>
</template>

<script>
import PartitionService from "../../services/PartitionService";
import { defaultPartition } from "../../data/defaultObjects";
import Permissions from "../../data/permissions";
import DataHelper from "../../utils/DataHelper";
import ListEditMixin from "../../mixins/ListEditMixin";
import BasePanel from "../../layouts/BasePanel.vue";
import BaseSearch from "../../components/base/BaseSearch.vue";
import BaseTreeListEdit from "../../components/base/BaseTreeListEdit.vue";

export default {
  name: "view-Partitions",
  components: {
    BasePanel,
    BaseSearch,
    BaseTreeListEdit,
  },
  mixins: [ListEditMixin],
  data: function () {
    const readOnly = !this.hasPermission(Permissions.PartitionsEdit);
    return {
      apiService: PartitionService,
      dataSource: [],
      copySource: [],
      searchText: "",
      readOnly,
      actions: !readOnly ? ["add", "edit", "delete"] : [],
    };
  },
  computed: {
    filteredItems() {
      if (!this.searchText) return this.dataSource;
      const a = this._.cloneDeep(this.dataSource);
      return DataHelper.treeFilter(a, this.searchText);
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async addTop() {
      this.searchText = "";
      const newPartition = defaultPartition();
      // newPartition = (await PartitionService.create(newPartition, false)).data;
      this.dataSource.push(newPartition);
      this.copyTree(this.dataSource);
      this.$refs.treeListEdit.editById(newPartition.Id, false);
    },

    // Редактирование
    async customInplaceEdit(value) {
      if (!value) return;
      const res = (
        await PartitionService[value.Id <= 0 ? "create" : "update"](
          value,
          false
        )
      ).data;
      return res;
    },

    // Удаление
    async customDelete(value) {
      if (!value) return;

      // Удаление на сервере
      if (value.Id >= 0) {
        if (
          !(await this.$confirm(
            `Вы действительно хотите удалить подразделение "${value.Name}"?`
          ))
        )
          return;
        await PartitionService.delete(value.Id);
      }

      // Удаление на клиенте
      const parent = value.ParentId
        ? DataHelper.findTreeById(this.dataSource, value.ParentId).Children
        : this.dataSource;
      const index = parent.findIndex((e) => e.Id === value.Id);
      parent.splice(index, 1);

      // Новая копия
      this.copyTree(this.dataSource);
    },
    change(value) {
      if (this.readOnly) return;
      // Перенесенный элемент
      const moved = this.findMoved(value);
      this.copyTree(value); // сразу копируем новую иерархию, чтобы не дублировалось обновление.
      if (moved) {
        // Результатов не ждем, иначе сбивается работа draggable-treeview.
        // Но если возникла ошибка, то обновляем данные.
        PartitionService.update(moved, false).catch(() => {
          this.init();
        });
      }
      this.dataSource = value;
    },
    DataFromServerNormalize(data) {
      this.copyTree(data);
      return data.sort(function (a, b) {
        const t1 = a.Name;
        const t2 = b.Name;

        return DataHelper.sortNumWithString(t1, t2);
      });
    },
    // Копирование оригинала иерархии подразделений
    copyTree(tree) {
      function copy(l1, l2) {
        for (const item1 of l1) {
          const item2 = { Id: item1.Id, Children: [] };
          l2.push(item2);
          if (item1.Children.length) copy(item1.Children, item2.Children);
        }
      }
      const tree2 = [];
      copy(tree, tree2);
      this.copySource = tree2;
    },
    // Поиск измененного подразделения после операции drag-n-drop
    findMoved(value) {
      function find(l1, l2, parent) {
        for (const item1 of l1) {
          const item2 = l2.find((e) => e.Id === item1.Id);
          if (!item2) {
            item1.ParentId = parent;
            return item1;
          }
          const res = find(item1.Children, item2.Children, item1.Id);
          if (res) return res;
        }
      }
      if (!this.copySource) return null;
      return find(value, this.copySource, 0);
    },
  },
};
</script>
