<template>
  <div class="block__content unwished-event-info-card" v-if="UnwishedEvent">
    <div
      class="unwished-event-info-card__title primary--text"
      @click="
        $router.push({
          name: 'UnwishedEventEdit',
          params: {
            objectId: UnwishedEvent.Id,
          },
        })
      "
    >
      Нежелательное событие: {{ UnwishedEvent.Content }} от
      {{ UnwishedEvent.Date.substr(0, 10) }}
    </div>
    <v-row class="mt-0 pa-0" v-if="UnwishedEvent.Type">
      <v-col>Вид нежелательного события</v-col>
      <v-col> {{ UnwishedEvent.Type.Name }} </v-col>
    </v-row>
    <v-row class="">
      <v-col>Что произошло</v-col>
      <v-col> {{ UnwishedEvent.Content }} </v-col>
    </v-row>
    <v-row class="mt-0 pa-0" v-if="UnwishedEvent.PlaceDisplay">
      <v-col>Место</v-col>
      <v-col> {{ UnwishedEvent.PlaceDisplay }} </v-col>
    </v-row>
    <v-row class="mt-0" v-if="UnwishedEvent.ConsequenceType">
      <v-col>Последствия</v-col>
      <v-col>{{ UnwishedEvent.ConsequenceType.Description }}</v-col>
    </v-row>
    <v-row class="mt-0" v-if="UnwishedEvent.Result">
      <v-col>Результат разбора</v-col>
      <v-col>{{ UnwishedEvent.Result }}</v-col>
    </v-row>
    <v-row class="mt-0" v-if="UnwishedEvent.Done">
      <v-col>Дата закрытия</v-col>
      <v-col>{{ UnwishedEvent.Done }}</v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    UnwishedEvent: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="scss">
.unwished-event-info-card {
  padding: 25px;
  font-size: 14px;
  line-height: 158%;
  color: var(--v-text-base);

  &__title {
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
  }
  .row .col:first-child {
    width: 250px;
    flex-grow: unset;
    flex-basis: unset;
    font-weight: bold;
    color: var(--v-text-base);
  }
}
</style>
