export default {
  watch: {
    queryParams() {
      this.init();
    },
  },
  inject: {
    queryParams: {
      default: () => ({
        params: {},
      }),
    },
    updateQueryParams1: {
      default: () => () => {},
    },
    updateQueryParams: {
      default: () => () => {},
    },
    queryParams1: {
      default: () => ({
        params: {},
      }),
    },
    filterParams: {
      default: () => ({
        params: {},
      }),
    },
  },
};
