<template>
  <base-table-full
    class="base-tree-table"
    :class="{
      'base-tree-table__nested': level,
      'mb-12': !level,
      'tree-teble-contents': treeTableContents,
      'tree-teble-contents__selected': this.$attrs.notShowSelect == undefined,
      'tree-teble-contents__noselected':
        this.$attrs.notShowSelect !== undefined,
    }"
    :headers="internalHeaders"
    :dataSource="items"
    :loading="loading"
    :searchText="searchText"
    :single-expand="singleExpand"
    v-bind="$attrs"
    v-on="$listeners"
    ref="baseTreeTable"
    :show-expand="showExpand"
    :item-class="getItemClass"
  >
    <template
      v-if="$scopedSlots['item.actions']"
      slot="item.actions"
      slot-scope="scope"
    >
      <v-btn
        v-if="dragable"
        class="sortHandle handle"
        @click.stop.prevent
        icon
        color="blue-grey"
        title="Переместить"
      >
        <m-icon icon="mdi-menu" small></m-icon>
      </v-btn>
      <slot name="item.actions" v-bind="scope" />
    </template>
    <template
      v-for="slot in Object.keys($scopedSlots).filter(
        (e) => e !== 'header' && e !== 'item.actions'
      )"
      :slot="slot"
      slot-scope="scope"
      ><slot :name="slot" v-bind="scope" />
    </template>
    <template slot="header" slot-scope="scope"
      ><slot name="header" v-bind="{ ...scope, level: level }" />
    </template>
    <!-- Инициализация тела object -->
    <template
      v-for="head in scopeHeaders.filter(
        (e) => e.dataType && e.dataType === 'object'
      )"
      :slot="'item.' + head.value"
      slot-scope="{ item }"
    >
      {{ columnObjectText(head, item[head.value], item) }}
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td
        v-if="item[childrenName] && item[childrenName].length"
        :colspan="headers.length"
        style="padding: 0 !important"
        class="base-tree-table__children"
      >
        <base-tree-table
          v-bind="{ ...$attrs, sortDesc, sortBy }"
          v-on="$listeners"
          :headers="headers"
          :items="item[childrenName]"
          :level="level + 1"
          :options="options"
          nested
          hide-default-header
          hide-default-footer
          :show-expanded-tree="showExpandedTree"
          :hidePaddingLeft="hidePaddingLeft"
          :group-by="null"
          :dragable="dragable"
          :tree-table-contents="treeTableContents"
        >
          <template
            v-for="slot in Object.keys($scopedSlots)"
            :slot="slot"
            slot-scope="scope"
            ><slot :name="slot" v-bind="scope" />
          </template>
        </base-tree-table>
      </td>

      <td
        v-else-if="showExpandedTree"
        v-bind="{ headers, item }"
        :colspan="headers.length - level + 1"
      >
        <slot name="expanded-tree" v-bind="{ headers, item }"></slot>
      </td>
    </template>

    <template v-if="isCustomEdit" v-slot:body.append="{}">
      <v-btn
        v-if="typeof showAddInTable === 'boolean'"
        @click="addInTable"
        color="primary"
        large
        fab
        :title="textCustomAdd"
        dark
        style="
          position: fixed;
          bottom: 16px;
          right: 16px;
          animation: showWithDelay 2s forwards;
          z-index: 2;
        "
      >
        <m-icon icon="mdi-plus"></m-icon>
      </v-btn>
      <v-menu offset-y v-else>
        <template #activator="{ on }">
          <v-btn
            v-on="on"
            color="primary"
            large
            fab
            :title="textCustomAdd"
            dark
            style="
              position: fixed;
              bottom: 16px;
              right: 16px;
              animation: showWithDelay 2s forwards;
              z-index: 2;
            "
          >
            <m-icon icon="mdi-plus"></m-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="item in showAddInTable.filter((e) => !e.hide)"
            :key="item.key"
            @click="addInTable(item.key)"
          >
            <v-list-item-icon v-if="item.icon">
              <m-icon
                :icon="UtilGetIcon(item.icon.name, item.icon.type)"
              ></m-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.text }} </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <template
      slot="item.data-table-expand"
      slot-scope="{ expand, isExpanded, item }"
    >
      <v-btn
        v-show="
          (item[childrenName] && item[childrenName].length) || showExpandedTree
        "
        @click.stop="expand && expand(!isExpanded)"
        icon
        style="margin-left: -5px"
      >
        <m-icon
          size="24"
          :icon="isExpanded ? 'tool-arrow-up' : 'tool-arrow-down'"
          :color="isExpanded ? 'primary' : ''"
        ></m-icon>
      </v-btn>
    </template>
  </base-table-full>
</template>

<script>
import BaseTableFull from "./BaseTableFull.vue";
import Sortable from "sortablejs";

export default {
  name: "base-tree-table",
  components: { BaseTableFull },
  props: {
    treeTableContents: {
      type: Boolean,
      default: false,
    },
    hidePaddingLeft: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    searchText: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    level: {
      type: Number,
      default: 0,
    },
    options: {
      type: [Array, String],
      default: "Children",
    },
    showAddInTable: [Boolean, Array],
    textCustomAdd: { type: String, default: "Добавить" },
    showExpandedTree: {
      type: Boolean,
      default: false,
    },
    singleExpand: {
      type: Boolean,
      default: true,
    },
    dragable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sortBy() {
      return this.$refs?.baseTreeTable?.$refs["base-table"]?.$refs?.dTable
        .$children[0]?.internalOptions?.sortBy;
    },
    sortDesc() {
      return this.$refs?.baseTreeTable?.$refs["base-table"]?.$refs?.dTable
        .$children[0]?.internalOptions?.sortDesc;
    },
    scopeHeaders() {
      const scopes = Object.keys(this.$scopedSlots).map((e) =>
        e.split(".").at(-1)
      );

      return this.headersInternal.filter((e) => !scopes.includes(e.value));
    },
    headersInternal() {
      if (!this.headers) return [];
      return this.headers.map((h) => {
        const header = { ...h };
        if (header.dataType === "object" && !header.sort && header.displayText)
          header.sort = (a, b) => {
            const s1 = header.displayText(a);
            const s2 = header.displayText(b);
            return this.sortStrings(s1, s2);
          };
        if (header.dataType === "array" && !header.sort && header.displayText)
          header.sort = (a, b) => {
            let result = 0;
            let index = 0;
            while (!result) {
              const s1 =
                a && a.length > index ? header.displayText(a[index]) : null;
              const s2 =
                b && b.length > index ? header.displayText(b[index]) : null;
              if (!s1 && !s2) return 0;
              result = this.sortStrings(s1, s2);
              index++;
            }
            return result;
          };
        return header;
      });
    },
    isCustomEdit() {
      return !!this.showAddInTable;
    },
    showExpand() {
      return true;
      /* try {
        return (
          (!!this.items[0][this.childrenName] &&
            !!this.items[0][this.childrenName].length) ||
          this.showExpandedTree
        );
      } catch {
        return false;
      } */
    },
    childrenName() {
      if (typeof this.options === "string") {
        return this.options;
      }
      return this.options[this.level];
    },

    internalHeaders() {
      if (this.level === 1 && !this.treeTableContents) {
        return [
          { text: "", value: "blank", cellClass: "blank" },
          ...this.headers,
        ];
      }
      return this.headers;
    },
  },
  watch: {
    level: {
      immediate: false,
      handler() {
        this.updateLevel();
      },
    },
  },

  mounted() {
    if (this.dragable) {
      /* eslint-disable no-new */
      setTimeout(() => {
        const nestedSortables = document.querySelectorAll(".dragTable");
        for (let i = 0; i < nestedSortables.length; i++) {
          new Sortable(nestedSortables[i], {
            draggable: ".sortableRow",
            handle: ".sortHandle",
            onEnd: this.dragReorder,
            group: "nested" + i,
            animation: 150,
            fallbackOnBody: true,
            swapThreshold: 0.65,
            ghostClass: "ghost",
          });
        }
        this.updateLevel();
      }, 1000);
    }
  },
  updated() {
    if (this.dragable) {
      const tableParent = document
        .querySelector(".base-tree-table")
        ?.children[0]?.children[1]?.querySelector("table > tbody");

      if (tableParent) {
        tableParent.classList.add("dragTable", "nested-0");

        const child = tableParent.querySelectorAll(
          ".v-data-table__expanded.v-data-table__expanded__content"
        );
        for (let i = 0; i < child.length; i++) {
          child[i]
            .querySelector("tbody")
            .classList.add("dragTable", "nested-1");
        }
      }
    }

    this.updateLevel();
  },
  methods: {
    getItemClass(item) {
      const arr = [];
      if (this.dragable) arr.push("sortableRow");
      arr.push("sortableRow-" + item.Id);

      if (this.$attrs["item-class"]) {
        const itemClass = this.$attrs["item-class"](item);
        if (itemClass) {
          arr.push(itemClass);
        }
      }

      return arr.join(" ");
    },
    dragReorder(evt) {
      // const itemId = evt.item.classList[1].split("-")[1];
      const from = evt.from.classList[1];
      const to = evt.to.classList[1];
      if (from === to) {
        const { oldIndex, newIndex } = evt;
        const movedItem = this.items.splice(oldIndex, 1)[0];
        this.items.splice(newIndex, 0, movedItem);
      }
    },
    columnObjectText(header, object, item) {
      return header?.displayText(object, item);
    },
    addInTable(key) {
      if (!this.$listeners.customAdd) {
        this.dataSource.push(this.defaultObject());

        // this.rowEditing = this.dataSource[this.dataSource.length - 1];
        // this.rowEditingIndex = this.dataSource.length - 1;
      } else this.$emit("customAdd", key);
      this.isAddNewItem = true;
      this.cancelEnabled = false;
    },
    updateLevel() {
      if (this.treeTableContents) return;
      // if (this.hidePaddingLeft) return;
      const btt = this.$refs.baseTreeTable;
      if (btt && this.level) {
        btt.$el.querySelector("td").style =
          "min-width: " + "5" * this.level + "px";

        btt.$el.querySelectorAll("td")[1].style =
          "min-width: " +
          "63" +
          "px;" +
          "max-width: " +
          "63" +
          "px;" +
          "width: " +
          "63" +
          "px!important";

        /* if (
          this.$attrs.notShowSelect === undefined ||
          this.$attrs.notShowSelect === false
        )
          btt.$el.querySelectorAll("td")[2].style =
            "max-width: 0; display:none;"; */
      } else if (btt && !this.lavel) {
        btt.$el.querySelectorAll("td")[0].style =
          "min-width: " +
          "63" +
          "px;" +
          "max-width: " +
          "63" +
          "px;" +
          "width: " +
          "63" +
          "px!important";
      }
    },
  },
};
</script>

<style lang="scss">
.tree-teble-contents {
  .v-data-table__expanded.v-data-table__expanded__content {
    display: contents;
    & > td {
      display: contents;
    }
    .base-tree-table__nested,
    .v-data-table,
    .v-data-table__wrapper,
    table,
    tbody {
      display: contents;
    }
  }
  &__noselected {
    table td:nth-child(2) {
      padding-left: 0 !important;
    }

    table table td:nth-child(2) {
      padding-left: 20px !important;
    }

    table table table td:nth-child(2) {
      padding-left: 40px !important;
    }

    table table table table td:nth-child(2) {
      padding-left: 60px !important;
    }
    table table table table table td:nth-child(2) {
      padding-left: 80px !important;
    }
    table table table table table td:nth-child(2) {
      padding-left: 100px !important;
    }
  }
  &__selected {
    table td:nth-child(3) {
      padding-left: 0 !important;
    }

    table table td:nth-child(3) {
      padding-left: 20px !important;
    }

    table table table td:nth-child(3) {
      padding-left: 40px !important;
    }

    table table table table td:nth-child(3) {
      padding-left: 60px !important;
    }
    table table table table table td:nth-child(3) {
      padding-left: 80px !important;
    }
    table table table table table td:nth-child(3) {
      padding-left: 100px !important;
    }
  }
}

.ghost {
  opacity: 0.5;
  background: var(--v-blue-grey-lighten2) !important;
}
.base-tree-table {
  background-color: var(--v-white-lighten1) !important;
}
.base-tree-table .td-minimum {
  width: 1%;
}

.base-tree-table .td-maximum {
  width: 100% !important;
}

.base-tree-table__nested {
  td.blank {
    padding: 0 !important;
    width: 5px;
    background-color: var(--v-text-lighten2);
  }
}
</style>
