var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('draggable-treeview',_vm._g(_vm._b({staticClass:"block__content tree-view",attrs:{"item-text":_vm.itemText,"value":_vm.items,"opens":_vm.open,"open-on-click":"","return-object":"","expand-icon":"$ToolArrowDown","disabled":_vm.readOnly,"updateItems":_vm.updateItems,"pageEditName":_vm.pageEditName},on:{"update:opens":function($event){_vm.open=$event}},scopedSlots:_vm._u([_vm._l((Object.keys(_vm.$scopedSlots).filter((e) => e !== 'label')),function(slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}}),{key:"label",fn:function(scope){return [_c('div',{staticStyle:{"display":"flex"}},[(_vm.nodeEditing && _vm.nodeEditing.Id === scope.item.Id)?_vm._t("input-edit",function(){return [_c('v-text-field',{directives:[{name:"focusInput",rawName:"v-focusInput:tree",value:(_vm.closeEdit),expression:"closeEdit",arg:"tree"}],staticClass:"base-tree-list-edit__field",attrs:{"placeholder":'Введите' + ' ' + _vm.caption,"dense":"","background-color":"transparent","hide-details":"","solo":""},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.nodeEditing[_vm.itemText]),callback:function ($$v) {_vm.$set(_vm.nodeEditing, _vm.itemText, $$v)},expression:"nodeEditing[itemText]"}})]},null,{
            nodeEditing: _vm.nodeEditing,
            caption: _vm.caption,
            closeEdit: _vm.closeEdit,
            itemText: _vm.itemText,
            item: scope.item,
          }):(!_vm.$scopedSlots['label'])?[_vm._v(_vm._s(scope.item[_vm.itemText]))]:_vm._t("label",null,null,scope),_vm._t("afterLabel",null,{"nodeEditing":_vm.nodeEditing},scope)],2)]}},(!_vm.readOnly)?{key:"append",fn:function({ item }){return [_c('div',{staticClass:"tree-view__actions-main"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.nodeEditing || item.Id === _vm.nodeEditing.Id),expression:"!nodeEditing || item.Id === nodeEditing.Id"}],staticClass:"tree-view__actions",class:{
            'tree-view__actions_active':
              _vm.nodeEditing && _vm.nodeEditing.Id === item.Id,
          }},[(_vm.nodeEditing && _vm.nodeEditing.Id === item.Id && _vm.cancelEnabled)?_c('v-btn',{attrs:{"title":"Отмена","icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.nodeEditing = null}}},[_c('m-icon',{attrs:{"small":"","icon":"mdi-close"}})],1):_vm._e(),(_vm.showActions.indexOf('add') !== -1)?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.nodeEditing || _vm.nodeEditing.Id !== item.Id),expression:"!nodeEditing || nodeEditing.Id !== item.Id"}],attrs:{"color":"blue-grey","icon":"","title":"Добавить в узел"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.add(item)}}},[_c('m-icon',{attrs:{"s16":"","icon":"tool-add-viol"}})],1):_vm._e(),(_vm.showActions.indexOf('edit') !== -1)?_c('v-btn',{attrs:{"color":_vm.nodeEditing && _vm.nodeEditing.Id === item.Id
                ? 'success'
                : 'primary',"icon":"","title":"Редактировать"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.edit(item)}}},[_c('m-icon',{attrs:{"s16":"","small":"","icon":_vm.nodeEditing && _vm.nodeEditing.Id === item.Id
                  ? 'mdi-check'
                  : 'tool-edit'}})],1):_vm._e(),(_vm.showActions.indexOf('delete') !== -1)?_c('v-btn',{attrs:{"icon":"","color":"error","title":"Удалить"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.del(item)}}},[_c('m-icon',{attrs:{"s16":"","icon":"tool-delete"}})],1):_vm._e()],1)])]}}:null],null,true)},'draggable-treeview',_vm.$attrs,false),_vm.$listeners)),_c('div',{staticStyle:{"height":"50px"}},[(_vm.showAdd)?_c('v-btn',{staticStyle:{"position":"fixed","bottom":"16px","right":"16px"},attrs:{"color":"primary","large":"","fab":"","title":'Добавить ' + _vm.caption,"dark":""},on:{"click":function($event){return _vm.add(null)}}},[_c('m-icon',{attrs:{"icon":"mdi-plus"}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }