<template>
  <base-detail-view
    :loading="loading"
    title="Настройки отчетов"
    :isUnsaved="isUnsaved"
    @onRefresh="init"
    @onSave="save"
  >
    <v-form class="block__content">
      <v-container>
        <v-row>
          <v-col>
            <m-select
              v-model="dataSource.AuditOperationReport"
              :items="reports"
              item-text="Name"
              return-object
              label='Отчет "О проверке"'
              :readonly="readOnly"
              :clearable="!readOnly"
            ></m-select>
            <m-select
              v-model="dataSource.AuditOperationObjectsReport"
              :items="reports"
              item-text="Name"
              return-object
              label='Отчет "О проверке" (с объектами проверки)'
              :readonly="readOnly"
              :clearable="!readOnly"
            ></m-select>
            <m-select
              v-model="dataSource.AuditOperationSummaryReport"
              :items="reports"
              item-text="Name"
              return-object
              label='Краткий отчет "О проверке" (с объектами проверки)'
              :readonly="readOnly"
              :clearable="!readOnly"
            ></m-select>
            <m-select
              v-model="dataSource.AuditOperationReportByIndicators"
              :items="reports"
              item-text="Name"
              return-object
              label="Отчет О выполнении проверки по показателям"
              :readonly="readOnly"
              :clearable="!readOnly"
            ></m-select>
            <m-select
              v-model="dataSource.CorrectiveActionListReport"
              :items="reports"
              item-text="Name"
              return-object
              label='Отчет: "Список несоответсвий по проверке"'
              :readonly="readOnly"
              :clearable="!readOnly"
            ></m-select>
            <m-select
              v-model="dataSource.CorrectiveActionPlanReport"
              :items="reports"
              item-text="Name"
              return-object
              label='Отчет "План мероприятий по устранению несоответсвий"'
              :readonly="readOnly"
              :clearable="!readOnly"
            ></m-select>
            <m-select
              v-model="dataSource.CorrectiveActionPlanExecutionReport"
              :items="reports"
              item-text="Name"
              return-object
              label='Отчет "Выполнение плана корректирующих мероприятий"'
              :readonly="readOnly"
              :clearable="!readOnly"
            ></m-select>
            <m-select
              v-model="dataSource.InspectionPlanReport"
              :items="reports"
              item-text="Name"
              return-object
              label='Отчет "План проверок"'
              :readonly="readOnly"
              :clearable="!readOnly"
            ></m-select>
            <m-select
              v-model="dataSource.DocumentAcquaintanceReport"
              :items="reports"
              item-text="Name"
              return-object
              label='Отчет "Лист ознакомления с документом"'
              :readonly="readOnly"
              :clearable="!readOnly"
            ></m-select>
            <m-select
              v-model="dataSource.ProjectPlanReport"
              :items="reports"
              item-text="Name"
              return-object
              label='Отчет "План мероприятий"'
              :readonly="readOnly"
              :clearable="!readOnly"
            ></m-select>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <template #action>
      <v-btn
        v-if="!readOnly"
        title="По умолчанию"
        :disabled="isDefault"
        @click="resetToDefault()"
        dark
        color="primary"
        class="ml-4"
      >
        По умолчанию
      </v-btn>
    </template>
  </base-detail-view>
</template>

<script>
import ReportService from "../../services/ReportService";
import ReportSettingsService from "../../services/ReportSettingsService";
import Permissions from "../../data/permissions";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";
import UnsavedMixin from "../../mixins/UnsavedMixin.js";
import BaseDetailView from "../../layouts/BaseDetailView.vue";
import MSelect from "../../components/base/inputs/mSelect.vue";

export default {
  name: "view-ReportSettingsEdit",
  components: {
    BaseDetailView,
    MSelect,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  data: function () {
    return {
      apiService: ReportSettingsService,
      loading: true,
      reports: [],
      dataSource: {
        AuditOperationReport: null,
        AuditOperationObjectsReport: null,
        AuditOperationSummaryReport: null,
        CorrectiveActionListReport: null,
        CorrectiveActionPlanReport: null,
        CorrectiveActionPlanExecutionReport: null,
        InspectionPlanReport: null,
        DocumentAcquaintanceReport: null,
        AuditOperationReportByIndicators: null,
      },
      defaultValues: {},
    };
  },
  computed: {
    isDefault() {
      for (const key in this.dataSource)
        if (
          this.dataSource[key] &&
          (!this.defaultValues[key] ||
            this.dataSource[key].Id !== this.defaultValues[key].Id)
        )
          return false;
      return true;
    },
    readOnly() {
      return !this.hasPermission(Permissions.SettingsEdit);
    },
  },
  async mounted() {
    this.reports = (await ReportService.get()).data;
    this.defaultValues = (await ReportSettingsService.getDefault()).data;
    await this.init();
  },
  methods: {
    resetToDefault() {
      for (const key in this.defaultValues)
        this.dataSource[key] = this.defaultValues[key];
    },
  },
};
</script>
