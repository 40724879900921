<template>
  <base-popup
    title="Выбрать чек-листы"
    :icon="UtilGetIcon('view-CheckLists')"
    @save="saveObjects"
    v-model="dialog"
    :width="width"
    :height="height"
    v-bind="$attrs"
    buttonSaveText="Добавить"
  >
    <template v-slot:activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>
    <v-container fill-height>
      <v-row class="d-flex fill-height">
        <v-col class="fill-height">
          <base-panel title="Показатели" class="fill-height">
            <base-search slot="action" v-model="search[0]" />
            <base-table-full
              :value="selectedIndicators"
              @input="changeIndicator"
              :search-text="search[0]"
              :dataSource="AuditIndicators"
              :headers="indicatorHeaders"
              :sort-by.sync="indicatorsSortBy"
              hide-default-footer
              :showSettings="false"
              class="fill-height"
            ></base-table-full>
          </base-panel>
        </v-col>
        <v-col class="fill-height">
          <base-panel title="Чек-листы" class="fill-height">
            <base-search slot="action" v-model="search[2]" />
            <base-table-full
              v-model="markedCheckLists"
              notCheckSelect
              :search-text="search[2]"
              :dataSource="checkLists"
              :headers="modifiedHeadersСheckListHeaders"
              :sort-by.sync="sortBy"
              hide-default-footer
              :showSettings="false"
              class="fill-height"
            >
              <template slot="item.Name" slot-scope="{ item }">
                {{ item.Name }}
              </template>
              <template
                v-if="
                  markedCheckLists.find((e) => e.Id === item.Id) &&
                  selectedObjectType !== undefined
                "
                slot="item.Сount"
                slot-scope="{ item }"
              >
                <v-text-field
                  v-if="
                    (selectedObjectType > 0 && selectedObjectType < 3) ||
                    selectedObjectType === 4
                  "
                  :value="item.count"
                  @input="handleCount($event, item)"
                  @change="changeCount(item)"
                  @click.prevent.stop
                  @focus="() => (activeCheckList = item)"
                  type="number"
                  min="1"
                  max="99"
                ></v-text-field>
              </template>
              <template
                v-if="
                  markedCheckLists.find((e) => e.Id === item.Id) &&
                  selectedObjectType !== 4 &&
                  selectedObjectType !== undefined
                "
                slot="item.btn"
                slot-scope="{ item }"
              >
                <v-btn small @click="addObjects(item)"> Добавить объект </v-btn>
              </template>
            </base-table-full>
          </base-panel>
        </v-col>
      </v-row>
    </v-container>
    <check-operation-add-dragg
      :check-list="currentCheckList"
      ref="checkOperationSelectPanel"
      v-model="dialogAddObject"
      :width="'60vw'"
      :height="'90vh'"
      @add="copyAddObjects"
      :selected-object-type="selectedObjectType"
    />
  </base-popup>
</template>

<script>
import { uniqBy } from "lodash";
import CheckListService from "../../services/CheckListService";
import AuditIndicatorService from "../../services/AuditIndicatorService";
import CheckOperationAddDragg from "@/components/AuditOperation/CheckOperationAddDragg";
import { mapActions } from "vuex";
import BasePopup from "../../layouts/BasePopup.vue";
import BasePanel from "../../layouts/BasePanel.vue";
import BaseTableFull from "../../components/base/BaseTableFull.vue";
import BaseSearch from "./../base/BaseSearch.vue";
import { numWord } from "../../utils/Functions";

export default {
  name: "view-CheckOperationAdd",
  components: {
    BasePopup,
    BasePanel,
    BaseTableFull,
    BaseSearch,
    CheckOperationAddDragg,
  },
  props: {
    auditors: {
      type: Array,
    },
    CheckOperations: Array,
    value: Boolean,
    width: String,
    height: String,
    needAddObject: {
      type: Boolean,
      default: true,
    },
    selectedObjectType: {
      type: Number,
    },
  },
  data: () => {
    return {
      search: ["", "", ""],
      checkLists: [],
      selectedIndicators: [],
      markedCheckLists: [],
      AuditIndicators: [],
      checkOperationAuditors: [],
      selectedCheckLists: [],
      currentCheckList: [],

      indicatorHeaders: [
        { text: "Показатели", value: "Name", customSort: "NumWithString" },
      ],
      сheckListHeaders: [
        { text: "Чек-листы", value: "Name", customSort: "NumWithString" },
        {
          text: "Количество",
          sortable: false,
          value: "Сount",
          width: "1%",
          tooltip: "Количество проверяемых объектов проверки",
        },
        {
          text: "",
          sortable: false,
          value: "btn",
          width: "1%",
        },
      ],
      sortBy: ["Name"],
      indicatorsSortBy: ["Name"],
      dialogAddObject: false,
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    modifiedHeadersСheckListHeaders() {
      return this.selectedObjectType === 0 ||
        this.selectedObjectType === undefined
        ? [...this.сheckListHeaders.slice(0, 1)]
        : this.selectedObjectType === 4
        ? [...this.сheckListHeaders.slice(0, 2)]
        : [...this.сheckListHeaders];
    },
  },
  watch: {
    dialog(visible) {
      if (visible) {
        this.selectedIndicators = [];
        this.markedCheckLists = [];
        if (this.CheckOperations.length) {
          this.markedCheckLists = this.uniqCheckLists(this.CheckOperations);
        }
        this.loadIndicators();
        this.updateCheckLists(true);
      }
    },
    checkLists: function (val) {
      for (const elem of val) {
        if (this.markedCheckLists.find((e) => e.Id === elem.Id)) {
          const foundItem = this.markedCheckLists.find((e) => e.Id === elem.Id);
          this.$set(elem, "objects", foundItem.objects);
          this.$set(elem, "count", foundItem.count);
        } else {
          if (!elem.objects) this.$set(elem, "objects", []);
          if (!elem.count) this.$set(elem, "count", 1);
        }
      }
    },
  },
  methods: {
    ...mapActions(["LOAD_AUDIT_INDICATORS"]), // todo: Не используется...
    async loadIndicators() {
      try {
        this.AuditIndicators = (
          await AuditIndicatorService.get(true, true)
        ).data;
      } catch (error) {
        console.log(error);
      }
    },
    changeCount(item) {
      if (item.count < item.objects?.length) {
        item.count = item.objects.length;
        const foundItem = this.markedCheckLists.find((e) => e.Id === item.Id);
        foundItem.count = item.objects?.length;
      } else if (item.count >= item.objects.length) {
        const foundItem = this.markedCheckLists.find((e) => e.Id === item.Id);
        foundItem.count = item.count;
      }
    },
    handleCount(e, item) {
      item.count = e;
      this.$nextTick(() => {
        item.count = Math.min(99, item.count);
        item.count = Math.max(1, item.count);
      });
    },
    changeIndicator(value) {
      this.selectedIndicators = value;
      this.updateCheckLists(true);
    },
    async updateDataCheckLists(forge) {
      const data = (
        await CheckListService.getByAuditIndicator(
          this.selectedIndicators?.length
            ? this.selectedIndicators.map((v) => v.Id)
            : []
        )
      ).data;
      if (forge) {
        this.checkLists = data;
      } else {
        for (let i = 0; i < data.length; i++) {
          if (!this.checkLists.find((e) => e.Id === data[i].Id)) {
            this.checkLists.push(data[i]);
          }
        }
      }
    },
    async updateCheckLists(forge) {
      try {
        this.updateDataCheckLists(forge);
      } catch (error) {
        console.log(error);
      }
    },
    async saveObjects() {
      this.selectedCheckLists = this._.cloneDeep(this.markedCheckLists);
      this.save(this.selectedCheckLists);
    },
    addObjects(item) {
      this.currentCheckList = [item];
      if (this.needAddObject) this.dialogAddObject = true;
    },
    copyAddObjects(item) {
      this.currentCheckList = [];
      const index = this.markedCheckLists.findIndex((e) => e.Id === item[0].Id);
      this.$set(this.markedCheckLists, index, item[0]);

      const foundСheckLists = this.checkLists.find((e) => e.Id === item[0].Id);
      foundСheckLists.count = item[0].count;
      foundСheckLists.objects = item[0].objects;
    },
    async save(value) {
      const removeCheckList = uniqBy(
        this.CheckOperations.filter(
          (e) => !value.find((e2) => e2.Id === e.CheckList.Id)
        ),
        "CheckList.Id"
      );

      const maxLength = removeCheckList.reduce((max, current) => {
        const currentLength = current.CheckList.Name.length;
        return currentLength > max ? currentLength : max;
      }, 0);

      let width = 400;
      if (maxLength >= 30) {
        width = 500;
        if (maxLength >= 40) {
          width = 600;
          if (maxLength >= 50) {
            width = 700;
          }
        }
      }

      if (
        removeCheckList.length &&
        !(await this.$confirm(
          "<b>Вы хотите исключить " +
            removeCheckList.length +
            numWord(removeCheckList.length, [
              "чек-лист",
              "чек-листа",
              "чек-листов",
            ]) +
            removeCheckList
              .map((e) => e.CheckList.Name)
              .map((e) => "</br>- " + e),
          {
            buttonTrueText: "Исключить",
            center: false,
            extionSticky: true,
            width: width,
          }
        ))
      )
        return;

      this.dialog = false;
      this.$emit("add", value);
    },
    uniqCheckLists(array) {
      return array
        .reduce((o, i) => {
          if (!o.find((v) => v.CheckList?.Id === i.CheckList?.Id)) {
            o.push(i);
          }
          return o;
        }, [])
        .map((f) => ({
          ...f.CheckList,
          count: array.filter((s) => s.CheckList.Id === f.CheckList.Id).length,
          objects:
            this.selectedObjectType === 0
              ? []
              : array
                  .filter((s) => s.CheckList.Id === f.CheckList.Id)
                  .map((d) => {
                    return d[this.getTypeText(this.selectedObjectType)];
                  })
                  .filter((obj) => obj !== undefined),
        }));
    },
    getTypeText(type) {
      switch (type) {
        case 1:
          return "PartitionObject";
        case 2:
          return "EmployeeObject";
        case 4:
          return "CustomObject";
        default:
          break;
      }
    },
  },
};
</script>

<style></style>
