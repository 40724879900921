/*
1) Фильтры - 
2) Источник данных
3) Display - name, value(fun)
*/
import dictionary from "@/dict";
import dictService from "../services/dictService";
import { debounce } from "lodash";
export default {
  props: {
    /*
			dict: {
				name: '',
				update: function,
				notItems: false
			}
		*/
    dict: {
      type: [String, Object],
      default: null,
    },
  },
  inject: {
    provideOptions: {
      default: () => null,
    },
    _provideApiServiceParams: {
      default: () => null,
    },
  },
  data() {
    return {
      paginationItems: [],
      virtualPage: 0,
      totalCount: null,
      loader: null,
      identifier: 1,
    };
  },
  computed: {
    _itemText() {
      if (this.itemText) {
        return this.itemText;
      } else if (this.getDict && this.getDict.itemText) {
        return (item) => {
          if (this.getDict && this.getDict.itemText) {
            return this.getDict.itemText(this, item);
          }
        };
      } else {
        return "value";
      }
    },
    getDict() {
      if (!this.dict) return null;

      let tmp = {
        name: "",
        update: null,
      };
      if (typeof this.dict === "string") {
        tmp.name = this.dict;
        tmp.update = () => {
          this.$store.dispatch(dictionary[this.dict].action);
        };
        tmp = { ...tmp, ...dictionary[this.dict] };
      } else {
        tmp = {
          update: () => {
            this.dict.name &&
              this.$store.dispatch(dictionary[this.dict.name].action);
          },
          ...this.dict,
          ...dictionary[this.dict.name],
        };
      }
      return tmp;
    },
    _items() {
      if (this.getDict?.serverName) {
        return this.paginationItems;
      } else if (this.getDict && !this.getDict?.notItems) {
        const tmp = this._.get(this.$store.state, this.getDict.items);
        if (tmp && this.filter) return this.applayFilter(tmp);
        return tmp;
      }

      return this.$attrs.items && this.filter
        ? this.applayFilter(this.$attrs.items)
        : this.$attrs.items;
    },
  },
  mounted() {
    this.myDynamicWatcher = this.$watch(
      () => this.$refs.select.isMenuActive,
      (val) => {
        if (val && this.getDict) {
          if (this.getDict?.serverName) {
            setTimeout(() => {
              this.identifier++;
            }, 1);
            if (this.loader) this.loader.reset();
            this.resetPagination();
          }

          this.getDict.update();
        }
      }
    );
  },
  watch: {
    search() {
      this.updateSearch();
    },
  },
  methods: {
    updateSearch: debounce(function () {
      this.resetPagination();
    }, 500),
    resetPagination() {
      this.virtualPage = 0;
      if (this.loader) this.loader.reset();
      this.totalCount = null;
      this.paginationItems = [];
    },
    async loadingDataSource() {
      if (!this.dict.serverName) throw new Error("Укажите dict.serverName");

      const res = (
        await dictService.GetListDictionaryValues({
          ...this._provideApiServiceParams.value,
          search: this.search,
          options: {
            ...this.provideOptions?.value,
            page: this.virtualPage,

            itemsPerPage: 5000,
          },
          objectField: this.dict.serverName,
        })
      ).data;

      if (this.virtualPage !== 1) {
        this.paginationItems = [...this.paginationItems, ...res.Values];
      } else this.paginationItems = res.Values;

      this.totalCount = res.TotalCount;
    },
    async infiniteHandler($state) {
      this.loader = $state;

      if (
        this.virtualPage * 5000 < this.totalCount ||
        this.totalCount === null
      ) {
        try {
          this.virtualPage += 1;
          await this.loadingDataSource();
        } catch (error) {
          this.virtualPage--;
          console.log(error);
          $state.error();
          return;
        }
        $state.loaded();
      } else {
        $state.complete();
      }
    },
    updateItemText() {
      this.$attrs.itemText = (item) => {
        return item.Id;
      };
    },
    applayFilter(items) {
      let tmp = [...items];

      if (typeof this.filter === "object" && this.filter.length) {
        for (let i = 0; i < this.filter.length; i++) {
          tmp = this.filtered(this.filter[i], tmp);
        }
      } else if (typeof this.filter === "string") {
        tmp = this.filtered(this.filter, tmp);
      }
      return tmp;
    },
    filtered(item, items) {
      if (typeof item === "string") {
        return this.$options.filters[item].apply(this, [items]);
      } else if (typeof item === "function") {
        return item.apply(this, [items]);
      }
    },
  },
};
