<template>
  <base-popup
    :headerHidden="true"
    v-model="dialog"
    :width="'502px'"
    :height="'347px'"
    v-bind="$attrs"
  >
    <template v-slot:activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>

    <v-container class="d-flex flex-column flex-sm-wrap pt-9 pl-3 pr-3 pb-4">
      <div class="d-flex justify-center">
        <m-icon icon="medexpert-phone" color="#2757FF" size="30"></m-icon>
      </div>

      <div v-if="show1" class="d-flex justify-center align-center flex-sm-wrap">
        <span
          class="mt-3"
          style="width: 100%; text-align: center; color: #2757ff"
        >
          Укажите номер телефона
        </span>
        <div style="width: 150px">
          <v-text-field
            class="mt-5"
            v-model="phoneNum"
            @input="formatNumber"
            @keypress="restrictInput"
            placeholder="+7 ("
            maxlength="18"
          >
          </v-text-field>
        </div>
      </div>

      <div v-if="show2" class="d-flex justify-center align-center flex-sm-wrap">
        <span
          class="mt-3"
          style="width: 100%; text-align: center; color: #2757ff"
        >
          Проверьте актуальность номера телефона
        </span>
        <strong class="black--text headline" style="margin: 32px 0 15px 0">
          Ваш номер {{ formatedUserPhone }}?
        </strong>
      </div>

      <span class="mt-3" style="width: 100%; text-align: center">
        Номер телефона необходим для восстановления пароля и доступа в систему
      </span>

      <div
        class="row flex-container justify-space-between ma-0 pt-8"
        style="width: auto"
      >
        <v-btn
          style="width: 79px"
          large
          outlined
          color="blue-grey"
          @click="closeDialog"
        >
          Отмена
        </v-btn>
        <v-btn
          :class="phoneNum.length !== 18 ? 'theme--dark' : ''"
          v-if="show1"
          :disabled="phoneNum.length !== 18"
          color="primary"
          large
          @click="saveNumber()"
        >
          Подтвердить
        </v-btn>
        <div v-if="show2">
          <v-btn
            style="margin-right: 15px; width: 130.55px"
            large
            outlined
            color="primary"
            @click="changeNumber()"
          >
            Изменить номер
          </v-btn>
          <v-btn color="primary" large @click="сonfirmNumber()">
            Подтвердить
          </v-btn>
        </div>
      </div>
    </v-container>
  </base-popup>
</template>
<script>
import BasePopup from "@/layouts/BasePopup.vue";
import ProfileService from "../../../services/ProfileService";

export default {
  components: { BasePopup },
  props: {
    value: { type: Boolean, default: false },
    settings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      apiService: ProfileService,
      dialog: false,
      phoneNum: "",
      show1: false,
      show2: false,
      cookieUser: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null,
    };
  },
  computed: {
    formatedUserPhone() {
      const number = this.$store.state.user.PhoneNum;
      return `+7 (${number.slice(1, 4)}) ${number.slice(4, 7)}-${number.slice(
        7,
        9
      )}-${number.slice(9, 11)}`;
    },
  },
  watch: {
    dialog(val) {
      this.$emit("input", val);
    },
    value(val) {
      if (val !== this.dialog) this.dialog = val;
    },
  },
  created() {
    if (!this.$store.state.user.PhoneNum) {
      this.show1 = true;
    } else this.show2 = true;
  },
  methods: {
    restrictInput(event) {
      const charCode = event.charCode;
      // Разрешаем только цифры (0-9)
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    },
    formatNumber() {
      this.code = null;

      let input = this.phoneNum || "";

      // Удаляем все нецифровые символы
      input = input.replace(/\D/g, "");

      if (input.startsWith("9")) {
        input = "7" + input;
      }

      if (input.length !== 0) {
        // Форматируем номер
        let formattedNumber = "+7 (";
        if (input.length >= 1) {
          formattedNumber +=
            input.substring(1, 4) + (input.length >= 4 ? ") " : "");
        }
        if (input.length >= 5) {
          formattedNumber += input.substring(4, 7);
        }
        if (input.length >= 8) {
          formattedNumber += "-" + input.substring(7, 9);
        }
        if (input.length >= 10) {
          formattedNumber += "-" + input.substring(9, 11);
        }

        this.phoneNum = formattedNumber;
      }
    },
    closeDialog() {
      this.cookieUser.modalNumberPhone = false; // флаг, чтобы модальное окно не открывалось, пока заново не авторизуется пользователь

      localStorage.setItem("user", JSON.stringify(this.cookieUser));
      this.$parent.showConfirmPhone = false;
    },
    async saveNumber() {
      const response = await this.apiService.updatePhoneNumber(this.phoneNum);
      if (response.status === 200) {
        const phone = this.phoneNum.replace(/\D/g, "");
        this.cookieUser.Employee.User.IsNumberChangedInformed = true;
        this.cookieUser.Employee.User.PhoneNum = phone;
        this.cookieUser.IsNumberChangedInformed = true;
        this.cookieUser.PhoneNum = phone;
        localStorage.setItem("user", JSON.stringify(this.cookieUser));
        this.$store.commit("CONFIRM_PHONE_NUMBER", phone);
        this.$parent.showConfirmPhone = false;
      }
    },
    changeNumber() {
      this.show1 = true;
      this.show2 = false;
    },
    async сonfirmNumber() {
      try {
        const response = await this.apiService.setNumberChangedInformed();

        if (response.status === 200) {
          this.cookieUser.Employee.User.IsNumberChangedInformed = true;
          this.cookieUser.IsNumberChangedInformed = true;
          localStorage.setItem("user", JSON.stringify(this.cookieUser));
          this.$store.commit("CONFIRM_PHONE_NUMBER");
          this.$parent.showConfirmPhone = false;
        }
      } catch {
        this.$alert("Повторите попытку позже");
      }
    },
  },
};
</script>
<style lang="scss"></style>
