<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :disabled="$attrs['readonly']"
    z-index="206"
    content-class="BaseDatePicker"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="text"
        :label="label"
        :clearable="$attrs['clearable']"
        :rules="$attrs['rules']"
        @click:clear="clear"
        :required="$attrs['required']"
        :title="title"
        v-mask="'##.##.####'"
        @change="inputFromText"
        :readonly="$attrs['readonly']"
      >
        <template v-slot:append>
          <m-icon
            v-if="!$attrs['readonly']"
            :icon="$attrs['append-icon'] || 'mdi-calendar'"
            v-on="on"
            v-bind="attrs"
          />
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      :value="date ? date.substr(0, 7) : ''"
      scrollable
      locale="ru"
      type="month"
      no-title
      @input="formatedDate($event)"
    >
    </v-date-picker>
    <v-date-picker
      v-model="date"
      @input="input"
      locale="ru"
      first-day-of-week="1"
      no-title
      scrollable
      :allowed-dates="$attrs['allowed-dates']"
      :weekday-format="getCustomDay"
      class="datePickerRange"
      ref="datePicker"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";
export default {
  props: {
    value: String,
    label: {
      type: String,
      default: "Дата",
    },
    title: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      text: null,
      date: null,
      menu: false,
    };
  },
  computed: {
    dateText: {
      get: function () {
        if (!this.date) return "";
        return this.date.split("-").reverse().join(".");
      },
      set: function () {
        this.date = null;
        this.input();
      },
    },
  },
  watch: {
    dateText: {
      immediate: true,
      handler(val) {
        this.$nextTick(() => {
          this.text = val;
        });
      },
    },
    value: {
      immediate: true,
      handler() {
        if (!this.value) {
          this.date = null;
          return;
        }

        // Убирает время
        let tmp = this.value.substr(0, 10);

        // Формат
        if (this.value.indexOf(".") !== -1) {
          tmp = tmp.split(".").reverse().join("-");
        }
        this.date = tmp;
      },
    },
  },
  methods: {
    inputFromText(text) {
      const date = moment(text, "DD.MM.YYYY");
      if (date.isValid()) {
        this.date = date.format("YYYY-MM-DD");
      } else {
        this.date = null;
      }

      if (this.date) this.formatedDate(this.date);

      this.input();
    },
    getCustomDay(date) {
      const daysOfWeek = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
      const i = new Date(date).getDay(date);
      return daysOfWeek[i];
    },
    formatedDate(date) {
      if (this.$refs.datePicker) this.$refs.datePicker.tableDate = date;
    },
    clear() {
      this.menu = false;
      this.$emit("input", null);
    },
    input() {
      if (!this.date) {
        this.$emit("input", null);
        return;
      }

      const t = moment(this.date, "YYYY-MM-DD");
      if (t.year() < 1900) {
        t.set("year", 1900);
      }
      this.date = t.format("YYYY-MM-DD");

      this.menu = false;
      this.$emit(
        "input",
        this.date.split("-").reverse().join(".") + " 00:00:00"
      );
    },
  },
};
</script>

<style lang="scss">
.v-date-picker-table.v-date-picker-table--date {
  height: auto;
}
</style>
