<template>
  <div>
    <v-container
      v-if="
        id >= 0 &&
        !loading &&
        !(isContentVisible || (fieldFiles && fieldFiles.length))
      "
    >
      <slot name="info"></slot>
      <v-row v-if="showAdd">
        <v-col v-if="showAddText">
          <v-btn block large color="primary" @click="addText">
            <m-icon icon="tool-edit" fill="white" left size="20" />
            Создать текст документа на основе шаблона
          </v-btn>
        </v-col>
        <v-col>
          <v-btn block large color="primary" @click="addFile2()">
            <m-icon icon="mdi-file-document-outline" left />
            <!-- Добавить файл готового документа -->
            {{ nameButtonAddFile }}
          </v-btn>
          <v-file-input
            class="d-none"
            placeholder="Выберите файл"
            @change="fileAdd($event)"
            ref="file"
            :accept="
              supportImg
                ? 'application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, image/png'
                : 'application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            "
          ></v-file-input>
        </v-col>
      </v-row>
    </v-container>
    <base-panel
      v-if="isContentVisible || (fieldFiles && fieldFiles.length)"
      headerTogether
      class="mt-4 local-document-edit__panel"
      :headerHidden="!showDocument || headerHidden"
      :icon="'mdi-file-document-outline'"
      :title="title"
    >
      <v-btn
        v-if="showDocument && showDocument.id !== -1"
        slot="action"
        icon
        title="Скачать оригинал"
        download
        small
        :href="urlForDownload"
      >
        <m-icon icon="tool-download" s16 />
      </v-btn>
      <v-btn
        v-if="!notShowDelete"
        slot="action"
        @click.stop.prevent="showDocument.id !== -1 ? documentDel() : textDel()"
        icon
        color="red lighten-1"
        title="Удалить"
        small
      >
        <m-icon icon="tool-delete" s16 />
      </v-btn>
      <base-left-tabs
        v-model="showDocument"
        @addFile="addFile"
        :show-add="!showAdd ? false : !!files"
        :show-add-text="!showAddText ? false : !isContentVisible"
        :hide-tabs="!!files"
        :dataSource="dataSource"
        ref="base-left-tabs"
      >
        <template slot="itemsAppend" slot-scope="{ showAdd, showAddText }">
          <div class="left-tab__tabs-item" v-if="showAdd">
            <v-file-input
              class="d-none"
              placeholder="Выберите файл"
              @change="fileAdd($event)"
              ref="file"
              :accept="
                supportImg
                  ? 'application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, image/png'
                  : 'application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              "
            ></v-file-input>
            <div>
              <v-btn
                v-if="showAddText"
                text
                color="primary"
                class="left-tab__btn"
                title="Добавить текст"
                block
                @click="addText()"
              >
                <m-icon icon="tool-add-item" left></m-icon>
                Добавить текст
              </v-btn>
              <v-btn
                text
                color="primary"
                class="left-tab__btn"
                title="Добавит документ"
                block
                @click="addFile2()"
              >
                <m-icon icon="tool-add-item" left size="20"></m-icon>
                Добавить файл
              </v-btn>
            </div>
          </div>
        </template>
        <base-left-tab
          v-if="isContentVisible"
          name="text"
          title="Текст"
          :id="-1"
          :value="-1"
        >
          <rich-viewer
            v-if="!loading"
            :readOnly="!enabledContent"
            :value="content"
            :fields="fields"
            ref="rich"
            document-type="document"
            @documentChanged="$emit('documentChanged', $event)"
            :loading="loading"
            style="margin-top: 0 !important"
        /></base-left-tab>

        <base-left-tab
          v-for="file in fieldFiles"
          :key="file.Id"
          :name="file.Name"
          :title="file.Name"
          :id="file.Id"
          :value="file.Id"
        >
          <img-viewer
            v-if="isImage(file)"
            :url="`${apiUrl}common/GetFile?externalId=` + file.ExternalId"
          ></img-viewer>

          <pdf-viewer
            v-else
            :url="`${apiUrl}common/GetFileStream?externalId=` + file.ExternalId"
          ></pdf-viewer>
        </base-left-tab>
      </base-left-tabs>
    </base-panel>
  </div>
</template>
<script>
import { defaultFile } from "@/data/defaultObjects";
import RichViewer from "@/components/rich/RichViewer.vue";
import BaseLeftTabs from "@/components/base/LeftTabs/BaseLeftTabs.vue";
import BaseLeftTab from "@/components/base/LeftTabs/BaseLeftTab.vue";
import PdfViewer from "@/components/rich/PdfViewer.vue";
import ImgViewer from "@/components/rich/ImgViewer.vue";
import BasePanel from "@/layouts/BasePanel.vue";

export default {
  components: {
    RichViewer,
    BaseLeftTabs,
    BaseLeftTab,
    PdfViewer,
    ImgViewer,
    BasePanel,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    fetchText: { type: Function, default: null },
    files: {
      type: Array,
      default: null,
    },
    content: { type: String, default: null },
    isContentVisible: {
      type: Boolean,
      default: false,
    },
    isEnabledContent: {
      type: Boolean,
      default: true,
    },
    templateId: { type: Number, default: 0 },
    fields: { type: Object, default: null },
    init: { type: Function, default: null },
    save: { type: Function, default: null },
    saveAddText: { type: Function, default: null },
    headerHidden: {
      type: Boolean,
      default: false,
    },
    showAddText: {
      type: Boolean,
      default: true,
    },
    showAdd: {
      type: Boolean,
      default: true,
    },
    disabledActions: {
      type: [Boolean, String],
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    nameButtonAddFile: {
      type: String,
      default: "Добавить файл готового документа",
    },
    notShowDelete: {
      type: Boolean,
      default: false,
    },
    supportImg: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showTabs: true,
      dataSource: { Id: 1 },
      showDocument: null,
      loading: false,
    };
  },
  computed: {
    urlForDownload() {
      if (!this.showDocument || !this.fieldFiles) return null;
      const field = this.fieldFiles.find(
        (e) => e.Id === this.showDocument.id || this.showDocument.Id
      );

      const url = `${this.apiUrl}common/GetFile?externalId=${field?.ExternalId}`;
      return url;
    },
    apiUrl() {
      // Полный путь к url должен быть именно в компоненте, а не в шаблоне.
      return process.env.VUE_APP_SERVER_URL;
    },
    fieldFiles() {
      const f = this.files;
      return f || { Value: [] };
    },
    enabledContent() {
      if (this.fields) {
        return this.fields.Content && this.fields.Content.Enabled;
      }
      return this.isEnabledContent;
    },
  },
  watch: {
    showDocument(newVal) {
      this.$emit("showDocument", newVal);
    },
  },
  methods: {
    async fileAdd(file) {
      if (file) {
        const newFile = defaultFile();
        const reader = new FileReader();
        const isWord =
          file.name.endsWith(".docx") || file.name.endsWith(".doc");
        if (file.size > 15728640 && isWord) {
          this.$alert("Файл в формате word не может превышать 15 МБ");
          return;
        }
        reader.readAsDataURL(file);
        reader.onload = () => {
          newFile.Name = file.name;
          newFile.Base64Content = reader.result;
          this.addFile(newFile);
          this.$refs.file.$refs.input.value = null;
        };
      }
    },
    addFile2() {
      if (this.dataSource.Id <= 0) {
        this.$alert("Для добавления файла сохраните документ.");
        return;
      } else if (this.disabledActions) {
        this.$alert(this.disabledActions);
        return;
      }

      this.$refs.file.$refs.input.click();
    },
    async addText() {
      if (this.disabledActions) {
        this.$alert(this.disabledActions);
        return;
      }
      this.$emit("update:isContentVisible", true);
      // Получение шаблонного текста
      const { data } = await this.fetchText(this.templateId);
      this.$emit("update:content", data.Template);
      await this.saveAddText();
    },
    async addFile(e) {
      let f = this.files;
      if (f.find((e2) => e2.Name === e.Name)) {
        if (
          await this.$confirm(
            "Файл " + e.Name + " уже добавлен. Перезаписать ?"
          )
        ) {
          f = this.files.filter((e2) => e2.Name !== e.Name);
          this.$emit("update:files", f);
        } else {
          return;
        }
      }

      this.$emit("update:files", [...f, e]);

      if (!(await this.save())) {
        this.init();
      }
      setTimeout(() => {
        this.$refs["base-left-tabs"].updateTabs();
      }, 100);
    },
    async textDel() {
      if (this.disabledActions) {
        this.$alert(this.disabledActions);
      } else if (await this.$confirm(`Текст будет удален. Вы уверены?`)) {
        this.$emit("update:isContentVisible", false);
        await this.save();
      }
    },
    async documentDel() {
      if (this.disabledActions) {
        this.$alert(this.disabledActions);
      } else if (
        await this.$confirm(
          `Документ ${this.showDocument.name} будет удален. Вы уверены?`
        )
      ) {
        this.$emit(
          "update:files",
          this.fieldFiles.filter((e) => e.Id !== this.showDocument.id)
        );
        await this.save();
      }
    },
    isImage(file) {
      const imageTypes = ["jpeg", "jpg", "png"];
      const fileName = file.Name || "";
      const extension = fileName.split(".").pop().toLowerCase();
      return imageTypes.includes(extension);
    },
  },
};
</script>
<style lang="scss">
.local-document-edit__panel {
  .v-toolbar__title {
    color: var(--v-primary-base) !important;
    font-weight: 500 !important;
    font-size: 16px !important;
  }
}
</style>
