<template>
  <base-list-view
    :dataSource="dataSource"
    :headers="headers"
    :loading="loading"
    @onRefresh="init"
    @onDelete="del"
    :disabledAdd="readOnly"
    title="Разделы деятельности"
    label-texttext-btn-create="Добавить раздел"
    label-search="Поиск раздела"
    :sort-by="['Name']"
    pageEditName="AuditIndicatorCategoriesEdit"
    :showFilters="true"
  >
    <template v-slot:item.data-table-select="{ item, isSelected, select }">
      <v-simple-checkbox
        :value="isSelected"
        :ripple="false"
        :disabled="item.Attribute.Disabled"
        @input="select($event)"
      ></v-simple-checkbox>
    </template>
  </base-list-view>
</template>

<script>
import AuditIndicatorCategoryService from "../../services/AuditIndicatorCategoryService";
import { defaultAuditIndicatorCategory } from "../../data/defaultObjects";
import Permissions from "../../data/permissions";
import BaseListView from "../../layouts/BaseListView.vue";
import ListEditMixin from "../../mixins/ListEditMixin";

export default {
  name: "view-AuditIndicatorCategories",
  components: {
    BaseListView,
  },
  mixins: [ListEditMixin],
  data: function () {
    return {
      loading: true,
      apiService: AuditIndicatorCategoryService,
      defaultObject: defaultAuditIndicatorCategory,
      headers: [
        { text: "Название", value: "Name" },
        { text: "Шаблон документа", value: "TemplateData" },
        {
          text: "Системный",
          value: "IsSystem",
          dataType: "boolean",
        },
      ],
      dataSource: [],
      editIndex: null,
    };
  },
  computed: {
    readOnly: function () {
      return !this.hasPermission(Permissions.AuditIndicatorCategoriesEdit);
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    DataFromServerNormalize(data) {
      for (let i = 0; i < data.length; i++) {
        data[i].Attribute = { Disabled: data[i].System };
      }
      return data;
    },
  },
};
</script>
