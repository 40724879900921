import Api from "./Api";
import qs from "qs";

export default {
  async show() {
    return Api().get("auditoperation/GetAnalysisSettings");
  },
  async update(data) {
    return Api().put("auditoperation/UpdateAnalysisSettings", data);
  },
  async getAuditIndicators() {
    return Api().get("auditoperation/GetAuditIndicatorFromSettings");
  },
  async updateAuditIndicators(data) {
    const auditIndicatorIds = qs.stringify(data);
    return Api().put(
      `auditoperation/UpdateAuditIndicatorInSettings?${auditIndicatorIds}`
    );
  },
};
