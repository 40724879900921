<template>
  <base-detail-view
    :loading="loading"
    title="Контрагент"
    :subTitle="this.type === 'create' ? 'Новый контрагент' : dataSource.Name"
    :isUnsaved="isUnsaved && valid"
    @onRefresh="init"
    @onSave="save"
  >
    <v-form v-model="valid" class="block__content">
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
              v-model="dataSource.Name"
              label="Название"
              :rules="[rules.required]"
              required
              maxlength="100"
              :readonly="readOnly"
              :clearable="!readOnly"
              v-up-first-symbol
            ></v-text-field>
            <v-text-field
              v-model="dataSource.Phone"
              label="Номер телефона"
              maxlength="30"
              :readonly="readOnly"
              :clearable="!readOnly"
            ></v-text-field>
            <v-text-field
              v-model="dataSource.Address"
              label="Адрес"
              maxlength="150"
              :readonly="readOnly"
              :clearable="!readOnly"
              v-up-first-symbol
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </base-detail-view>
</template>

<script>
import ContragentService from "../../services/ContragentService";
import { defaultContragent } from "../../data/defaultObjects";
import Permissions from "../../data/permissions";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";
import UnsavedMixin from "../../mixins/UnsavedMixin.js";
import BaseDetailView from "../../layouts/BaseDetailView.vue";

export default {
  name: "view-ContragentEdit",
  components: {
    BaseDetailView,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  data: function () {
    return {
      apiService: ContragentService,
      getDefaultObject: defaultContragent,
      getObjectText: (obj, isNew) => (isNew ? "новый" : obj.Name),
      loading: true,
      valid: false,
      dataSource: { ...defaultContragent() },
      rules: {
        required: (value) => !!value || "Укажите значение.",
      },
      readOnly: !this.hasPermission(Permissions.ContragentsEdit),
    };
  },
  async mounted() {
    await this.init();
  },
};
</script>
