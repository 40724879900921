<template>
  <base-detail-view
    :loading="loading"
    title="Сотрудник"
    :subTitle="
      this.type === 'create'
        ? 'новый сотрудник'
        : dataSource.LastName + ' ' + dataSource.FirstName
    "
    :isUnsaved="isUnsaved && valid"
    @onRefresh="init"
    @onSave="save"
  >
    <report-activator
      slot="action"
      report="EmployeeReport"
      :params="{
        objectId: { name: 'pEmployee', value: $route.params.objectId },
      }"
    />
    <v-form v-model="valid" class="block__content">
      <v-container>
        <v-row>
          <v-col>
            <div class="d-flex justify-center align-center">
              <v-list-item-avatar
                color="grey lighten-3"
                style="width: 200px; height: 200px; margin: 0"
              >
                <v-img v-if="pictureSrc" :src="pictureSrc"></v-img>
                <span v-else style="font-size: 40px">{{
                  $store.state.user.Employee | PersonInitials
                }}</span>
              </v-list-item-avatar>
            </div>
            <input-load-file
              v-if="!readonlyUser"
              :files="picture"
              accept="image/*"
              label="Фото"
              prepend-icon=""
            >
            </input-load-file>
          </v-col>
          <v-col>
            <v-text-field
              v-model="dataSource.LastName"
              label="Фамилия"
              :rules="[rules.required]"
              required
              maxlength="100"
              @change="changeLastName"
              :readonly="readonlyEmployee"
              :clearable="!readonlyEmployee"
              v-up-first-symbol
            >
            </v-text-field>
            <v-text-field
              v-model="dataSource.FirstName"
              label="Имя"
              :rules="[rules.required]"
              required
              maxlength="100"
              :readonly="readonlyEmployee"
              :clearable="!readonlyEmployee"
              v-up-first-symbol
            ></v-text-field>
            <v-text-field
              v-model="dataSource.MiddleName"
              label="Отчество"
              maxlength="100"
              :readonly="readonlyEmployee"
              :clearable="!readonlyEmployee"
              v-up-first-symbol
            ></v-text-field>
            <base-date-picker
              v-model="dataSource.BirthDate"
              label="Дата рождения"
              :readonly="readonlyEmployee"
              :clearable="!readonlyEmployee"
            ></base-date-picker>
          </v-col>
          <v-col>
            <v-text-field
              v-model="dataSource.User.UserName"
              label="Логин"
              :rules="[rules.required]"
              required
              :readonly="readonlyUser"
            ></v-text-field>
            <v-checkbox
              v-model="dataSource.User.IsActive"
              label="Активный"
              :readonly="readonlyUser"
            ></v-checkbox>
            <m-select
              v-model="dataSource.User.Roles"
              label="Роли"
              :items="roles"
              :item-text="(item) => item.Name"
              return-object
              multiple
              :readonly="readonlyUser"
              :clearable="!readonlyUser"
              dict="Role"
            ></m-select>
            <v-text-field
              v-model="dataSource.User.PhoneNum"
              @input="formatNumber"
              @keypress="restrictInput"
              label="Номер телефона"
              :rules="[rules.requiredNum]"
              placeholder="+7 ("
              maxlength="18"
              :readonly="readonlyUser"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-form v-model="valid">
      <base-panel class="mt-2" headerHidden>
        <m-tabs v-model="tab">
          <v-tab key="Workplaces">Должности</v-tab>

          <v-tab key="Documents">Документы</v-tab>
          <v-tab key="Courses">Курсы</v-tab>
          <v-tab key="UserDevices" v-if="dataSource.User">
            Вход с устройств
          </v-tab>
        </m-tabs>
        <v-tabs-items v-model="tab" class="mt-2">
          <v-tab-item key="Workplaces">
            <workplaces
              :workplaces="dataSource.WorkPlaces"
              :readonly="readonlyEmployee"
              @edit="editWorkplaces"
            ></workplaces>
          </v-tab-item>
          <v-tab-item key="Documents" class="mt-2">
            <list-document
              :value="dataSource.OrganizationDocuments"
              :show-add-in-table="true"
            >
            </list-document>
          </v-tab-item>
          <v-tab-item key="Courses" class="mt-2">
            <list-course :value="dataSource.Courses" :show-add-in-table="true">
            </list-course>
          </v-tab-item>
          <v-tab-item v-if="dataSource.User" key="UserDevices">
            <base-list-view
              :dataSource="dataSource.User.Devices"
              :headers="userDeviceHeaders"
              isNested
              headerHidden
              hide-default-footer
              notShowSelect
              hide-edit
              :showSettings="false"
              not-filter
            >
              <template #item.InitializedValue="{ item }">
                {{ item.Initialized }}
              </template>
            </base-list-view>
          </v-tab-item>
        </v-tabs-items>
      </base-panel>
    </v-form>
    <template slot="action">
      <v-btn
        v-if="!readonlyUser"
        :disabled="isUnsaved"
        @click="resetPassword()"
        dark
        color="primary"
        class="ml-4"
      >
        Сбросить пароль
      </v-btn>
    </template>
  </base-detail-view>
</template>

<script>
import { mapActions } from "vuex";
import EmployeeService from "../../services/EmployeeService";
import { defaultEmployee } from "../../data/defaultObjects";
import Permissions from "../../data/permissions";
import DataHelper from "../../utils/DataHelper";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";
import UnsavedMixin from "../../mixins/UnsavedMixin.js";
import BaseDetailView from "../../layouts/BaseDetailView.vue";
import BaseListView from "../../layouts/BaseListView.vue";
import BasePanel from "../../layouts/BasePanel.vue";
import BaseDatePicker from "../../components/base/BaseDatePicker.vue";
import MSelect from "../../components/base/inputs/mSelect";
import InputLoadFile from "../../components/loadFiles/InputLoadFile.vue";
import Workplaces from "../../components/organization/Workplaces.vue";
import ReportActivator from "../../components/report/ReportActivator.vue";
import mTabs from "@/components/base/tabs/mTabs";

export default {
  name: "view-EmployeeEdit",
  components: {
    BaseDetailView,
    BaseListView,
    BasePanel,
    BaseDatePicker,
    InputLoadFile,
    MSelect,
    Workplaces,
    ReportActivator,
    mTabs,
    listDocument: () => import("@/components/document/ListDocument.vue"),
    listCourse: () => import("@/components/knowledgeBase/ListCourse.vue"),
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  data: function () {
    return {
      apiService: EmployeeService,
      getDefaultObject: defaultEmployee,
      getObjectText: (obj, isNew) =>
        isNew ? "новый" : this.$options.filters.PersonShortName(obj),
      loading: true,
      valid: false,
      dataSource: defaultEmployee(),
      tab: null,
      userDeviceHeaders: [
        { text: "ОС", value: "OSType" },
        { text: "Версия ОС", value: "OSVersion" },
        { text: "Модель", value: "Model" },
        {
          text: "Дата инициализации",
          value: "InitializedValue",
          dataType: "Date",
        },
      ],
      picture: [],
      pictureSrc: "",
      roles: [],
      baseUrl: process.env.VUE_APP_SERVER_URL,
      rules: {
        required: (value) => !!value || "Укажите значение.",
        requiredNum: (value) => {
          if (!value) return true;
          return value.length === 18 || "Введите номер полностью";
        },
      },
      readonlyEmployee: !this.hasPermission(Permissions.EmployeesEdit),
      readonlyUser:
        !this.hasPermission(Permissions.EmployeesEdit) ||
        !this.hasPermission(Permissions.UsersEdit),
    };
  },
  watch: {
    picture(value) {
      if (this.dataSource.User) {
        this.dataSource.User.Picture = value.length ? value[0] : null;
        if (!value.length || !value[0].ExternalId) this.pictureSrc = "";
      }
    },
  },
  async mounted() {
    this.roles = await this.LOAD_ROLES();
    await this.init();
  },
  methods: {
    ...mapActions(["LOAD_ROLES"]),
    DataFromServerNormalize(data) {
      if (!data.Employees) data.Employees = [];
      if (!data.OrganizationDocuments) data.OrganizationDocuments = [];
      if (!data.Courses) data.Courses = [];

      if (data.User) {
        if (data.User.Picture) {
          this.picture.splice(0, 1);
          this.picture.push(data.User.Picture);
          this.pictureSrc = `${this.baseUrl}common/GetFile?externalId=${data.User.Picture.ExternalId}`;
        }
        data.User.Devices.forEach((device) => {
          device.InitializedValue = DataHelper.toDateTime(
            device.Initialized
          ).getTime();
        });

        if (data.User && data.User.PhoneNum) {
          const number = data.User.PhoneNum;
          data.User.PhoneNum = `+7 (${number.slice(1, 4)}) ${number.slice(
            4,
            7
          )}-${number.slice(7, 9)}-${number.slice(9, 11)}`;
        }
      }
      return data;
    },
    changeLastName(value) {
      if (this.type === "create") {
        const login = DataHelper.translite(value, true, false);
        this.$set(this.dataSource.User, "UserName", login);
      }
    },
    editWorkplaces(value) {
      if (!value) return;
      if (value.Id < 0) this.dataSource.WorkPlaces.push(value);
      else {
        const index = this.dataSource.WorkPlaces.findIndex(
          (item) => item.Id === value.Id
        );
        if (index !== -1) this.$set(this.dataSource.WorkPlaces, index, value);
      }
    },
    async resetPassword() {
      if (
        await this.$confirm(
          `Вы действительно хотите сбросить пароль на значение по умолчанию?`
        )
      ) {
        const res = await EmployeeService.updatePassword(this.dataSource.Id);
        this.$alert("Пароль сброшен", { type: "success" });
        const chPwd = res.data.User.ChangePasswordOnFirstLogon;
        this.dataSource.User.ChangePasswordOnFirstLogon = chPwd;
        this.originObject.User.ChangePasswordOnFirstLogon = chPwd;
      }
    },
    restrictInput(event) {
      const charCode = event.charCode;
      // Разрешаем только цифры (0-9)
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    },
    formatNumber() {
      let input = this.dataSource.User.PhoneNum || "";

      // Удаляем все нецифровые символы
      input = input.replace(/\D/g, "");

      if (input.startsWith("9")) {
        input = "7" + input;
      }

      if (input.length !== 0) {
        // Форматируем номер
        let formattedNumber = "+7 (";
        if (input.length >= 1) {
          formattedNumber +=
            input.substring(1, 4) + (input.length >= 4 ? ") " : "");
        }
        if (input.length >= 5) {
          formattedNumber += input.substring(4, 7);
        }
        if (input.length >= 8) {
          formattedNumber += "-" + input.substring(7, 9);
        }
        if (input.length >= 10) {
          formattedNumber += "-" + input.substring(9, 11);
        }

        this.dataSource.User.PhoneNum = formattedNumber;
      } else {
        this.dataSource.User.PhoneNum = null;
      }
    },
    async save() {
      const { data } = await this.baseSave();
      const user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;
      if (user.Id === data.UserId) {
        this.$store.commit("SET_PROFILE", data);

        if (data.User) {
          this.$store.commit("CONFIRM_PHONE_NUMBER", data.User.PhoneNum);
          user.Employee.User.Picture = data.User.Picture;
          user.Picture = data.User.Picture;
        }

        localStorage.setItem("user", JSON.stringify(user));
      }
    },
  },
};
</script>
