<template>
  <base-popup
    title="Добавить объекты"
    @save="save"
    v-model="dialog"
    :icon="UtilGetIcon('view-CheckLists')"
    :width="width"
    :height="height"
    buttonSaveText="Сохранить"
    v-bind="$attrs"
  >
    <template
      slot="subTitle"
      v-if="activeCheckList && activeCheckList.objects && activeCheckList.count"
    >
      <span class="check-operation-add__header__number">
        Выбрано
        {{ activeCheckList.objects.length + " из " + activeCheckList.count }}
      </span>
      <base-search
        v-if="selectedObjectType === 1"
        class="ml-10"
        v-model="listObjectsSearch"
      />
    </template>
    <v-container>
      <v-row>
        <v-col>
          <base-panel v-if="checkList.length > 0" header-hidden>
            <base-table
              @click:row="
                (value) => {
                  this.activeCheckList = value;
                }
              "
              :headers="headersСheckOperation"
              :items="addCheckList"
              notShowSelect
              notCheckSelect
              :showSettings="false"
              hide-default-footer
            >
              <template
                v-for="header in headersСheckOperation"
                v-slot:[`header.${header.value}`]
              >
                <span :key="header.value" :title="header.tooltip">
                  {{ header.text }}
                </span>
              </template>
              <template slot="item.count" slot-scope="{ item }">
                <v-text-field
                  :value="item.count"
                  @input="handleCount($event, item)"
                  @change="changeCount(item)"
                  @click.prevent.stop
                  @focus="() => (activeCheckList = item)"
                  type="number"
                  min="1"
                  max="99"
                ></v-text-field>
              </template>
            </base-table>
          </base-panel>
        </v-col>
        <v-col v-if="activeCheckList">
          <base-panel :loading="loading" headerHidden>
            <div
              class="v-treeview theme--light block__content"
              v-if="selectedObjectType === 1"
            >
              <div class="v-treeview-node__root" style="height: 64px">
                <div class="pl-2" style="color: var(--v-blue-grey-base)">
                  Подразделения
                </div>
              </div>
              <v-treeview
                :value="selectPartitions()"
                @input="activeCheckList.objects = $event"
                :items="listObjects"
                :search="listObjectsSearch"
                selection-type="independent"
                item-key="Id"
                item-text="Name"
                item-children="Children"
                dense
                selectable
                open-all
                return-object
              >
              </v-treeview>
            </div>
            <base-table
              v-else-if="selectedObjectType === 2"
              :headers="headers"
              :items="listObjects"
              :loading="loading"
              v-model="activeCheckList.objects"
              :showSettings="false"
            >
              <template slot="item.objectCheck" slot-scope="{ item }">
                {{ item | PersonShortName }}
              </template>
            </base-table>
          </base-panel>
        </v-col>
      </v-row>
    </v-container>
  </base-popup>
</template>

<script>
import { mapActions } from "vuex";
import PartitionService from "@/services/PartitionService";
import BaseSearch from "../base/BaseSearch.vue";
import BasePanel from "../../layouts/BasePanel";
import BaseTable from "../base/BaseTable.vue";
import BasePopup from "../../layouts/BasePopup.vue";
import DataHelper from "../../utils/DataHelper";
import { numWord } from "../../utils/Functions";

export default {
  name: "view-CheckOperationAddDragg",
  components: {
    BasePanel,
    BaseSearch,
    BaseTable,
    BasePopup,
  },
  props: {
    checkList: {
      type: Array,
      default: () => [],
    },
    value: Boolean,
    width: String,
    height: String,
    selectedObjectType: Number,
  },
  data() {
    return {
      activeCheckList: null,
      active: [],
      loading: false,
      titles: ["1", "Структурные подразделения", "Сотрудники"],
      headersСheckOperation: [
        { text: "Чек-лист", value: "Name", tooltip: "Наименование чек-листа" },
        {
          text: "Количество",
          sortable: false,
          value: "count",
          width: "1%",
          tooltip: "Количество проверяемых объектов проверки",
        },
      ],
      headers: [{ text: "Объект проверки", value: "objectCheck" }],
      listObjectsSearch: "",
      listObjects: [],
      addCheckList: [],
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    "activeCheckList.objects": function (val) {
      if (val && val?.length > this.activeCheckList?.count) {
        this.activeCheckList.count = val.length;
      } else if (val?.length < this.activeCheckList?.count) {
        this.activeCheckList.count = val.length;
      }
    },
    activeCheckList: function () {
      this.updateObjectsList();
    },
    addCheckList: function (val) {
      this.activeCheckList = val[0];
    },
    dialog(visible) {
      if (visible) {
        this.addCheckList = this._.cloneDeep(this.checkList);
      }
      if (!visible) {
        this.activeCheckList = null;
      }
    },
  },
  methods: {
    ...mapActions(["LOAD_EMPLOYEES"]),
    async save() {
      const removeObjects = this.checkList[0].objects.filter(
        (e) => !this.addCheckList[0].objects.find((e2) => e2.Id === e.Id)
      );

      const maxLength = removeObjects.reduce((max, current) => {
        const currentLength =
          this.selectedObjectType === 2
            ? this.$options.filters.PersonShortName({
                FirstName: current.FirstName,
                LastName: current.LastName,
                MiddleName: current.MiddleName,
              })
            : current.CheckList.Name.length;
        return currentLength > max ? currentLength : max;
      }, 0);

      let width = 400;
      if (maxLength >= 30) {
        width = 500;
        if (maxLength >= 40) {
          width = 600;
          if (maxLength >= 50) {
            width = 700;
          }
        }
      }

      if (
        removeObjects.length &&
        !(await this.$confirm(
          "<b>Вы хотите исключить " +
            removeObjects.length +
            numWord(removeObjects.length, ["объект", "объекта", "объектов"]) +
            removeObjects
              .map((e) =>
                this.selectedObjectType === 2
                  ? this.$options.filters.PersonShortName(e)
                  : e.Name
              )
              .map((e) => "</br>- " + e),
          {
            buttonTrueText: "Исключить",
            center: false,
            extionSticky: true,
            width: width,
          }
        ))
      )
        return;

      this.dialog = false;
      this.$emit("add", this.addCheckList);
    },
    changeCount(item) {
      if (item.count < item.objects.length) item.count = item.objects.length;
    },
    handleCount(e, item) {
      item.count = e;
      this.$nextTick(() => {
        item.count = Math.min(99, item.count);
        item.count = Math.max(1, item.count);
      });
    },
    selectPartitions() {
      return this.activeCheckList.objects.map((e) =>
        DataHelper.findTreeById(this.listObjects, e?.Id)
      );
    },
    async updateObjectsList() {
      this.loading = true;
      this.listObjects = [];

      if (this.selectedObjectType === 2) {
        const employees = await this.LOAD_EMPLOYEES();
        for (const i in employees) {
          this.listObjects.push(employees[i]);
        }
      } else if (this.selectedObjectType === 1) {
        const partitions = (await PartitionService.get()).data;
        // Add property ParantId
        var addParentId = (items, parentId) => {
          for (const j in items) {
            items[j].ParentId = parentId;
            if (items[j].Children.length !== 0)
              addParentId(items[j].Children, items[j].Id);
          }
        };
        for (const i in partitions) {
          if (partitions[i].Children?.length !== 0) {
            addParentId(partitions[i].Children, partitions[i].Id);
          }
        }

        for (const i in partitions) {
          this.listObjects.push(partitions[i]);
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="sass">
.check-operation-add__header__number
  color: var(--v-text-base)
  font-weight: 500
  font-size: 14px
  line-height: 158%
  margin-left: 50px
  font-weight: bold
</style>
