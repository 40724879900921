<template>
  <v-list-group color="primary" class="navigation-item-group">
    <template v-slot:activator>
      <v-list-item-icon>
        <m-icon :icon="UtilGetIcon(view, typeView)"></m-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="text"></v-list-item-title>
      </v-list-item-content>

      <v-list-item-icon>
        <NotificationPoint
          :typeView="typeView"
          :view="view"
        ></NotificationPoint>
      </v-list-item-icon>
    </template>
    <navigation-item
      v-for="(item, i) in children"
      :key="i"
      :level="1"
      v-bind="item"
    />
  </v-list-group>
</template>

<script>
import NotificationPoint from "../modules/Notification/notificationPoint.vue";
import NavigationItem from "./NavigationItem.vue";

export default {
  name: "view-NavigationGroup",
  components: { NavigationItem, NotificationPoint },
  props: {
    view: String,
    typeView: String,
    text: String,
    children: Array,
  },
};
</script>
<style lang="scss">
.navigation-item-group {
  .v-list-item__icon.v-list-group__header__append-icon {
    min-width: 24px !important;
    margin-left: 8px !important;
  }
}
</style>
