<template>
  <base-list-view
    :dataSource="workplaces"
    :headers="headers"
    header-hidden
    isNested
    hide-default-footer
    show-actions
    editable
    :show-add-in-table="!readonly"
    :hideDelete="readonly"
    text-custom-add="Добавить должность"
    @customAdd="add"
    @customEdit="edit"
  >
    <workplace-edit
      v-model="dialogEdit"
      :workplace="current"
      :readonly="readonly"
      :width="'80vw'"
      :height="'80vh'"
      @save="change"
    ></workplace-edit>
  </base-list-view>
</template>

<script>
import Vue from "vue";
import { defaultWorkplace } from "../../data/defaultObjects";
import BaseListView from "../../layouts/BaseListView.vue";
import WorkplaceEdit from "./WorkplaceEdit.vue";

export default {
  name: "view-Workplaces",
  components: {
    BaseListView,
    WorkplaceEdit,
  },
  props: {
    workplaces: {
      type: Array,
      default: () => [],
    },
    readonly: Boolean,
  },
  data: () => {
    return {
      headers: [
        { text: "Должность", value: "Position.Name" },
        {
          text: "Дата начала",
          value: "Period.DateIn",
          dataType: "Date",
          displayText: Vue.filter("DateShort"),
        },
        {
          text: "Дата окончания",
          value: "Period.DateOut",
          dataType: "Date",
          displayText: Vue.filter("DateShort"),
        },
        { text: "Подразделение", value: "Place.Name" },
        { text: "Расписание", value: "Schedule" },
        { text: "Примечание", value: "Comment" },
      ],
      dialogEdit: false,
      current: defaultWorkplace(),
    };
  },
  methods: {
    add() {
      this.current = defaultWorkplace();
      this.dialogEdit = true;
    },
    edit(value) {
      if (value) {
        this.current = this._.clone(value);
        this.dialogEdit = true;
      }
    },
    change(value) {
      this.dialogEdit = false;
      this.$emit("edit", value);
    },
  },
};
</script>
