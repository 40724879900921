<template>
  <v-snackbar
    top
    :value="value"
    error
    :color="(type === 'red' ? 'lighten-2' : '') + ' ' + type"
    dark
  >
    {{ message }}

    <template v-slot:action="{}">
      <v-btn icon @click.native="value = false">
        <m-icon :icon="$vuetify.icons.values.close"></m-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "base-alert",
  components: {},

  props: {
    message: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "red",
    },
  },
  data() {
    return {
      value: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.$destroy();
    }, 10000);
    document.addEventListener("keyup", this.onEnterPressed);
  },
  destroyed() {
    document.removeEventListener("keyup", this.onEnterPressed);
  },
};
</script>
