import Api from "./Api";

export default {
  import(file) {
    const data = new FormData();

    data.append("File", file);

    return Api({ "Content-Type": "multipart/form-data" }).post(
      "exchange/ImportPartitions",
      data
    );
  },
  task(id) {
    return Api().get("exchange/GetImportPartitionsTask", {
      params: { id },
    });
  },
  tasks() {
    return Api().get("exchange/GetImportPartitionsTasks ");
  },
};
