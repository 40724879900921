<template>
  <base-detail-view
    :loading="loading"
    :title="'Вид НС: ' + dataSource.Name"
    :isUnsaved="isUnsaved && valid"
    @onSave="save"
    @onRefresh="init"
  >
    <v-form v-model="valid" :readonly="readonly">
      <div class="block__content">
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-model="dataSource.Name"
                label="Название"
                maxlength="200"
                :rules="[rules.required]"
                required
                :readonly="readonly"
                v-up-first-symbol
              ></v-text-field>
              <v-checkbox
                v-if="$store.state.Organization.OwnerType === 2"
                v-model="dataSource.IsShared"
                label="Другие МО"
                :readonly="readonly"
                @change="updateTabs"
              ></v-checkbox>
            </v-col>
            <v-col>
              <m-autocomplete
                multiple
                v-model="dataSource.Responsibles"
                :items="employees"
                label="Ответственные"
                return-object
                filter="EmployeeActive"
                dict="Employees"
                :textCustomAdd="'Создать нового сотрудника'"
                :readonly="readonly"
                :clearable="!readonly"
              ></m-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-form>
  </base-detail-view>
</template>

<script>
import UnwishedEventTypeService from "../../services/UnwishedEventTypeService";
import { defaultUnwishedEventType } from "../../data/defaultObjects";

import UnsavedMixin from "../../mixins/UnsavedMixin.js";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";
import { mapActions } from "vuex";
import BaseDetailView from "../../layouts/BaseDetailView.vue";
import mAutocomplete from "../../components/base/inputs/mAutocomplete.vue";

export default {
  name: "view-UnwishedEventTypesEdit",
  components: {
    BaseDetailView,
    mAutocomplete,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  data: function () {
    return {
      // Api
      apiService: UnwishedEventTypeService,
      getDefaultObject: defaultUnwishedEventType,
      getObjectText: (obj, isNew) =>
        isNew ? "новое" : obj.Date?.substr(0, 10),
      loading: true,
      tab: null,
      dataSource: defaultUnwishedEventType(),
      valid: false,
      showCompleted: false,
      rules: {
        required: (value) => !!value || "Укажите значение.",
      },
      employees: [],
    };
  },
  computed: {
    readonly() {
      return this.dataSource.OnlyRead;
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    ...mapActions(["LOAD_EMPLOYEES"]),
    DataFromServerNormalize(data) {
      return data;
    },

    async save() {
      await this.baseSave();
    },
    updateTabs() {
      this.$refs.mtabs.$refs.tabs.callSlider();
    },
  },
};
</script>

<style lang="scss"></style>
