<template>
  <div>
    <typical-violation-table
      v-bind="$attrs"
      :readOnly="readOnly"
      change-typical
    >
      <template
        v-if="item && item.Images.length"
        slot="violation-expansion-td"
        slot-scope="item"
      >
        <images2
          :images="item.Images"
          @update:images="updateImages($event, item)"
          :readOnly="readOnly"
        />
      </template>
      <template slot="expansionAction" slot-scope="{ item, readOnly, recm }">
        <v-row>
          <images
            :images="item.Images"
            :type="recm ? 1 : 0"
            :readOnly="readOnly"
          />
          <task-one-info
            :task.sync="item.CheckTask"
            :item="item"
            :type="recm ? 1 : 0"
            :readOnly="readOnly"
          />
        </v-row>
      </template>
    </typical-violation-table>
  </div>
</template>

<script>
import TypicalViolationTable from "./TypicalViolationTable";
import Images from "./Images";
import Images2 from "./Images2";

import TaskOneInfo from "../../../task/TaskOneInfo.vue";

export default {
  name: "view-violation-table",
  components: { TypicalViolationTable, Images, Images2, TaskOneInfo },
  props: {
    readOnly: {
      type: Boolean,
    },
  },
  methods: {
    updateImages(imgs, item) {
      item.Images = imgs;
      this.$emit("update:item", item);
    },
  },
};
</script>
