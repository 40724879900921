<template>
  <base-detail-view
    :loading="loading"
    title="Показатель оценки качества"
    :subTitle="this.type === 'create' ? 'новый показатель' : dataSource.Name"
    :isUnsaved="isUnsaved && valid"
    @onRefresh="init"
    @onSave="save"
  >
    <v-form v-model="valid" class="block__content">
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
              v-model="dataSource.Name"
              label="Название"
              :rules="[rules.required]"
              required
              maxlength="200"
              :readonly="readOnly"
              :clearable="!readOnly"
              v-up-first-symbol
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <m-select
              v-model="dataSource.ActivitySection"
              :items="categories"
              item-text="Name"
              label="Раздел деятельности"
              return-object
              :readonly="readOnly"
              :clearable="!readOnly"
            ></m-select>
          </v-col>
          <v-col>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="dataSource.RefValue1"
                  type="Number"
                  label="Реф. значение 1"
                  :rules="[rules.refRange]"
                  :readonly="readOnly"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="dataSource.RefValue2"
                  type="Number"
                  label="Реф. значение 2"
                  :rules="[
                    rules.refRange,
                    (value) => rules.ref2(value, dataSource.RefValue1),
                  ]"
                  :readonly="readOnly"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="dataSource.RefValue3"
                  type="Number"
                  label="Реф. значение 3"
                  :rules="[
                    rules.refRange,
                    (value) => rules.ref3(value, dataSource.RefValue2),
                  ]"
                  :readonly="readOnly"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="dataSource.Description"
              label="Описание"
              rows="2"
              maxlength="500"
              :readonly="readOnly"
              v-up-first-symbol
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <m-tabs v-model="tab" :slider-size="2" :with-route="false" class="mt-3">
      <v-tab key="CheckList">Чек-листы</v-tab>
      <v-tab key="Document">Документы</v-tab>
    </m-tabs>

    <v-window v-model="tab">
      <v-window-item>
        <base-list-view
          title="Чек-листы"
          pageEditName="CheckListEdit"
          :funcHandleClickId="
            (val) => {
              return val.CheckList.Id;
            }
          "
          :dataSource="dataSource.CheckLists"
          :headers="headers"
          header-hidden
          isNested
          hide-default-footer
          notShowSelect
          editable
          show-actions
          add-in-table
          :show-add-in-table="!readOnly"
          :hideDelete="readOnly"
          hideEdit
          :defaultObject="getDefaultCheckList"
          text-custom-add="Добавить чек-лист"
          @customAdd="dialog = true"
          class="mt-2"
        >
          <template #item.CheckList.Name="scope">
            <m-autocomplete
              v-if="scope.rowEditing && scope.item.Id === scope.rowEditing.Id"
              v-model="scope.rowEditing.CheckList"
              :items="filteredCheckLists(scope.rowEditing)"
              item-text="Name"
              return-object
              :dict="{
                name: 'CheckList',
                notItems: true,
                update: updateCheckLists,
              }"
            ></m-autocomplete>
            <span v-else>{{ getCheckListName(scope.item.CheckList) }}</span>
          </template>
          <template #item.ObjectCount="scope">
            <v-text-field
              v-if="scope.rowEditing && scope.item.Id === scope.rowEditing.Id"
              :value="scope.item.ObjectCount"
              @change="scope.rowEditing.ObjectCount = parseInt($event)"
              type="Number"
            ></v-text-field>
            <span v-else>{{ scope.item.ObjectCount }}</span>
          </template>
        </base-list-view>
      </v-window-item>
      <v-window-item>
        <list-document-with-template
          v-model="dataSource.Documents"
          class="mt-2"
          :show-add-in-table="true"
        ></list-document-with-template>
      </v-window-item>
    </v-window>

    <check-operation-add
      :CheckOperations="dataSource.CheckLists"
      :auditors="[]"
      :width="'60vw'"
      :height="'90vh'"
      :needAddObject="false"
      v-model="dialog"
      @add="addCheckLists"
    />
  </base-detail-view>
</template>

<script>
import AuditIndicatorService from "../../services/AuditIndicatorService";
import AuditIndicatorCategoryService from "../../services/AuditIndicatorCategoryService";
import CheckListService from "../../services/CheckListService";
import { defaultAuditIndicator } from "../../data/defaultObjects";
import Permissions from "../../data/permissions";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";
import UnsavedMixin from "../../mixins/UnsavedMixin.js";
import BaseDetailView from "../../layouts/BaseDetailView.vue";
import BaseListView from "../../layouts/BaseListView.vue";
import MSelect from "../../components/base/inputs/mSelect.vue";
import mAutocomplete from "../../components/base/inputs/mAutocomplete.vue";
import CheckOperationAdd from "../../components/AuditOperation/CheckOperationAdd.vue";
import { sortBy } from "../../utils/Functions";
import { mapActions } from "vuex";

export default {
  name: "view-AuditIndicatorEdit",
  components: {
    mTabs: () => import("@/components/base/tabs/mTabs"),
    listDocumentWithTemplate: () =>
      import("@/components/document/ListDocumentWithTemplate.vue"),
    BaseDetailView,
    BaseListView,
    MSelect,
    mAutocomplete,
    CheckOperationAdd,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  data: function () {
    return {
      apiService: AuditIndicatorService,
      getDefaultObject: defaultAuditIndicator,
      getObjectText: (obj, isNew) => (isNew ? "новый" : obj.Name),
      loading: true,
      valid: false,
      dataSource: { ...defaultAuditIndicator() },
      checkLists: [],
      categories: [],
      headers: [
        {
          text: "Чек-лист",
          value: "CheckList.Name",
          dataType: "string",
          displayText: (p) => p?.Name,
        },
        {
          text: "Примечание",
          value: "CheckList.Comment",
          dataType: "string",
          align: "center",
          cellClass: "text-no-wrap",
        },
        {
          text: "Статус",
          value: "CheckList.Archived",
          align: "center",
          width: "10%",
          dataType: "boolean",
          options: { false: "Действующий", true: "В архиве" },
        },
      ],
      getDefaultCheckList: () => {
        return {
          Id: -Math.round(Math.random() * 100000),
          CheckList: null,
          ObjectCount: 0,
        };
      },
      rules: {
        required: (value) => !!value || "Укажите значение.",
        refRange: (value) =>
          (value >= 0 && value <= 100) || "Значение должно быть от 0 до 100",
        ref2: (value, val1) =>
          value === 0 ||
          value > parseFloat(val1) ||
          "Каждое значение должно быть последовательно больше предыдущего",
        ref3: (value, val2) =>
          value === 0 ||
          (val2 > 0 && value > parseFloat(val2)) ||
          "Каждое значение должно быть последовательно больше предыдущего",
      },
      readOnly: !this.hasPermission(Permissions.AuditIndicatorsEdit),
      dialog: false,
      tab: 0,
    };
  },
  async mounted() {
    this.categories = sortBy(
      (await AuditIndicatorCategoryService.get()).data,
      "Name"
    );
    this.LOAD_DOCUMENT_TEMPLATE_ALL();
    await this.updateCheckLists();
    await this.init();
  },
  methods: {
    ...mapActions("loaderDataSources", ["LOAD_DOCUMENT_TEMPLATE_ALL"]),
    DataFromServerNormalize(data) {
      data.CheckLists = data.CheckLists.map((c) => ({
        ...c,
        Type: c.CheckList.Type,
      }));
      return data;
    },
    async updateCheckLists() {
      this.checkLists = (await CheckListService.get([], [0, 1, 2, 4])).data;
    },
    filteredCheckLists(rowEditing) {
      return sortBy(
        this.checkLists.filter(
          (e) =>
            !this.dataSource.CheckLists.find(
              (e2) => e2?.CheckList?.Id === e?.Id && rowEditing.Id !== e2.Id
            )
        ),
        "Name"
      );
    },
    editDetails(item) {
      this.$router.push({
        name: "CheckListEdit",
        params: {
          objectId: item.CheckList.Id,
        },
      });
    },
    getCheckListName(list) {
      return list?.Name;
    },
    addCheckLists(val) {
      if (!val || !val.length) return;
      this.dataSource.CheckLists = [];
      for (const ch of val) {
        const newCheckList = this.getDefaultCheckList();
        newCheckList.CheckList = ch;
        if (!this.dataSource.CheckLists.some((c) => c.CheckList.Id === ch.Id))
          this.dataSource.CheckLists.push(newCheckList);
      }
    },
  },
};
</script>
