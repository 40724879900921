import Api from "./Api";
import qs from "qs";

export default {
  async getListPerPage(
    options,
    {
      project,
      unwishedEvent,
      parent,
      author,
      executor,
      notify,
      employeeId,
      done,
      doneIn,
      doneOut,
      links,
      currentTaskId,
    }
  ) {
    return Api().post("common/GetListPerPage", {
      ...options,
      ...{
        project,
        unwishedEvent,
        parent,
        author,
        executor,
        notify,
        employeeId,
        done,
        doneIn,
        doneOut,
        links,
        currentTaskId,
      },
      listObjectType: "Task",
    });
  },
  async getListByGroup(
    options,
    {
      project,
      unwishedEvent,
      parent,
      author,
      executor,
      notify,
      employeeId,
      done,
      doneIn,
      doneOut,
      links,
      currentTaskId,
    }
  ) {
    return Api().post("common/GetListByGroup", {
      options,
      ...{
        project,
        unwishedEvent,
        parent,
        author,
        executor,
        notify,
        employeeId,
        done,
        doneIn,
        doneOut,
        links,
        currentTaskId,
      },
      listObjectType: "Task",
    });
  },
  async get({
    project,
    unwishedEvent,
    parent,
    author,
    executor,
    notify,
    employeeId,
    done,
    doneIn,
    doneOut,
    links,
    currentTaskId,
    document,
  }) {
    return Api().get("task/getTasks", {
      params: {
        project,
        parent,
        author,
        executor,
        notify,
        employeeId,
        done,
        doneIn,
        doneOut,
        links,
        unwishedEvent,
        currentTaskId,
        document,
      },
    });
  },
  async getTasksByAuditOperations(ids = [], planId = 0) {
    return Api().get("task/GetTasksByAuditOperations", {
      params: { ids, planId },
      paramsSerializer: function (params) {
        return qs.stringify(params);
      },
    });
  },
  async show(id) {
    return Api().get("task/getTask", { params: { id } });
  },
  async create(task) {
    return Api().post("task/CreateTask", task);
  },
  async update(task) {
    return Api().put("task/UpdateTask", task);
  },
  async updateState(id, done, result, isPeriodicityClosing = false) {
    return Api().put("task/UpdateTaskState", {
      TaskId: id,
      Done: done,
      Result: result,
      IsPeriodicityClosing: isPeriodicityClosing,
    });
  },
  async delete(id) {
    return Api().delete("task/deleteTask", {
      data: { id: typeof id === "number" ? id : id.Id },
    });
  },
  async updateMultiple(executorId, done, objectIds) {
    return Api().put("task/UpdateTasks", {
      ExecutorId: executorId,
      Done: done,
      ObjectIds: objectIds,
    });
  },

  getTasksWithChildren(ids) {
    return Api().get("task/getTasksWithChildren", {
      params: { ids },
      paramsSerializer: function (params) {
        return qs.stringify(params);
      },
    });
  },

  makeIndependentTask(id) {
    return Api().put("task/MakeIndependentTask", { id });
  },

  document: {
    async delete(task) {
      if (task.Id < 0) return true;
      return Api().delete("task/DeleteDocumentTask", { data: { Id: task.Id } });
    },
  },
  unwishedEvent: {
    async delete(task) {
      if (task.Id < 0) return true;
      return Api().delete("task/DeleteUnwishedEventTask", {
        data: { Id: task.Id },
      });
    },
  },
  project: {
    async delete(task) {
      if (task.Id < 0) return true;
      return Api().delete("task/DeleteProjectTask", { data: { Id: task.Id } });
    },
  },
};
