<template>
  <base-detail-view
    :loading="loading"
    :title="'Ответственный за результаты нежелательных событий'"
    :isUnsaved="isUnsaved && valid"
    @onSave="save"
    @onRefresh="init"
  >
    <v-form v-model="valid">
      <div class="block__content">
        <v-container>
          <m-autocomplete
            v-model="dataSource.Employee"
            :items="employees"
            label="Сотрудник"
            required
            :rules="[rules.required]"
            return-object
            :clearable="false"
            filter="EmployeeActive"
            dict="Employees"
            :textCustomAdd="'Создать нового сотрудника'"
            autofocus
          ></m-autocomplete>
          <v-row>
            <v-col>
              <m-select
                :value="!dataSource.AllTypes ? dataSource.Types : []"
                @input="dataSource.Types = $event"
                :items="eventTypes"
                multiple
                item-text="Name"
                :label="
                  !dataSource.AllTypes
                    ? 'Вид нежелательного события'
                    : 'Все виды нежелательного события'
                "
                :disabled="dataSource.AllTypes"
                :clearable="dataSource.AllTypes"
                return-object
                :required="!dataSource.AllTypes ? true : false"
                :rules="!dataSource.AllTypes ? [rules.required] : []"
              >
              </m-select>
            </v-col>
            <v-col>
              <v-checkbox
                label="Все виды НС"
                v-model="dataSource.AllTypes"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row class="mt-n6">
            <v-col>
              <m-tree-select
                :value="!dataSource.AllPartitions ? dataSource.Partitions : []"
                @input="dataSource.Partitions = $event"
                :items="partitions"
                :multiple="true"
                :label="
                  !dataSource.AllPartitions
                    ? 'Подразделение'
                    : 'Все подразделения'
                "
                selection-type="independent"
                allowSelectParents
                :disabled="dataSource.AllPartitions"
                :clearable="dataSource.AllPartitions"
                :dict="{ name: 'Partition', notItems: true }"
                :required="!dataSource.AllPartitions ? true : false"
                :rules="!dataSource.AllPartitions ? [rules.required] : []"
              >
              </m-tree-select>
            </v-col>
            <v-col>
              <v-checkbox
                label="Все подразделения"
                v-model="dataSource.AllPartitions"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-form>
  </base-detail-view>
</template>

<script>
import UnwishedEventResponsibleService from "../../services/UnwishedEventResponsibleService";
import { defaultUnwishedEventResponsible } from "../../data/defaultObjects";
import UnsavedMixin from "../../mixins/UnsavedMixin.js";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";
import { mapActions } from "vuex";
import BaseDetailView from "../../layouts/BaseDetailView.vue";
import MSelect from "../../components/base/inputs/mSelect.vue";
import mAutocomplete from "../../components/base/inputs/mAutocomplete.vue";
import MTreeSelect from "../../components/base/inputs/mTreeSelect.vue";

export default {
  name: "view-UnwishedEventResponsiblesEdit",
  components: {
    BaseDetailView,
    MSelect,
    mAutocomplete,
    MTreeSelect,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  data: function () {
    return {
      // Api
      apiService: UnwishedEventResponsibleService,
      loading: true,
      dataSource: defaultUnwishedEventResponsible(),
      valid: false,
      showCompleted: false,
      employees: [],
      eventTypes: [],
      partitions: [],
      rules: {
        required: (value) =>
          (!!value && value.length !== 0) || "Укажите значение.",
      },
    };
  },
  computed: {
    readonly() {
      return false;
    },
  },
  watch: {},
  async mounted() {
    try {
      this.employees = await this.LOAD_EMPLOYEES();
      this.eventTypes = await this.LOAD_UNWISHED_EVENT_TYPES();
      this.partitions = await this.LOAD_PARTITIONS();
    } catch (error) {
      console.log(error);
    }
    await this.init();
  },
  methods: {
    ...mapActions([
      "LOAD_EMPLOYEES",
      "LOAD_UNWISHED_EVENT_TYPES",
      "LOAD_PARTITIONS",
    ]),
    DataFromServerNormalize(data) {
      return data;
    },

    async save() {
      if (this.dataSource.AllPartitions) this.dataSource.Partitions = [];
      if (this.dataSource.AllTypes) this.dataSource.Types = [];
      await this.baseSave();
    },
  },
};
</script>

<style lang="scss"></style>
