import Api from "./Api";

export default {
  async get(done, params) {
    return Api().get("unwishedevent/getUnwishedEvents", {
      params: { done, ...params },
    });
  },
  async show(id) {
    return Api().get("unwishedevent/getUnwishedEvent", { params: { id } });
  },
  async create(ev) {
    return Api().post("unwishedevent/createUnwishedEvent", ev);
  },
  async update(ev) {
    return Api().put("unwishedevent/updateUnwishedEvent", ev);
  },
  async delete(id) {
    return Api().delete("unwishedevent/deleteUnwishedEvent", {
      params: { id },
    });
  },
  async updateStatus(ev) {
    return Api().put("unwishedevent/UpdateUnwishedEventState", ev);
  },
  async getByPeriods(year, periodType) {
    return Api().get("unwishedevent/GetUnwishedEventPeriods", {
      params: { year, periodType },
    });
  },

  pin() {},
  unPin(Id) {
    return Api().put("/unwishedevent/UnlinkUnwishedEvent", {
      Id,
    });
  },
};
