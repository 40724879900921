<template>
  <base-list-view
    :dataSource="dataSource"
    :headers="headers"
    :loading="loading"
    @onRefresh="init"
    @customDelete="customDelete"
    show-actions
    :editable="!readonly"
    :showDelete="false"
    :disabledAdd="readonly"
    hideEdit
    title="Виды нежелательных событий"
    label-text-btn-create="Добавить вид"
    label-text-search="Поиск вида"
    notShowSelect
    pageEditName="UnwishedEventTypesEdit"
    :disabledDelete="(item) => item.OnlyRead"
    :showFilters="true"
  >
    <template #item.Name="scope">
      <span>{{ scope.item.Name }}</span>
    </template>
    <template #item.Owner="scope">
      {{
        scope.item.Owner.Id !== $store.state.Organization.OwnerId
          ? scope.item.Owner.Name
          : "-"
      }}
    </template>
    <template #item.Responsibles="scope">
      <span>{{
        scope.item.Responsibles.map((e) =>
          $options.filters.PersonShortName(e)
        ).join(", ")
      }}</span>
    </template>
  </base-list-view>
</template>

<script>
import { mapActions } from "vuex";
import UnwishedEventTypeService from "../../services/UnwishedEventTypeService";
import { defaultUnwishedEventType } from "../../data/defaultObjects";
import Permissions from "../../data/permissions";
import BaseListView from "../../layouts/BaseListView.vue";
import ListEditMixin from "../../mixins/ListEditMixin";
import { sortBy } from "@/utils/Functions";

export default {
  name: "view-UnwishedEventTypes",
  components: { BaseListView },
  mixins: [ListEditMixin],
  data: function () {
    return {
      loading: true,
      apiService: UnwishedEventTypeService,
      defaultObject: defaultUnwishedEventType,
      headers: [
        { text: "Название", value: "Name", notGroup: true },
        {
          text: "Ответственные",
          value: "Responsibles",
          displayText: (e) => this.$options.filters.PersonShortName(e),
          dataType: "array",
        },
        this.$store.state.Organization.OwnerType !== 0
          ? this.$store.state.Organization.OwnerType === 2
            ? {
                text: "Другие МО",
                value: "IsShared",
                dataType: "enum",
                options: [
                  { id: 1, value: "да" },
                  { id: 0, value: "нет" },
                ],
                align: "center",
              }
            : {
                text: "Головная организация",
                value: "Owner",
                dataType: "object",
                displayText: (item) => item.Name,
                align: "center",
              }
          : null,
      ].filter(Boolean),

      dataSource: [],
      editIndex: null,
    };
  },
  computed: {
    readonly() {
      return !this.hasPermission(Permissions.UnwishedEventTypesEdit);
    },
  },
  async mounted() {
    await this.init();
    this.LOAD_EMPLOYEES();
  },
  methods: {
    ...mapActions(["LOAD_EMPLOYEES"]),
    DataFromServerNormalize(data) {
      for (const item of data) {
        item.IsShared = item.IsShared ? 1 : 0;
        item.Responsibles = sortBy(item.Responsibles, (e) => {
          return this.$options.filters.PersonShortName(e);
        });
      }
      return data;
    },
    async customDelete(value) {
      if (!value) return;
      if (value.Id > 0) await UnwishedEventTypeService.delete(value.Id);
      const index = this.dataSource.findIndex((item) => item.Id === value.Id);
      if (index >= 0) this.dataSource.splice(index, 1);
      this.editIndex = -1;
    },
  },
};
</script>
