<template>
  <base-popup
    title="Выбрать подразделения"
    @save="save"
    v-model="dialog"
    :width="width"
    :height="height"
    :icon="UtilGetIcon('view-Partitions')"
  >
    <v-form>
      <v-container>
        <v-flex class="d-flex justify-end">
          <base-search slot="action" v-model="searchText"></base-search>
        </v-flex>

        <v-divider />
        <v-flex class="d-flex flex-row align-center justify-space-between">
          <v-checkbox v-model="allSelected" style="margin-left: 37px" />
          <label class="flex-grow-1">Подразделение</label>
          <label class="treeview__col2">Стандарт оснащения</label>
        </v-flex>
        <v-divider />
        <v-treeview
          item-key="Id"
          item-text="Name"
          item-children="Children"
          :items="dataSource"
          :search="searchText"
          :filter="filter"
          :value="selectPartitions()"
          @input="selected = $event"
          selectable
          selection-type="independent"
          return-object
          expand-icon="$ToolArrowDown"
        >
          <template #label="{ item }">
            <v-flex class="d-flex flex-row align-center justify-space-between">
              <div>{{ item.Name }}</div>
              <div class="treeview__col2">
                {{ item.EquipmentStandarts.map((s) => s.Name).join(", ") }}
              </div>
            </v-flex>
          </template>
        </v-treeview>
      </v-container>
    </v-form>
  </base-popup>
</template>

<script>
import BasePopup from "../../layouts/BasePopup.vue";
import BaseSearch from "../base/BaseSearch.vue";
import PartitionService from "../../services/PartitionService";
import CheckListService from "../../services/CheckListService";
import DataHelper from "../../utils/DataHelper";
import { uniqBy } from "lodash";
import { numWord } from "../../utils/Functions";

export default {
  name: "view-CheckOperationESAdd",
  components: {
    BasePopup,
    BaseSearch,
  },
  props: {
    CheckOperations: Array,
    value: Boolean,
    width: String,
    height: String,
  },
  data: function () {
    return {
      loading: false,
      dataSource: [],
      selected: [],
      searchText: "",
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.searchText = "";
        this.$emit("input", value);
      },
    },
    dataList() {
      const list = [];
      DataHelper.treeListTraverse(this.dataSource, (item) => {
        list.push(item);
      });
      return list;
    },
    allSelected: {
      get() {
        return (
          this.dataList.length === this.selected.length &&
          this.selected.length > 0
        );
      },
      set(value) {
        this.selected = [];
        if (value) this.selected.push(...this.dataList);
      },
    },
  },
  watch: {
    dialog(visible) {
      if (visible) {
        this.selected = this._.cloneDeep(
          uniqBy(
            this.CheckOperations.map((e) => e.PartitionObject),
            "Id"
          )
        );
      }
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      try {
        this.loading = true;
        const res = await PartitionService.get({
          fields: 5,
        });
        this.dataSource = res.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    selectPartitions() {
      return this.selected.map((e) =>
        DataHelper.findTreeById(this.dataSource, e?.Id)
      );
    },
    async save() {
      const removeObjects = uniqBy(
        this.CheckOperations.filter(
          (e) => !this.selected.find((e2) => e2.Id === e.PartitionObject?.Id)
        ),
        "PartitionObject.Id"
      );

      const maxLength = removeObjects.reduce((max, current) => {
        const currentLength = current.PartitionObject.Name.length;
        return currentLength > max ? currentLength : max;
      }, 0);

      let width = 400;
      if (maxLength >= 30) {
        width = 500;
        if (maxLength >= 40) {
          width = 600;
          if (maxLength >= 50) {
            width = 700;
          }
        }
      }

      if (
        removeObjects.length &&
        !(await this.$confirm(
          "<b>Вы хотите исключить " +
            removeObjects.length +
            numWord(removeObjects.length, ["объект", "объекта", "объектов"]) +
            removeObjects
              .map((e) => e.PartitionObject?.Name)
              .map((e) => "</br>- " + e),
          {
            buttonTrueText: "Исключить",
            center: false,
            extionSticky: true,
            width: width,
          }
        ))
      )
        return;

      this.dialog = false;

      const partitionIds = this.selected.map((p) => p.Id);
      const checkLists = (await CheckListService.createES(partitionIds)).data;
      const tmp = [];

      for (const partition of this.selected) {
        for (const es of partition.EquipmentStandarts) {
          const checkList = checkLists.find((l) => l.Standart?.Id === es.Id);
          tmp.push({ checkList, partition });
        }
      }

      this.$emit(
        "add",
        tmp.map((e) => ({ ...e.checkList, count: 1, objects: [e.partition] }))
      );
      this.selected = [];
    },
    filter(item, search) {
      const s = search.toLocaleUpperCase();
      return (
        (item.Name && item.Name.toLocaleUpperCase().indexOf(s) > -1) ||
        item.EquipmentStandarts.findIndex(
          (s) => s.Name && s.Name.toLocaleUpperCase().indexOf(s) > -1
        ) > -1
      );
    },
  },
};
</script>

<style lang="sass">
.treeview__col2
  min-width: 400px
  max-width: 400px
  padding-left: 20px
  white-space: normal
</style>
