<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    :label="label"
    :value="dataSource"
    @input="(value) => $emit('input', value)"
    :items="documents"
    item-value="Id"
    item-text="Text"
    return-object
    :readonly="readonly"
    :clearable="!readonly"
    append-icon="$ToolArrowDown"
  >
    <template #append-outer>
      <m-icon
        @click="edit"
        v-if="!readonly"
        :icon="isNull ? 'mdi-plus' : 'mdi-pencil'"
      ></m-icon>
      <base-popup
        :title="label"
        v-model="dialog"
        @save="change()"
        :width="'50vw'"
        :height="'80vh'"
      >
        <v-form v-model="valid" ref="from">
          <base-date-picker
            v-model="editSource.Date"
            label="Дата"
            :rules="[rules.required]"
            required
          ></base-date-picker>
          <base-date-picker
            v-model="editSource.DateOut"
            label="Дата завершения"
            clearable
          ></base-date-picker>
          <v-text-field
            v-model="editSource.Num"
            :rules="[rules.required]"
            maxlength="50"
            label="Номер"
            required
          ></v-text-field>
          <m-select
            v-model="editSource.Contragent"
            :items="contragents"
            item-text="Name"
            label="Контрагент"
            return-object
          ></m-select>
          <v-textarea
            v-model="editSource.Comment"
            label="Примечание"
            maxlength="400"
          ></v-textarea>
        </v-form>
        <v-tabs v-model="tab">
          <v-tab key="Files">Файлы</v-tab>
          <v-tab key="Equipments">Медицинские изделия</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item key="Files">
            <table-load-file
              class="mt-2"
              :files="editSource.Files"
              @addFile="addFile"
            />
          </v-tab-item>
          <v-tab-item key="Equipments">
            <base-list-view
              :dataSource="editSource.Equipments"
              :headers="equipmentsHeaders"
              isNested
              headerHidden
              hide-default-footer
              notShowSelect
              hide-edit
            />
          </v-tab-item>
        </v-tabs-items>
      </base-popup>
    </template>
  </v-select>
</template>

<script>
import { mapActions } from "vuex";
import AppliedDocumentService from "../../services/AppliedDocumentService";
import { defaultAppliedDocument } from "../../data/defaultObjects";
import BasePopup from "../../layouts/BasePopup.vue";
import BaseDatePicker from "../base/BaseDatePicker.vue";
import MSelect from "../base/inputs/mSelect.vue";
import TableLoadFile from "../loadFiles/TableLoadFile.vue";
import BaseListView from "../../layouts/BaseListView.vue";

export default {
  name: "view-EquipmentDocumentEdit",
  components: {
    BasePopup,
    BaseDatePicker,
    MSelect,
    TableLoadFile,
    BaseListView,
  },
  props: {
    documentType: Number,
    label: String,
    value: Object,
    readonly: Boolean,
  },
  data: function () {
    return {
      valid: null,
      dataSource: defaultAppliedDocument(),
      editSource: defaultAppliedDocument(),
      documents: [],
      contragents: [],
      dialog: false,
      tab: null,
      isNull: false,
      equipmentsHeaders: [
        { text: "Название", value: "Name" },
        { text: "Рег. номер", value: "RegNum" },
        { text: "Инв. номер", value: "Num" },
        { text: "Примечание", value: "Comment" },
      ],
      rules: {
        required: (value) => !!value || "Укажите значение.",
      },
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        // С новым значением необходимо обновить источник данных документов
        if (value) {
          const index = this.documents.findIndex((d) => d.Id === value.Id);
          if (index < 0) {
            const removeOld = this.documents.findIndex((d) => d.Id < 0);
            if (removeOld >= 0) this.documents.splice(removeOld, 1);
            this.documents.push(value);
          } else this.$set(this.documents, index, value);
          value.Text = this.getText(value);
        }
        this.dataSource = value ?? defaultAppliedDocument(this.documentType);
        this.isNull = value === null;
      },
    },
  },
  async mounted() {
    try {
      this.documents = (
        await AppliedDocumentService.get(this.documentType, true, true, 0, -1)
      ).data;
      this.documents.forEach((d) => (d.Text = this.getText(d)));
      this.contragents = await this.LOAD_CONTRAGENTS();
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    ...mapActions(["LOAD_CONTRAGENTS"]),
    edit() {
      this.editSource = this._.cloneDeep(this.dataSource);
      this.dialog = true;
    },
    change() {
      this.$refs.from.validate();
      if (!this.valid) return;
      this.dialog = false;
      this.$emit("input", this.editSource);
    },
    getText(d) {
      return `${d.Num} от ${d.Date.substr(0, 10)}`;
    },
    async addFile(newFile) {
      const f = this.editSource.Files;

      if (f.find((e2) => e2.Name === newFile.Name)) {
        if (
          await this.$confirm(
            "Файл " + newFile.Name + " уже добавлен. Перезаписать ?"
          )
        ) {
          this.editSource.Files = this.editSource.Files.filter(
            (e2) => e2.Name !== newFile.Name
          );
        } else {
          return;
        }
      }
      this.editSource.Files.push(newFile);
    },
  },
};
</script>
