import { diff } from "deep-diff";
export default {
  data: () => {
    return {
      originObject: null,
      allowSaveForge: false,
    };
  },
  mounted() {},
  watch: {
    isUnsaved: {
      immediate: true,
      handler: function (val) {
        if (this.currentRouter) {
          this.$store.commit("pageTabs/UPDATE_TAB_IS_UNSAVED", {
            route: this.currentRouter.meta.normalizeFullPath,
            val,
          });
        }
      },
    },
  },
  computed: {
    isUnsaved() {
      if (this.readonly && !this.allowSaveForge) return false;
      if (this.type === "create" || this.type === "copy") return true;
      const newObj = this.getActualDataSources(this.dataSource);

      if (!newObj || !this.originObject) return false;
      if (!diff(newObj, this.originObject)) return false;

      return true;
    },
  },
};
