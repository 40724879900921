import Api from "./Api";

export default {
  async show(id) {
    return Api().get("auditoperation/getCheckOperation", {
      params: { id, allAuditors: true },
    });
  },
  async update(chechQuestion, only = false, external) {
    const chechQuestions =
      chechQuestion.length || chechQuestion?.length === 0
        ? chechQuestion
        : [chechQuestion];
    if (!chechQuestions.length) return;

    // При добавлении новых нарушений отметить вопрос как "нет"
    chechQuestions.forEach((element, index) => {
      if (element.Violations.find((e2) => e2.Id <= 0 && e2.IsChecked)) {
        chechQuestions[index].Answer = 2;
      }
    });
    // При установки ответа !== "Нет" Удаления нарушений
    // Очистка предложений/нарушений
    chechQuestions.forEach((element, index) => {
      if (element.Violations.length !== 0) {
        if (element.Answer !== 2 || element.Answer === 0) {
          for (let i = 0; i < chechQuestions[index].Violations.length; i++) {
            chechQuestions[index].Violations[i].IsChecked = false;
            for (
              let j = 0;
              j < chechQuestions[index].Violations[i].Recommendations.length;
              j++
            ) {
              chechQuestions[index].Violations[i].Recommendations[
                j
              ].IsChecked = false;
            }
          }
        }
      }
    });

    // При создании новой рекомендации и предложения добавление задачи
    if (!external)
      chechQuestions.forEach((element) => {
        // Добавлять задачи к рекомендациям
        element.Violations.forEach((violation) => {
          violation.Recommendations.forEach((recommendation) => {
            if (recommendation.IsChecked && !recommendation.CheckTask) {
              recommendation.CheckTask = {
                Id: -1,
                Task: {
                  Id: -1,
                  Name: recommendation.Description,
                  Content: recommendation.Description,
                  Priority: 2, // Средний
                },
              };
            }
          });
        });

        // Добавление задач к предложениям
        element.Recommendations.forEach((recommendation) => {
          if (recommendation.IsChecked && !recommendation.CheckTask) {
            recommendation.CheckTask = {
              Id: -1,
              Task: {
                Id: -1,
                Name: recommendation.Description,
                Content: recommendation.Description,
                Priority: 2, // Средний
              },
            };
          }
        });
      });

    if (only) {
      return Api().put(
        "auditOperation/updateCheckQuestionResult",
        chechQuestions[0]
      );
    } else {
      return Api().put(
        "auditOperation/updateCheckQuestionResults",
        chechQuestions
      );
    }
  },
};
