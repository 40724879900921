<template>
  <v-menu
    ref="menu"
    v-model="menu"
    v-on:update:return-value="update"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :close-on-content-click="false"
    :return-value="date"
    :disabled="$attrs['readonly']"
    content-class="BaseDatePicker"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="text"
        :label="label"
        :clearable="$attrs['clearable']"
        :rules="$attrs['rules']"
        :required="$attrs['required']"
        @click:clear="clear"
        v-mask="'##.##.#### ##:##:##'"
        @change="inputFromText"
      >
        <template v-slot:append>
          <m-icon
            :icon="$attrs['append-icon'] || 'mdi-calendar'"
            v-on="on"
            v-bind="attrs"
          />
        </template>
      </v-text-field>
    </template>

    <template>
      <v-date-picker
        :value="date ? date.substr(0, 7) : ''"
        scrollable
        locale="ru"
        type="month"
        no-title
        @input="formatedDate($event)"
      >
      </v-date-picker>
      <v-date-picker
        v-model="date"
        locale="ru"
        first-day-of-week="1"
        no-title
        scrollable
        :weekday-format="getCustomDay"
        class="datePickerRange"
        ref="datePicker"
      >
        <v-container class="d-flex align-center ma-0 pa-0 pl-5">
          <v-text-field
            v-model="time"
            type="time"
            label="Время"
            step="1"
          ></v-text-field>
          <div>
            <v-btn text color="primary" @click="menu = false"> Отмена </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                () => {
                  $refs.menu.save(date);
                  input();
                }
              "
            >
              OK
            </v-btn>
          </div>
        </v-container>
      </v-date-picker>
    </template>
  </v-menu>
</template>

<script>
import moment from "moment";
export default {
  props: {
    value: String,
    label: {
      type: String,
      default: "Дата",
    },
  },
  data: () => {
    return {
      text: null,
      date: null,
      time: null,
      menu: false,
    };
  },
  computed: {
    dateText: {
      get: function () {
        if (!this.date) return "";
        return this.date.split("-").reverse().join(".") + " " + this.time;
      },
      set: function () {
        this.date = null;
        this.input();
      },
    },
    currentTime() {
      let hours = new Date().getHours();
      let minutes = new Date().getMinutes();
      let seconds = new Date().getSeconds();
      if (hours < 10) hours = "0" + hours;
      if (minutes < 10) minutes = "0" + minutes;
      if (seconds < 10) seconds = "0" + seconds;

      return `${hours}:${minutes}:${seconds}`;
    },
  },
  watch: {
    dateText(val) {
      this.text = val;
    },
    value: {
      immediate: true,
      handler() {
        if (!this.value) {
          this.date = null;
          this.time = this.currentTime;
          return;
        }

        // Убирает время
        let tmp = this.value.substr(0, 10);

        // Формат
        if (this.value.indexOf(".") !== -1) {
          tmp = tmp.split(".").reverse().join("-");
        }
        this.date = tmp;
        this.time = this.value.substr(11);
      },
    },
  },
  methods: {
    inputFromText(text) {
      const date = moment(text, "DD.MM.YYYY HH:mm:ss");
      if (date.isValid()) {
        this.date = date.format("YYYY-MM-DD");
        this.time = date.format("HH:mm:ss");
      }

      if (this.date) this.formatedDate(this.date);

      this.input();
    },
    getCustomDay(date) {
      const daysOfWeek = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
      const i = new Date(date).getDay(date);
      return daysOfWeek[i];
    },
    formatedDate(date) {
      if (this.$refs.datePicker) this.$refs.datePicker.tableDate = date;
    },
    clear() {
      this.menu = false;
      this.$emit("input", null);
    },
    input() {
      if (!this.date) return;
      if (!this.time) this.time = "00:00:00";

      const t = moment(this.date, "YYYY-MM-DD");
      if (t.year() < 1900) {
        t.set("year", 1900);
      }
      this.date = t.format("YYYY-MM-DD");

      this.menu = false;
      this.$emit(
        "input",
        this.date.split("-").reverse().join(".") + " " + this.time
      );
    },
    update(event) {
      this.date = event;
      this.time = this.value ? this.value.substr(11) : this.currentTime;
    },
  },
};
</script>

<style lang="scss">
.v-date-picker-table.v-date-picker-table--date {
  height: auto;
}
.v-date-picker-table table thead tr {
  height: 40px;
}
</style>
