import Api from "./Api";
import store from "@/store";

export default {
  async show() {
    const id = store.getters.GET_USER.Employee.Id;
    return Api().get("organization/getEmployee", { params: { id } });
  },
  async update(employee) {
    const { data } = await Api().put("user/updateProfile", employee);
    store.commit("SET_PROFILE", data);
    return { data };
  },
  async changePassword(oldPassword, newPassword) {
    await Api().post("user/changePassword", {
      OldPassword: oldPassword,
      NewPassword: newPassword,
    });
  },
  updatePhoneNumber(PhoneNum) {
    return Api().put("user/UpdatePhoneNumber", {
      PhoneNum,
    });
  },
  setNumberChangedInformed() {
    return Api().post("user/SetNumberChangedInformed");
  },
};
