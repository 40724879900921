<template>
  <div class="violation__images__main">
    <div v-if="type === 0" class="violation__images__content">
      <m-icon
        icon="mdi-camera-plus-outline"
        :disabled="readOnly"
        @click="$refs.inputUpload.click()"
        title="Добавить фото"
      ></m-icon>
      {{ images.length }}
      <input
        v-show="false"
        @change="uploadImage"
        ref="inputUpload"
        type="file"
        accept="image/*"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "view-images",
  props: {
    images: Array,
    type: Number,
    readOnly: Boolean,
  },
  methods: {
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.$set(this.images, this.images.length, {
          Id: -Math.round(Math.random() * 1000000),
          Base64Content: e.target.result,
          ExternalId: null,
          Name: image.name,
        });
      };
    },
  },
};
</script>
<style lang="scss">
.violation__images {
  &__main {
    width: 50px;
  }
  &__content {
    cursor: pointer;
  }
}
</style>
