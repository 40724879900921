import Api from "./Api";

export default {
  getOwners() {
    return Api().get("StatisticIndicatorTFOMS/GetOwners");
  },
  tfoms1: {
    async show(id, params = {}) {
      return Api().get("StatisticIndicatorTFOMS/GetStatisticIndicatorTFOMS", {
        params: {
          group: 1,
          year: params?.year || 2024,
          halfYear: params?.halfYear,
          quarter: params?.quarter,
          month: params?.month,
          ownerId: params?.ownerId,
          meeEkmpDateType: params?.meeEkmpDateType,
        },
      });
    },

    getReasonDetail(params) {
      return Api().get(
        "StatisticIndicatorTFOMS/GetReasonRefusalPayDetailData",
        {
          params: {
            ...params,
            year: params?.year || 2024,
            meeEkmpDateType: params?.meeEkmpDateType,
          },
        }
      );
    },
    getAggregatedInfo(params) {
      return Api().get(
        "StatisticIndicatorTFOMS/GetStatisticIndicatorTFOMSAggregatedInfo",
        {
          params: {
            year: params?.year || 2024,
            ownerId: params?.ownerId,
            groupId: params?.groupId,
            meeEkmpDateType: params?.meeEkmpDateType,
          },
        }
      );
    },
    GetEmployeeDetail(params) {
      return Api().get("StatisticIndicatorTFOMS/GetEmployeeTFOMSDetailInfo", {
        params: {
          ...params,
          year: params?.year || 2024,
        },
      });
    },

    getEmployeeValues(params) {
      return Api().get("StatisticIndicatorTFOMS/GetEmployeeValues", {
        params: {
          ...params,
          year: params?.year || 2024,
        },
      });
    },
    getTFOMSDetailedInfo(params) {
      return Api().get(
        "StatisticIndicatorTFOMS/GetStatisticIndicatorTFOMSDetailedInfo",
        {
          params: {
            ...params,
            year: params?.year || 2024,
          },
        }
      );
    },
    more1: {
      getDenyReasonContentData(params) {
        return Api().get("StatisticIndicatorTFOMS/GetDenyReasonContentData", {
          params: {
            reasonCode: params.code,
            year: params?.year || 2024,
            ownerId: params?.ownerId,
            meeEkmpDateType: params?.meeEkmpDateType,
          },
        });
      },
      GetHealthCareContent(params) {
        return Api().get("StatisticIndicatorTFOMS/GetHealthCareContent", {
          params: {
            healthCareCode: params.healthCareCode,
            reasonCode: params.code,
            valueType: 1,
            year: params?.year || 2024,
            ownerId: params?.ownerId,
            meeEkmpDateType: params?.meeEkmpDateType,
          },
        });
      },
      show(_, params) {
        return Api().get("StatisticIndicatorTFOMS/GetDetailedValues", {
          params: {
            valueType: 1,
            year: params?.year || 2024,
            ownerId: params?.ownerId,
            meeEkmpDateType: params?.meeEkmpDateType,
          },
        });
      },
    },
    more2: {
      getDenyReasonContentData(params) {
        return Api().get("StatisticIndicatorTFOMS/GetDenyReasonContentData", {
          params: {
            reasonCode: params.code,
            year: params?.year || 2024,
            ownerId: params?.ownerId,
            meeEkmpDateType: params?.meeEkmpDateType,
          },
        });
      },
      GetHealthCareContent(params) {
        return Api().get("StatisticIndicatorTFOMS/GetHealthCareContent", {
          params: {
            healthCareCode: params.healthCareCode,
            reasonCode: params.code,
            valueType: 2,
            year: params?.year || 2024,
            ownerId: params?.ownerId,
            meeEkmpDateType: params?.meeEkmpDateType,
          },
        });
      },
      show(_, params) {
        return Api().get("StatisticIndicatorTFOMS/GetDetailedValues", {
          params: {
            valueType: 2,
            year: params?.year || 2024,
            ownerId: params?.ownerId,
            meeEkmpDateType: params?.meeEkmpDateType,
          },
        });
      },
    },
    more3: {
      getDenyReasonContentData(params) {
        return Api().get("StatisticIndicatorTFOMS/GetDenyReasonContentData", {
          params: {
            reasonCode: params.code,
            year: params?.year || 2024,
            ownerId: params?.ownerId,
            meeEkmpDateType: params?.meeEkmpDateType,
          },
        });
      },
      GetHealthCareContent(params) {
        return Api().get("StatisticIndicatorTFOMS/GetHealthCareContent", {
          params: {
            healthCareCode: params.healthCareCode,
            reasonCode: params.code,
            valueType: 4,
            year: params?.year || 2024,
            ownerId: params?.ownerId,
            meeEkmpDateType: params?.meeEkmpDateType,
          },
        });
      },
      show(_, params) {
        return Api().get("StatisticIndicatorTFOMS/GetDetailedValues", {
          params: {
            valueType: 4,
            year: params?.year || 2024,
            ownerId: params?.ownerId,
            meeEkmpDateType: params?.meeEkmpDateType,
          },
        });
      },
    },
  },
  tfoms2: {
    async show(id, params = {}) {
      return Api().get("StatisticIndicatorTFOMS/GetStatisticIndicatorTFOMS", {
        params: {
          group: 2,
          year: params?.year || 2024,
          halfYear: params?.halfYear,
          quarter: params?.quarter,
          month: params?.month,
          ownerId: params?.ownerId,
          meeEkmpDateType: params?.meeEkmpDateType,
        },
      });
    },
    getObject(params) {
      return Api().get(
        "StatisticIndicatorTFOMS/GetStatisticIndicatorNodeObject",
        {
          params: {
            treeId: params.Id,
            year: params.year || 2024,
            objectType: params.objectType,
            ownerId: params?.ownerId,
            meeEkmpDateType: params?.meeEkmpDateType,
          },
        }
      );
    },
  },
  tfoms3: {
    async show(id, params = {}) {
      return Api().get("StatisticIndicatorTFOMS/GetStatisticIndicatorTFOMS", {
        params: {
          group: 3,
          year: params?.year || 2024,
          halfYear: params?.halfYear,
          quarter: params?.quarter,
          month: params?.month,
          ownerId: params?.ownerId,
          meeEkmpDateType: params?.meeEkmpDateType,
        },
      });
    },
    getObject(params) {
      return Api().get("StatisticIndicatorTFOMS/GetStatisticIndicatorObject", {
        params: {
          id: params.Id,
          year: params.year || 2024,
          objectType: params.objectType,
          ownerId: params?.ownerId,
          meeEkmpDateType: params?.meeEkmpDateType,
        },
      });
    },
  },
};
