<template>
  <base-table-expanded :colspan="$attrs['headers'].length">
    <v-row>
      <v-col>
        <table>
          <tr>
            <td>Члены комиссии:</td>
            <td>
              {{
                AuditOperation.Members.filter(
                  (e) => e.Role === 0 || e.Role === 1
                )
                  .map((e) => $options.filters.PersonShortName(e.Employee))
                  .join(", ")
              }}
            </td>
          </tr>
          <tr>
            <td>
              <div>Председатель комиссии:</div>
            </td>
            <td>
              {{
                AuditOperation.Members.filter((e) => e.Role === 1)
                  .map((e) => $options.filters.PersonShortName(e.Employee))
                  .join(", ")
              }}
            </td>
          </tr>
          <tr>
            <td>
              <div>Присутствующие:</div>
            </td>
            <td>
              {{
                AuditOperation.Members.filter((e) => e.Role === 2)
                  .map((e) => $options.filters.PersonShortName(e.Employee))
                  .join(", ")
              }}
            </td>
          </tr>
        </table>
      </v-col>
      <v-col>
        <table>
          <tr>
            <td>
              <div>Предмет проверки:</div>
            </td>
            <td>
              <div>{{ AuditOperation.Subject }}</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Чек-листы:</div>
            </td>
            <td>
              <div>{{ CheckListNames }}</div>
            </td>
          </tr>
        </table>
      </v-col>
    </v-row>
  </base-table-expanded>
</template>

<script>
import BaseTableExpanded from "../../layouts/BaseTableExpanded.vue";
export default {
  components: { BaseTableExpanded },
  props: {
    AuditOperation: Object,
  },

  computed: {
    CheckListNames() {
      const сheckOperations = this.AuditOperation.CheckOperations.reduce(
        (o, i) => {
          if (!o.find((v) => v.CheckList.Id === i.CheckList.Id)) {
            o.push(i);
          }
          return o;
        },
        []
      );
      if (!сheckOperations.length) return null;
      return сheckOperations.map((t) => t.CheckList.Name).join(", ");
    },
  },
};
</script>

<style></style>
