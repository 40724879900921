<template>
  <v-list-item
    v-if="!hide"
    @click="handlerClick(route)"
    link
    :class="levelClass"
  >
    <v-list-item-icon>
      <m-icon
        :icon="UtilGetIcon(view, typeView)"
        :active="route === $route.meta.normalizeFullPath"
      ></m-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title v-text="text"></v-list-item-title>
    </v-list-item-content>
    <v-list-item-icon>
      <NotificationPoint :typeView="typeView" :view="view"></NotificationPoint>
    </v-list-item-icon>
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";
import NotificationPoint from "../modules/Notification/notificationPoint.vue";

export default {
  name: "view-NavigationItem",
  components: {
    NotificationPoint,
  },
  props: {
    route: String,
    view: String,
    typeView: String,
    text: String,
    level: {
      type: Number,
      default: 0,
    },
    hidden: [Boolean, Function],
  },
  computed: {
    ...mapGetters("routesData", ["GET_IS_HOVER", "GET_IS_FIXED"]),
    levelClass() {
      return this.level &&
        (this.GET_IS_FIXED || (this.GET_IS_HOVER && !this.GET_IS_FIXED))
        ? { "pl-10": true }
        : null;
    },
    hide() {
      if (!this.hidden) return false;
      else if (typeof this.hidden === "function") {
        return this.hidden();
      }
      return this.hidden;
    },
  },
  methods: {
    handlerClick(route) {
      if (route === this.$route.meta.normalizeFullPath) return;
      this.$store.commit("pageTabs/SET_FROM_NAVIGATION", true);
      this.$router.push({
        path: route,
      });
    },
  },
};
</script>
