import Api from "./Api";
import qs from "qs";

export default {
  async get(type = [1], content = false, readers = false) {
    return Api().get("news/GetNews", {
      params: {
        actual: true,
        content: content,
        types: type,
        readers: readers,
      },
      paramsSerializer: function (params) {
        return qs.stringify(params);
      },
    });
  },
  async show(id, readers = false, content = true) {
    return Api().get("news/GetNewsInfo", { params: { id, readers, content } });
  },
  read(id) {
    return Api().put("news/ReadNews", { Id: id });
  },
  getLastUpdateNews() {
    return Api().get("news/GetLastUpdateNews", {
      params: {
        types: [1, 2],
      },
      paramsSerializer: function (params) {
        return qs.stringify(params);
      },
    });
  },
};
