<template>
  <base-popup
    title="Добавить задачи"
    :icon="UtilGetIcon('view-Tasks')"
    @save="save"
    v-model="dialog"
    :width="width"
    :height="height"
  >
    <v-form>
      <v-container>
        <v-layout column>
          <v-flex>
            <base-list-view
              title="Задачи"
              :dataSource="tasks"
              :headers="headers"
              :showDelete="false"
              :notShowActions="['add']"
              :search.sync="search"
              :selectedItems="selectedTasks"
              @input="(value) => (this.selectedTasks = value)"
              @onRefresh="loadTasks"
              :loading="loading"
              :showFilters="true"
            >
            </base-list-view>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </base-popup>
</template>

<script>
import BaseListView from "@/layouts/BaseListView.vue";
import BasePopup from "@/layouts/BasePopup.vue";
import TaskService from "@/services/TaskService";
import Vue from "vue";

export default {
  name: "view-TaskAdd",
  components: {
    BasePopup,
    BaseListView,
  },
  props: {
    // Аргумент, передающий id родительской задачи
    // Нужен  для исключения род. задачи при добавлении подзадачи
    currentTaskId: {
      type: Number,
      default: null,
    },
    Tasks: {
      type: Array,
      default: () => [],
    },
    value: Boolean,
    width: {
      type: String,
      default: "80vw",
    },
    height: {
      type: String,
      default: "80vh",
    },
    typeParent: {
      default: null,
      validator: function (value) {
        return [null, "project", "document", "unwishedEvent"].includes(value);
      },
    },
  },
  data: () => {
    return {
      apiService: TaskService,
      tasks: [],
      selectedTasks: [],
      search: "",
      headers: [],
      loading: false,
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    dialog(visible) {
      if (visible) {
        this.search = null;
        this.loadTasks();
      }
    },
  },
  mounted() {
    this.headers = [
      { text: "Номер", value: "Num", width: "1%" },
      {
        text: "Название",
        value: "Name",
        cellClass: "text-wrap text-break",
      },
      {
        text: "Исполнитель",
        value: "Executors",
        dataType: "array",
        displayText: Vue.filter("PersonShortName"),
        width: "1%",
      },
      {
        text: "Срок",
        value: "PlannedPeriod",
        dataType: "Period",
        displayText: (e) => Vue.filter("PeriodFormat")(e.DateIn, e.DateOut),
      },
      {
        text: "Проверка",
        value: "AuditOperation.Num",
        dataType: "string",
        cellClass: "td-minimum text-no-wrap td-small text-center",
        width: "1%",
        disabled: this.typeParent !== "project",
      },
      {
        text: "Показатель оценки качества",
        value: "Indicator",
        dataType: "object",
        width: "1%",
        cellClass: "td-minimum td-small text-break",
        showGroupName: false,
        displayText: (el) => el?.Name,
        disabled: this.typeParent !== "project",
      },
      {
        text: "Чек-лист",
        value: "QuestionResult.CheckList",
        dataType: "object",
        cellClass: "td-minimum td-large",
        width: "1%",
        displayText: (el) => el?.Name,
        disabled: this.typeParent !== "project",
      },
      {
        text: "Требование",
        value: "QuestionResult.Requirement",
        dataType: "string",
        width: "1%",
        cellClass: "td-minimum td-3",
        disabled: this.typeParent !== "project",
      },
      {
        text: "План / Проект",
        value: "Project",
        dataType: "object",
        cellClass: "td-minimum td-large",
        showGroupName: false,
        displayText: (e) => e?.Name,
        disabled: this.typeParent !== "project",
      },
    ];
  },
  methods: {
    async save() {
      this.dialog = false;
      const ids = this.selectedTasks.map((e) => e.Id);
      const newTasks = (await TaskService.getTasksWithChildren(ids)).data;

      for (const i in newTasks) {
        this.Tasks.push(newTasks[i]);
      }
      this.selectedTasks = [];
    },
    async loadTasks() {
      this.tasks = [];
      this.loading = true;
      try {
        const requestParams = { parent: false };
        if (this.typeParent === "document") {
          requestParams.document = false;
        }
        if (this.typeParent === "unwishedEvent") {
          requestParams.unwishedEvent = false;
        }
        if (this.typeParent === "project") {
          requestParams.done = false;
        }
        const data = (
          await TaskService.get({
            ...requestParams,
            currentTaskId: this.currentTaskId,
          })
        ).data;
        this.tasks = data
          .filter(
            (task) =>
              !this.Tasks.map((t) => t.Id).includes(task.Id) &&
              !task.Periodicity
          )
          .sort((first, second) => second.Num - first.Num);
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
  },
};
</script>

<style></style>
