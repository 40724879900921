<template>
  <div
    class="analysis-tfoms__base-table analysis-tfoms__table__tree"
    style="margin-top: 20px"
  >
    <base-tree-table
      :items="data"
      :headers="[
        { text: '', value: 'Name', width: '100%' },
        {
          text: 'План',
          value: 'VolumePlanValue',
          align: 'center',
          cellClass: 'td1',
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
        {
          text: 'Факт',
          value: 'VolumeValue',
          align: 'center',
          cellClass: 'text-no-wrap td1',
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
        {
          text: 'План',
          value: 'FinancialPlanValue',
          align: 'center',
          cellClass: 'td1',
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
        {
          text: 'Факт',
          value: 'FinancialValue',
          align: 'center',
          cellClass: 'text-no-wrap',
          cellClass: 'td1',
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
        {
          text: 'Штрафы',
          value: 'FineValue',
          align: 'center',
          width: '30px',
          cellClass: 'td1',
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
        {
          text: 'Санкции',
          value: 'SanctionValue',
          align: 'center',
          cellClass: 'td1',
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
      ]"
      hide-default-header
      hide-default-footer
      notShowSelect
      nested
      class="analysis-tfoms__table__tree-wapper"
      @click:row="clickRow"
      :item-class="
        (item) => {
          return value && value.Id === item.Id ? 'font-weight-bold' : '';
        }
      "
    >
      <template v-slot:header="scope">
        <thead v-if="!scope.level">
          <tr>
            <th></th>
            <th class="text-center"></th>
            <th class="text-center" colspan="2">Объемы</th>
            <th class="text-center" colspan="2">Финансовое обеспечение</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th class="text-center">План</th>
            <th class="text-center">Факт</th>
            <th class="text-center">План</th>
            <th class="text-center">Факт</th>
            <th class="text-center">Штрафы</th>
            <th class="text-center">Санкции</th>
            <th></th>
          </tr>
        </thead>
      </template>
      <template slot="item.VolumeValue" slot-scope="{ item }">
        {{ item.VolumeValue | numberSplit
        }}<m-icon
          v-if="item.VolumeValue > item.VolumePlanValue"
          color="success"
          :icon="'mdi-arrow-up-thin'"
        /><m-icon
          v-if="item.VolumeValue < item.VolumePlanValue"
          color="error"
          :icon="'mdi-arrow-down-thin'"
        />
      </template>
      <template slot="item.FinancialValue" slot-scope="{ item }">
        {{ item.FinancialValue | numberSplit
        }}<m-icon
          v-if="item.FinancialValue > item.FinancialPlanValue"
          color="success"
          :icon="'mdi-arrow-up-thin'"
        /><m-icon
          v-if="item.FinancialValue < item.FinancialPlanValue"
          color="error"
          :icon="'mdi-arrow-down-thin'"
        />
      </template>
    </base-tree-table>
  </div>
</template>
<script>
import BaseTreeTable from "@/components/base/BaseTreeTable";
export default {
  components: {
    BaseTreeTable,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    data: {
      type: Array,
      default: () => [
        {
          Name: "Амбулаторно",
          VolumePlanValue: 1589,
          VolumeValue: 1571,
          FinancialPlanValue: 1589,
          FinancialValue: 1571,
          sh: 1589,
          s: 1436,
          Children: [
            {
              Name: "посещения с профилактической и иной целью",
              VolumePlanValue: 1589,
              VolumeValue: 1571,
              FinancialPlanValue: 1589,
              FinancialValue: 1571,
              sh: 1589,
              s: 1436,
              Children: [
                {
                  Name: "мобильный комплекс",
                  VolumePlanValue: 1589,
                  VolumeValue: 1571,
                  FinancialPlanValue: 1589,
                  FinancialValue: 1571,
                  sh: 1589,
                  s: 1436,
                },
              ],
            },
          ],
        },
      ],
    },
  },
  methods: {
    clickRow(item) {
      this.$emit("input", item);
    },
  },
};
</script>
<style lang="scss">
.analysis-tfoms__table__tree-wapper {
  tbody {
    td {
      height: 32px !important;
      max-height: 32px !important;
    }
  }
}
.analysis-tfoms__table__tree {
  .td1 {
    width: 130px;
  }
}
</style>
