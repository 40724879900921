<template>
  <base-list-view
    :dataSource="dataSource"
    :headers="headers"
    :loading="loading"
    show-actions
    header-hidden
    class="mt-2"
    :notShowSelect="!readonly"
    hide-default-footer
    notShowSettings
    :show-add-in-table="
      !readonly
        ? [
            {
              text: 'Прикрепить похожие НС',
              key: 'addUnwishedLink',
            },
            {
              text: 'Прикрепить НС',
              key: 'addUnwished',
            },
          ]
        : false
    "
    @customAdd="customAdd"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template slot="item.actions" slot-scope="{ item }">
      <v-btn
        title="Открепить"
        icon
        @click.stop.prevent="unPin(item.Id)"
        v-if="!readonly"
      >
        <m-icon icon="mdi-close" small></m-icon>
      </v-btn>
    </template>
    <template #group.header="{ group, toggle, isOpen, items }">
      <v-btn @click="toggle" icon>
        <m-icon :icon="isOpen ? 'mdi-minus' : 'mdi-plus'" />
      </v-btn>
      <span class="subtitle-2">{{ group }} ({{ items.length }})</span>
    </template>

    <template slot="item.Done" slot-scope="{ item }">
      {{ item.Done ? item.Done.substr(0, 10) : "" }}
    </template>

    <unwished-event-list-popup
      v-if="!readonly"
      :dialog.sync="dialog"
      :currentUnwished="currentUnwished"
      :popupType="popupType"
      @save="save"
      :sourceItems="items"
    ></unwished-event-list-popup>
  </base-list-view>
</template>
<script>
import Vue from "vue";
import UnwishedEventService from "@/services/UnwishedEventService";
export default {
  components: {
    BaseListView: () => import("@/layouts/BaseListView.vue"),
    UnwishedEventListPopup: () => import("./UnwishedEventListPopup.vue"),
  },
  props: {
    items: {
      type: Array,
      default: null,
    },
    sourceItems: {
      type: Array,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    currentUnwished: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dataSource: [],
      // Дочернии НС
      headers: [
        {
          text: "Дата",
          value: "Date",
          dataType: "Date",
          width: "1%",
          align: "center",
          displayText: Vue.filter("DateShort"),
        },
        {
          text: "Место",
          value: "PlaceDisplay",
          width: "1%",
        },
        {
          text: "Что произошло",
          value: "Content",
          notGroup: true,
        },
        {
          text: "Последствия",
          value: "ConsequenceType",
          dataType: "object",
          displayText: (type) => type?.Description,
        },
        {
          text: "Результат разбора",
          value: "Result",
          notGroup: true,
        },
        {
          text: "Примечание",
          value: "Comment",
          notGroup: true,
        },
      ],
      loading: true,
      dialog: false,
      popupType: null,
    };
  },
  watch: {
    items: {
      deep: true,
      handler() {
        this.init();
      },
    },
    type() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    save(items) {
      this.$emit("update:items", [
        ...this.items,
        ...items.filter((e) => !this.items.find((e2) => e2.Id === e.Id)),
      ]);
      this.dialog = false;
    },
    customAdd(params) {
      this.popupType = params;
      this.dialog = true;
    },
    async init() {
      this.loading = true;
      if (this.items) {
        this.dataSource = this.items;
      } else {
        if (this.type === "addUnwishedLink") {
          const { data } = await UnwishedEventService.get(undefined, {
            similar: true,
            injuredPerson: this.currentUnwished.InjuredPerson,
            injuredKind: this.currentUnwished.InjuredKind,
            typeId: this.currentUnwished?.Type.Id,
            excludeEventId: this.currentUnwished.Id,
            exludeLinks: true,
          });
          this.dataSource = data;
        } else {
          const { data } = await UnwishedEventService.get(undefined, {
            excludeEventId: this.currentUnwished.Id,
            exludeLinks: true,
          });
          this.dataSource = data;
        }
        if (this.sourceItems) {
          this.dataSource = this.dataSource.filter(
            (e) => !this.sourceItems.find((e2) => e2.Id === e.Id)
          );
        }
      }
      this.loading = false;
    },
    async unPin(id) {
      this.$emit(
        "update:items",
        this.dataSource.filter((e) => e.Id !== id)
      );
    },
  },
};
</script>
