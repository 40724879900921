<template>
  <div class="d-flex justify-space-between pl-10">
    <div class="d-flex d-flex analysis-tfoms__action" style="width: 1072px">
      <div style="width: 91px">
        <m-select
          v-model="year"
          :items="years"
          placeholder="2024"
          :clearable="false"
        ></m-select>
      </div>
      <div style="width: 182px">
        <m-select
          :items="optionsHalfYear"
          v-model="selectHalfYear"
          placeholder="Полугодие"
          @input="unselectAll('selectHalfYear')"
          itemValue="id"
        ></m-select>
      </div>
      <div style="width: 175px">
        <m-select
          :items="optionsQuarter"
          v-model="selectQuarter"
          placeholder="Квартал"
          @input="unselectAll('selectQuarter')"
          itemValue="id"
        ></m-select>
      </div>
      <div style="width: 156px">
        <m-select
          :items="optionsMonth"
          v-model="selectMonth"
          placeholder="Месяц"
          @input="unselectAll('selectMonth')"
          itemValue="id"
        ></m-select>
      </div>
      <div style="width: 364px">
        <m-autocomplete
          v-if="ownerViewAll"
          :items="owners"
          v-model="owner"
          placeholder="Собственник"
          item-text="Name"
          item-value="Id"
          @input="update"
        ></m-autocomplete>
      </div>
    </div>
    <div class="d-flex d-flex analysis-tfoms__action">
      <div style="width: 120px">
        <m-select
          v-model="unit"
          :items="optionsUnit"
          :clearable="false"
          return-object
        ></m-select>
      </div>
      <div style="width: 120px">
        <m-select
          v-model="rub"
          :items="optionsRub"
          :clearable="false"
          return-object
        ></m-select>
      </div>
    </div>
  </div>
</template>
<script>
import mSelect from "@/components/base/inputs/mSelect";
import mAutocomplete from "@/components/base/inputs/mAutocomplete";
import TFormService from "../../../../services/TFormService";
import Permissions from "@/data/permissions";

export default {
  components: {
    mSelect,
    mAutocomplete,
  },

  data() {
    return {
      year: 2024,
      selectHalfYear: null,
      selectQuarter: null,
      selectMonth: null,
      years: [],
      owners: [],
      owner: null,
      optionsHalfYear: [
        { id: 1, value: "Первое полугодие" },
        { id: 2, value: "Второе полугодие" },
      ],
      optionsQuarter: [
        { id: 1, value: "1-ый квартал" },
        { id: 2, value: "2-ый квартал" },
        { id: 3, value: "3-ый квартал" },
        { id: 4, value: "4-ый квартал" },
      ],
      optionsMonth: [
        { id: 1, value: "Январь" },
        { id: 2, value: "Февраль" },
        { id: 3, value: "Март" },
        { id: 4, value: "Апрель" },
        { id: 5, value: "Май" },
        { id: 6, value: "Июнь" },
        { id: 7, value: "Июль" },
        { id: 8, value: "Август" },
        { id: 9, value: "Сентябрь" },
        { id: 10, value: "Октябрь" },
        { id: 11, value: "Ноябрь" },
        { id: 12, value: "Декабрь" },
      ],
      unit: { id: 0, value: "Случаи", text: "Случаев", alpha: 1 },
      optionsUnit: [
        { id: 0, value: "Случаи", text: "Случаев", alpha: 1 },
        { id: 1, value: "Тыс. случаев", text: "Тыс. случаев", alpha: 1000 },
      ],
      rub: { id: 1, value: "Тыс. руб.", text: "Тыс. руб.", alpha: 1000 },
      optionsRub: [
        { id: 0, value: "Рублей", text: "Рублей", alpha: 1 },
        { id: 1, value: "Тыс. руб.", text: "Тыс. руб.", alpha: 1000 },
      ],
      ownerViewAll: this.hasPermission(Permissions.OwnerViewAll),
    };
  },
  watch: {
    unit: {
      immediate: true,
      handler(val) {
        this.$emit("update:unit", val);
      },
    },
    rub: {
      immediate: true,
      handler(val) {
        this.$emit("update:rub", val);
      },
    },
    year() {
      this.$emit("update", {
        year: this.year,
        halfYear: this.selectHalfYear,
        quarter: this.selectQuarter,
        month: this.selectMonth,
      });
    },
  },
  async mounted() {
    this.years = [];
    for (let year = 2015; year <= new Date().getFullYear(); year++)
      this.years.push(year);

    if (this.ownerViewAll) {
      const { data } = await TFormService.getOwners();
      this.owners = data;
    }
  },
  methods: {
    update() {
      this.$emit("update", {
        year: this.year,
        halfYear: this.selectHalfYear,
        quarter: this.selectQuarter,
        month: this.selectMonth,
        ownerId: this.owner,
      });
    },
    unselectAll(except) {
      if (except !== "selectHalfYear") this.selectHalfYear = null;
      if (except !== "selectQuarter") this.selectQuarter = null;
      if (except !== "selectMonth") this.selectMonth = null;

      this.update();
    },
  },
};
</script>
<style lang="scss">
.analysis-tfoms__action {
  & > div {
    margin-right: 20px;
  }
}
</style>
