<template>
  <div v-if="type === 1">
    <m-icon
      v-if="typeTask >= 0"
      class="task-one-info"
      :icon="icons[typeTask]"
      @click="hadlerClick()"
      :title="tooltips[typeTask]"
      :disabled="!isPermissionEdit"
    ></m-icon>
  </div>
</template>
<script>
import { defaultTask } from "../../data/defaultObjects";
import Permissions from "../../data/permissions";

export default {
  inject: ["external"],
  props: {
    task: Object,
    type: Number,
    readOnly: Boolean,
    item: Object,
  },
  data() {
    return {
      isPermissionEdit:
        this.hasPermission(Permissions.TasksEdit) ||
        this.hasPermission(Permissions.AllTasksEdit),
      icons: [
        "mdi-account-question",
        "mdi-account-cancel",
        "mdi-account-clock",
        "mdi-account-plus",
      ],
      tooltips: [
        "Задача создана, исполнитель и сроки не установлены. Нажмите для редактирования задачи",
        "Задача создана, исполнитель назначен, сроки не установлены. Нажмите для редактирования задачи",
        "Задача создана, исполнитель и сроки установлены. Нажмите для редактирования задачи",
        "Создать задачу",
      ],
    };
  },
  computed: {
    typeTask() {
      if (!this.task?.Task) {
        if (this.external && this.$store.state.Organization.OwnerType !== 2)
          return 3;
        else return -1;
      }
      if (!this.task?.Task?.Executors?.length) return 0;
      else if (!this.task?.Task?.PlannedStart) return 1;
      else return 2;
    },
  },
  methods: {
    hadlerClick() {
      let task = this.task;
      if (!this.task) {
        task = {
          Id: -Math.round(Math.random() * 1000000),
          Task: {
            ...defaultTask(),
            Name: this.item.Description,
            Content: this.item.Description,
            Priority: 2, // Средний
          },
        };
        this.$emit("update:task", task);
      }

      this.$store.dispatch("frame/RUN_COMPONENT", {
        componentName: "task/TaskEdit",
        params: {
          _objectId: task.Task.Id,
          _object: task.Task,
          simple: true,
          persistent: true,
        },
        callback: () => {
          task.Task = this.$store.getters["frame/GET_DATA"]().object;
          this.$store.dispatch("frame/SHOW_DIALOG", { show: false });
        },
      });
    },
  },
};
</script>
<style lang="scss"></style>
