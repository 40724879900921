<template>
  <tfoms-panel
    class="analysis-tfoms__progress"
    v-bind="$attrs"
    :icon-color="factColor"
    style="padding: 20px 20px"
  >
    <template slot="subTitle" slot-scope="{ subTitle }">
      {{ subTitle }} <span class="primary--text ml-3">{{ itemName }}</span>
    </template>
    <base-tree-table
      :headers="onlyNoPayment ? headers2 : headers"
      :items="data"
      hide-default-footer
      notShowSelect
      not-filter
      nested
      @item-expanded="expended"
      :item-class="
        (item) => {
          return (
            (showItems.length === 0 || showItems.includes(item.Id)
              ? ''
              : 'analysis-tfoms__progress--disabled') +
            (value && value.Id === item.Id
              ? ' analysis-tfoms__progress--active'
              : '') +
            (value && value.Id === item.Id ? ' table-item--active' : '')
          );
        }
      "
      :items-per-page="perPage"
      @click:row="onClick"
      title=""
      tree-table-contents
      sort-by="Value3"
      :sort-desc="true"
      :exclude-settings-actions="['table', 'group']"
    >
      <template slot="item.Name" slot-scope="{ item }">
        <div class="td2" :title="item.Name">
          {{ item.Name }}
        </div>
      </template>
      <template slot="item.Value1" slot-scope="scope">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-progress-linear
              v-on="on"
              :value="(scope.item.Value1 / scope.item.MaxValues[0]) * 100"
              :buffer-value="100"
              height="24"
              :color="backgroundColor"
              >{{ scope.item.Value1 | numberSplit }}</v-progress-linear
            >
          </template>
          <div>
            {{ scope.item.Labels[0] }}: {{ scope.item.Value1 | numberSplit }}
            {{ scope.item.Unit }}
          </div>
        </v-tooltip>
      </template>

      <template slot="item.Value2" slot-scope="scope">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-progress-linear
              v-on="on"
              :value="(scope.item.Value2 / scope.item.MaxValues[1]) * 100"
              :buffer-value="100"
              height="24"
              :color="factColor"
              >{{ scope.item.Value2 | numberSplit }}</v-progress-linear
            >
          </template>
          <div>
            {{ scope.item.Labels[1] }}: {{ scope.item.Value2 | numberSplit }}
            {{ scope.item.Unit }}
          </div>
        </v-tooltip>
      </template>

      <template slot="item.Value3" slot-scope="scope">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-progress-linear
              v-on="on"
              :value="(scope.item.Value3 / scope.item.MaxValues[2]) * 100"
              :buffer-value="100"
              height="24"
              :color="colors[2]"
              >{{ scope.item.Value3 | numberSplit }}</v-progress-linear
            >
          </template>
          <div>
            {{ scope.item.Labels[2] }}: {{ scope.item.Value3 | numberSplit }}
            {{ scope.item.Unit }}
          </div>
        </v-tooltip>
      </template>
    </base-tree-table>
  </tfoms-panel>
</template>
<script>
import BaseTreeTable from "@/components/base/BaseTreeTable";
import tfomsPanel from "@/components/analysis/panel.vue";
import DataHelper from "@/utils/DataHelper";
export default {
  components: {
    BaseTreeTable,
    tfomsPanel,
  },
  inject: {
    provideUnits: {
      default: () => ({
        rub: null,
      }),
    },
    tfomsParams: {
      default: () => ({}),
    },
  },

  props: {
    value: {
      type: Object,
      default: null,
    },
    itemName: {
      type: String,
      default: "",
    },
    data: {
      type: Array,
      default: () => [],
    },
    factColor: {
      type: String,
      default: "#5379FB",
    },
    backgroundColor: {
      type: String,
      default: "rgba(198, 200, 213, 0.3)",
    },
    showNum: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: 0,
    },
    factText: {
      type: String,
      default: "Факт",
    },
    planText: {
      type: String,
      default: "План",
    },
    colors: {
      type: Array,
      default: () => ["rgba(198, 200, 213, 0.3)", "#5379FB", "red"],
    },
    onlyNoPayment: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "",
          value: "Name",
          cellClass: "td2",
          sortable: false,
        },
        {
          text: "Неоплата",
          value: "Value1",
          cellClass: "td3",
          align: "center",
        },

        {
          text: "Штрафы",
          value: "Value2",
          cellClass: "td4",
          align: "center",
        },

        {
          text: "Итого санкций",
          value: "Value3",
          align: "center",
        },
      ],
      headers2: [
        {
          text: "",
          value: "Name",
          cellClass: "td2",
          sortable: false,
        },
        {
          text: "Неоплата",
          value: "Value1",
          cellClass: "td3",
          align: "center",
        },
      ],
      expends: {},
      showItems: [],
      perPage: 5,
    };
  },
  computed: {},
  methods: {
    onClick(e) {
      const t = this.value && this.value.Id === e.Id ? null : e;
      this.tfomsParams.value.setSelectCode(t || { Id: null });
      this.$emit("click", t);
    },
    showMore() {
      if (this.perPage < this.data.length) {
        this.perPage += Math.min(Math.abs(this.data.length - 10), 10);
      }
      if (this.perPage >= this.data.length) {
        this.perPage = -1;
      }
    },
    tt(item, e) {
      return this.showItems.length === 0 || this.showItems.includes(item.Id)
        ? ""
        : "analysis-tfoms__progress--disabled";
    },
    // expended(e) {
    //   if (e.value) {
    //     if (this.expends[e.item.Id]) {
    //       console.log("delete", e.item.Id);
    //       delete this.expends[e.item.Id];
    //       this.expended = { ...this.expended };
    //     }
    //     this.expends[e.item.Id] = e.item.Children.map((e) => e.Id);
    //   } else {
    //     delete this.expends[e.item.Id];
    //   }
    //   const keys = Object.keys(this.expends);
    //   if (!keys.length) this.showItems = [];
    //   else {
    //     this.showItems = this.expends[keys[keys.length - 1]];
    //   }
    //   console.log(this.expends, keys, this.showItems);
    // },
    expended(e) {
      if (e.value) {
        this.showItems = e.item.Children.map((e) => e.Id);
      } else {
        // Ищем родителя элемента
        DataHelper.treeListTraverse(this.data, (item, index, parant) => {
          if (item.Id === e.item.Id) {
            if (parant === null) {
              this.showItems = [];
            } else {
              this.showItems = parant.Children.map((e) => e.Id);
            }
            return true;
          }
        });
      }
    },
  },
};
</script>
<style lang="scss">
.analysis-tfoms__progress {
  .v-progress-linear {
    text-overflow: ellipsis;

    overflow: hidden;
    white-space: nowrap;
  }
  td {
    border-bottom: unset !important;
    height: 32px !important;
  }

  .td2 {
    text-overflow: ellipsis;
    white-space: pre;
    padding: 0 !important;
    overflow: hidden;
  }

  &--active {
    opacity: 1;

    td {
      color: var(--v-primary-base) !important;
    }
  }
}
</style>
