<template>
  <v-combobox
    v-bind="$attrs"
    v-on="$listeners"
    item-value="Id"
    :clearable="clearable"
    deletable-chips
    append-icon="$ToolArrowDown"
    @change="handlerChange"
    class="m__combobox"
    ref="vcombobox"
  >
    <template
      v-if="$attrs.hasOwnProperty('multiple') || $attrs.hasOwnProperty('chips')"
      #selection="{ item, parent, index }"
    >
      <slot name="selection" v-bind="{ item, parent, index }">
        <v-chip
          :class="{ 'primary--text': chipSelectItem === item || !chipSelect }"
          @click:close="parent.selectItem(item)"
          :color="typeof chipColor === 'function' ? chipColor(item) : chipColor"
          :close="parent.multiple && clearable"
          @click.stop="clickChip($event, item)"
          class="m__combobox__chip"
        >
          <span class="m__combobox__chip__text">{{
            parent.getText(item)
          }}</span>
        </v-chip>
      </slot>
    </template>
  </v-combobox>
</template>

<script>
export default {
  props: {
    chipSelect: Boolean,
    clearable: {
      type: Boolean,
      default: true,
    },
    chipColor: {
      type: [String, Function],
      default: "#E8ECFF",
    },
  },
  data: () => ({
    chipSelectItem: null,
  }),
  mounted() {
    this.myDynamicWatcher = this.$watch(
      () => this.$refs.vcombobox.isMenuActive,
      (val) => {
        this.$emit("menuActive", {
          value: val,
          elem: this.$refs.vcombobox,
        });
        this.$store.commit("globalOverlay/SET", val);
      }
    );
  },
  methods: {
    clickChip(event, item) {
      if (!this.chipSelect) return;
      event.stopPropagation();
      this.chipSelectItem = item;
      this.$emit("chipChange", item);
    },
    handlerChange(val) {
      if (!this.chipSelect) return;

      if (!this.chipSelectItem) {
        this.chipSelectItem = val[0];
      } else if (!val || !val.length) this.chipSelectItem = null;
      this.$emit("chipChange", this.chipSelectItem);
    },
  },
};
</script>

<style lang="sass">
.m__combobox__chip__text
  max-width: 100vw
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden

.v-combobox__content
  max-height: 80vh !important
</style>
