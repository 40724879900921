<template>
  <base-popup
    title="Выбрать показатели оценки качества"
    :icon="UtilGetIcon('view-AuditIndicators')"
    @save="save"
    v-model="dialog"
    :width="width"
    :height="height"
    buttonSaveText="Применить"
  >
    <div class="audit-indicator-modal">
      <base-list-view
        title="Показатели оценки качества"
        :dataSource="indicators"
        :headers="headers"
        :showDelete="false"
        :notShowActions="['add']"
        :search.sync="search"
        :selectedItems="selectedIndicators"
        @input="(value) => (this.selectedIndicators = value)"
        :sort-by.sync="sortBy"
      >
      </base-list-view>
    </div>
  </base-popup>
</template>

<script>
import BaseListView from "@/layouts/BaseListView.vue";
import BasePopup from "@/layouts/BasePopup.vue";
import AnalysisIndicatorService from "../../services/AnalysisIndicatorService";
import AuditIndicatorService from "../../services/AuditIndicatorService";
import AnalysisSettingsService from "../../services/AnalysisSettingsService";

export default {
  name: "view-TaskAdd1",
  components: {
    BasePopup,
    BaseListView,
  },
  props: {
    value: Boolean,
    width: {
      type: String,
      default: "80vw",
    },
    height: {
      type: String,
      default: "80vh",
    },
    selectedAuditIndicators: {
      type: Array,
      default: () => [],
    },
  },
  data: () => {
    return {
      apiService: AnalysisIndicatorService,
      indicators: [],
      selectedIndicators: [],
      search: "",
      headers: [{ text: "Название", value: "Name" }],
      sortBy: ["Name"],
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    dialog(visible) {
      if (visible) {
        this.search = null;
        this.loadIndicators();
      }
    },
  },
  methods: {
    save() {
      this.dialog = false;
      if (!this.selectedIndicators.length) this.$emit("save", []);
      else this.$emit("save", this.selectedIndicators);
    },

    async loadIndicators() {
      try {
        this.indicators = (await AuditIndicatorService.get(true, true)).data;
        const indicatorsFromSettings = (
          await AnalysisSettingsService.getAuditIndicators()
        ).data;

        if (this.selectedAuditIndicators.length) {
          this.selectedIndicators = this.selectedAuditIndicators.slice();
        } else {
          if (indicatorsFromSettings && !indicatorsFromSettings.length) {
            this.selectedIndicators = [];
          } else {
            this.selectedIndicators = indicatorsFromSettings.map((a) => ({
              Id: a.Id,
              Name: a.Name,
            }));
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="sass">
.audit-indicator-modal .v-data-table__wrapper
  height: 35vh
</style>
