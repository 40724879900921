var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":420,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.itemCount && (_vm.itemCount.Count > 0 || _vm.fullName === 'total')),expression:"itemCount && (itemCount.Count > 0 || fullName === 'total')"}],staticClass:"notification__point",class:{ 'notification__point--active': _vm.notSmall },staticStyle:{"font-size":"0.8em"},attrs:{"fab":"","color":"primary","dark":"","x-small":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.itemCount ? _vm.itemCount.Count : 0)+" ")])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[(_vm.itemCount)?_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"452","loading":_vm.loading}},[_c('v-toolbar',{staticClass:"mb-2",attrs:{"height":"46px"}},[_c('m-icon',{staticClass:"mr-4",attrs:{"icon":"medexpert-notifications","active":""}}),_c('v-toolbar-title',{style:({
          fontSize: '16px',
          fontWeight: _vm.itemCount.Count ? '600' : '500',
        })},[_vm._v(" Уведомления "),(_vm.itemCount.Count)?_c('span',{staticClass:"primary--text"},[_vm._v(" ("+_vm._s(_vm.itemCount.Count)+") ")]):_vm._e()]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.menu = false}}},[_c('m-icon',{attrs:{"color":"primary","icon":"mdi-close"}})],1)],1),_c('div',{staticClass:"body"},[_c('v-list',{staticClass:"pt-0",attrs:{"two-line":""}},[(false)?_c('v-divider'):_vm._e(),_vm._l((_vm.categorizedItems),function({ label, items, button }){return _c('div',{key:label},[(items.length)?_c('v-subheader',{staticClass:"d-flex justify-space-between align-center",staticStyle:{"color":"var(--v-text-lighten1)","font-size":"14px","font-weight":"600"}},[_vm._v(" "+_vm._s(label)+" "),(button)?_c('v-btn',{attrs:{"text":"","color":"primary","disabled":!items.length || items[0].IsRead},on:{"click":function($event){return _vm.itemsIsRead()}}},[_vm._v(" Прочитать все ")]):_vm._e()],1):_vm._e(),(items.length)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-divider',{staticStyle:{"max-width":"415px"}})],1):_vm._e(),_vm._l((items),function(item){return _c('v-list-item',{key:item.Id,on:{"click":function($event){return _vm.clickItem(item)}}},[_c('v-list-item-avatar',{staticClass:"mr-0 rounded-0"},[_c('NotificationIcon',{attrs:{"notificationItem":item,"color":!item.IsRead ? 'primary' : 'disabled'}})],1),_c('v-list-item-content',[_c('v-list-item-title',{class:{
                  'font-weight-bold': !item.IsRead,
                  'truncate-text': true,
                }},[_vm._v(" "+_vm._s(item.Header))]),_c('v-list-item-subtitle',{class:{
                  'font-weight-bold': !item.IsRead,
                  'truncate-text': true,
                },staticStyle:{"color":"var(--v-text-primary) !important"}},[_vm._v(" "+_vm._s(item.Text)+" ")]),_c('v-list-item-subtitle',{staticClass:"notification__date"},[_vm._v(" "+_vm._s(_vm._f("DateNear")(item.Date))+" ")])],1)],1)})],2)}),(!_vm.loading && _vm.fullName === 'total')?_c('v-list-item',[_c('v-btn',{staticClass:"my-1",attrs:{"block":"","depressed":"","loading":_vm.loadingMore},on:{"click":_vm.more}},[_vm._v(" Показать еще ")])],1):_vm._e()],2)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }