<template>
  <base-popup
    v-model="dialog"
    :icon="UtilGetIcon('view-Equipments')"
    title="Изделия и оборудование по стандарту оснащения"
    :hideSave="readOnly"
    :width="width"
    :height="height"
    @save="$emit('save')"
  >
    <span class="caption">{{ standart.Name }}</span>
    <base-table :headers="headers" :items="standart.Records" notShowSelect>
      <template #item.Equipments="{ item }">
        <m-autocomplete
          v-if="!readOnly"
          v-model="item.Equipments"
          :items="item.SourceEquipments"
          :item-text="(eq) => eq.Name"
          @change="changedLinks(item)"
          multiple
          return-object
        ></m-autocomplete>
        <div v-else>
          {{ item.Equipments.map((eq) => eq.Name).join(", ") }}
        </div>
      </template>
    </base-table>
  </base-popup>
</template>

<script>
import _ from "lodash";
import MAutocomplete from "../base/inputs/mAutocomplete.vue";
import BasePopup from "../../layouts/BasePopup.vue";
import BaseTable from "../base/BaseTable.vue";

export default {
  name: "view-PartitionStandartLinksEdit",
  components: { BasePopup, BaseTable, MAutocomplete },
  props: {
    value: Boolean,
    standart: Object,
    readOnly: Boolean,
    width: String,
    height: String,
  },
  data: () => {
    return {
      headers: [
        { text: "№", value: "Num", width: "1%", sortable: false },
        { text: "Название", value: "Name" },
        { text: "Количество", value: "Quantity" },
        {
          text: "Изделия и оборудование",
          value: "Equipments",
        },
      ],
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    // Изменение списка доступных изделий при выборе/отмене в записи стандарта оснащения
    changedLinks(item) {
      // Измененное изделие
      let tmp = _.xor(item.OldEquipments, item.Equipments);
      tmp = tmp.length ? tmp[0] : null;
      if (!tmp) return;

      // Изделие добавлено
      if (item.OldEquipments.indexOf(tmp) === -1) {
        item.OldEquipments.push(tmp);
        for (const record of this.standart.Records) {
          if (record === item) continue;
          const index = record.SourceEquipments.indexOf(tmp);
          if (index >= 0) record.SourceEquipments.splice(index, 1);
        }

        // Изделие удалено
      } else {
        item.OldEquipments.splice(item.OldEquipments.indexOf(tmp), 1);
        for (const record of this.standart.Records) {
          if (record === item) continue;
          record.SourceEquipments.push(tmp);
        }
      }
    },
  },
};
</script>
