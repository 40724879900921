<template>
  <v-layout>
    <base-detail-view
      :loading="loading"
      :title="dataSource.Title"
      class="news-edit"
      :showSave="false"
      :closeBtnTitle="'Закрыть'"
    >
      <template slot="btn-close" slot-scope="{ click }">
        <v-btn slot="action" color="primary" class="ml-3" @click="click">
          {{ "Закрыть" }}
        </v-btn>
      </template>
      <word-press-viewer
        v-if="dataSource.WebId"
        :postId="dataSource.WebId"
        type="news"
        @getData="dataSourceWp = $event"
      />
      <div
        v-else
        class="news__item"
        v-for="(news, i) in dataSource.Content"
        :key="i"
      >
        <div class="news__item-title" v-html="news.Title"></div>
        <div class="news__item-body" v-html="news.Text" />
      </div>
    </base-detail-view>
  </v-layout>
</template>

<script>
import NewsService from "@/services/NewsService";
import WordPressViewer from "@/components/WordPress/WordPressViewer.vue";
import BaseDetailView from "@/layouts/BaseDetailView.vue";
import CreateAndEditMixin from "@/mixins/CreateAndEditMixin";
export default {
  name: "view-NewsEdit",
  components: {
    BaseDetailView,
    WordPressViewer,
  },
  mixins: [CreateAndEditMixin],
  data: function () {
    return {
      apiService: NewsService,
      dataSourceWp: {},
    };
  },
  async mounted() {
    await this.init();
    for (let i = 0; i < this.dataSource.Content.length; i++) {
      this.dataSource.Content[i].Title = this.dataSource.Content[
        i
      ].Title.replaceAll("\r\n", "</br>");

      this.dataSource.Content[i].Text = this.dataSource.Content[
        i
      ].Text.replaceAll("\r\n", "</br>");
    }
    this.readNews();
  },

  methods: {
    async readNews() {
      if (this.dataSource.Read) return false;
      await NewsService.read(this.dataSource.Id);
      this.$emit("readNews", this.dataSource.Id);
      this.$set(this.dataSource, "Read", true);
    },
  },
};
</script>

<style lang="scss">
.news {
  &__item {
    &:not(:last-child) {
      margin-bottom: 1.8rem;
    }
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      font-size: 18px;
      line-height: 158%;
      letter-spacing: -0.01em;
      color: var(--v-text-base);
    }
  }
}
</style>
