<template>
  <base-list-view
    :title="
      $store.state.Organization.OwnerType === 2
        ? 'Дочерние организации'
        : 'Головные организации'
    "
    @onRefresh="init"
    :dataSource="dataSource"
    :headers="headers"
    :loading="loading"
    :notShowActions="['delete', 'search', 'add']"
    notShowSelect
    :showFilters="true"
  >
  </base-list-view>
</template>

<script>
import ListEditMixin from "@/mixins/ListEditMixin";
import OrganizationService from "@/services/OrganizationService";
import BaseListView from "../../layouts/BaseListView.vue";

export default {
  name: "view-Organizations",
  components: {
    BaseListView,
  },

  mixins: [ListEditMixin],
  data() {
    return {
      apiService: OrganizationService.relatedOrganizations,
      loading: true,
      headers: [
        { text: "Наименование", value: "Name", notGroup: true, width: "50%" },
        { text: "Адрес", value: "LegalAddress" },
      ],

      dataSource: [],
    };
  },

  created() {
    this.init();
  },
  methods: {},
};
</script>
