import Vue from "vue";

const CheckOperationsNormalize = (CheckOperations) => {
  // Добовление поля objectCkeck
  for (let i = 0; i < CheckOperations.length; i++) {
    const item = CheckOperations[i];
    CheckOperations[i].objectCheck = item.CustomObject
      ? item.CustomObject
      : item.EmployeeObject
      ? Vue.options.filters.PersonShortName(item.EmployeeObject)
      : item.PartitionObject
      ? item.PartitionObject.Name
      : item?.CheckList?.Type === 0
      ? ""
      : "Не выбрано";
  }
  return CheckOperations;
};

export { CheckOperationsNormalize };
