<template>
  <base-list-view
    :dataSource="dataSource"
    :headers="headers"
    :loading="loading"
    @onRefresh="init"
    :customInplaceEdit="customInplaceEdit"
    @customDelete="customDelete"
    :editIndex.sync="editIndex"
    :defaultObject="defaultObject"
    show-actions
    :editable="(item) => !readOnly && (item.Id <= 0 || !item.IsSystem)"
    :showDelete="false"
    :disabledAdd="readOnly"
    title="Типы прикладных документов"
    label-text-btn-create="Добавить тип документов"
    label-text-search="Поиск типа документов"
    add-in-table
    notShowSelect
    :showFilters="true"
  >
    <template #item.Name="scope">
      <v-text-field
        v-if="scope.rowEditing && scope.item.Id === scope.rowEditing.Id"
        :value="scope.rowEditing.Name"
        @change="scope.rowEditing.Name = $event"
        v-focusInput="scope.closeEdit"
        placeholder="Название"
        v-up-first-symbol
      ></v-text-field>
      <span v-else>{{ scope.item.Name }}</span>
    </template>
    <template #item.Object="scope">
      <m-select
        v-if="scope.rowEditing && scope.item.Id === scope.rowEditing.Id"
        v-model="scope.rowEditing.Object"
        placeholder="Объект"
        :items="
          objectTypes.map((elem) => {
            return { Id: elem.id, value: elem.value };
          })
        "
      ></m-select>
      <span v-else>{{ columnEnumText(objectTypes, scope.item.Object) }}</span>
    </template>
  </base-list-view>
</template>

<script>
import AppliedDocumentTypeService from "../../services/AppliedDocumentTypeService";
import { AppliedDocumentTypeObject } from "../../data/enums";
import { defaultAppliedDocumentType } from "../../data/defaultObjects";
import Permissions from "../../data/permissions";
import BaseListView from "../../layouts/BaseListView.vue";
import MSelect from "../../components/base/inputs/mSelect.vue";
import ListEditMixin from "../../mixins/ListEditMixin";

export default {
  name: "view-AppliedDocumentTypes",
  components: {
    BaseListView,
    MSelect,
  },
  mixins: [ListEditMixin],
  data: function () {
    return {
      loading: true,
      apiService: AppliedDocumentTypeService,
      defaultObject: defaultAppliedDocumentType,
      headers: [
        { text: "Название", value: "Name", width: "50%" },
        {
          text: "Объект",
          value: "Object",
          dataType: "enum",
          options: AppliedDocumentTypeObject,
        },
        {
          text: "Системный",
          value: "SystemText",
          align: "center",
          cellClass: "td-minimum td5 text-no-wrap",
        },
      ],
      objectTypes: AppliedDocumentTypeObject,
      dataSource: [],
      readOnly: !this.hasPermission(Permissions.AppliedDocumentTypesEdit),
      editIndex: null,
    };
  },
  async mounted() {
    await this.init();
  },
  methods: {
    DataFromServerNormalize(data) {
      data.forEach((item) => {
        item.SystemText = item.IsSystem ? "Да" : "";
      });
      return data;
    },
    async customInplaceEdit(value) {
      if (!value) return;
      const res = (
        await AppliedDocumentTypeService[value.Id <= 0 ? "create" : "update"](
          value
        )
      ).data;
      return res;
    },
    async customDelete(value) {
      if (!value) return;
      if (value.Id > 0) await AppliedDocumentTypeService.delete(value.Id);
      const index = this.dataSource.findIndex((item) => item.Id === value.Id);
      if (index >= 0) this.dataSource.splice(index, 1);
      this.editIndex = -1;
    },
    columnEnumText(options, value) {
      return typeof options[0] === "object"
        ? options.find((el) => el.id === value)?.value
        : options[value];
    },
  },
};
</script>
