<template>
  <v-btn
    class="richViewer__fab"
    color="primary"
    dark
    fixed
    bottom
    right
    fab
    :title="textFab"
    @click="$emit('input', !value)"
  >
    <transition name="slide-fade">
      <v-alert
        v-if="fullScreenShowAlert"
        color="primary"
        :class="{ 'richViewer__fab-show': value }"
        >{{ textFab }}</v-alert
      >
    </transition>
    <m-icon :icon="!value ? 'mdi-fullscreen' : 'mdi-fullscreen-exit'" large />
  </v-btn>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fullScreenShowAlert: false,
      fullScreenCol: 0,
      timer: null,
    };
  },
  computed: {
    textFab() {
      return !this.value
        ? "Показать документ на весь экран"
        : "Свернуть документ";
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.fullScreenCol++ < 2) {
          this.showAlert();
        } else {
          this.fullScreenShowAlert = false;
        }
      },
    },
  },
  methods: {
    showAlert() {
      this.fullScreenShowAlert = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.fullScreenShowAlert = false;
      }, 4000);
    },
  },
};
</script>
<style lang="scss">
.richViewer__fab {
  z-index: 1002 !important;
  bottom: 45px !important;

  .v-alert {
    position: absolute;
    left: -352px;
    top: -14px;
    opacity: 0.7;
    border-radius: 10px;
    &.richViewer__fab-show {
      left: -222px;
    }
  }
}
</style>
