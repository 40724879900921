<template>
  <v-card flat>
    <v-card-text row>
      <div>
        <violation-table
          :items="Violations"
          :treeNames="treeNames"
          :auditOperationNum="auditOperationNum"
          :checkListName="checkListName"
          ref="violations__violationsTabele"
          type="violation"
          :readOnly="readOnly"
          @update:item="updateItem"
          :objectCheck="objectCheck"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import ViolationTable from "../../../../components/CheckList/Question/violation/ViolationTable";

export default {
  components: { ViolationTable },
  props: {
    Violations: Array,
    readOnly: {
      type: Boolean,
    },
    treeNames: Object,
    auditOperationNum: [String, Number],
    checkListName: String,
    objectCheck: String,
  },
  methods: {
    updateItem(item) {
      const i = this.Violations.findIndex((e) => e.Id === item.Id);
      this.$set(this.Violations, i, item);
    },
  },
};
</script>

<style></style>
