<template>
  <base-list-view
    :dataSource="files"
    :headers="headers"
    :default-object="defaultFile"
    text-custom-add="Добавить файл"
    isNested
    show-actions
    editable
    header-hidden
    hide-default-footer
    notShowSelect
    :show-add-in-table="!readonly"
    :hideDelete="readonly"
    :hideEdit="true"
    @customAdd="customAdd"
  >
    <template slot="item.Name" slot-scope="scope">
      <v-file-input
        v-if="scope.rowEditing && scope.item.Id === scope.rowEditing.Id"
        outlined
        dense
        hide-details
        placeholder="Выберите файл"
        autofocus
        @change="fileChange(scope.rowEditing, $event)"
        ><template v-slot:selection="{ text }">
          <v-chip small label color="primary">
            {{ text }}
          </v-chip>
        </template></v-file-input
      >
      <span v-else>{{ scope.item.Name }}</span>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn
        v-if="item.ExternalId"
        color="'blue-grey"
        title="Скачать"
        icon
        download
        :href="`${apiUrl}common/GetFile?externalId=${item.ExternalId}`"
      >
        <m-icon icon="tool-download" s16></m-icon>
      </v-btn>
    </template>
    <v-file-input
      class="d-none"
      placeholder="Выберите файл"
      @change="fileAdd($event)"
      ref="file"
    ></v-file-input>
  </base-list-view>
</template>

<script>
import BaseListView from "../../layouts/BaseListView.vue";
import { defaultFile } from "../../data/defaultObjects";

export default {
  name: "view-loadFiles",
  components: {
    BaseListView,
  },
  props: {
    files: Array,
    readonly: Boolean,
  },
  data: () => ({
    headers: [{ text: "Прикрепленные файлы", value: "Name" }],
  }),
  computed: {
    apiUrl() {
      // Полный путь к url должен быть именно в компоненте, а не в шаблоне.
      return process.env.VUE_APP_SERVER_URL;
    },
  },
  methods: {
    defaultFile,
    customAdd(key) {
      this.$refs.file.$refs.input.click();
    },
    async fileChange(item, file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => (item.Base64Content = reader.result);
        item.Name = file.name;
      }
    },
    async fileAdd(file) {
      if (file) {
        const newFile = defaultFile();
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => (newFile.Base64Content = reader.result);
        newFile.Name = file.name;

        this.$emit("addFile", newFile);
        this.$refs.file.$refs.input.value = null;
      }
    },
  },
};
</script>

<style></style>
