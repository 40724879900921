<template>
  <base-table-expanded :colspan="$attrs['headers'].length">
    <v-row>
      <v-col>
        <table>
          <tr>
            <td>Название:</td>
            <td>
              {{ Project.Name }}
            </td>
          </tr>
          <tr>
            <td>
              <div>Описание:</div>
            </td>
            <td>
              {{ Project.Content }}
            </td>
          </tr>
          <tr>
            <td>
              <div>Руководитель:</div>
            </td>
            <td>
              {{ $options.filters.PersonShortName(Project.Responsible) }}
            </td>
          </tr>
          <tr>
            <td>
              <div>Авторы:</div>
            </td>
            <td>
              {{ authors }}
            </td>
          </tr>
        </table>
      </v-col>
      <v-col>
        <table>
          <tr>
            <td>
              <div>Дата начала:</div>
            </td>
            <td>
              <div>{{ Project.DateIn | formatDate }}</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Дата окончания:</div>
            </td>
            <td>
              <div>{{ Project.DateOut | formatDate }}</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Дата фактического завершения:</div>
            </td>
            <td>
              <div>{{ Project.Done | formatDate }}</div>
            </td>
          </tr>
        </table>
      </v-col>
    </v-row>
  </base-table-expanded>
</template>

<script>
import BaseTableExpanded from "@/layouts/BaseTableExpanded";
import DataHelper from "@/utils/DataHelper";

export default {
  components: {
    BaseTableExpanded,
  },
  filters: {
    formatDate: function (value) {
      return DataHelper.toDate(value)?.toLocaleDateString();
    },
  },
  props: {
    Project: Object,
  },
  computed: {
    authors() {
      return this.Project.Authors.map((a) =>
        this.$options.filters.PersonShortName(a)
      ).join(", ");
    },
  },
};
</script>

<style></style>
