<template>
  <base-detail-view
    class="analysis-tfoms"
    :loading="loading"
    :title="'Анализ информации ТФОМС'"
    :isUnsaved="isUnsaved && valid"
    @onRefresh="init(queryParams)"
    :showSave="false"
    fillHeader
  >
    <template slot="title">
      <div class="v-toolbar__title d-flex align-center">
        Анализ информации ТФОМС
        <div class="caption mt-2 ml-5 d-flex align-center">
          <div class="mr-2 mb-1">Организация:</div>
          <m-autocomplete
            v-if="ownerViewAll"
            :items="owners"
            v-model="owner"
            placeholder="Собственник"
            item-text="Name"
            item-value="Id"
            @input="
              queryParams = { ...queryParams, ownerId: owner };
              update(queryParams);
            "
            style="width: 400px"
          ></m-autocomplete>
        </div>
      </div>
    </template>
    <v-btn v-if="false" slot="action" color="primary" class="ml-3">
      Настройка показателей
    </v-btn>
    <tfoms-header
      v-if="
        $refs['tfoms' + (this.tab + 1)] &&
        $refs['tfoms' + (this.tab + 1)].dataSource
      "
      v-model="tab"
      @update="update"
      :unit.sync="unit"
      :rub.sync="rub"
      :dataSource="$refs['tfoms' + (this.tab + 1)].dataSource"
      @update:year="updateYear"
      @update:dateType="updateDateType"
    ></tfoms-header>
    <tfoms-select-period
      v-if="false"
      @update="update"
      :unit.sync="unit"
      :rub.sync="rub"
    ></tfoms-select-period>

    <v-window v-model="tab">
      <v-window-item>
        <tfoms1
          ref="tfoms1"
          :unit.sync="unit"
          :rub.sync="rub"
          @updateParams="queryParams = { ...$event }"
          @updatedetailParams="detailParams = { ...$event }"
        ></tfoms1>
      </v-window-item>
      <v-window-item>
        <tfoms2 ref="tfoms2" :unit.sync="unit" :rub.sync="rub"></tfoms2>
      </v-window-item>
      <v-window-item>
        <tfoms3 ref="tfoms3" :unit.sync="unit" :rub.sync="rub"></tfoms3>
      </v-window-item>
    </v-window>
  </base-detail-view>
</template>

<script>
import UnsavedMixin from "@/mixins/UnsavedMixin.js";
import CreateAndEditMixin from "@/mixins/CreateAndEditMixin.js";
import Permissions from "@/data/permissions";
import TFormService from "@/services/TFormService";

import BaseDetailView from "@/layouts/BaseDetailView.vue";
import tfomsHeader from "./components/header";
import tfomsSelectPeriod from "./components/tfomsSelectPeriod";
import tfoms1 from "./tabs/tfoms1.vue";
import tfoms2 from "./tabs/tfoms2.vue";
import tfoms3 from "./tabs/tfoms3.vue";

export default {
  name: "view-AnalysisTform",
  components: {
    BaseDetailView,
    tfomsHeader,
    tfomsSelectPeriod,
    tfoms1,
    tfoms2,
    tfoms3,
    mAutocomplete: () => import("@/components/base/inputs/mAutocomplete"),
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  provide() {
    const provideUnits = {};
    const queryParams = {};
    const detailParams = {};

    Object.defineProperty(provideUnits, "rub", {
      enumerable: true,
      get: () => this.rub,
    });

    Object.defineProperty(provideUnits, "unit", {
      enumerable: true,
      get: () => this.unit,
    });

    Object.defineProperty(queryParams, "params", {
      enumerable: true,
      get: () => this.queryParams,
    });

    Object.defineProperty(detailParams, "params", {
      enumerable: true,
      get: () => this.detailParams,
    });

    return {
      provideUnits,
      queryParams,
      detailParams,
    };
  },
  data() {
    return {
      loading: true,
      tab: 0,
      unit: null,
      rub: null,
      queryParams: { year: 2024, meeEkmpDateType: 2 },
      detailParams: {},
      ownerViewAll: this.hasPermission(Permissions.OwnerViewAll),
      owners: [],
      owner: this.hasPermission(Permissions.OwnerViewAll)
        ? parseInt(window.localStorage.getItem("tfoms_owner_id", null))
        : null,
    };
  },

  watch: {
    owner(val) {
      window.localStorage.setItem("tfoms_owner_id", val);
    },
  },
  async mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);

    if (this.ownerViewAll) {
      const { data } = await TFormService.getOwners();
      this.owners = data;
    }

    if (this.owner || this.owner === 0)
      this.queryParams = { ...this.queryParams, ownerId: this.owner };
    await this.init(this.queryParams);
  },

  methods: {
    updateYear(val) {
      this.queryParams = { ...this.queryParams, year: val };
      this.update(this.queryParams);
    },
    updateDateType(val) {
      this.queryParams = { ...this.queryParams, meeEkmpDateType: val };
      this.update(this.queryParams);
    },
    update(e) {
      this.init(e);
    },

    async init(e) {
      await this.$refs["tfoms" + (this.tab + 1)].init(e);
    },
  },
};
</script>

<style lang="scss">
.analysis-tfoms {
  .base-panel__body {
    padding-top: 20px !important;
  }
}
.analysis-tfom__icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 37px;
  background-color: #e8ecff;
}

.analysis-tfoms__table {
  tbody {
    td {
      height: 32px !important;
    }
  }
}

.analysis-tfoms__base-table.analysis-tfoms__table-total {
  thead {
    tr:first-child {
      height: 20px !important;
      th {
        height: 20px !important;
      }
    }
    tr:last-child {
      height: 20px !important;
      th {
        height: 20px !important;
      }
    }
  }
  tbody {
    tr:last-child {
      font-weight: bold;
    }
  }
}

.analysis-tfoms__base-table {
  thead {
    background-color: #e8ecff !important;
    tr {
      height: 36px !important;
      background-color: #e8ecff !important;
    }
    th {
      vertical-align: bottom;
      background-color: #e8ecff !important;
    }

    tr:first-child {
      th {
        box-shadow: unset !important;
      }
    }

    tr:first-child {
      height: 36px !important;
      th {
        height: 36px !important;
        vertical-align: middle;
      }
    }
    tr:last-child {
      height: 36px !important;
      th {
        top: 0 !important;
        height: 36px !important;
      }
    }
  }
}
</style>
