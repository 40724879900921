<template>
  <div>
    <recommendation-table
      v-bind="$attrs"
      isOffer
      text-add="Добавить предложение"
      text-placeholder="Введите предложение"
      hide-select
    >
      <template slot="expansionAction" slot-scope="{ item }">
        <task-one-info :task.sync="item.CheckTask" :item="item" :type="1" />
      </template>
    </recommendation-table>
  </div>
</template>

<script>
import TaskOneInfo from "../../../task/TaskOneInfo.vue";
import RecommendationTable from "./RecommendationTable.vue";

export default {
  name: "view-violation-table",
  components: { RecommendationTable, TaskOneInfo },
};
</script>
