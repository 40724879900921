<template>
  <div>
    <div class="d-flex">
      <panel-filter
        :items="data"
        :value="value"
        @input="$emit('input', $event)"
      >
        <template slot="panelFilterPrepend">
          <slot name="panelFilterPrepend"></slot>
        </template>
      </panel-filter>
      <div class="ml-5" style="flex: 1">
        <v-row>
          <v-col>
            <v-row>
              <v-col
                v-for="indicator in dataSource"
                :key="indicator.Id"
                lg="6"
                sm="12"
              >
                <kibmd-info-chart-with-table
                  :statisticIndicator="indicator"
                  :isRefreshing="loading"
                ></kibmd-info-chart-with-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import KibmdService from "@/services/KibmdService";
import kibmdTab from "../mixins/kibmdTab";
export default {
  name: "view-AnalysisKibmd-tab2",
  components: {
    kibmdInfoChartWithTable: () =>
      import("../components/kibmdInfoChartWithTable.vue"),
    panelFilter: () => import("@/components/base/panelFilter"),
  },
  mixins: [kibmdTab],
  props: {
    value: { type: Array, default: null },
  },
  data() {
    return {
      dataSource: [],
      loading: true,
      data: [
        {
          title: "Показатели",
          items: [],
          moreText: "видов НС",
        },
        {
          title: "Медицинские организации",
          items: [],
          moreText: "МО",
        },
        {
          title: "Подразделения",
          items: [],
          moreText: "Подр.",
        },
      ],
    };
  },
  mounted() {
    this.init(this.queryParams.params);
    this.initFilter();
  },

  methods: {
    async init(e) {
      this.loading = true;

      const { data } = await KibmdService.kibmd2.get(e);
      this.dataSource = data;
      this.loading = false;
    },

    async initFilter() {
      const names = (await KibmdService.kibmd2.getFilterData()).data;

      this.data[0].items =
        names.StatisticIndicators?.map((e) => ({
          id: e.Id,
          value: e.Name,
        })) || [];

      this.data[1].items =
        names.Organizations?.map((e) => ({
          id: e.Id,
          value: e.Name,
        })) || [];

      this.data[2].items =
        names.Partitions?.map((e) => ({
          id: e.Id,
          value: e.Name,
        })) || [];
    },
  },
};
</script>
<style lang="scss">
.kibmd-tab2 {
  &__card {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &__table {
    .v-data-table {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }
}
</style>
