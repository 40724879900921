import Api from "./Api";
import qs from "qs";

export default {
  async get(
    dateIn,
    dateOut,
    checkListIds,
    status,
    isMember = false,
    full = false,
    external = false
  ) {
    return Api().get("auditOperation/getAuditOperations", {
      params: {
        dateIn,
        dateOut,
        checkListIds,
        status,
        isMember,
        full,
        external,
      },
      paramsSerializer: function (params) {
        return qs.stringify(params);
      },
    });
  },
  async show(id) {
    return Api().get("auditOperation/getAuditOperation", { params: { id } });
  },
  async create(auditOperation) {
    return Api().post("auditOperation/CreateAuditOperation", auditOperation);
  },
  async update(auditOperation) {
    return Api().put("auditOperation/UpdateAuditOperation", auditOperation);
  },
  async delete(id) {
    return Api().delete("auditOperation/DeleteAuditOperation", {
      params: { id },
    });
  },
  async getAuditMemberOperations(id) {
    return Api().get("auditOperation/getAuditMemberOperations", {
      params: { auditMemberId: id },
    });
  },
  async updateAuditMember(memberId, employeeId) {
    return Api().put("auditOperation/updateAuditMember", {
      id: memberId,
      employeeId,
    });
  },
  async getAuditIndicators() {
    return Api().get("auditOperation/GetAuditIndicators", {
      params: { onlyUserData: true },
    });
  },

  async getAuditOperationNum(year) {
    return Api().get("auditOperation/GetAuditOperationNum", {
      params: { year },
    });
  },

  updateStatus(AuditId, Action, EmployeeId) {
    return Api().put("auditOperation/updateAuditOperationStatus", {
      AuditId,
      Action,
      EmployeeId,
    });
  },
};
