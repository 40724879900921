<template>
  <div>
    <div class="d-flex">
      <panel-filter
        :items="data"
        :value="value"
        @input="$emit('input', $event)"
      >
        <template slot="panelFilterPrepend">
          <slot name="panelFilterPrepend"></slot>
        </template>
      </panel-filter>
      <div class="ml-5" style="flex: 1">
        <div class="d-flex">
          <div style="width: 355px; height: 281px">
            <panel
              :subTitle="labels[0]"
              small
              title="Общее количество нежелательных событий"
              hideIcon
              style="height: 100%"
            >
              <div
                v-if="dataSource.MonthValues"
                style="
                  font-size: 48px;
                  line-height: 158%;
                  font-weight: 500;
                  color: var(--v-blue-grey-base);
                  margin-top: 33px;
                  text-align: center;
                "
              >
                {{ dataSource.MonthValues.reduce((sum, e) => sum + e, 0) }}
                <span style="font-size: 24px">случаев</span>
              </div></panel
            >
          </div>
          <div style="flex: 1" class="ml-4">
            <tfoms-finance
              v-if="dataSource.MonthValues"
              title="Динамика  нежелательных событий за  год"
              :subTitle="labels[0]"
              hideIcon
              :data="dataSource.MonthValues.map((e) => [e])"
              :factColor="'var(--v-primary-base)'"
              labelFact="Нежелательные события"
              @itemClick="itemClick"
              :hideDatalabel="false"
            >
            </tfoms-finance>
          </div>
        </div>

        <div class="mt-4">
          <m-tabs v-model="tab" :slider-size="2" :with-route="false">
            <v-tab key="Organizations">Медицинские организации</v-tab>
            <v-tab key="Types">Виды нежелательных событий</v-tab>
          </m-tabs>

          <v-window :value="tab" class="mt-2">
            <v-window-item>
              <v-row>
                <v-col>
                  <panel
                    title="Медицинские организации"
                    :subTitle="labels[0]"
                    small
                    hide-icon
                  >
                    <kibmd-table
                      v-model="selectOrganization"
                      :data="dataSource.OrganizationsValues"
                      :MonthsCount="dataSource.MonthsCount"
                      :month="month ? months[month - 1] : null"
                    ></kibmd-table>
                  </panel>
                </v-col>
                <v-col>
                  <panel
                    title="Виды нежелательных событий"
                    :subTitle="labels[0]"
                    :labels="[
                      ...(selectOrganization
                        ? [selectOrganization.Object]
                        : []),
                      ...(month ? [months[month - 1]] : []),
                    ]"
                    small
                    hide-icon
                    :isClickLabel="false"
                  >
                    <kibmd-table
                      :data="TypesValues"
                      :type="2"
                      :MonthsCount="dataSource.MonthsCount"
                      :month="month ? months[month - 1] : null"
                    ></kibmd-table>
                  </panel>
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item>
              <v-row>
                <v-col>
                  <panel
                    title="Виды нежелательных событий"
                    :subTitle="labels[0]"
                    small
                    hide-icon
                  >
                    <kibmd-table
                      v-model="selectType"
                      :data="dataSource.TypesValues"
                      :type="2"
                      :MonthsCount="dataSource.MonthsCount"
                      :month="month ? months[month - 1] : null"
                    ></kibmd-table>
                  </panel>
                </v-col>
                <v-col>
                  <panel
                    title="Медицинские организации"
                    :subTitle="labels[0]"
                    :labels="[
                      ...(selectType ? [selectType.Type] : []),
                      ...(month ? [months[month - 1]] : []),
                    ]"
                    small
                    hide-icon
                    :isClickLabel="false"
                  >
                    <kibmd-table
                      :data="OrganizationsValues"
                      :MonthsCount="dataSource.MonthsCount"
                      :month="month ? months[month - 1] : null"
                    ></kibmd-table>
                  </panel>
                </v-col>
              </v-row>
            </v-window-item>
          </v-window>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import KibmdService from "@/services/KibmdService";
import tfomsFinance from "@/views/analysis/tfoms/components/tfomsFinance";
import { groupBy, reduce } from "lodash";

import mTabs from "@/components/base/tabs/mTabs";
import kibmdTab from "../mixins/kibmdTab";
export default {
  name: "view-AnalysisKibmd-tab3",
  components: {
    panelFilter: () => import("@/components/base/panelFilter"),
    panel: () => import("@/components/analysis/panel.vue"),
    kibmdTable: () => import("../components/kibmdTable.vue"),
    tfomsFinance,
    mTabs,
  },
  mixins: [kibmdTab],
  props: {
    value: { type: Array, default: null },
  },
  data() {
    return {
      tab: 0,
      dataSource: [],
      dataSource2: { OrganizationsValues: [] },
      loading: true,
      data: [
        {
          title: "Виды нежелательных событий",
          items: [],
          moreText: "видов НС",
        },
        {
          title: "Медицинские организации",
          items: [],
          moreText: "МО",
        },
        {
          title: "Подразделения",
          items: [],
          moreText: "Подр.",
        },
      ],
      selectOrganization: null,
      selectType: null,
      TypesValues: [],
      OrganizationsValues: [],
      months: [
        "янв",
        "фев",
        "март",
        "апр",
        "май",
        "июнь",
        "июль",
        "авг",
        "сен",
        "окт",
        "ноя",
        "дек",
      ],

      month: null,
    };
  },
  computed: {
    labels() {
      return [this.queryParams.params.Year + " год"];
    },
    organizations() {
      const tmp = [];
      for (let i = 0; i < this.dataSource.length; i++) {
        for (let j = 0; j < this.dataSource[i].Values.length; j++) {
          tmp.push(this.dataSource[i].Values[j]);
        }
      }
      return reduce(
        groupBy(this.dataSource, (e) => e.Item3?.Id),
        function (array, children, key) {
          array.push({
            Item1: "",
            Item2: children.reduce((sum, val) => (sum += val.Item2), 0),
            Item3: children[0]?.Item3,
            Children: children.map((e) => ({
              ...e,
              Item3: e.Item1?.Name ? e.Item1 : "Без вида",
            })),
            Id: key,
          });

          return array;
        },
        []
      );
    },
  },
  watch: {
    async selectOrganization(val) {
      if (!val) {
        this.TypesValues = this.dataSource.TypesValues;
      } else {
        const { data } =
          await KibmdService.kibmd3.getUnwishedEventByTypeAnalysis({
            ...this.queryParams.params,
            ObjectId: val.Object.Id,
            ObjectType: val.Object.Type,
            Month: this.month,
          });
        this.TypesValues = data.TypesValues;
      }
    },
    async selectType(val) {
      if (!val) {
        this.OrganizationsValues = this.dataSource.OrganizationsValues;
      } else {
        const { data } =
          await KibmdService.kibmd3.getUnwishedEventByTypeAnalysis({
            ...this.queryParams.params,
            TypeId: val.Type.Id,
            Month: this.month,
          });
        this.OrganizationsValues = data.OrganizationsValues;
      }
    },
    async month(val) {
      const { data } = await KibmdService.kibmd3.get({
        ...this.queryParams.params,
        Month: val,
      });

      this.TypesValues = [...data.TypesValues];
      this.OrganizationsValues = [...data.OrganizationsValues];
      this.dataSource.TypesValues = data.TypesValues;
      this.dataSource.OrganizationsValues = data.OrganizationsValues;
    },
  },
  mounted() {
    this.init(this.queryParams.params);
    this.initFilter();
  },

  methods: {
    async init(e) {
      this.loading = true;
      this.month = null;

      const { data } = await KibmdService.kibmd3.get(e);
      this.dataSource = data;

      this.TypesValues = [...data.TypesValues];
      this.OrganizationsValues = [...data.OrganizationsValues];
      this.loading = false;
    },

    async initFilter() {
      const names = (await KibmdService.kibmd3.getFilterData()).data;

      this.data[0].items =
        names.UnwishedEventTypes?.map((e) => ({
          id: e.Id,
          value: e.Name,
        })) || [];

      this.data[1].items =
        names.Organizations?.map((e) => ({
          id: e.Id,
          value: e.Name,
        })) || [];

      this.data[2].items =
        names.Partitions?.map((e) => ({
          id: e.Id,
          value: e.Name,
        })) || [];
    },

    itemClick(index) {
      const t = index + 1;
      if (t !== this.month) this.month = t;
      else this.month = null;

      this.selectOrganization = null;
      this.selectType = null;
    },
  },
};
</script>
<style lang="scss">
.kibmd-tab2 {
  &__card {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &__table {
    .v-data-table {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }
}
</style>
