<template>
  <base-detail-view
    :loading="loading"
    title="Создание чек-листа на основе имеющихся"
    :isUnsaved="isUnsaved && valid"
    @onSave="save"
  >
    <check-list-info
      :newCheckList="dataSource"
      :valid="valid"
      @selectCheckList="checkListSelect = $event"
      @validate="valid = $event"
    ></check-list-info>
    <check-list-select
      class="mt-5"
      :checkList="checkListSelect"
      :newCheckList="dataSource"
      @input="dataSource.Questions = $event"
    ></check-list-select>
  </base-detail-view>
</template>

<script>
import CheckListInfo from "../../components/CheckList/clone/CheckListInfo.vue";
import CheckListSelect from "../../components/CheckList/clone/CheckListSelect.vue";
import { defaultCheckListCopy } from "../../data/defaultObjects";
import BaseDetailView from "../../layouts/BaseDetailView.vue";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin";
import UnsavedMixin from "../../mixins/UnsavedMixin";
import CheckListService from "../../services/CheckListService";
import DataHelper from "../../utils/DataHelper";

export default {
  name: "view-CheckListClone",
  components: {
    BaseDetailView,
    CheckListInfo,
    CheckListSelect,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  data: () => {
    return {
      // API
      apiService: CheckListService,
      getDefaultObject: defaultCheckListCopy,
      pageEditName: "CheckListEdit",

      loading: false,
      checkListSelect: null,
      dataSource: defaultCheckListCopy(),
      onlyCreate: true,
      valid: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    save() {
      DataHelper.treeListGenerateNumeration(this.dataSource.Questions, "Num");

      this.baseSave();
    },
  },
};
</script>

<style></style>
